import { Routes } from '@angular/router';

import { CustomTabComponent } from './components/custom-tab/custom-tab.component';

export const customTabRoutes: Routes = [
  {
    path: 'customtab/:tabname',
    component: CustomTabComponent
  }
];
