import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
import * as _assign from 'lodash/assign';
import { Moment } from 'moment';

import { ActivityLogSearchModel } from '../../classes/activity-log-search.model';
import { UsersService } from '../../../users/services/users/users.service';
import { UserActivityLogResource } from '../../../users/classes/user-activity-log-resource.class';
import { ActivityLogCategoriesResource } from '../../classes/activity-log-categories-resource.class';
import { ITableColumnDefinition, ISelectedRow } from '../../../design/components/table/table.component';
import { SearchActivityLogComponent } from '../search-users-activity-log/search-activity-log.component';
import { ActivityLogCategory } from '../../enums/activity-log-category.enum';
import { formatToDateTime } from '../../../shared/utils/date.util';
import { formatNumberToLocalLangFormat } from '../../../shared/utils/number.util';
import { AuthService } from '../../../auth/services/auth.service';

interface IUserActivityTableColums {
  username: string;
  date: string;
  category: string;
  details: string;
}

@Component({
  selector: 'sv-users-activity-log',
  templateUrl: './users-activity-log.component.html',
  styleUrls: ['./users-activity-log.component.scss']
})
export class UsersActivityLogComponent implements OnInit, OnDestroy,AfterViewInit {
  public searchData: ActivityLogSearchModel;
  public displaySearchData: ActivityLogSearchModel;
  public userList: UserActivityLogResource[] = [];
  public page = 1;
  public sort = '-date';
  public totalItems: number;
  public formatedTotalItems: string;
  public pageSize = 20;
  public isLoadingDataPage = false;
  public selectedRows: ISelectedRow[] = [];
  public userTableColumns = [
    <ITableColumnDefinition<string>>{ title: this._translateService.instant('Display Name'), field: 'username', isSortable: false },
    <ITableColumnDefinition<Moment>>{ title: this._translateService.instant('Date/Time'), field: 'date', isSortable: true, reverseSortIndicator: true },
    <ITableColumnDefinition<ActivityLogCategory>>{ title: this._translateService.instant('Activity'), field: 'category', isSortable: true },
    <ITableColumnDefinition<string>>{ title: this._translateService.instant('Protocol'), field: 'details', isSortable: false },
  ];
  private _availableCategories: ActivityLogCategoriesResource;

  private _tableColumns: IUserActivityTableColums = {
    username: 'Display Name',
    date: 'Date/Time',
    category: 'Activity',
    details: 'Protocol'
  };


  constructor(
    private _usersService: UsersService,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    public authService: AuthService,
    public renderer: Renderer2
  ) {
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });
    // in future if you want to use searchStore like it is implemented in user-list.component => uncomment the code bellow
    // this._searchStoreSub = this._searchStore.usersActivityLogSearch.subscribe((search) => {
    //   if (!search) {
    //     return;
    //   }

    //   this.searchData = search;

    //   this.getActivityLog(true);
    // });
  }
  ngAfterViewInit(): void {
    const elements = document.querySelectorAll('.mat-paginator-page-size-label, .mat-paginator-range-label');
    const itemsPerPage = document.querySelectorAll('.mat-paginator-page-size-value')
    const icons = document.querySelectorAll('.mat-paginator-icon')
    const paginationColor = JSON.parse(localStorage.getItem('paginationColor'))

    // icons.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // itemsPerPage.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // elements.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
  }

  ngOnInit() {
    this.getActivityLog();
    this.getUserCategory();
  }

  public openDialog() {
    const position = document.getElementById('searchButton');
    const topPosition = position.getBoundingClientRect().top + window.pageYOffset;
    const dialogConfig = new MatDialogConfig<any>();
    dialogConfig.panelClass = 'search-activity-log'
    dialogConfig.data = {
      searchData: this.searchData || new ActivityLogSearchModel(),
      categories: this._availableCategories.categories,
    };
    const dialogRef = this.dialog.open(SearchActivityLogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((value: ActivityLogSearchModel) => {
      if (value) {
        this.searchData = new ActivityLogSearchModel();
        this.searchData = _assign(this.searchData, value);
        // this._searchStore.saveSearch(this.searchData, this.searchStoreType);
        this.getActivityLog(true);
      }
    });
  }

  public getActivityLog(resetPagination = false) {
    if (resetPagination) {
      this.page = 1;
    }
    // we set isLoadingDataPage flag for showing progress spinner while it's value is true.
    this.isLoadingDataPage = true;
    this._usersService.getUsersActivityLog({
      page: this.page,
      pageSize: this.pageSize,
      sort: this.sort,
    }, this.searchData).subscribe((data) => {
      this.userList = this.parseDates(data.pageData);
      this.totalItems = data.totalItems;
      this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, data.totalItems);
      this.isLoadingDataPage = false;
    });
  }

  private parseDates(userList: UserActivityLogResource[]) {
    return userList.map((userListItem: UserActivityLogResource) => {
      return _assign(new UserActivityLogResource(userListItem), {
        ...userListItem,
        date: userListItem.date ? formatToDateTime(userListItem.date, 'DD.MM.YYYY HH:mm:ss') : ''
      });
    });
  }

  private getUserCategory() {
    this._usersService.getUserCategory().subscribe((result: ActivityLogCategoriesResource) => {
      this._availableCategories = result;
    });

  }

  public changePage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getActivityLog();
  }

  public getPageIndex() {
    return this.page - 1;
  }

  private onTranslateChange() {
    // Translate column title propertis in a programatical way
    this.userTableColumns.map((tableCol: ITableColumnDefinition<string | Moment | ActivityLogCategory>) => {
      tableCol.title = this._translateService.instant(this._tableColumns[tableCol.field]);
    });
    this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, this.totalItems);
  }

  ngOnDestroy() {
    this.dialog.closeAll();

    // if (this._searchStoreSub) {
    //   this._searchStoreSub.unsubscribe();
    // }
  }
}
