import { UserStatus } from '../enums/user-status.enum';
import { Serialize, ApiModel, SerializeEnum } from '../../shared/classes/api-model.class';

export class UserSearchModel extends ApiModel {
  @Serialize() accountNumber: string;
  @Serialize() corporateCustomer: boolean;
  @Serialize() displayName: string;
  @Serialize() email: string;
  @Serialize() employee: boolean;
  @Serialize() inboxWithoutEmail: boolean;
  @Serialize() optIn: boolean;
  @SerializeEnum({
    [UserStatus.Open]: 'OPEN',
    [UserStatus.Deactivated]: 'DEACTIVATED',
    [UserStatus.NotOpen]: 'NOT_OPEN',
    [UserStatus.Closed]: 'CLOSED',
    [UserStatus.Disabled]: 'DISABLED',
    [UserStatus.Inactive]: 'INACTIVE',
    [UserStatus.Unconfirmed]: 'UNCONFIRMED'
  }) status: UserStatus;
  @Serialize() userName: string;
}
