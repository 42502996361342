<div class="create_edit_group_dialog_content">
  <div class="create_edit_person_dialog_content__header">
    <h1 mat-dialog-title>{{title | translate}}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>
  <div mat-dialog-content class="create_edit_group_dialog__form">
    <form [formGroup]="cereateEditPersonForm" (ngSubmit)="submitWithPropt()" *ngIf="this.cereateEditPersonForm">
      <mat-form-field color="primary">
        <mat-label>{{'salutation' | translate}}</mat-label>
        <mat-select formControlName="salutation">
          <mat-option value="MALE">{{ 'male' | translate }}</mat-option>
          <mat-option value="FEMALE">{{'female' | translate}}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="cereateEditPersonForm.controls.salutation.touched && cereateEditPersonForm.controls.salutation.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'first-name' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="firstName" placeholder="{{'first-name' | translate}}">
        <mat-error
          *ngIf="cereateEditPersonForm.controls.firstName.touched && cereateEditPersonForm.controls.firstName.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'last-name' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="lastName" placeholder="{{'last-name' | translate}}">
        <mat-error
          *ngIf="cereateEditPersonForm.controls.lastName.touched && cereateEditPersonForm.controls.lastName.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary" *ngIf="isXUser">
        <mat-label>{{'Username' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="username" placeholder="{{'Username' | translate}}*">
        <mat-error
          *ngIf="cereateEditPersonForm.controls.username.touched && cereateEditPersonForm.controls.username.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <div *ngFor="let accountNumber of accountNumbers; let i = index"
        [ngClass]="{'disabled-field': !personId ? false : true}">
        <mat-form-field color="primary">
          <mat-label>{{'Account Number' | translate}}*</mat-label>
          <input matInput autocomplete="off" [formControlName]="!personId ? 'accountNumber' : 'accountNumber' + i"
            placeholder="{{ 'Account Number' | translate }} *" [value]="accountNumber">
          <mat-error
            *ngIf="cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.touched && cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.hasError('required')">
            {{ 'Required' | translate }}
          </mat-error>
          <mat-error
            *ngIf="cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.touched &&  cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.invalid">
            {{ 'Invalid value' | translate }}
          </mat-error>
          <mat-error
            *ngIf="cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.touched &&  cereateEditPersonForm.controls[!personId ? 'accountNumber' : 'accountNumber' + i]?.hasError('minlength')">
            {{ 'Minimal length is 2' | translate }}
          </mat-error>
          <button *ngIf="personId && (i === accountNumbers.length - 1) && !additionalAccountNumbers" class="plus-button"
            (click)="addAccountNumberField($event)">
            <mat-icon color="primary" class="icon-plus" [svgIcon]="'custom:plus'"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field color="primary"
          [ngClass]="{'disabled-field': !personId ? false : true, 'company-prefix': !personId ? false : true}">
          <mat-label>{{'Company Prefix' | translate}}*</mat-label>
          <mat-select [formControlName]="!personId ? 'companyPrefix' : 'companyPrefix' + i">
            <ng-container *ngIf="createCompanyPrefixes">
              <mat-option *ngFor="let prefix of createCompanyPrefixes" [value]="prefix">
                {{ prefix.subCompanyName + ' -' }} {{ prefix.userPrefix}} {{ prefix.subCompanyPrefix ? ' - ' +
                prefix.subCompanyPrefix : '' }}
              </mat-option>
            </ng-container>
            <ng-container *ngIf="!createCompanyPrefixes">
              <mat-option *ngFor="let prefix of groupPrefixes" [value]="prefix">
                {{ prefix.subCompanyName + ' -' }} {{ prefix.userPrefix}} {{ prefix.subCompanyPrefix ? ' - ' +
                prefix.subCompanyPrefix : '' }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="cereateEditPersonForm.controls[!personId ? 'companyPrefix' : 'companyPrefix' + i]?.touched && cereateEditPersonForm.controls[!personId ? 'companyPrefix' : 'companyPrefix' + i]?.hasError('required')">
            {{ 'Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngFor="let item of [].constructor(additionalAccountNumbers); let i = index">
        <mat-form-field color="primary">
          <mat-label>{{'Account Number' | translate}}</mat-label>
          <input matInput autocomplete="off" [formControlName]="'accountNumber' + (i + accountNumbers.length)"
            placeholder="{{ 'Account Number' | translate }}">
          <mat-error>
            <mat-error
              *ngIf="cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.touched && cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.hasError('required')">
              {{ 'Required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.touched && cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.invalid">
              {{ 'Invalid value' | translate }}
            </mat-error>
            <mat-error
              *ngIf="cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.touched && cereateEditPersonForm.controls['accountNumber' + (i + accountNumbers.length)]?.hasError('minlength')">
              {{ 'Minimal length is 2' | translate }}
            </mat-error>
          </mat-error>
          <button *ngIf="additionalAccountNumbers === i + 1 && personId" class="plus-button"
            (click)="addAccountNumberField($event)">
            <mat-icon color="primary" class="icon-plus" [svgIcon]="'custom:plus'"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>{{'Company Prefix' | translate}}</mat-label>
          <mat-select [formControlName]="!personId ? 'companyPrefix' : 'companyPrefix' + (i + accountNumbers.length)">
            <mat-option *ngFor="let prefix of groupPrefixes" [value]="prefix">
              {{ prefix.subCompanyName + ' -' }} {{ prefix.userPrefix}} {{ prefix.subCompanyPrefix ? ' - ' +
              prefix.subCompanyPrefix : '' }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="cereateEditPersonForm.controls[!personId ? 'companyPrefix' : 'companyPrefix' + (i + accountNumbers.length)]?.touched && cereateEditPersonForm.controls[!personId ? 'companyPrefix' : 'companyPrefix' + (i + accountNumbers.length)]?.hasError('required')">
            {{ 'Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field color="primary">
        <mat-label>Email</mat-label>
        <input matInput autocomplete="off" formControlName="email" placeholder="Email">
        <mat-error
          *ngIf="cereateEditPersonForm.controls.email.touched && cereateEditPersonForm.controls.email.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
        <mat-error
          *ngIf="cereateEditPersonForm.controls.email.touched && cereateEditPersonForm.controls.email.hasError('email')">
          {{'Email format is invalid' | translate}}
        </mat-error>
      </mat-form-field>
      <div class="mobile-number-wrapper">
        <mat-form-field color="primary">
          <mat-label>{{ 'Phone number' | translate}} (ex. +43)</mat-label>
          <input matInput autocomplete="off" formControlName="countryCodePhoneNum"
            (input)="onCountryCodePhoneNumInput($event)" placeholder="{{ 'Phone number' | translate}} (ex. +43)">
          <mat-error
            *ngIf="cereateEditPersonForm.controls.countryCodePhoneNum.touched && cereateEditPersonForm.controls.countryCodePhoneNum.hasError('required')">
            {{ 'Required' | translate }}
          </mat-error>
          <mat-error
            *ngIf="cereateEditPersonForm.controls.countryCodePhoneNum.touched && cereateEditPersonForm.controls.countryCodePhoneNum.hasError('pattern')">
            {{ 'Number should be in +43 format' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="primary">
          <mat-label>000</mat-label>
          <input matInput autocomplete="off" formControlName="netNumberPhoneNum" placeholder="000">
          <mat-error
            *ngIf="cereateEditPersonForm.controls.netNumberPhoneNum.touched && cereateEditPersonForm.controls.netNumberPhoneNum.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.netNumberPhoneNum.touched && !cereateEditPersonForm.controls.netNumberPhoneNum.hasError('required') &&
              cereateEditPersonForm.controls.netNumberPhoneNum.hasError('maxlength')">
            {{'Maximum length exceeded (3 numbers allowed)' | translate}}
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.netNumberPhoneNum.touched && !cereateEditPersonForm.controls.netNumberPhoneNum.hasError('required') &&
              cereateEditPersonForm.controls.netNumberPhoneNum.hasError('minlength')">
            {{'The minimum length is' | translate}} 2
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.netNumberPhoneNum.touched && !cereateEditPersonForm.controls.netNumberPhoneNum.hasError('required') &&
              cereateEditPersonForm.controls.netNumberPhoneNum.hasError('pattern')">
            {{'Invalid value' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>00 00 000</mat-label>
          <input matInput autocomplete="off" formControlName="subscriberNumberPhoneNum" placeholder="00 00 000">
          <mat-error
            *ngIf="cereateEditPersonForm.controls.subscriberNumberPhoneNum.touched && cereateEditPersonForm.controls.subscriberNumberPhoneNum.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.subscriberNumberPhoneNum.touched && !cereateEditPersonForm.controls.subscriberNumberPhoneNum.hasError('required') &&
              cereateEditPersonForm.controls.subscriberNumberPhoneNum.hasError('minlength')">
            {{'The minimum length is' | translate}} 6
          </mat-error>
          <mat-error
            *ngIf="cereateEditPersonForm.controls.subscriberNumberPhoneNum.touched && cereateEditPersonForm.controls.subscriberNumberPhoneNum.hasError('pattern')">
            {{'Invalid value' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field color="primary">
        <mat-label>{{'Street' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="streetName" placeholder="{{'Street' | translate}}">
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Street Number' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="streetNumber" placeholder="{{'Street Number' | translate}}">
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Door Number' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="doorNumber" placeholder="{{'Door Number' | translate}}">
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'ZIP' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="zipCode" placeholder="{{ 'ZIP' | translate }}">
        <mat-error
          *ngIf="cereateEditPersonForm.controls.zipCode.touched && cereateEditPersonForm.controls.zipCode.hasError('invalidZip')">
          {{ 'Invalid format' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Town' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="city" placeholder="{{'Town' | translate}}">
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Country' | translate}}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of allCountries" [value]="country.alpha2"
            [ngStyle]="{ 'border-bottom': country.alpha2 === 'ch' && '2px solid #E0E0E0' }">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'prefixed-title' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="prefixedTitle"
          placeholder="{{'prefixed-title' | translate}}">
      </mat-form-field>
      <mat-checkbox *ngIf="einfachBriefEnabled" color="primary" formControlName="sendToPrint">{{'Send to Print Channel' | translate }}
      </mat-checkbox>
      <button color="primary" mat-button mat-raised-button [disabled]="cereateEditPersonForm.invalid"
        type="submit">{{'Submit' | translate}}</button>
    </form>
  </div>
</div>
<div class="loading-container" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>