<div class="search-dialog">

  <!-- <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon> -->

    <form [formGroup]="searchGroupForm" (ngSubmit)="onSubmit()">
        <div class="form-fields" *ngIf="displayAccountNumber">
            <div class="searchText-wrap">
              <p class="label">{{'Account Number' | translate}}:</p>
              <mat-form-field color="primary">
                <input  name="accountNumber" matInput formControlName="accountNumber"
                  placeholder="{{ 'Enter the account number..' | translate }}">
              </mat-form-field>
            </div>
          </div>
          <div class="form-fields">
            <div class="searchText-wrap">
              <p class="label">{{'Display Name' | translate}}:</p>
              <mat-form-field color="primary">
                <input  name="displayName" matInput formControlName="displayName"
                  placeholder="{{ 'Enter the displayname...' | translate }}">
              </mat-form-field>
            </div>
          </div>
          <div class="form-fields">
            <div class="searchText-wrap">
              <p class="label">{{'Company Name' | translate}}:</p>
              <mat-form-field color="primary">
                <input  name="groupDescription" matInput formControlName="groupDescription"
                  placeholder="{{ 'Enter the Company Name...' | translate }}">
              </mat-form-field>
            </div>
          </div>
          <div class="form-actions">
            <button  mat-flat-button type="button" (click)="onReset()">{{'resetUserSearch' | translate}}</button>
            <button color="primary" mat-button mat-raised-button type="submit" [disabled]="!searchGroupForm.valid">{{'searchUser' | translate}}</button>
          </div>
    </form>
</div>