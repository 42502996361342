import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// @ts-ignore
import * as has from 'lodash/has';

import { AuthService } from '../../../auth/services/auth.service';
import { UserDataResource } from '../../../auth/classes/user-data-resource.class';

@Component({
  selector: 'sv-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnDestroy {
  private _translateChangeSub: Subscription;
private _routeParamsChangeSub: Subscription;
  public errorCode = '';
  public errorMessages: {
    title: string;
    titleStatusCode: string;
    errorMessage: string;
    causesTitle: string;
    causesMessage: string;
    img: string;
  };
  private errors = {
    401: {
      title: '',
      titleStatusCode: this.errorCode ? this.errorCode : '401',
      errorMessage: '',
      causesTitle: '',
      causesMessage: '',
      img: 'assets/images/poldi.png'
    },
    404: {
      title: '',
      titleStatusCode: this.errorCode ? this.errorCode : '404',
      errorMessage: '',
      causesTitle: '',
      causesMessage: '',
      img: 'assets/images/404.png'
    },
    500: {
      title: '',
      titleStatusCode: this.errorCode ? this.errorCode : '500',
      causesMessage: '',
      img: 'assets/images/poldi.png'
    },
    503: {
      title: '',
      titleStatusCode: this.errorCode ? this.errorCode : '503',
      causesMessage: '',
      img: 'assets/images/poldi.png'
    }
  };

  constructor(
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _auth: AuthService,
    private _router: Router,
  ) {
    const userData: UserDataResource = this._auth.getUserData();
    if (userData && userData.userLanguage) {
      this._translate.use(userData.userLanguage);
    }
    this._routeParamsChangeSub = this._route.params.subscribe((params) => {
      if (params['errorCode']) {
        this.errorCode = params['errorCode'];
      }
      //  we have list of possible error codes in errors objects.
      //  so first we check if error code exist in errors objects
      // if exists then showing error page with that code, if not navigate to 404-error page.
      const errorStatus = has(this.errors, this.errorCode);
      if (errorStatus) {
        this.errorMessages = this.errors[this.errorCode];
      } else {
        this._router.navigateByUrl('error;errorCode=404');
      }
    });
    this._updateTranslations();
    this._translateChangeSub = this._translate.onLangChange.subscribe(() => {
      this._updateTranslations();
    });
  }

  // updating translations method => implemented because we need to update translation for error messages
  // on translation change(language change)
  private _updateTranslations() {
    this.errors[401].title = this._translate.instant('error-page:title');
    this.errors[401].errorMessage = this._translate.instant('error-page-401:error-message');
    this.errors[401].causesTitle = this._translate.instant('error-page:causes-title');
    this.errors[401].causesMessage = this._translate.instant('error-message-401:causes-message');
    this.errors[404].title = this._translate.instant('error-page:title');
    this.errors[404].errorMessage = this._translate.instant('error-page-404:error-message');
    this.errors[404].causesTitle = this._translate.instant('error-page:causes-title');
    this.errors[404].causesMessage = this._translate.instant('error-message-404:causes-message');
    this.errors[500].title = this._translate.instant('internal-server-error-page:title');
    this.errors[500].causesMessage = this._translate.instant('internal-server-error-message:causes-message');
    this.errors[503].title = this._translate.instant('maintenance-page:title');
    this.errors[503].causesMessage = this._translate.instant('maintenance-message:causes-message');
  }

  ngOnDestroy() {
    if (this._translateChangeSub) {
      this._translateChangeSub.unsubscribe();
    }
    if (this._routeParamsChangeSub) {
      this._routeParamsChangeSub.unsubscribe();
    }
  }
}
