import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../auth/services/auth.service";
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GroupService } from "../group.service";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";

@Component({
  selector: "sv-upload-company-logo",
  templateUrl: "./upload-company-logo.component.html",
  styleUrls: ["./upload-company-logo.component.scss"],
})
export class UploadCompanyLogoComponent implements OnInit {
  public pdfData: { userAccountPdfFile: string; fileName: string };
  public data;
  public selectedImage;
  public logos
  public uploadedImages = []
  constructor(
    public sanitizer: DomSanitizer,
    public notificationService: MultipleNotificationsService,
    public authService: AuthService,
    public groupService: GroupService,
    private matDialogRef: MatDialogRef<UploadCompanyLogoComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = {...data}
    this.logos = [...data.logos] || []
    this.selectedImage = this.logos.find(img => img.selected)?.logoUrl || this.logos.find(img => img.selected)?.temporaryLogoUrl || ''
  }

  ngOnInit(): void {}

  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  public toggleCheckbox(imageIndex: string) {
    if (this.selectedImage && (this.logos[imageIndex].logoUrl === this.selectedImage || this.logos[imageIndex].temporaryLogoUrl === this.selectedImage)) {
      this.selectedImage = null;
      this.logos = [...this.logos];
      this.logos[imageIndex] = { ...this.logos[imageIndex], selected: false };
    } else {
      this.selectedImage = this.logos[imageIndex].logoUrl || this.logos[imageIndex].temporaryLogoUrl;
      this.logos = this.logos.map(img => ({ ...img, selected: false }));
      this.logos[imageIndex] = { ...this.logos[imageIndex], selected: true };
    }
  }

  public copyImageUrl(imageIndex) {
    navigator.clipboard.writeText(this.logos[imageIndex].logoUrl)
    this.groupService.statusMessage('snack-bar-success', 'Copied image URL');
  }

  public deleteImage(imageIndex: number) {
    if (this.logos[imageIndex].temporaryLogoUrl) {
      this.logos.splice(imageIndex, 1)
    } else {
      this.logos[imageIndex] = {...this.logos[imageIndex], logoHistory: this.logos[imageIndex].logoUrl, logoUrl: '', valid: false}
    }
  }

  public onImageUpload(e) {
  const file = e.target.files[0];

  function encodeFileName(fileName) {
    return encodeURIComponent(fileName);
  }

  if (file) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result as string;

      const image = {
        logoHistory: '',
        logoImage: {
          name: encodeFileName(file.name),
          mimeType: file.type || '',
          payload: base64Image.split(',')[1],
        },
        logoUrl: '',
        temporaryLogoUrl: base64Image,
        valid: true,
        selected: false,
      };

      !this.logos.find(img => img.temporaryLogoUrl === image.temporaryLogoUrl) && (this.logos = [...this.logos, image]);
    };

    reader.readAsDataURL(file);
  }
}

  public onImageReplace(e, imageIndex) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64Image = reader.result as string;
  
        const image = {
          logoHistory: this.logos[imageIndex].logoHistory,
          logoImage: {
            name: this.logos[imageIndex].logoImage.name,
            mimeType: file.type || '',
            payload: base64Image.split(',')[1],
          },
          logoUrl: '',
          temporaryLogoUrl: base64Image,
          valid: true,
          selected: false,
        };
  
        this.logos[imageIndex] = image
      };
  
      reader.readAsDataURL(file);
    }
  }

  public save() {
    this.groupService.temporarilySavedTenantLogos = [...this.logos.filter(img => img.valid)]
    this.matDialogRef.close('save')
  }
}
