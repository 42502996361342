import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HandleApiErrorInterceptor } from './interceptors/handle-api.error.interceptor';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ErrorPageComponent } from './components/error-page/error-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
  ],
  declarations: [
    ErrorPageComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HandleApiErrorInterceptor, multi: true },
  ],
})
export class ServerStatusModule { }
