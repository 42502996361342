import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  NgZone,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  BehaviorSubject,
  forkJoin,
  map,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import { AuthService } from "../../../auth/services/auth.service";
import { DocumentUploadModel } from "../../classes/document-upload-model.class";
import { UploadConfigModel } from "../../classes/uploadconfig.model";
import { HttpClient } from "@angular/common/http";
import { CreateDeliveryModel } from "../../classes/create-delivery.model";
import { environment } from "../../../../environments/environment";
import { UploadService } from "./upload.service";
import { MatSelect } from "@angular/material/select";
import { DictionaryPDFService } from "../../../group/dictionary-pdf/dictionary-pdf.service";
import { UploadUtilityService } from "./upload-utility.service";
import { UploadViewService } from "./upload-view.service";
import { ITempUploadsState } from "../../../group/interfaces/temp-upload-state.interface";
import { IUploadErrors } from "../../../group/interfaces/upload-errors.interface";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "../confirmation-dialog/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { GroupService } from "../../../group/group.service";
import { DeliverDocsToUsersComponent } from "../../../group/deliver-docs-to-users/deliver-docs-to-users.component";

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  tempUploadsState: ITempUploadsState = {
    numOfDocumentsExceeded: false,
    uploadsSuccessful: false,
    totalFilesSizeExceeded: false,
    invalidExtention: false,
  };
  private _uploadState: ITempUploadsState;

  // save upload notification
  @ViewChild("files") public filesForm;
  @ViewChild("fileInput") public fileInput;
  @ViewChild("fileTrigger") public fileTrigger;
  @ViewChild("salutationSelect") salutationRef: MatSelect;
  @Output() tempUploadsStateOutput = new EventEmitter<ITempUploadsState>();
  tempValidDocuments = new BehaviorSubject<DocumentUploadModel[]>([]);

  numOfDocuments = 0;
  status = [];

  public files: Set<File> = new Set();
  public selectedFiles: File[];
  public iconLeft = false;
  public companies = [];
  private _uploadSortSub: Subscription;
  private _uploadProcessesSub: Subscription;
  uploadConfig: UploadConfigModel;
  uploadSortSubject = new Subject<void>();
  pendingDocuments = false;
  totalFilesSize: number;
  public createDeliveryModel: CreateDeliveryModel;
  public zipContainsErrors = [];
  foreignId: any;
  public groupName = "";
  public templates = [];
  preUploadDocuments = [];
  public visible = false;
  public shServiceLineUsed: boolean;
  @ViewChild("document") document: HTMLDivElement;
  isSending: boolean;
  isBroadcastDelivery: boolean;
  moreThenOneDocument: boolean = false;
  uploadedFilesFromEvent: Set<File> = new Set<File>();
  public subjectForm: UntypedFormGroup;
  public userIds = [];
  public deliverDocsToMultipleUsers: boolean;
  // displayPdfConversionCode = false;
  constructor(
    private matDialogRef: MatDialogRef<UploadDialogComponent>,
    private _dictionaryService: DictionaryPDFService,
    private _uploadUtilityService: UploadUtilityService,
    private translate: TranslateService,
    private _groupsService: GroupService,
    public authService: AuthService,
    public viewService: UploadViewService,
    public uploadService: UploadService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isBroadcastDelivery = data.isBroadcastDelivery;
    this.deliverDocsToMultipleUsers = data.deliverDocsToMultipleUsers;
    this.uploadService.deliverDocsToMultipleUsers = this.deliverDocsToMultipleUsers
    this.userIds = data.userIds;
    this.uploadService.isBroadCastDelivery = data.isBroadcastDelivery;
    if (this.uploadService.isBroadCastDelivery) {
      this.subjectForm = new UntypedFormGroup({
        subject: new UntypedFormControl("", Validators.required),
        companies: new UntypedFormControl("", Validators.required),
      });
      if (this.deliverDocsToMultipleUsers) {
        this.subjectForm.removeControl('companies')
      }
      this._groupsService.getGroupsForBroadcasting().subscribe((data) => {
        this.companies = data;
        this.subjectForm.controls.companies && this.subjectForm.controls.companies.setValue(this.companies);
      });
    }

    const groupId = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.tenantId;
    this._dictionaryService
      .getSelectedPDFDictionaryForCompany(groupId)
      .subscribe((templates: any[]) => {
        this.templates = [...templates, "ServiceLine"];

        if (this.templates.length === 1) {
          this.uploadService.conversionCode = this.templates[0];
        }
      });
    this.setUploadsState();
    this.uploadConfig = new UploadConfigModel(
      this.uploadService.isBroadCastDelivery
        ? ["pdf"]
        : ["pdf", "xml", "zip", "7z", "txt"]
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.createDeliveryModel.documents.forEach((document) => document.cancel());
    this.files = new Set();
    this.uploadService.documents = [];
    this.uploadService.conversionCode = "";
    this.viewService.displaySendBtn = false;
    this.viewService.displayFileUpload = true;
    this.uploadService.zipContainsErrors = [];
    this.viewService.displayPdfConversionCode = false;
    this.uploadService.shServiceLineUsed = false;
    this.viewService.displayShServiceLineUsed = true;
    this.viewService.isDropDownValueChosen = false;
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  getDeliveryDocuments(documents: DocumentUploadModel[]) {
    if (documents) {
      this.createDeliveryModel = new CreateDeliveryModel();
      this.createDeliveryModel.documents.push(...documents);
      let filesSize = 0;
      this.totalFilesSize = filesSize;
    }
  }

  getUploadState(state: ITempUploadsState) {
    if (state) {
      this._uploadState = state;
      // this.handleUploadsState();
    }
  }

  isFileContentEqual(file1: File, file2: File): Promise<boolean> | boolean {
    if (
      file1.lastModified !== file2.lastModified &&
      file1.name !== file2.name
    ) {
      return false;
    }

    const reader1 = new FileReader();
    const reader2 = new FileReader();

    reader1.readAsArrayBuffer(file1);
    reader2.readAsArrayBuffer(file2);

    return new Promise<boolean>((resolve) => {
      reader1.onload = (event1) => {
        reader2.onload = (event2) => {
          const buffer1 = event1.target.result as ArrayBuffer;
          const buffer2 = event2.target.result as ArrayBuffer;
          resolve(
            JSON.stringify(Array.from(new Uint8Array(buffer1))) ===
              JSON.stringify(Array.from(new Uint8Array(buffer2)))
          );
        };
      };
    });
  }

  onFilesAdded(event) {
    if (
      (this.uploadService.isBroadCastDelivery &&
        this.uploadedFilesFromEvent.size > 0) ||
      event.dataTransfer?.files?.length > 1
    ) {
      this.moreThenOneDocument = true;
      this.fileInput.nativeElement.value = "";
      return;
    } else {
      this.moreThenOneDocument = false;
    }

    let files: { [key: string]: File };
    files = event.target.files;
    if (!files.length) {
      return;
    }
    this.files = new Set();

    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(files[key]);
      }
    }

    if (this.files.size > 0) {
      const files = event.target["files"];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let isDuplicate = false;

        for (const existingFile of this.uploadedFilesFromEvent) {
          if (this.isFileContentEqual(existingFile, file)) {
            isDuplicate = true;
            break;
          }
        }

        if (!isDuplicate) {
          this.uploadedFilesFromEvent.add(file);
        }
      }

      this._uploadUtilityService
        .encodeFilesToBase64(
          this._uploadUtilityService.convertSetToFiles(
            this.uploadedFilesFromEvent
          )
        )
        .subscribe((encoded) => {
          this.preUploadDocuments = encoded.map((data) =>
            this._uploadUtilityService.checkForUnsportedMediaFiles(
              data,
              this.uploadConfig.allowedFileExtensionList
            )
          );
          this.viewService.preUpload = true;
          this.viewService.hasError = this.preUploadDocuments.some(
            (document) => document.unsupportedMediaType
          );
          this.viewService.displayPdfConversionCodeMethod(
            this.templates,
            this.preUploadDocuments,
            this.uploadedFilesFromEvent
          );
        });
    }

    this.fileInput.nativeElement.value = "";
  }

  uploadFiles() {
    this.uploadService.isProcessingOver = false
    this.moreThenOneDocument = false;
    if (this.uploadService.isBroadCastDelivery) {
      
      const broadcastDeliverySubject = this.subjectForm.get("subject").value;
      const broadcastDeliveryReceiverCompanies = this.deliverDocsToMultipleUsers
        ? this.uploadService.companyDisplayName
        : this.subjectForm.get("companies").value;
        const isBroadCastOrDeliveryToMultipleUsers = this.uploadService.isBroadCastDelivery || this.deliverDocsToMultipleUsers
      this.uploadService.broadcastDeliverySubject = isBroadCastOrDeliveryToMultipleUsers
        ? {
            broadcastDeliverySubject,
            broadcastDeliveryReceiverCompanies,
          }
        : {
            broadcastDeliverySubject: '',
            broadcastDeliveryReceiverCompanies: [],
          };
      this.uploadService.broadcastDelivery = true
    }

    this.saveTemporaryUploadedData(this.preUploadDocuments);
    this.viewService.hasError = true;
    this.viewService.preUpload = false;
    this.viewService.displaySendBtn = true;
    this.viewService.displayFileUpload = false;
    this.viewService.displayPdfConversionCode = false;
    this.viewService.displayShServiceLineUsed = false;
  }
  selectConversionCode() {
    this.viewService.isDropDownValueChosen = true;
    if (this.salutationRef.value === "ServiceLine") {
      this.uploadService.shServiceLineUsed = true;
      return;
    }
    this.uploadService.shServiceLineUsed = false;
    this.uploadService.conversionCode = this.salutationRef.value;
  }

  saveTemporaryUploadedData(files) {
    if (!files) {
      return;
    }
    const newObservables = [];

    const validatedFiles = this._uploadUtilityService.validateUploadFiles(
      files,
      this.numOfDocuments,
      this.uploadConfig.allowedFileExtensionList
    );
    const statuses = this.uploadTempDeliveryFiles(
      validatedFiles.validFiles as Set<File>
    );
    validatedFiles.validFiles.forEach((file) => {
      const document = new DocumentUploadModel();
      const status = statuses.filter(
        (fileStatus) => fileStatus.file === file
      )[0];
      document.fileName = file["name"];
      document.fileSize = file["size"];
      document.watchProgress(status.progress);
      document.cancel = status.cancel;
      document.valid = true;
      document.pending = false;
      document.type = file["mimeType"];
      document.payload = file["payload"].split(",")[0];
      this.numOfDocuments += 1;

      status.request.subscribe({
        next: (response) => {
          document.finishedUpload = true;
          document.id = Math.floor(Math.random() * 1000) + 1;
          document.fileName = file["name"];
          this.setUploadsState();
        },
      });

      status.uploadError.subscribe({
        next: (response) => {
          this.numOfDocuments -= 1;
          document.valid = false;
          document.hasVirus = response.hasVirus;
          document.unsupportedMediaType = response.unsupportedMediaType;
        },
      });

      // this.uploadService.documents = []
      this.uploadService.documents.push(document);
      newObservables.push(document.uploadedPercentageSubject);
    });
    validatedFiles.invalidFiles.forEach((fileObj) => {
      const document = new DocumentUploadModel();
      document.file = fileObj.file;
      document.fileName = fileObj.file.name;
      document.fileSize = fileObj.file.size;
      document.unsupportedMediaType = fileObj.error.extendion;
      document.valid = false;
      document.pending = fileObj.error.pending;
      document.cancel = () => {};
      this.uploadService.documents.push(document);
    });
    // When all progress-observables are completed...
    // this.uploadService.documents = this.uploadService.documents.filter((value,index,self) => index === self.findIndex((t) => (t.fileName === value.fileName)))

    this._uploadProcessesSub = forkJoin(newObservables).subscribe((end) => {
      this.setUploadsState();
    });
    this.setUploadsState();
    this.uploadSortSubject.next();
  }

  setUploadsState() {
    if (
      !this.uploadService.documents ||
      this.uploadService.documents.length < 1
    ) {
      this.tempUploadsState.uploadsSuccessful = false;
      this.getUploadState(this.tempUploadsState);
      this.getDeliveryDocuments([]);

      return;
    }
    // ... the upload was successful...
    const numOfValidDocuments = this.uploadService.documents.filter((d) => {
      return d.valid;
    }).length;
    const inavlidDoscs = this.uploadService.documents.filter((d) => {
      return !d.valid;
    }).length;
    const validUploadedDocuments = this.uploadService.documents.filter((d) => {
      return d.finishedUpload;
    });
    const numOfUploadedDocuments = validUploadedDocuments.length;

    const newUploadSuccessfulState =
      numOfValidDocuments === numOfUploadedDocuments && numOfValidDocuments > 0;
    this.tempUploadsState.uploadsSuccessful =
      newUploadSuccessfulState && inavlidDoscs === 0;
    this.getUploadState(this.tempUploadsState);
    this.getDeliveryDocuments(validUploadedDocuments);
  }
  convertToMb(fileSize: number): number {
    const convertedSize = fileSize / 1024 / 1024;
    return convertedSize;
  }

  uploadTempDeliveryFiles(files: Set<File>): {
    progress: Subject<number>;
    loaded: Subject<number>;
    cancel: () => void;
    file: File;
    request: Subject<any>;
    uploadError: Subject<IUploadErrors>;
  }[] {
    this.uploadService.uploadAsyncSingleTempFile(files);
    return this.uploadService.status;
  }

  onDelete(document: DocumentUploadModel) {
    this.uploadService.conversionCode = "";
    this.uploadService.shServiceLineUsed = false;
    const documents = this.uploadService.documents;
    const index = documents.indexOf(document);
    const pendingDocs = documents.filter((d) => {
      return d.pending;
    });

    documents.splice(index, 1);
    document.cancel();

    for (const obj of this.files) {
      if (obj.name === document.fileName) {
        this.files.delete(obj);
        let index = this.preUploadDocuments.findIndex(
          (upload) => upload.name === document.fileName
        );
        if (index !== -1) {
          this.preUploadDocuments.splice(index, 1);
          this.preUploadDocuments.length === 0 ? this.viewService.preUpload = true: this.viewService.preUpload = false
        }
      }
    }
    if (this.uploadService.documents.length === 0) {
      this.viewService.displayFileUpload = true;
      this.viewService.displaySendBtn = false;
      this.uploadedFilesFromEvent = new Set();
      this.subjectForm?.controls?.subject?.reset();
      this.subjectForm?.controls?.companies?.setValue(this.companies);  
    }

    this.uploadService
      .delete(document.sourceFileUID, this.uploadService.foreignId)
      .subscribe((response) => {
        this.viewService.disablePointerEvent = !response.success;
        this.uploadService.zipContainsErrors = response.errorFiles;
        this.viewService.triggerChangeDetection.next(true);
      });

    this.viewService.hasError = this.uploadService.documents.some(
      (doc) => doc.isInvalidDictionary || doc.isXMLTemplateInvalid
    );
    this.setUploadsState();
  }

  onDeletePreUpload(index: number) {
    const filesArray = Array.from(this.uploadedFilesFromEvent);
    filesArray.splice(index, 1);
    this.uploadedFilesFromEvent = new Set(filesArray);

    this.preUploadDocuments.splice(index, 1);

    this.viewService.removePdfConversionCode(this.preUploadDocuments);
    this.viewService.hasError = this.preUploadDocuments.some(
      (document) => document.unsupportedMediaType
    );
    this.viewService.displayPdfConversionCodeMethod(
      this.templates,
      this.preUploadDocuments,
      this.uploadedFilesFromEvent
    );

    if (
      (this.uploadService.isBroadCastDelivery || this.deliverDocsToMultipleUsers) &&
      this.uploadedFilesFromEvent.size == 0
    ) {
      this.moreThenOneDocument = false;
    }
  }

  onSendDocuments = () => {
    if (!this.uploadService.isBroadCastDelivery) {
      return this.sendDocuments();
    }

    let dialogRef;
    const config = new MatDialogConfig<ConfirmationDialogData>();
    config.disableClose = true;
    config.autoFocus = false;
    config.data = {
      dialogTitle: this.translate.instant("uploadConfirmation.title"),
      onCancel: {
        label: this.translate.instant("uploadConfirmation.labelCancel"),
        cta: () => {},
      },
      onConfirm: {
        label: this.translate.instant("uploadConfirmation.labelConfirm"),
        cta: () => {
          this.sendDocuments();
          dialogRef.close();
        },
      },
    };
    dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
  };

  sendDocuments = () => {
    this.matDialogRef.close("sendDocuments");
    this.uploadService.closeDeliverDocsToMultipleUserDialog.next(true);
    this.viewService.statusMessage("We are processing in the background", true);
    this.uploadService.sendDocuments(this.uploadService.foreignId).subscribe(
      () => {
      },
      (err) => {
        if (err?.statusCode !== 504) {
          this.viewService.statusMessage(
            "A problem occurred during sending. Please try again later",
            false
          );
        }
      }
    );
  };

  public closeDialog() {
    this.matDialogRef.close();
  }
}
