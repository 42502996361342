<div class="change-password-page">
  <div class="change-password-form-wrapper">
    <form [formGroup]="form" (ngSubmit)="submit()" class="form">
      <h1>{{ "Reset password" | translate }}</h1>
      <div class="input-wrapper" *ngIf="!isResetPasswordFromEmail">
        <div class="input" >
          <input
            formControlName="oldPassword"
            (input)="oldAndNewPasswordsMatching()"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            placeholder="{{ 'Current password' | translate }}"
            class="input__field-input"
          />
          <label class="input__field-label">{{ "Password" | translate }}</label>
          <div class="input__field-error">
            <ng-container #errorContainer></ng-container>
          </div>
        </div>
        <button mat-button (click)="toggleHideCurrentPassword($event)">
          <mat-icon color="primary" mat-button mat-raised-button
            class="visible-icon"
            [svgIcon]="
              hideCurrentPassword
                ? 'custom:password_invisible'
                : 'custom:password_visible'
            "
          ></mat-icon>
        </button>
        <mat-error *ngIf="form.controls.oldPassword.touched">
          <mat-error *ngIf="form.controls.oldPassword.hasError('required')">
            {{ "Required" | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls.oldPassword.hasError('minlength')">
            {{ "The minimum length is" | translate }} 6
          </mat-error>
          <mat-error *ngIf="form.controls.oldPassword.hasError('maxlength')">
            {{ "Invalid value" | translate }}
          </mat-error>
        </mat-error>
      </div>
      <h2>{{ "Password" | translate }}</h2>
      <p class="password-validation-title">
        {{'The password must be at least 8 to 64 characters long and fulfil 3 of 4 points: at least 1 upper case letter, 1 lower case letter, 1 special character, 1 number.' | translate}}
      </p>
      <div class="input-wrapper">
        <div class="input">
          <input
            formControlName="newPassword"
            (input)="onTouch()"
            [type]="hideNewPassword ? 'password' : 'text'"
            placeholder="{{ 'Password' | translate }}"
            class="input__field-input"
          />
          <label class="input__field-label">{{ "Password" | translate }}</label>
        </div>
        <button mat-button (click)="toggleHideNewPassword($event)">
          <mat-icon color="primary" mat-button mat-raised-button
            class="visible-icon"
            [svgIcon]="
              hideNewPassword
                ? 'custom:password_invisible'
                : 'custom:password_visible'
            "
          ></mat-icon>
        </button>
        <mat-error *ngIf="form.controls.newPassword.touched">
          <mat-error *ngIf="form.controls.newPassword.hasError('required')">
            {{ "Required" | translate }}
          </mat-error>
          <mat-error *ngIf="form.controls.newPassword.hasError('minlength')">
            {{ "The minimum length is" | translate }} 8
          </mat-error>
          <mat-error *ngIf="form.controls.newPassword.hasError('maxlength')">
            {{ "The maximum length is" | translate }} 64
          </mat-error>
        </mat-error>
      </div>
      <div class="input-wrapper">
        <div class="input">
          <input
            formControlName="confirmedNewPassword"
            (input)="onTouch()"
            [type]="hideConfirmPassword ? 'password' : 'text'"
            placeholder="{{ 'Confirm password' | translate }}"
            class="input__field-input"
          />
          <label class="input__field-label">{{
            "Confirm password" | translate
          }}</label>
          <mat-error *ngIf="form.controls.confirmedNewPassword.touched">
            <mat-error
              *ngIf="form.controls.confirmedNewPassword.hasError('required')"
            >
              {{ "Required" | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.confirmedNewPassword.hasError('minlength')">
              {{ "The minimum length is" | translate }} 8
            </mat-error>
            <mat-error *ngIf="form.controls.confirmedNewPassword.hasError('maxlength')">
              {{ "The maximum length is" | translate }} 64
            </mat-error>
          </mat-error>
        </div>
        <button mat-button (click)="toggleHideConfirmPassword($event)">
          <mat-icon color="primary" mat-button mat-raised-button
            class="visible-icon"
            [svgIcon]="
              hideConfirmPassword
                ? 'custom:password_invisible'
                : 'custom:password_visible'
            "
          ></mat-icon>
        </button>
      </div>
    </form>
    <div class="password-validation-list">
      <h4 [ngClass]="{'valid': capitalLetterPolicy && smallLetterPolicy && symbolPolicy && numberPolicy && passwordsMatched , 'invalid': (!capitalLetterPolicy || !smallLetterPolicy || !symbolPolicy || !numberPolicy || !passwordsMatched) && (form.controls.newPassword.value || form.controls.confirmedNewPassword.value)}" class="pwSecurity__headline heading--fineline">{{'Password security' | translate}}</h4>
      <ul class="pwSecurity__list">
        <li [ngClass]="{'valid': capitalLetterPolicy && form.controls.newPassword.value.length > 0 , 'invalid': !capitalLetterPolicy && form.controls.newPassword.value.length > 0}" class="pwSecurity__item">
          <mat-icon class="error-icon" svgIcon="custom:full-close"></mat-icon>1
          {{'Upper case letter' | translate}}
        </li>
        <li [ngClass]="{'valid': smallLetterPolicy && form.controls.newPassword.value.length > 0 , 'invalid': !smallLetterPolicy && form.controls.newPassword.value.length > 0}" class="pwSecurity__item">
          <mat-icon class="error-icon" svgIcon="custom:full-close"></mat-icon>1
          {{'Lower case letter' | translate}}
        </li>
        <li [ngClass]="{'valid': symbolPolicy && form.controls.newPassword.value.length > 0 , 'invalid': !symbolPolicy && form.controls.newPassword.value.length > 0}" class="pwSecurity__item">
          <mat-icon class="error-icon" svgIcon="custom:full-close"></mat-icon>1
          {{'Special character' | translate}}
        </li>
        <li [ngClass]="{'valid': numberPolicy && form.controls.newPassword.value.length > 0 , 'invalid': !numberPolicy && form.controls.newPassword.value.length > 0}" class="pwSecurity__item">
          <mat-icon class="error-icon" svgIcon="custom:full-close"></mat-icon>1
          {{'Number' | translate}}
        </li>
      </ul>
    </div>
  </div>
  <div class="error-notification" *ngIf="(samePasswords || !passwordsMatched) && (form.value.newPassword || form.value.confirmedNewPassword)">{{'Passwords match' | translate}}</div>
  <div class="button-wrapper">
    <div class="button-container">
      <button type="button" class="cancel" #btn (click)="closeDialog()" color="primary" [ngStyle]="{
        'color': '#FFF',
        'background-color': '#9a9a9a',
        'pointer-events': 'visible'
      }">
    <div class="title back">
      {{'Cancel' | translate}}
    </div>
    <div class="button__footer">
      <div class="button__subline">

      </div>
      <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
        <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
      </div>
    </div>
  </button>


  <button
    (click)="submit()"
    type="submit"
    class="submit-bttn"
    [disabled]="!validForm || samePasswords || !passwordsMatched"
    color="primary" mat-button mat-raised-button
  >
    <div class="title">
      {{ "Save" | translate }}
    </div>
    <span class="right-icon"
      ><mat-icon class="error-icon" svgIcon="custom:icon-right"></mat-icon
    ></span>
  </button>
</div>
</div>
</div>
