
import {map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { Moment } from 'moment';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { PagingOptions } from '../../../shared/interfaces/paging-options.interface';
import { SortingOptions } from '../../../shared/interfaces/sorting-options.interface';
import { UserResource } from '../../classes/user-resource.class';
import { DataPage } from '../../../shared/classes/data-page.class';
import { UserSearchModel } from '../../classes/user-search-model.class';
import { ApiResource } from '../../../shared/classes/api-resource.class';
import { DeletedUserSearchModel } from '../../classes/deleted-user-search-model.class';
import { AdminActivityLogResource } from '../../classes/admin-activity-log-resource.class';
import { UserInformationApiResource, UserAddress } from '../../classes/user-information-api-resource.class';
import { UserAccountDataApiResource } from '../../classes/user-account-data-api-resource.class';
import { UserAccountDataModelResource } from '../../classes/user-account-data-model-resource.class';
import { ActivityLogCategoriesResource } from '../../../activity-log/classes/activity-log-categories-resource.class';
import { AdminActivityLogApiResource } from '../../classes/admin-activity-log-api-resource.class';
import {
  AdminActivityLogCategoriesResource
} from '../../../dashboard/classes/admin-activity-log-categories-resource.class';
import { UserActivityLogResource } from '../../classes/user-activity-log-resource.class';
import { ActivityLogSearchModel } from '../../../activity-log/classes/activity-log-search.model';
import { AdminActivityLogSearchModel } from '../../../dashboard/classes/admin-activity-log-search.model';
import { ISearchUser } from '../../interfaces/search-user.interface';
import { UserActivityLogApiResources } from '../../classes/user-activity-log-api-resources.class';

const usersPrefix = 'person';

export interface IUserInformation {
  accountCreated: string;
  welcomeLetterSent: string;
  accountState: string;
  accountStateChanged: string;
  eBriefEnabled: string;
  eBriefServiceEnabledChanged: string;
  authSecLevel: string;
  authSecLevelChanged: string;
  street: string; // <= Address part
  houseNr: string; // <= Address part
  postalCode: string; // <= Address part
  city: string; // <= Address part
  addressStateChangedDate: string;
  activationletterSentDate: string;
  activationLetterConfirmed: string;
  clearingCode: string;
  ssoAccountState: string;
  ssoAccountStateChanged: string;
  lastUpdateFromSsoDate: string;
  addressData: {
    addressStreet: string;
    addressHouseDoorNo: string
    addressPostalCode: string;
    addressCity: string;
    stateChanged: Moment;
    activationLetterSentOn: Moment;
    activationLetterConfirmed: string;
    clearingCode: string;
  }[];
}

export interface IUserInformationData {
  title: string;
  body: object;
}

export interface IAccountData {
  id: string;
  familyName: string;
  givenName: string;
  displayName: string;
  email: string;
  accountNumber: string;
  userName: string;
  phone: string;
  optIn: boolean;
  status: string;
  corporateCustomer: boolean;
  employee: boolean;
  personalBranding: string;
  additionalEmailList: string[];
}

export let adminCategories = {};

@Injectable()
export class UsersService {
  private entriesSource = new BehaviorSubject('');
  currentEntries = this.entriesSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _auth: AuthService
  ) { }

  changeEntries(entries: string) {
    this.entriesSource.next(entries);
  }

  getUserInfo(id: string) {
    return this._http.get(`${environment.apiUrl}/${usersPrefix}/additionalUserInformation/${id}`).pipe(
      map((res: IUserInformation) => {
        const resource = new UserInformationApiResource(res);
        resource.userAddresses = res.addressData.map((address) => new UserAddress(address));
        return resource;
      }));
  }

  getUsersAccountData(id: string) {
    return this._http.get(`${environment.apiUrl}/${usersPrefix}/singlePerson/${id}`).pipe(
      map((res: Response) => {
        return new UserAccountDataApiResource(res);
      }));
  }

  editUserAccountData(accountData: UserAccountDataModelResource) {
    return this._http.put(`${environment.apiUrl}/${usersPrefix}/editAccountData`,
      accountData.toSerializableObject(),
      { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  getUsersActivityLog(options: SortingOptions & PagingOptions, searchData: ActivityLogSearchModel, userId?: string) {
    let params = new HttpParams();
    params = params.append('page', (options.page - 1).toString());
    params = params.append('size', options.pageSize.toString());
    let userDataFilters = {};
    if (userId) {
      userDataFilters = {
        'ownerIds': [userId],
      };
    }
    if (options.sort && options.sort[0] === '-') {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort.slice(1))},desc`);
    } else if (options.sort) {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort)},asc`);
    }

    return this._http
      .post<UserActivityLogApiResources>(
        `${environment.apiUrl}/activityLog/bertUser`,
        {
          ...(searchData && searchData.toSerializableObject()),
          ...userDataFilters
        },
        { params },
      ).pipe(map((response) => {
        return new DataPage<UserActivityLogResource>(
          options.page,
          options.pageSize,
          response.totalItems,
          response.activityItems.map((item) => new UserActivityLogResource(item)),
        );
      }));
  }

  getAdminActivityLog(options: SortingOptions & PagingOptions, searchData: AdminActivityLogSearchModel) {
    let params = new HttpParams();
    params = params.append('page', (options.page - 1).toString());
    params = params.append('size', options.pageSize.toString());
    const userData = this._auth.getUserData();
    const userDataFilters = {
      'user': userData.displayName,
      'tenant': userData.tenantId,
    };

    if (options.sort && options.sort[0] === '-') {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort.slice(1))},desc`);
    } else if (options.sort) {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort)},asc`);
    }

    return this._http
      .post<AdminActivityLogApiResource>(
        `${environment.apiUrl}/activityLog/adminLog`,
        {
          ...(searchData && searchData.toSerializableObject()),
          ...userDataFilters,
        },
        { params },
      ).pipe(map((response: AdminActivityLogApiResource) => {
        return new DataPage(
          options.page,
          options.pageSize,
          response.totalItems,
          response.activityItems.map((item) => new AdminActivityLogResource(item)),
        );
      }));
  }


  deleteUsers(users: { id: string }[]) {
    return this._http.request('delete', `${environment.apiUrl}/${usersPrefix}/deleteUsers`, { body: users });
  }

  restoreUsers(users: { id: string }[]) {
    return this._http.request('delete', `${environment.apiUrl}/${usersPrefix}/restoreDeletedUsers`, { body: users });
  }

  searchUsers(data: UserSearchModel, options: SortingOptions & PagingOptions) {
    const params = this.setParams(options);
    const requestData = data ? data.toSerializableObject() : {}; // Handle null data
    
    return this._http.post<ISearchUser>(
      `${environment.apiUrl}/${usersPrefix}/search`,
      requestData,
      { params }
    ).pipe(
      map((response: ISearchUser) => {
        return new DataPage(
          options.page,
          options.pageSize,
          response.totalItems,
          response.personItems.map((item) => new UserResource(item)),
        );
      })
    );
  }

  searchDeletedUsers(data: DeletedUserSearchModel, options: SortingOptions & PagingOptions) {
    const params = this.setParams(options);
    return this._http.post<ISearchUser>(`${environment.apiUrl}/${usersPrefix}/searchDeleted`,
      data.toSerializableObject(),
      { params }
    ).pipe(map((response: ISearchUser) => {
      return new DataPage(
        options.page,
        options.pageSize,
        response.totalItems,
        response.personItems.map((item) => new UserResource(item)),
      );
    }));
  }

  setParams(options: SortingOptions & PagingOptions) {
    let params = new HttpParams();
    params = params.append('page', (options.page - 1).toString());
    params = params.append('size', options.pageSize.toString());
    if (options.sort && options.sort[0] === '-') {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort.slice(1))},desc`);
    } else if (options.sort) {
      params = params.append('sort', `${ApiResource.getOriginalField(UserResource, options.sort)},asc`);
    }
    return params;
  }

  getUserCategory() {
    return this._http.get(`${environment.apiUrl}/activityLog/categoriesBertUser`).pipe(
      map((response: ActivityLogCategoriesResource) => {
        return new ActivityLogCategoriesResource(response);
      }));
  }

  getAdminCategory() {
    return this._http.get(`${environment.apiUrl}/activityLog/categoriesAdminUser`).pipe(
      map((response: AdminActivityLogCategoriesResource) => {
        return new AdminActivityLogCategoriesResource(response);
      }));
  }

  getPersonForActivities() {
    return this._http.get(`${environment.apiUrl}/person/personsForActivities`);
  }

  updatePassword(passwordData) {
    return this._http.post(`${environment.apiUrl}/person/updatePassword`, passwordData);
  }
  downloadUnreadDocuments(personId: string) {
    return this._http.get(`${environment.apiUrl}/deliveryHandler/prepareDownload?personId=${personId}`)
    .pipe(switchMap(data => {
      console.log("data",data)
      return this._http.get(`${environment.apiUrl}/deliveryHandler/download?token=${data['token']}`,
        {
          observe: 'response',
          responseType: 'blob'
        }
      )}
    ))
  }
}
