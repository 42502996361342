import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MultipleNotificationsService {
  public messages = [];
  constructor() {}

  showNotifications(messages) {
    if (Array.isArray(messages)) {
      // If notification exists, replace it with a new message
      let messagesToAdd = messages.filter((newMsg) => {
        const duplicateIndex = this.messages.findIndex(
          (existingMsg) => existingMsg.message === newMsg.message
        );
        if (duplicateIndex !== -1) {
          this.messages.splice(duplicateIndex, 1);
          return false;
        }
        return true;
      });

      this.messages.push(...messagesToAdd);
    } else {
      const duplicateIndex = this.messages.findIndex(
        (existingMsg) => existingMsg.message === messages.message
      );
      if (duplicateIndex !== -1) {
        this.messages.splice(duplicateIndex, 1);
      }
      this.messages.push(messages);
    }

    setTimeout(() => {
      this.messages.splice(0, messages.length || 1);
    }, 15000);
  }
}
