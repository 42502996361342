import { Injectable } from "@angular/core";
import { PersonRole } from "../../../group/enum/personRole.enum";
import { UserDataResource } from "../../../auth/classes/user-data-resource.class";

@Injectable({
  providedIn: "root",
})
export class UserPermissionService {
  private storageKey = "sv-user-data";

  constructor() {}

  // Get user data from session storage
  getUserData(): UserDataResource {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : null;
  }

  hasRight(right: PersonRole | PersonRole[]): boolean {
    const userData = this.getUserData();

    if (!userData) {
      return false;
    }

    const allRights = this.getAllRights();

    if (Array.isArray(right)) {
      return allRights.some((elem) => right.includes(elem));
    }

    return allRights.includes(right);
  }

  hasRightForTenant(hasPermisionOptions: {
    right: PersonRole | PersonRole[];
    tentnatId?: string;
  }): boolean {
    const { tentnatId, right } = hasPermisionOptions;
    const userData = this.getUserData();

    if (!userData) {
      return false;
    }

    const allRights = this.getAllRightsForTenant(tentnatId);

    if (Array.isArray(right)) {
      return allRights.some((elem) => right.includes(elem));
    }

    return allRights.includes(right);
  }

  getAllRights(): PersonRole[] {
    const userData = this.getUserData();
    const rootRights = userData.rights;

    if (!userData) {
      return [];
    }

    const tenantRights =
      userData.rightsPerTenant &&
      [].concat(...Object.values(userData.rightsPerTenant));
    const rightsSet = new Set([...rootRights, ...(tenantRights || [])]);
    return Array.from(rightsSet);
  }

  getAllRightsForTenant(tentnatId?: string): PersonRole[] {
    const userData = this.getUserData();
    const rootRights = userData.rights;

    if (!userData) {
      return [];
    }

    if (!tentnatId && rootRights) {
      return rootRights;
    }

    const tenantRights =
      userData.rightsPerTenant && userData.rightsPerTenant[tentnatId];
    const rightsSet = new Set([...rootRights, ...(tenantRights || [])]);
    return Array.from(rightsSet);
  }

  // Clear user data from session storage
  clearUserData(): void {
    sessionStorage.removeItem(this.storageKey);
  }
}
