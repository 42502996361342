import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private _auth: AuthService,
    private _router: Router,
  ) { }

  canActivate() {
    const accessToken = this._auth.isLoggedIn;
    if (!accessToken) {
      this._router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
