import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatCheckbox,
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from "@angular/material/checkbox";
import { MatOption } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import { DocumentVisibility } from "../interfaces/visibility";
import { PersonService } from "../person/person.service";
import { xUserService } from "../xUserService/xUser.service";
import { TranslateService } from "@ngx-translate/core";
import { Tenant } from "../enum/tenant.enum";
import { PersonRole } from "../enum/personRole.enum";

@Component({
  selector: "sv-document-visibility",
  templateUrl: "./document-visibility.component.html",
  styleUrls: ["./document-visibility.component.scss"],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: "check" } as MatCheckboxDefaultOptions,
    },
  ],
})
export class DocumentVisibilityComponent implements OnInit {
  public categories: DocumentVisibility[];
  public transformedCategories$: Observable<DocumentVisibility[]>;
  permissionNameMap = {
    Administrator: "Company Admin",
    UserAdministrator: "Customer Creator",
    DeliverySubmitter: "Data Submitter",
    "Company Admin": PersonRole.administrator,
    "Customer Creator": PersonRole.userAdministrator,
    "Data Submitter": PersonRole.deliverySubmitter,
  };

  @ViewChildren("categoriesOption") categoriesOption: QueryList<MatOption>;
  @ViewChildren("checkbox") checkbox: QueryList<MatCheckbox>;
  public tenantId = "";
  constructor(
    private matDialogRef: MatDialogRef<DocumentVisibilityComponent>,
    private _personService: PersonService,
    @Inject(MAT_DIALOG_DATA) private _data,
    public auth: AuthService,
    public xUserService: xUserService,
    private _translate: TranslateService
  ) {
    this.tenantId = JSON.parse(localStorage.getItem("tenant"));
    if (this.tenantId === Tenant.datapart) {
      this._personService
        .getPermisions(this._data.studentId)
        .subscribe((data) => {
          this.categories = data.sort((a, b) =>
            a.categoryType.localeCompare(b.categoryType)
          );
        });
    } else {
      this.xUserService
        .getRoles(this._data.studentId, this._data.groupName)
        .subscribe((data: any) => {
          this.categories = data;
        });
    }

    this.transformedCategories$ = this.xUserService
      .getRoles(this._data.studentId, this._data.groupName)
      .pipe(
        map((data: any) =>
          data.map((category) => ({
            ...category,
            name: this.permissionNameMap[category.name] || category.name,
          }))
        )
      );
  }

  ngOnInit(): void {}
  public submit(): void {
    const documentsVisibility = this.checkbox.toArray().map((matOption) => ({
      categoryType: matOption.value,
      allowed: matOption.checked,
    }));

    this._personService
      .postPermisions(this._data.studentId, documentsVisibility)
      .pipe(
        switchMap(() => this._personService.getPermisions(this._data.studentId))
      )
      .subscribe(
        (data) => {
          this.categories = data;
          this._personService.statusMessage(
            "snack-bar-success",
            "Rights updated"
          );
          this.closeDialog();
        },
        (err) => {
          this._personService.statusMessage(
            "snack-bar-success",
            "Rights failed"
          );
        }
      );
  }
  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  public submitForPayslip() {
    const documentsVisibility = this.checkbox
      .toArray()
      .filter((matOption) => matOption.checked)
      .map((data) => ({
        id: data.value,
        name: this.permissionNameMap[data.name] || data.name,
      }));

    this.xUserService
      .postRoles(
        this._data.studentId,
        documentsVisibility,
        this._data.groupName
      )
      .pipe(
        switchMap(() =>
          this.xUserService.getRoles(this._data.studentId, this._data.groupName)
        )
      )
      .subscribe(
        (data) => {
          this.categories = data;
          this._personService.statusMessage(
            "snack-bar-success",
            "Rights updated"
          );
          this.closeDialog();
          this.xUserService.triggerDataReaload.next(true);
        },
        (err) => {
          this._personService.statusMessage(
            "snack-bar-success",
            "Rights failed"
          );
        }
      );
  }
}
