import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UploadUtilityService } from './upload-utility.service';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class UploadViewService {

  constructor(
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar,
    private _uploadUtilityService: UploadUtilityService,
    ) { }

  public preUpload = true;
  public hasError = true;
  public displaySendBtn = false;
  public displayFileUpload = true;
  public displayPdfConversionCode = false;
  public disablePointerEvent = true;
  public disableUpload = true;
  public displayShServiceLineUsed = true;
  public isDropDownValueChosen = false;
  public containsZip: boolean;
  public closeDeliverDocsToMultipleUserDialog = new Subject()
  public triggerChangeDetection = new Subject<boolean>()
  public statusMessage( message: string,isSucesfull) {
    if (isSucesfull) {      
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      config.duration = 15000;
      config.panelClass = 'snack-bar-success';
      let messageSnackBar = this._translateService.instant(message);
      this._snackbar.open(messageSnackBar, undefined, config);
      // this.matDialogRef.close();
    }
    else {
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      config.duration = 15000;
      config.panelClass = 'snack-bar-fail';
      let messageSnackBar = this._translateService.instant(message);
      this._snackbar.open(messageSnackBar, undefined, config);
    }

  }

  public async displayPdfConversionCodeMethod(templates, preUploadDocuments, uploadedFilesFromEvent) {
    const hasPdfInZip = await Promise.all(preUploadDocuments.map(async (document) => {
      if (document.payload) {
        const binaryContent = await this.convertPayloadToBinary(document.payload);
  
        if (binaryContent) {
          if (this._uploadUtilityService.IsZipFile(document)) {
            return await this.checkForPdfInZip(binaryContent);
          } else {
            return this._uploadUtilityService.isPdf(document.name, 'pdf');
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }));

    const hasAtLeastOnePdf = hasPdfInZip.some(value => value);
    this.displayPdfConversionCode = hasAtLeastOnePdf;
  }
  
  private async convertPayloadToBinary(payload: string): Promise<ArrayBuffer | null> {
    try {
      const binaryData = atob(payload);
  
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
  
      return arrayBuffer;
    } catch (error) {
      return null;
    }
  }
  
  private async checkForPdfInZip(zipContent: ArrayBuffer): Promise<boolean> {
    const zip = new JSZip();

    try {
      const zipData = await zip.loadAsync(zipContent);
      
      let hasPdf = false;
  
      zipData.forEach((relativePath, zipEntry) => {
        if (zipEntry.name.toLowerCase().endsWith('.pdf')) {
          hasPdf = true
        }
      });
  
      return hasPdf;
    } catch (error) {
      return false;
    }
  }

  public removePdfConversionCode(preUploadDocuments) {
    const izZip = this._uploadUtilityService.hasZipFiles(preUploadDocuments.map(document => ({...document,type:document.mimeType})));
    const hasPdf = preUploadDocuments.some(document => this._uploadUtilityService.isPdf(document.name,'pdf'))
    if ((!izZip  && !hasPdf) || preUploadDocuments.lenght > 0) {
      this.displayPdfConversionCode = false
    }
  }
}
