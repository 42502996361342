const cachedNumberFormats: any = {};

export function formatNumberToLocalLangFormat(lang: string, value: number): string {
  let numberFormat = cachedNumberFormats[lang];
  if (!numberFormat) {
    numberFormat = cachedNumberFormats[lang] = new Intl.NumberFormat(lang);
  }
  return numberFormat.format(value);
}
export function convertToMb(fileSize: number): number {
  const convertedSize = fileSize / 1024 / 1024;

  return convertedSize;
}