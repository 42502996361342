import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DictionaryXMLService {
  constructor(
    private http: HttpClient,
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar
  ) {}

  public getXMLTemplates() {
    return this.http.get(`${environment.apiUrl}/group/xmlTemplate`);
  }

  public getXMLTemplatesForCompany(companyName: string) {
    return this.http.get(
      `${environment.apiUrl}/group/template/tenant/${companyName}`
    );
  }

  public assignXMLDictionary(companyName: string, payload) {
    return this.http.put(
      `${environment.apiUrl}/group/assignTemplate/${companyName}`,
      payload
    );
  }

  public handleSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-success";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }
}
