<div class="export-csv">
  <div class="export-csv-title">
      <h1 mat-dialog-title>{{ "Download CSV"  | translate }}</h1>
      <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>
  <div mat-dialog-content>
      <form [formGroup]="exportAccountingForm" (ngSubmit)="onSubmit()" class="form">
        <mat-form-field color="accent">
          <mat-label>{{ "Month" | translate }}</mat-label>
          <mat-select formControlName="month">
            <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
          </mat-select>
            <mat-error *ngIf="exportAccountingForm.controls.month.touched || exportAccountingForm.controls.month.hasError('required')">
              {{ "Required" | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ "Type" | translate }}</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type">{{ type | translate }}</mat-option>
          </mat-select>
            <mat-error *ngIf="exportAccountingForm.controls.type.touched || exportAccountingForm.controls.type.hasError('required')">
              {{ "Required" | translate }}
            </mat-error>
        </mat-form-field>
        <button type="submit" class="submit-bttn" mat-button mat-raised-button color="primary" >{{ "Download CSV" | translate }} 
        </button>
      </form>
  </div>
</div>
<div class="loading-container" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>