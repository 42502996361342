import { Moment } from 'moment';
// @ts-ignore
import * as _keys from 'lodash/keys';

import { ApiModel, Serialize, SerializeDate } from '../../shared/classes/api-model.class';
import { ActivityLogCategory } from '../enums/activity-log-category.enum';
import { IActivityLogSelectedCategory } from '../../shared/interfaces/activity-log-selected-category.interface';

export class ActivityLogSearchModel extends ApiModel {
  @Serialize() public text: string;
  @Serialize() public userName: string;

  @SerializeDate({ convertToUtc: true, format: 'YYYY-MM-DDT[00:00:00.000]' }) public startDate: Moment | string;
  @SerializeDate({ convertToUtc: true, format: 'YYYY-MM-DDT[23:59:59.000]' }) public endDate: Moment | string;

  public categories: IActivityLogSelectedCategory;

  toSerializableObject() {
    const serializableObject = ApiModel.prototype.toSerializableObject.call(this);
    const categoryMap: { [key: string]: string } = {
      [ActivityLogCategory.Delivery]: 'DELIVERY',
      [ActivityLogCategory.Notification]: 'NOTIFICATION',
      [ActivityLogCategory.DeliveryDeleted]: 'DELIVERY_DELETED',
      [ActivityLogCategory.Document]: 'DOCUMENT',
      [ActivityLogCategory.Login]: 'LOGIN',
      [ActivityLogCategory.Payment]: 'PAYMENT',
      [ActivityLogCategory.Email]: 'EMAIL',
      [ActivityLogCategory.SMS]: 'SMS',
      [ActivityLogCategory.UserAccount]: 'USER_ACCOUNT',
      [ActivityLogCategory.Postbox]: 'POSTBOX',
      [ActivityLogCategory.Update]: 'UPDATE',
      [ActivityLogCategory.Delete]: 'DELETE',
    };

    serializableObject.category = _keys(this.categories)
      .filter((cat: string) => this.categories[cat])
        .map((cat: string) => categoryMap[cat]);

    return serializableObject;
  }
}
