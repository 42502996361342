import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
export class MaintenanceDate{
  year:number;
  month:number;
  day:number;
  hours:number;
  minutes:number;
}
@Component({
  selector: 'app-banner-component',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit {
  @Input() fromToDate: string;
  @ViewChild('banner') banner: ElementRef<HTMLDivElement>
  
  constructor() {
  }

  ngOnInit(): void {
  }
  closeBanner() {
    this.banner.nativeElement.style.display = "none"
  }
}
