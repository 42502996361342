import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
// @ts-ignore
import * as _keys from 'lodash/keys';

import { UserStatus } from '../../enums/user-status.enum';
import { ActivityLogCategory } from '../../../activity-log/enums/activity-log-category.enum';
import { OnSet } from '../../../shared/decorators/on-set/on-set.decorator';
import { ActivityLogSearchModel } from '../../../activity-log/classes/activity-log-search.model';
import { IUserSearchFields } from '../../interfaces/user-search-fields.interface';
import { StoreService } from '../../../shared/services/store/store.service';
import { SearchStoreType } from '../../../shared/enums/searchStoreType.enum';

interface IMap {
  [key: string]: string;
}

@Component({
  selector: 'sv-user-search-display',
  templateUrl: './user-search-display.component.html',
  styleUrls: ['./user-search-display.component.scss']
})
export class UserSearchDisplayComponent<T> {
  @Input() totalItems: string;
  @Input() totalLabel: string;
  @Input() searchType: SearchStoreType;
  @OnSet('updateChips') @Input() public filterValues: T[] = [];
  @Output() public filterValuesChange = new EventEmitter();
  public removable = true;
  public pipe = new DatePipe('en-US');

  public queryChips = [];
  public searchData: ActivityLogSearchModel;
  public queryFieldsDisplayMap: IUserSearchFields;

  constructor(
    private _translate: TranslateService,
    private _searchStore: StoreService,
  ) {
    _translate.onLangChange.subscribe(() => {
      this.updateChips();
    });
  }

  // remote filter
  public remove(queryField: string): void {
    this.filterValues[queryField] = undefined;
    this.filterValuesChange.emit(this.filterValues);
    if (typeof(this.searchType) !== 'undefined') {
      this._searchStore.clearSearchField(queryField, this.searchType);
    }
    this.updateChips();
  }

  // use the filter values object to create a list of query chips to display
  public updateChips() {
    if (!this.filterValues) {
      this.queryChips = [];
    } else {
      // Map object for display names for each field
      this.queryFieldsDisplayMap = {
        firstName: this._translate.instant('First Name'),
        lastName: this._translate.instant('Last Name'),
        displayName: this._translate.instant('Display Name'),
        userName: this._translate.instant('Username'),
        eMail: this._translate.instant('Email'),
        accountNumber: this._translate.instant('Account Number'),
        optIn: this._translate.instant('Opt Ins'),
        corporateCustomer: this._translate.instant('Corporate customer'),
        employee: this._translate.instant('Employee'),
        inboxWithoutEmail: this._translate.instant('Inbox Without Email Address'),
        status: this._translate.instant('Status'),
        email: this._translate.instant('Email'),
        deletedFrom: this._translate.instant('Deleted from'),
        deletedTo: this._translate.instant('Deleted to'),
        categories: this._translate.instant('Category'),
        searchText: this._translate.instant('Searched Text'),
        text: this._translate.instant('Searched Text'),
        startDate: this._translate.instant('Start Date'),
        endDate: this._translate.instant('End Date'),
        ownerId: this._translate.instant('Username'),
        groupDescription: this._translate.instant('Description'),
        role: this._translate.instant('Function')
      };

      // Map object for display strings for user status
      const statusDisplayMap: IMap =  {
        [UserStatus.Open]: this._translate.instant('Open'),
        [UserStatus.NotOpen]: this._translate.instant('NotOpen'),
        [UserStatus.Closed]: this._translate.instant('Closed'),
        [UserStatus.Unconfirmed]: this._translate.instant('Unconfirmed'),
        [UserStatus.Deactivated]: this._translate.instant('Deactivated'),
        [UserStatus.Disabled]: this._translate.instant('Disabled'),
        [UserStatus.Inactive]: this._translate.instant('Inactive'),
      };

      // Map object for display strings for activity log category
      const categoryDisplayMap: IMap = {
        [ActivityLogCategory.Delivery]: this._translate.instant('Delivery'),
        [ActivityLogCategory.DeliveryDeleted]: this._translate.instant('Delivery Deleted'),
        [ActivityLogCategory.Document]: this._translate.instant('Document'),
        [ActivityLogCategory.Login]: this._translate.instant('Login'),
        [ActivityLogCategory.Logout]: this._translate.instant('Logout'),
        [ActivityLogCategory.Notification]: this._translate.instant('Notification'),
        [ActivityLogCategory.Payment]: this._translate.instant('Payment'),
        [ActivityLogCategory.SMS]: this._translate.instant('SMS'),
        [ActivityLogCategory.Email]: this._translate.instant('Email'),
        [ActivityLogCategory.UserAccount]: this._translate.instant('User Account'),
        [ActivityLogCategory.Postbox]: this._translate.instant('Inbox'),
        [ActivityLogCategory.Update]: this._translate.instant('Update'),
        [ActivityLogCategory.Delete]: this._translate.instant('Delete'),
      };

      // Some fields have special toString functions
      const queryFieldsValueToString = {
        status: (value: string | number) => statusDisplayMap[value],
        endDate: (value: string | number) => this.pipe.transform(value, 'mediumDate'),
        startDate: (value: string | number) => this.pipe.transform(value, 'mediumDate'),
        deletedFrom: (value: string | number) => this.pipe.transform(value, 'mediumDate'),
        deletedTo: (value: string | number) => this.pipe.transform(value, 'mediumDate'),
        categories: (par: string[] | number[]) => {
          return _keys(par).map((cat) => categoryDisplayMap[cat]).join(', ');
        },
        ownerId: (value: { displayName: string }) => {
          return value.displayName;
        }
      };
      // populate query chips using the above map objects to get displayValues, also filter out empty values
      this.queryChips = _keys(this.filterValues).filter((queryField) => this.filterValues[queryField]).map((queryField) => ({
        field: queryField,
        displayValue: queryFieldsValueToString[queryField] ?
          queryFieldsValueToString[queryField](this.filterValues[queryField])
          : this.filterValues[queryField],
      })).filter((chip) => !!chip.displayValue);
    }
  }
}
