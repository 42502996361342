import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { dashboardRoutes } from './dashboard.routes';
import { UsersModule } from '../users/users.module';
import { DesignModule } from '../design/design.module';
import { CustomTabModule } from '../custom-tab/custom-tab.module';
import { StatisticsModule } from '../statistics/statistics.module';
import { ActivityLogModule } from '../activity-log/activity-log.module';
import { SidebarService } from './services/sidebar-service/sidebar.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { AdminActivityLogComponent } from './components/admin-activity-log/admin-activity-log.component';
import { SearchAdminLogComponent } from './components/search-admin-activity-dialog/search-admin-log.component';
import { AutoLogoutDialogComponent } from './components/auto-logout-dialog/auto-logout-dialog.component';
import { GroupModule } from '../group/group.module';
import { AuthService } from '../auth/services/auth.service';
import { StubsModule } from '../stubs/stubs.module';
import { UploadService } from '../shared/components/upload/upload.service';
import { FaqComponent } from './components/faq/faq.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionContentComponent } from './components/accordion/accordion-content.component';
import { AccordionGroupComponent } from './components/accordion/accordion-group.component';
import { AccordionHeaderComponent } from './components/accordion/accordion-header.component';


@NgModule({
    imports: [
        CommonModule,
        UsersModule,
        RouterModule.forChild(dashboardRoutes),
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        CustomTabModule,
        StatisticsModule,
        ActivityLogModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatMenuModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatTooltipModule,
        DesignModule,
        FormsModule,
        MatProgressSpinnerModule,
        GroupModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        DashboardComponent,
        LogoutDialogComponent,
        AdminActivityLogComponent,
        SearchAdminLogComponent,
        AutoLogoutDialogComponent,
        FaqComponent,
        AccordionComponent,
        AccordionContentComponent,
        AccordionGroupComponent,
        AccordionHeaderComponent,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        DashboardComponent,
        AccordionComponent,
        AccordionContentComponent,
        AccordionGroupComponent,
        AccordionHeaderComponent,
    ],
    providers: [
        SidebarService,
        UploadService,
        { provide: MatDialogRef, useValue: {} },
    ]
})
export class DashboardModule { }
