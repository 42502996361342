export enum UserStatus {
  Unconfirmed = 'Unconfirmed',
  Deactivated = 'Deactivated',
  Open = 'Open',
  NotOpen = 'NotOpen',
  Disabled = 'Disabled',
  Closed = 'Closed',
  Inactive = 'Inactive',
  AllStatuses = 'AllStatuses'
}
