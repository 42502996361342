import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { UsersModule } from '../users/users.module';
import { StatisticsService } from './services/statistics/statistics.service';
import { StatisticsGeneralComponent } from './components/statistics-general/statistics-general.component';
import { StatisticsEbriefComponent } from './components/statistics-ebrief/statistics-ebrief.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    UsersModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SharedModule
  ],
  declarations: [
    StatisticsGeneralComponent,
    StatisticsEbriefComponent,
    StatisticsComponent
  ],
  providers: [
    StatisticsService,
  ]
})
export class StatisticsModule { }
