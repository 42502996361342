<div class="create_edit_group_dialog_content">
  <div class="create_edit_group_dialog_content__header">
      <h1 mat-dialog-title>{{ !groupId && !externalid ? ('Create Group' | translate) : ('Edit Group'  | translate) }}</h1>
      <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>
  <div mat-dialog-content class="create_edit_group_dialog__form">
      <form [formGroup]="createOrEditForm" (ngSubmit)="submit()">
          <mat-form-field color="primary">
            <mat-label>{{'Account Number' | translate}}*</mat-label>
              <input matInput autocomplete="off" formControlName="externalId" placeholder="{{'Account Number' | translate}}*">
                  <mat-error *ngIf="createOrEditForm.controls.externalId.touched && createOrEditForm.controls.externalId.hasError('required')">
                      {{ 'Required' | translate}}
                  </mat-error>
                  <mat-error *ngIf="createOrEditForm.controls.externalId.touched && createOrEditForm.controls.externalId.hasError('pattern') && !createOrEditForm.controls.externalId.hasError('required')">
                      {{'Exactly 5 digits required' | translate}}
                  </mat-error>
                  <mat-error *ngIf="createOrEditForm.controls.externalId.touched && createOrEditForm.controls.externalId.hasError('notUnique') && !createOrEditForm.controls.externalId.hasError('required')">
                    {{'Unique id required' | translate}}
                </mat-error>
          </mat-form-field>
          <mat-form-field color="primary">
            <mat-label>{{'Display Name' | translate}}</mat-label>
              <input matInput autocomplete="off" formControlName="name" placeholder="{{'Display Name' | translate}}">
                  <mat-error *ngIf="createOrEditForm.controls.name.touched && createOrEditForm.controls.name.hasError('required')">
                      {{'Required' | translate}}
                  </mat-error>
          </mat-form-field>
          <mat-form-field color="primary">
            <mat-label>{{'Description' | translate}}</mat-label>
              <input matInput autocomplete="off" formControlName="description" placeholder="{{'Description'| translate}}" >
                  <mat-error *ngIf="createOrEditForm.controls.description.touched && createOrEditForm.controls.description.hasError('required')">
                      {{'Required' | translate}}
                  </mat-error>
          </mat-form-field>

          <button color="primary" type="submit" [disabled]="!createOrEditForm.valid" mat-button mat-raised-button>{{'Submit' | translate}}</button>
      </form>
  </div>
</div>

<div class="loading-container" *ngIf="isLoading">
  <mat-progress-spinner  color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
