export class GeneralTableSettings {
    accountNumber: string;
    // corporateCustomer: string;
    displayName: string;
    email: string;
    employee: string;
    // optIn: string;
    status: string;
    userName: string;
    export: string;
    unreadDocuments: string;
}
