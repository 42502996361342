<div class="group-prefixes-dialog">
    <div class="group-prefixes-dialog_header">
        <h1 mat-dialog-title>{{'Company Prefixes' | translate}}</h1>
        <mat-icon class="close" (click)="closeDialog()"  [svgIcon]="'custom:close'"></mat-icon>
    </div>
    <div mat-dialog-content class="prefixes-fields">
        <form [formGroup]="companyPrefixes" (ngSubmit)="submit()">
            <div class="user-prefix" [ngClass]="i === data.length - 1 && 'last-prefix'" *ngFor="let prefix of data; let i = index">
                <mat-form-field color="primary">
                    <mat-label>
                        {{'User Prefix' | translate}}
                    </mat-label>
                    <input matInput autocomplete="off" [formControlName]="'userPrefix' + (i)">
                        <mat-error *ngIf="companyPrefixes.controls['userPrefix' + i].touched && companyPrefixes.controls['userPrefix' + i].hasError('required')">
                            {{'Required' | translate}}
                        </mat-error>
                </mat-form-field>
                <mat-form-field color="primary">
                    <mat-label>
                        {{'Subcompany Prefix' | translate}}
                    </mat-label>
                    <input matInput autocomplete="off" [formControlName]="'subCompanyPrefix' + (i)" >
                        <mat-error *ngIf="companyPrefixes.controls['subCompanyPrefix' + i].touched && companyPrefixes.controls['subCompanyPrefix' + i].hasError('sameSubCompanyPrefix')">
                            {{'Invalid value' | translate}}
                        </mat-error>
                </mat-form-field>
                <mat-form-field color="primary">
                    <mat-label>
                        {{'Subcompany Name' | translate}}
                    </mat-label>
                    <input matInput autocomplete="off" [formControlName]="'subCompanyName' + (i)">
                        <mat-error *ngIf="companyPrefixes.controls['subCompanyName' + i].touched && companyPrefixes.controls['subCompanyName' + i].hasError('required')">
                            {{'Required' | translate}}
                        </mat-error>
                </mat-form-field>
                <mat-form-field color="primary">
                    <mat-label>
                        {{'Prefix Length' | translate}}
                    </mat-label>
                    <input type="number" min="0" matInput autocomplete="off" [formControlName]="'identPrefixLength' + (i)">
                        <mat-error *ngIf="companyPrefixes.controls['identPrefixLength' + i].touched && companyPrefixes.controls['identPrefixLength' + i].hasError('required')">
                            {{'Required' | translate}}
                        </mat-error>
                </mat-form-field>
                <div color="primary" class="checkbox-wrapper">
                    <input type="checkbox" [id]="'keepZeros' + (i)" [formControlName]="'keepZeros' + (i)">
                  <label [for]="'keepZeros' + (i)">{{"Don't trim leading zeros" | translate}}</label>
                </div>
            </div>
            <div class="buttons" *ngIf="isSuperAdmin">
                <button color="primary"  mat-button mat-raised-button (click)="addCompanyPrefix($event)" color="primary" >{{'Add User Prefix' | translate}}</button>
                <button color="primary" type="submit" mat-button mat-raised-button>{{'Save' | translate}}</button>
            </div>
        </form>
    </div>
</div>
