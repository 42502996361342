import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../auth/services/auth.service";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { PersonRole, PersonRoleDatapart } from "../enum/personRole.enum";
import { PersonService } from "../person/person.service";

@Component({
  selector: "sv-search-person-dialog",
  templateUrl: "./search-person-dialog.component.html",
  styleUrls: ["./search-person-dialog.component.scss"],
})
export class SearchPersonDialogComponent implements OnInit {
  searchUserForm: UntypedFormGroup;
  public userData = this.authService.getUserData();
  public isAdmin: boolean;
  public data;
  public tenant: string;
  public roles = Object.keys(PersonRoleDatapart).map((key) => ({
    key,
    value: PersonRoleDatapart[key],
  }));
  constructor(
    public dialogRef: MatDialogRef<SearchPersonDialogComponent>,
    public authService: AuthService,
    private _personService: PersonService,
    private _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    this.tenant = this.userData.tenantId;
    this.searchUserForm = new UntypedFormGroup({
      accountNumber: new UntypedFormControl(
        this._personService.searchPersonQuery.accountNumber
      ),
      email: new UntypedFormControl(
        this._personService.searchPersonQuery.email
      ),
      userName: new UntypedFormControl(
        this._personService.searchPersonQuery.userName
      ),
    });
    if (!data.adminUsers) {
      this.searchUserForm.addControl(
        "displayName",
        new UntypedFormControl(
          this._personService.searchPersonQuery.displayName || ""
        )
      );
    } else {
      this.searchUserForm.addControl(
        "firstName",
        new UntypedFormControl(
          this._personService.searchPersonQuery.firstName || ""
        )
      );
      this.searchUserForm.addControl(
        "lastName",
        new UntypedFormControl(
          this._personService.searchPersonQuery.lastName || ""
        )
      );
    }
    if (this.tenant === "datapart") {
      this.searchUserForm.addControl(
        "role",
        new UntypedFormControl(this._personService.searchPersonQuery.role || "")
      );
    }
    this.isAdmin = this._userPermissionService.hasRight(PersonRole.admin);
  }

  ngOnInit(): void {}

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  }

  public onReset() {
    this.searchUserForm.reset();
    this._personService.searchPersonQuery = {
      accountNumber: "",
      displayName: "",
      email: "",
      userName: "",
      firstName: "",
      lastName: "",
      role: "",
    };
    this.dialogRef.close(this.searchUserForm.value);
  }
  public onSubmit() {
    if (!this.searchUserForm.value.role) {
      this.searchUserForm.value.role = null;
    }
    this._personService.searchPersonQuery = this.searchUserForm.value;
    this.dialogRef.close(this.searchUserForm.value);
  }
}
