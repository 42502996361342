<div class="group-person-container">

  <div class="group-person-container__header">
    <h1>{{'Admin Users from' | translate}} {{ groupDescription | slice:0:20 }}{{ groupDescription?.length > 20 ? '...' :
      '' }}</h1>
    <div class="button-wraper">
      <button mat-button mat-raised-button color="primary" style="margin-right: 10px;" (click)="createXUser()">{{'Create
        Admin User' | translate}}</button>
      <div id="searchButton" class="search" (click)="openSearchPersonDialog()">
        <mat-icon class="search-icon" aria-label="">search</mat-icon>
        <span class="search-label" translate>Search</span>
        <mat-icon class="mat-arrow-drop-down" aria-label="">arrow_drop_down</mat-icon>
      </div>
    </div>
  </div>
  <sv-user-search-display [totalItems]="(persons$ | async)?.content?.length"
    [filterValues]="_personService.searchPersonQuery"
    (filterValuesChange)="filterValueChange($event)"></sv-user-search-display>

  <sv-table [data]="(persons$ | async)?.content" [columns]="personsTableColumns" [page]="(persons$ | async)?.number"
    pageSize="pageSize" [(selected)]="selectedRows" cursorOnHover="true" [actions]="actions"
    (actionEvent)="action($event)" [(sort)]="sort" (sortChange)="sortEvent($event)"
    (handleRowClick)="handleRowClick($event)"></sv-table>
  <mat-paginator [length]="(persons$ | async)?.totalElements" [pageSize]="pageSize"
    [pageIndex]="(persons$ | async)?.number" (page)="changePage($event)"></mat-paginator>

</div>