import { Component, OnInit, Inject, ViewChild, AfterViewInit, Renderer2, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
import * as _pickBy from 'lodash/pickBy';
// @ts-ignore
import * as _assign from 'lodash/assign';
import * as moment from 'moment';
import { Moment } from 'moment';

import { ActivityLogSearchModel } from '../../classes/activity-log-search.model';
import { ActivityLogCategory } from '../../enums/activity-log-category.enum';
import { getCurrentDate } from '../../../shared/utils/date.util';
import { ISearchCategoryCheckbox } from '../../../shared/interfaces/search-category-checkbox.interface';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'sv-search-activity-log',
  templateUrl: './search-activity-log.component.html',
  styleUrls: ['./search-activity-log.component.scss']
})
export class SearchActivityLogComponent implements OnInit {
  public model: ActivityLogSearchModel;
  @ViewChild('searchActivity') public form: NgForm;
  public currentDate = getCurrentDate();
  public maxStartDate: Moment | string;
  public textColor = '';
  public categoryCheckboxes: ISearchCategoryCheckbox[] = [
    { value: ActivityLogCategory.Delivery, label: this._translate.instant('Delivery') },
    { value: ActivityLogCategory.Notification, label: this._translate.instant('Notification') },
    { value: ActivityLogCategory.DeliveryDeleted, label: this._translate.instant('Delivery Deleted') },
    { value: ActivityLogCategory.Document, label: this._translate.instant('Document') },
    { value: ActivityLogCategory.Login, label: this._translate.instant('Login') },
    { value: ActivityLogCategory.Payment, label: this._translate.instant('Payment') },
    { value: ActivityLogCategory.Email, label: this._translate.instant('Email') },
    { value: ActivityLogCategory.SMS, label: this._translate.instant('SMS') },
    { value: ActivityLogCategory.UserAccount, label: this._translate.instant('User Account') },
    { value: ActivityLogCategory.Postbox, label: this._translate.instant('Inbox') },
    { value: ActivityLogCategory.Update, label: this._translate.instant('Update') },
    { value: ActivityLogCategory.Delete, label: this._translate.instant('Delete') },
  ];

  public filteredCategoryCheckboxes: ISearchCategoryCheckbox[];

  constructor(
    public dialogRef: MatDialogRef<SearchActivityLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { searchData: ActivityLogSearchModel, categories: ActivityLogCategory[] },
    private _translate: TranslateService,
    public authService: AuthService,
  ) { 



  }




  ngOnInit() {

    this.dialogRef.updatePosition({
      right: '1%',
      bottom: '30px'
    });
    
    this.model = this.data.searchData || new ActivityLogSearchModel();
    this.maxStartDate = this.model.endDate || this.currentDate;
    this.model.categories = this.model.categories || {};
    this.filteredCategoryCheckboxes = this.categoryCheckboxes
      .filter((c: ISearchCategoryCheckbox) => this.data.categories.indexOf(c.value) !== -1);
      this.authService.textColors.subscribe(res=> this.textColor = res.input)

    }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onFormSubmit();
    }
  }

  public onReset() {
    this.form.reset();
    this.model.categories = {};
    this.maxStartDate = this.currentDate;
  }

  // For maxStartDate first we use selected endDate that user picked from datepicker,
  // if there is no endDate selected then take currentDate asa maxstartDate
  public setStartDateMax() {
    this.maxStartDate = this.model.endDate || this.currentDate;
  }

  public onFormSubmit() {
    // Filter out the properties in the models categories (checkboxes) that have false (unchecked) values
    const data: ActivityLogSearchModel = _assign(new ActivityLogSearchModel(), {
      ...this.model,
      userName: this.model.userName ? this.model.userName.trim() : '',
      categories: _pickBy(this.model.categories, (value) => value),
    });
    this.dialogRef.close(data);
  }

}
