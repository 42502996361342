import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { environment } from "../../../../environments/environment";
import { PersonRole } from "../../../group/enum/personRole.enum";
import { UserPermissionService } from "../../../shared/services/user-permission/user-permission.service";

@Component({
  selector: "tp-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          overflow: "hidden",
          height: "*",
        })
      ),
      state(
        "closed",
        style({
          overflow: "hidden",
          height: "1px",
        })
      ),
      transition("open => closed", [animate("0.6s")]),
      transition("closed => open", [animate("0.4s")]),
      // transition('open <=> closed', [
      //   animate('400ms ease-in-out')
      // ])
    ]),
  ],
})
export class FaqComponent implements OnInit {
  public newUrl: string;
  public infoMailNumber = "";
  public isSuperAdmin: boolean;
  public isAdministrator: boolean;
  public temporaryEnvironment;
  public isEdeja =
    environment.apiUrl ===
      "http://10.5.10.34:8085/be.e-gehaltszettel_t/supportView/v1" ||
    environment.apiUrl ===
      "https://supportviewpayslip.edeja.com/be/supportView/v1";
  constructor(
    private _meta: Meta,
    private _userPermissionService: UserPermissionService
  ) {
    this.temporaryEnvironment = { ...environment };
    this.isAdministrator = this._userPermissionService.hasRight(
      PersonRole.administrator
    );
    this.isSuperAdmin = this._userPermissionService.hasRight(PersonRole.admin);
  }
  ngOnInit() {
    if (this.isEdeja) {
      this.temporaryEnvironment.apiUrl =
        "https://eboxpayslip.edeja.com/be.e-gehaltszettel_t";
    } else {
      this.temporaryEnvironment.apiUrl = environment.apiUrl;
      this.newUrl = this.temporaryEnvironment.apiUrl
        .split("/")
        .slice(0, 4)
        .join("/");
    }
  }
}
