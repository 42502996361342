import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// @ts-ignore
import * as _forEach from 'lodash/forEach';
// @ts-ignore
import * as _assign from 'lodash/assign';
// @ts-ignore
import * as _forIn from 'lodash/forIn';

import { ActivityLogSearchModel } from '../../../activity-log/classes/activity-log-search.model';
import { AdminActivityLogSearchModel } from '../../../dashboard/classes/admin-activity-log-search.model';
import { DeletedUserSearchModel } from '../../../users/classes/deleted-user-search-model.class';
import { UserSearchModel } from '../../../users/classes/user-search-model.class';
import { SearchStoreType } from '../../enums/searchStoreType.enum';

@Injectable()
export class StoreService {
  private _currentUserSearchStore: UserSearchModel = null;
  private _currentUserActivityLogSearchStore: ActivityLogSearchModel = null;
  private _currentUsersActivityLogSearchStore: ActivityLogSearchModel = null;
  private _currentAdminActivityLogSearchStore: AdminActivityLogSearchModel = null;
  private _currentDeletedUserSearchStore: DeletedUserSearchModel = null;

  private _userSearch: BehaviorSubject<UserSearchModel> = new BehaviorSubject(null);
  private _userActivityLogSearch: BehaviorSubject<ActivityLogSearchModel> = new BehaviorSubject(null);
  private _usersActivityLogSearch: BehaviorSubject<ActivityLogSearchModel> = new BehaviorSubject(null);
  private _adminActivityLogSearch: BehaviorSubject<AdminActivityLogSearchModel> = new BehaviorSubject(null);
  private _deletedUsersSearch: BehaviorSubject<DeletedUserSearchModel> = new BehaviorSubject(null);

  public get userActivityLogSearch() {
    return this._userActivityLogSearch.asObservable();
  }

  public get usersActivityLogSearch() {
    return this._usersActivityLogSearch.asObservable();
  }

  public get adminActivityLogSearch() {
    return this._adminActivityLogSearch.asObservable();
  }

  public get deletedUserSearch() {
    return this._deletedUsersSearch.asObservable();
  }

  public get userSearch() {
    return this._userSearch.asObservable();
  }

  constructor() { }

  public saveSearch(
      search: Partial<ActivityLogSearchModel | AdminActivityLogSearchModel | UserSearchModel | DeletedUserSearchModel>,
      searchType: SearchStoreType
  ) {
    switch (searchType) {
      case SearchStoreType.USER:
        this._currentUserSearchStore = _assign(new UserSearchModel(), search);
        this._userSearch.next(this._currentUserSearchStore);
        break;
      case SearchStoreType.ADMIN:
        this._currentAdminActivityLogSearchStore = _assign(new AdminActivityLogSearchModel(), search);
        this._adminActivityLogSearch.next(this._currentAdminActivityLogSearchStore);
        break;
      case SearchStoreType.USERS_ACTIVITY:
        this._currentUsersActivityLogSearchStore = _assign(new ActivityLogSearchModel(), search);
        this._usersActivityLogSearch.next(this._currentUsersActivityLogSearchStore);
        break;
      case SearchStoreType.USER_ACTIVITY:
        this._currentUserActivityLogSearchStore = _assign(new ActivityLogSearchModel(), search);
        this._userActivityLogSearch.next(this._currentUserActivityLogSearchStore);
        break;
    }
  }

  public clearSearchField(searchFields: string, searchType: SearchStoreType) {
    switch (searchType) {
      case SearchStoreType.USER:
        if (searchFields in this._currentUserSearchStore) {
          this._currentUserSearchStore[searchFields] = null;
        }

        this._userSearch.next(this._currentUserSearchStore);
        break;
      case SearchStoreType.USER_ACTIVITY:
        if (searchFields in this._currentUserActivityLogSearchStore) {
          this._currentUserActivityLogSearchStore[searchFields] = null;
        }

        this._userActivityLogSearch.next(this._currentUserActivityLogSearchStore);
        break;
      case SearchStoreType.USERS_ACTIVITY:
        if (searchFields in this._currentUsersActivityLogSearchStore) {
          this._currentUsersActivityLogSearchStore[searchFields] = null;
        }

        this._usersActivityLogSearch.next(this._currentUsersActivityLogSearchStore);
        break;
      case SearchStoreType.ADMIN:
        if (searchFields in this._currentAdminActivityLogSearchStore) {
          this._currentAdminActivityLogSearchStore[searchFields] = null;
        }

        this._adminActivityLogSearch.next(this._currentAdminActivityLogSearchStore);
        break;
      case SearchStoreType.DELETED_USER:
        if (searchFields in this._currentDeletedUserSearchStore) {
          this._currentDeletedUserSearchStore[searchFields] = null;
        }

        this._deletedUsersSearch.next(this._currentDeletedUserSearchStore);
        break;
    }
  }

  public clearSearch(searchType: SearchStoreType) {
    switch (searchType) {
      case SearchStoreType.USER:
        if (this._currentUserSearchStore) {
          // @ts-ignore
          _forIn(this._currentUserSearchStore, (value, key) => this._currentUserSearchStore[key] = null);
        }
        this._userSearch.next(this._currentUserSearchStore);
        break;
      case SearchStoreType.USER_ACTIVITY:
        if (this._currentUserActivityLogSearchStore) {
          // @ts-ignore
          _forIn(this._currentUserActivityLogSearchStore, (value, key) => this._currentUserActivityLogSearchStore[key] = null);
        }
        this._userActivityLogSearch.next(this._currentUserActivityLogSearchStore);
        break;
      case SearchStoreType.USERS_ACTIVITY:
        if (this._currentUsersActivityLogSearchStore) {
          // @ts-ignore
          _forIn(this._currentUsersActivityLogSearchStore, (value, key) => this._currentUsersActivityLogSearchStore[key] = null);
        }
        this._usersActivityLogSearch.next(this._currentUsersActivityLogSearchStore);
        break;
      case SearchStoreType.ADMIN:
        if (this._currentAdminActivityLogSearchStore) {
          // @ts-ignore
          _forIn(this._currentAdminActivityLogSearchStore, (value, key) => this._currentAdminActivityLogSearchStore[key] = null);
        }
        this._adminActivityLogSearch.next(this._currentAdminActivityLogSearchStore);
        break;
      case SearchStoreType.DELETED_USER:
        if (this._currentDeletedUserSearchStore) {
          // @ts-ignore
          _forIn(this._currentDeletedUserSearchStore, (value, key) => this._currentDeletedUserSearchStore[key] = null);
        }
        this._deletedUsersSearch.next(this._currentDeletedUserSearchStore);
        break;
    }
  }

}
