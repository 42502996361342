import { Injectable, inject } from '@angular/core';

import { Observable, of } from 'rxjs';
import { CanDeactivate, CanDeactivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MaintenanceService } from '../../shared/services/maintenance/maintenance.service';

export type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
 }
 
export const canDeactivateFn: CanDeactivateFn<any> = (component: CanComponentDeactivate) => {
  const maintanaceService = inject(MaintenanceService)
  return !maintanaceService.getMaintance();
}

