import { Routes } from '@angular/router';

import { LoginComponent } from './auth/components/login/login.component';
import { ErrorPageComponent } from './server-status/components/error-page/error-page.component';
import { CustomMaintanacePageComponent } from './custom-maintanance-page/custom-maintanance-page.component';
import { canDeactivateFn } from './auth/guards/maintanace.guard';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path:'maintance-page',
    component: CustomMaintanacePageComponent,
    canDeactivate: [canDeactivateFn]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
