import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
// import { Subscription } from 'rxjs/Subscription';

// @ts-ignore
import * as _assign from 'lodash/assign';

import { formatToDateTime } from '../../../shared/utils/date.util';
import { UsersService } from '../../services/users/users.service';
// import { StoreService } from '../../../shared/services/store/store.service';
import { ActivityLogCategoriesResource } from '../../../activity-log/classes/activity-log-categories-resource.class';
import { UserActivityLogResource } from '../../classes/user-activity-log-resource.class';
import { ActivityLogSearchModel } from '../../../activity-log/classes/activity-log-search.model';
import { ITableColumnDefinition, ISelectedRow } from '../../../design/components/table/table.component';
import { SearchUserActivityComponent } from '../search-user-activity/search-user-activity.component';
import { ActivityLogCategory } from '../../../activity-log/enums/activity-log-category.enum';
import { formatNumberToLocalLangFormat } from '../../../shared/utils/number.util';
import { AuthService } from '../../../auth/services/auth.service';
// import { SearchStoreType } from '../../../shared/enums/searchStoreType.enum';

enum TableColumns {
  username = 'Display name',
  date = 'Date/Time',
  category = 'Activity',
  details = 'Protocol'
}
@Component({
  selector: 'sv-user-activity-log',
  templateUrl: './user-activity-log.component.html',
  styleUrls: ['./user-activity-log.component.scss']
})
export class UserActivityLogComponent implements OnInit, OnDestroy, AfterViewInit {
  public userId: string;
  public searchData: ActivityLogSearchModel;
  public displaySearchData: ActivityLogSearchModel;
  public userList: UserActivityLogResource[] = [];
  public page = 1;
  public sort = '-date';
  public totalItems: number;
  public formatedTotalItems: string;
  public pageSize = 20;
  public isLoadingDataPage = false;
  public selectedRows: ISelectedRow[] = [];
  public userTableColumns = [
    <ITableColumnDefinition<Moment>>{ title: this._translateService.instant('Date/Time'), field: 'date', isSortable: true },
    <ITableColumnDefinition<ActivityLogCategory>>{ title: this._translateService.instant('Activity'), field: 'category', isSortable: true },
    <ITableColumnDefinition<string>>{ title: this._translateService.instant('Protocol'), field: 'details', isSortable: false },
  ];
  // public searchStoreType = SearchStoreType.USER_ACTIVITY;
  private _availableCategories: ActivityLogCategoriesResource;
  // private _searchStoreSub: Subscription;

  constructor(
    private _usersService: UsersService,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    private route: ActivatedRoute,
    public authService: AuthService,
    public renderer: Renderer2
    // private _searchStore: StoreService
  ) {
    this.userId = this.route.snapshot.parent.params.id;
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });

    // in future if you want to use searchStore like it is implemented in user-list.component => uncomment the code bellow
    // this._searchStoreSub = this._searchStore.userActivityLogSearch.subscribe((search) => {
    //   if (!search) {
    //     return;
    //   }

    //   this.searchData = search;

    //   this.getActivityLog(true);
    // });
  }
  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getActivityLog(true);
    this.getUserCategory();
  }

  private parseDates(userList: UserActivityLogResource[]) {
    return userList.map((userListItem) => {
      return _assign(new UserActivityLogResource(userListItem), {
        ...userListItem,
        date: userListItem.date ? formatToDateTime(userListItem.date, 'DD.MM.YYYY HH:mm:ss') : '',
      });
    });
  }

  private getUserCategory() {
    this._usersService.getUserCategory().subscribe((result) => {
      this._availableCategories = result;
    });
  }

  private onTranslateChange() {
    // Translate column title properties in a programatical way
    this.userTableColumns.map((tableCol) => {
      tableCol.title = this._translateService.instant(TableColumns[tableCol.field]);
    });
    this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, this.totalItems);
    this._usersService.changeEntries(this.formatedTotalItems);
  }

  public openDialog() {
    const position = document.getElementById('searchButton');
    const topPosition = position.getBoundingClientRect().top + window.pageYOffset;
    const dialogConfig = new MatDialogConfig<any>();
    dialogConfig.position = {
      
      left: '50%',
    };
    dialogConfig.minHeight = '780px'
    dialogConfig.data = {
      searchData: this.searchData || new ActivityLogSearchModel(),
      categories: this._availableCategories.categories,
    };
    const dialogRef = this.dialog.open(SearchUserActivityComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.searchData = new ActivityLogSearchModel();
        this.searchData = _assign(this.searchData, value);
        // this._searchStore.saveSearch(this.searchData, this.searchStoreType);
        this.getActivityLog(true);
      }
    });
  }
  // we set isLoadingDataPage flag for setting progress spinner while it's value is true.
  public getActivityLog(resetPagination = false) {
    this.isLoadingDataPage = true;
    if (resetPagination) {
      this.page = 1;
    }
    this._usersService.getUsersActivityLog({
      page: this.page,
      pageSize: this.pageSize,
      sort: this.sort,
    }, this.searchData, this.userId).subscribe((data) => {
      this.userList = this.parseDates(data.pageData);
      this.totalItems = data.totalItems;
      this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, this.totalItems);
      this._usersService.changeEntries(this.formatedTotalItems);
      this.isLoadingDataPage = false;
    });
  }

  public changePage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getActivityLog();
  }

  public getPageIndex() {
    return this.page - 1;
  }

  ngOnDestroy() {
    this.dialog.closeAll();

    // if (this._searchStoreSub) {
    //   this._searchStoreSub.unsubscribe();
    // }
  }
}
