<div class="top-menu-wrap">
  <sv-top-menu totalLabel='Total entries' [totalItems]="entries"></sv-top-menu>
</div>
<div  [ngClass]="{ 'user-details-border-bottom-none' : userActivityLink.isActive }" class="user-details">
  <h2 class="user-details-header">
    <span class="display-name">{{ displayName }}</span>
  </h2>

  <div class="details-nav">
    <a class="details-nav-item" routerLink="account-data" [queryParams]="{ displayName: displayName }"
      [routerLinkActive]="'active'" translate>Account Data</a>
    <a class="details-nav-item" routerLink="information" [queryParams]="{ displayName: displayName }"
      [routerLinkActive]="'active'" translate>User Information</a>
    <a class="details-nav-item" routerLink="user-activity" [queryParams]="{ displayName: displayName }"
      [routerLinkActive]="'active'" #userActivityLink="routerLinkActive" translate>User Activity</a>
  </div>
  <router-outlet></router-outlet>
</div>
