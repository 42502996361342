import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import * as moment from 'moment';

import { UsersService, IUserInformationData } from '../../services/users/users.service';
import { UserInformationApiResource } from '../../classes/user-information-api-resource.class';
import { UserDataResource } from '../../../auth/classes/user-data-resource.class';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'sv-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {
  public userId: string;
  public userInformationData: IUserInformationData[];
  public loading: boolean;
  public userInformation: UserInformationApiResource;
  public userData: UserDataResource = this.auth.getUserData();
  public tenant: string
  constructor(
    private _usersService: UsersService,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    public auth: AuthService,
  ) {
    this.tenant = this.userData.tenantId
    // get user id from router
    this.userId = this.route.snapshot.parent.params.id;
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });
  }

  ngOnInit() {
    this.loading = true;
    this.fetchUserInformation();
  }

  fetchUserInformation() {
    this._usersService.getUserInfo(this.userId)
      .subscribe((userInfo: UserInformationApiResource) => {
        this.userInformation = userInfo;
        this.formatUserInformation(this.userInformation);
      });
  }

  private onTranslateChange() {
    // Translate column title properties in a programatical way
    this.formatUserInformation(this.userInformation);
  }

  formatDate(date: Moment): string {
    return date ? moment(date).format('DD.MM.YYYY [/] HH:MM') : '';
  }

  formatUserInformation(data) {
    if (!data) {
      return
    }
    const userAddressesFormated: IUserInformationData[] = data.userAddresses.map((element, index) => ({
      title: `Address ${index}`,
      body: [
        {
          key: 'Address', value: `${element.street} ${element.addressHouseDoorNo},
        ${element.postalCode} ${element.city}`
        },
        { key: 'State changed on', value: this.formatDate(element.addressStateChangedDate) },
        { key: 'Activation letter sent on', value: this.formatDate(element.activationletterSentDate) },
        {
          key: 'Activation letter confirmed on', value: this.formatDate(element.activationLetterConfirmed)
            || this._translateService.instant('not-yet-confirmed')
        },
        { key: 'Clearing code', value: element.clearingCode }
      ]
    }));

    this.userInformationData = [
      {
        title: 'User Account',
        body: [
          { key: 'Account created on', value: this.formatDate(data.accountCreated) },
          { key: 'Welcome letter sent on', value: this.formatDate(data.welcomeLetterSent) },
          { key: 'Account state', value: data.accountState === 'OPEN' ? this._translateService.instant('OPEN') : this._translateService.instant('Disabled') },
          { key: 'Account state changed on', value: this.formatDate(data.accountStateChanged) },
          { key: 'E-Brief Service Enabled', value: data.eBriefEnabled ? this._translateService.instant('Yes') : this._translateService.instant('No') },
          { key: 'E-Brief Enabled since', value: this.formatDate(data.eBriefServiceEnabledChanged) },
          { key: 'AuthSecLevel', value: data.authSecLevel },
          { key: 'Auth Sec Level changed on', value: this.formatDate(data.authSecLevelChanged) },
          ...(this.tenant !== 'datapart' ? [{ key: 'Groups', value: data._resourceObject.groups}] : []),
        ]
      },
      ...userAddressesFormated,
      {
        title: 'SSO',
        body: [
          { key: 'SSO Account State', value: data.ssoAccountState },
          { key: 'SSO Account State changed on', value: this.formatDate(data.ssoAccountStateChanged) },
          { key: 'Last Update from SSO Date', value: this.formatDate(data.lastUpdateFromSsoDate) },
          { key: 'SSO ID', value: data.ssoPersonId },
        ]
      },
    ];
    this.loading = false;
  }
}
