
export class ApiError {
  public apiUrl: string;
  public statusCode: string;
  public errorMessage: string;

  constructor(
    apiUrl: string,
    statusCode: string,
    errorMessage: string,
  ) {
    this.apiUrl = apiUrl;
    this.statusCode = statusCode;
    this.errorMessage = errorMessage;
  }

  toString() {
    return `${this.apiUrl} [API Error ${this.statusCode}] ${this.errorMessage}`;
  }
}
