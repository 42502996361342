import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { AuthService } from "../../auth/services/auth.service";
import { AssignUserToCompaniesResponse } from "../interfaces/assign-user-to-companies-response";
import { PersonService } from "../person/person.service";
import { AssignUsersToCompaniesService } from "./assign-users-to-companies.service";
import { TranslateService } from "@ngx-translate/core";
interface Config {
  title: string;
  inputPlaceHolder: string;
  personId: string;
  billersInvoiceRecipientID: string;
}
interface StepState {
  stepOne: boolean;
  stepTwo: boolean;
}
@Component({
  selector: "sv-assign-users-to-companies",
  templateUrl: "./assign-users-to-companies.component.html",
  styleUrls: ["./assign-users-to-companies.component.scss"],
})
export class AssignUsersToCompaniesComponent implements OnInit {
  public config: Config = {
    title: "",
    inputPlaceHolder: "",
    personId: "",
    billersInvoiceRecipientID: "",
  };
  public steps: StepState = {
    stepOne: true,
    stepTwo: false,
  };

  public userChange = false;
  @ViewChildren("billersInvoiceRecipientID")
  billersInvoiceRecipientID: QueryList<ElementRef>;
  columns = ["Company UID", "Company Name", "Status"];
  data = [];
  selection = new Map();
  filterValue = "";
  pageIndex = 0;
  totalItems = 0;
  // billersInvoiceRecipientID = ''
  itemsPerPage = 6;
  isbillersInvoiceRecipientEmpty = [];
  public pagedData = [];
  public filteredData = [];
  public pageSize = 5;
  public selectedData = [];
  public allSelected: boolean;
  public displayErrorMessage: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayNextButton: any;
  public displaySaveButton: boolean = false;
  public companyPrefix;
  constructor(
    private _dialogRef: MatDialogRef<AssignUsersToCompaniesComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data: Config,
    private _service: AssignUsersToCompaniesService,
    private userService: PersonService,
    private translateService: TranslateService
  ) {
    this.config = data;
    this._getCompanies();
  }

  ngOnInit(): void {}

  public handleFiltered = (filteredData) => {
    this.pageIndex = 0;
    this.totalItems = filteredData.length;
    this.filteredData = filteredData;
  };

  public isPreselected(row): boolean {
    return this.selectedData.includes(row.column1);
  }

  public toggleSelection(row) {
    let index = this.selectedData.findIndex(
      (item) => row.companyId == item.companyId
    );
    if (index > -1) {
      this.selectedData.splice(index, 1);
    } else {
      this.selectedData.push(row);
    }
    this.updateNextButtonDisplay();
    this.updateSaveButtonDisplay();
  }

  public toggleAll() {
    this.selectedData = this.isFiltered();
    this.data = this.data.map((data) => ({
      ...data,
      assigned: this.allSelected,
    }));
    this.selectedData = this.allSelected ? this.data : [];
    this.updateNextButtonDisplay();
    this.updateSaveButtonDisplay();
  }
  private updateNextButtonDisplay(): void {
    this.displayNextButton = this.selectedData.some(
      (data) => data.billersInvoiceRecipientID === null
    );
  }

  private updateSaveButtonDisplay(): void {
    this.displaySaveButton =
      (this.selectedData.every((data) => data.prefix && data.userInput) &&
        this.selectedData.length > 0) ||
      (this.steps.stepOne && this.selectedData.length > 0);
  }

  public isFiltered() {
    return this.filteredData.length === 0
      ? this.data.map((data) => ({ ...data }))
      : this.filteredData.map((data) => ({ ...data }));
  }

  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.paginateData();
  }
  public paginateData() {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    this.pagedData = this.filteredData.slice(start, end);
  }
  public submit() {
    this.displayErrorMessage = this.selectedData.some(
      (selectedData) =>
        typeof selectedData.userInput === "undefined" && this.steps.stepTwo
    );
    if (this.displayErrorMessage) {
      this.isbillersInvoiceRecipientEmpty = this.selectedData.filter(
        (data) =>
          typeof data.userInput === "undefined" ||
          data.userInput === "" ||
          data.userInput === null
      );
      return;
    }
    const payload = this.data
      .map((data) => ({
        ...data,
        companyStatus: data.column3 === "Aktiv",
        billersInvoiceRecipientID: data.userInput || null,
        prefix: data.prefix,
      }))
      .map(({ userInput, ...rest }) => rest);

    this._service
      .submitCompanies(this.config.personId, payload, this.allSelected)
      .subscribe(
        (res) => {
          this._service.handleSuccess(
            "User assignment for company administration was successfully taken over"
          );
          this.userService.triggerDataReaload.next(true);
          this.closeDialog();
        },
        (err) => {
          this._service.handleError(err.errorMessage);
        }
      );
  }
  public closeDialog() {
    this._dialogRef.close();
  }

  public selectCompanyPrefix(e, row) {
    this.updateSaveButtonDisplay();
    this.data.find(
      (company) =>
        company.companyId === row.companyId && (company.prefix = e.value)
    );
  }

  private _getCompanies() {
    this._service
      .getCompanies(this.config.personId)
      .subscribe((data: AssignUserToCompaniesResponse) => {
        this.data = data.companyDescription
          .map((data) => ({
            ...data,
            companyStatus: data.companyStatus
              ? this.translateService.instant("Active")
              : this.translateService.instant("Deactivated"),
          }))
          .sort((a, b) => (b.assigned ? 1 : -1) - (a.assigned ? 1 : -1));
        this.selectedData = this.data.filter((selected) => selected.assigned);
        this.allSelected = data.existInAllCompanies;
        // this.isbillersInvoiceRecipientEmpty = data.companyDescription.filter(data =>  data.billersInvoiceRecipientID === null)
        this.totalItems = this.data.length;
      });
  }

  public setStep() {
    this.steps.stepOne = false;
    this.steps.stepTwo = true;
    this.selectedData = this.selectedData.filter(
      (data) =>
        data.billersInvoiceRecipientID === null && data.assigned === true
    );
    // this.displayNextButton = this.selectedData.every(data => data.billersInvoiceRecipientID === null && data.assigned === true )
    this.pageIndex = 0;
    this.updateNextButtonDisplay();
    this.updateSaveButtonDisplay();
  }
}
