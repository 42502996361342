import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

import { DashboardModule } from '../dashboard/dashboard.module';
import { TokenInterceptor } from './interceptors/token/token.interceptor';
import { TokenRefreshInterceptor } from './interceptors/token-refresh/token-refresh.interceptor';
import { AutoLogoutInterceptor } from './interceptors/auto-logout/auto-logout.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AutoLogoutInterceptor, multi: true },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    TranslateModule.forChild(),
    MatIconModule
  ],
  declarations: [LoginComponent],
  providers: [
    AuthService,
    AuthGuard
  ],
})
export class AuthModule { }
