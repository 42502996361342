// @ts-ignore
import * as _keys from "lodash/keys";
import {
  ApiResource,
  ApiResourceValue,
} from "../../shared/classes/api-resource.class";
import { TableConfigColumnType } from "../../shared/enums/table-config-column-type.enum";
import { PersonRole } from "../../group/enum/personRole.enum";

export class UserDataResource extends ApiResource {
  @ApiResourceValue("userId") public id: string;
  @ApiResourceValue() public email: string;
  @ApiResourceValue() public displayName: string;
  @ApiResourceValue() public tenantId: string;
  @ApiResourceValue() public userLanguage: string;
  @ApiResourceValue() public urlLogo: string;
  @ApiResourceValue() public lastLogin: string;
  @ApiResourceValue() public rights: PersonRole[];
  @ApiResourceValue() public accountNumber: string;
  @ApiResourceValue() public rightsPerTenant: Record<string, []>;

  // A list of configured columns and their types
  public allowedColumnsByTable: {
    personViewTable: { [field: string]: TableConfigColumnType };
  };

  constructor(_resourceObject: any) {
    super(_resourceObject);

    // map person view table columns to our representation of the configuration
    if (
      _resourceObject.allowedColumnsByTable &&
      _resourceObject.allowedColumnsByTable.personViewTable
    ) {
      // mar type strings to our enum values
      const enumMap = {
        String: TableConfigColumnType.String,
        boolean: TableConfigColumnType.Boolean,
      };
      this.allowedColumnsByTable = {
        personViewTable: {},
      };
      _keys(_resourceObject.allowedColumnsByTable.personViewTable).forEach(
        (column) => {
          const mappedField = this.getMappedField(column);
          this.allowedColumnsByTable.personViewTable[mappedField] =
            enumMap[
              _resourceObject.allowedColumnsByTable.personViewTable[column]
            ];
        }
      );
    }
  }
}
