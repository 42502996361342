<div class="upload-company-logo-dialog">
    <div class="upload-company-logo-dialog_header">
        <h1 mat-dialog-title>{{'Edit Company Logo' | translate}}</h1>
        <span class="close">
            <mat-icon (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
        </span>
    </div>
    <div mat-dialog-content class="images">
        <label for="uploadImage" class="uplaod-image-box">
            <input type="file" id="uploadImage" accept="image/jpeg, image/jpg" (change)="onImageUpload($event)">
            <span><mat-icon class="upload" [svgIcon]="'custom:post-icon-upload'"></mat-icon></span>
        </label>
        <div *ngFor="let image of logos; let i = index" class="logo-wrapper">
            <div class="image-box">
                <img *ngIf="image.temporaryLogoUrl || image.logoUrl" [src]="image.temporaryLogoUrl ? image.temporaryLogoUrl : image.logoUrl" alt="" class="image-background">
                <button *ngIf="image.logoUrl || image.temporaryLogoUrl" class="delete-bttn" (click)="deleteImage(i)"><mat-icon class="delete" [svgIcon]="'custom:post-trash'" title="{{ 'Delete' | translate }}"></mat-icon></button>
                <label class="replace-image" *ngIf="!image.logoUrl && !image.temporaryLogoUrl && !image.logoImage.payload" for="'replaceImage' + i">
                    <input type="file" id="'replaceImage' + i" [accept]="image.logoImage.mimeType" (change)="onImageReplace($event, i)">
                    <span *ngIf="!image.logoUrl"><mat-icon class="upload" [svgIcon]="'custom:post-icon-upload'"></mat-icon></span>
                </label>
                <button *ngIf="image.logoUrl || (image.logoHistory && image.logoImage.payload) || image.temporaryLogoUrl" class="image-checkbox" (click)="toggleCheckbox(i)"  [style.backgroundColor]="(selectedImage && (selectedImage === (image.logoUrl  || image.temporaryLogoUrl)) || image.selected) ? (authService.backgroundColors | async)?.primaryButtonColor : '#FFF'">
                    <mat-icon class="check" *ngIf="(selectedImage && selectedImage === (image.logoUrl || image.temporaryLogoUrl) || image.selected)" class="upload" [svgIcon]="'custom:check'">check</mat-icon>
                </button>
            </div>
            <div *ngIf="image.logoUrl" class="copy-url">
                <p>{{image.logoUrl}}</p>
                <button color="primary" [ngStyle]="{
                    'color': (authService.backgroundColors | async)?.buttonTextColor,
                    'background-color': (authService.backgroundColors | async)?.primaryButtonColor
                  }">
                    <mat-icon class="copy-url-bttn" (click)="copyImageUrl(i)" [svgIcon]="'custom:post-link'"></mat-icon>
                </button>
            </div>
        </div>
        <button color="primary" type="submit" mat-button mat-raised-button class="submit-button" (click)="save()">{{'Save' | translate}}</button>
    </div>
</div>
