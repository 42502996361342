import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
import * as _pickBy from 'lodash/pickBy';
// @ts-ignore
import * as _assign from 'lodash/assign';

import { getCurrentDate } from '../../../shared/utils/date.util';
import { ActivityLogCategory } from '../../../activity-log/enums/activity-log-category.enum';
import { ActivityLogSearchModel } from '../../../activity-log/classes/activity-log-search.model';
import { UserActivityLogComponent } from '../user-activity-log/user-activity-log.component';
import { ISearchCategoryCheckbox } from '../../../shared/interfaces/search-category-checkbox.interface';
import { Moment } from 'moment';

@Component({
  selector: 'sv-search-user-activity',
  templateUrl: './search-user-activity.component.html',
  styleUrls: ['./search-user-activity.component.scss']
})
export class SearchUserActivityComponent implements OnInit {
  @ViewChild('searchActivity',{static: true}) public form: NgForm;
  public model: ActivityLogSearchModel;
  public currentDate = getCurrentDate();
  public maxStartDate: Moment | string;
  public filteredCategoryCheckboxes: ISearchCategoryCheckbox[];
  public categoryCheckboxes: ISearchCategoryCheckbox[] = [
    { value: ActivityLogCategory.Delivery, label: this._translate.instant('Delivery') },
    { value: ActivityLogCategory.Notification, label: this._translate.instant('Notification') },
    { value: ActivityLogCategory.DeliveryDeleted, label: this._translate.instant('Delivery Deleted') },
    { value: ActivityLogCategory.Document, label: this._translate.instant('Document') },
    { value: ActivityLogCategory.Login, label: this._translate.instant('Login') },
    { value: ActivityLogCategory.Payment, label: this._translate.instant('Payment') },
    { value: ActivityLogCategory.Email, label: this._translate.instant('Email') },
    { value: ActivityLogCategory.SMS, label: this._translate.instant('SMS') },
    { value: ActivityLogCategory.UserAccount, label: this._translate.instant('User Account') },
  ];

  constructor(
    public dialogRef: MatDialogRef<UserActivityLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { searchData: ActivityLogSearchModel, categories: ActivityLogCategory[] },
    private _translate: TranslateService,
  ) { }


  ngOnInit() {
    this.model = this.data.searchData || new ActivityLogSearchModel();
    this.maxStartDate = this.model.endDate || this.currentDate;
    this.model.categories = this.model.categories || {};
    this.filteredCategoryCheckboxes = this.categoryCheckboxes
      .filter((c: ISearchCategoryCheckbox) => this.data.categories.indexOf(c.value) !== -1);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onFormSubmit();
    }
  }

  // For maxStartDate first we use selected endDate that user picked from datepicker,
  // if there is no endDate selected then take currentDate asa maxstartDate
  public setStartDateMax() {
    this.maxStartDate = this.model.endDate || this.currentDate;
  }

  public onReset() {
    this.form.reset();
    this.model.categories = {};
    this.maxStartDate = this.currentDate;
  }

  public onFormSubmit() {
    // Filter out the properties in the models categories (checkboxes) that have false (unchecked) values
    const data: ActivityLogSearchModel = _assign(new ActivityLogSearchModel(), {
      ...this.model,
      categories: _pickBy(this.model.categories, (value) => value),
    });
    this.dialogRef.close(data);
  }
}
