import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// @ts-ignore
import * as _assign from 'lodash/assign';

// import { fixButtonRippleEffectBug } from '../../../shared/utils/mat-autofocus-fix.util';
import { UsersService } from '../../services/users/users.service';
import { AuthService } from '../../../auth/services/auth.service';
import { SidebarService } from '../../../dashboard/services/sidebar-service/sidebar.service';
import { StoreService } from '../../../shared/services/store/store.service';
import { UserSearchModel } from '../../classes/user-search-model.class';
import { UserResource } from '../../classes/user-resource.class';
import { GeneralTableSettings } from '../../../shared/classes/general-table-settings.class';
import { ITableColumnDefinition } from '../../../design/components/table/table.component';
// import { DeleteDialogComponent, IDeleteDialogData } from '../delete-dialog/delete-dialog.component';
import { UserSearchComponent } from '../user-search-dialog/user-search-dialog.component';
import { formatNumberToLocalLangFormat } from '../../../shared/utils/number.util';
import { UserStatus } from '../../enums/user-status.enum';
import { SearchStoreType } from '../../../shared/enums/searchStoreType.enum';
import * as FileSaver from 'file-saver';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

enum TableColumns {
  displayName = 'Display Name',
  userName = 'Username',
  email = 'Email',
  accountNumber = 'Account Number',
  status = 'Status',
  export = 'Export',
  unreadDocuments = 'Unread Documents'
  // optIn = 'Opt-Ins',
  // corporateCustomer = 'Corporate customer',
  // employee = 'Employee'
}

@Component({
  selector: 'sv-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild('dialogButton', {static: true}) public dialogOpenButton: MatButton;
  private subscribe: Subscription;
  private dialogSub: Subscription;
  public filterValues = new UserSearchModel();
  public userTableColumns?: ITableColumnDefinition<string | UserStatus>[];
  public userList: UserResource[] = [];
  public page = 1;
  public sort: string = null;
  public totalItems: number;
  public formatedTotalItems: string;
  public pageSize = 20;
  // public selectedRows: string[] = [];
  // public isDeleting = false;
  public isLoadingDataPage = false;
  public searchStoreType = SearchStoreType.USER;
  private _searchStoreSub: Subscription;
  public actions = {
  }
  displayLoadingIndicator: boolean;

  constructor(
    public authService: AuthService,
    private _usersService: UsersService,
    private _translateService: TranslateService,
    private _sidebarService: SidebarService,
    private dialog: MatDialog,
    private _router: Router,
    private _searchStore: StoreService,
    public renderer: Renderer2,
    public snackBar: MatSnackBar,
  ) {
    this._sidebarService.openSidebarSubmenu();
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });

    this._searchStoreSub = this._searchStore.userSearch.subscribe((search) => {
      if (!search) {
        return;
      }

      this.filterValues = search;

      this.getUsersData(true);
    });
  }

  ngAfterViewInit(): void {
    const elements = document.querySelectorAll('.mat-paginator-page-size-label, .mat-paginator-range-label');
    const itemsPerPage = document.querySelectorAll('.mat-paginator-page-size-value')
    const icons = document.querySelectorAll('.mat-paginator-icon')
    const paginationColor = JSON.parse(localStorage.getItem('paginationColor'))

    // icons.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // itemsPerPage.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // elements.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
  }

  ngOnInit() {
    this.filterAllowedColumns();
    this.getUsersData(true);
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });
    this.filterValues = null;
  }

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
    if (this.dialogSub) {
      this.dialogSub.unsubscribe();
    }
    if (this._searchStoreSub) {
      this._searchStoreSub.unsubscribe();
    }
    this.dialog.closeAll();
  }

  public getUsersData(resetPagination: boolean) {
    if (resetPagination) {
      this.page = 1;
    }
    let username = ''
    if (this.sort?.includes('userName')) {
      username = this.sort.replace('userName','username') 
    }
    this.isLoadingDataPage = true;
    this._usersService.searchUsers(this.filterValues, {
      page: this.page,
      pageSize: this.pageSize,
      sort: username || this.sort,
    }).subscribe((res) => {
      this.isLoadingDataPage = false;
      // this.selectedRows = [];
      // this.originalUser = res.pageData;
      this.actions = {payslip: [
        {
          edit: {
            name: "Export",
            onClick: "Export",
            showIf:(row: any) => {
             return  Number(row.unreadDocuments) > 0
            
            }
          },
        }
      ],
      datapart: [
        {
          edit: {
            name: "Export",
            onClick: "Export",
            showIf:(row: any) => {
              return  Number(row.unreadDocuments) > 0
             
             }
          },}
      ],
      post: [
        {
          edit: {
            name: "Export",
            onClick: "Export",
            showIf:(row: any) => {
              return  Number(row.unreadDocuments) > 0
             
             }
          },}
      ]}
      this.userList = res.pageData;
      this.totalItems = res.totalItems;
      this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, res.totalItems);
      this.onTranslateChange();
    });
  }

  public filterAllowedColumns() {
    // setting the columns for table based on general settings
    const generalSettings: GeneralTableSettings = this.authService.getGeneralTableSettings();
    const userTableColumnsConfig: ITableColumnDefinition<string | UserStatus>[] = [
      <ITableColumnDefinition<string>>
      { title: this._translateService.instant('Display Name'), field: 'displayName', isSortable: true, width: 200 },
      <ITableColumnDefinition<string>>
      { title: this._translateService.instant('Username'), field: 'userName', isSortable: true, width: 140 },
      <ITableColumnDefinition<string>>
      { title: this._translateService.instant('Email'), field: 'email', isSortable: true, width: 240 },
      <ITableColumnDefinition<string>>
      { title: this._translateService.instant('Account-Number'), field: 'accountNumber', width: 100, isSortable: true },
      <ITableColumnDefinition<UserStatus>>
      {
        title: this._translateService.instant('Status'),
        field: 'status', isSortable: true, width: 80, toDisplayString: (value: UserStatus) => {
          return this._translateService.instant(value);
        }
      },
      <ITableColumnDefinition<UserStatus>>
      {
        title: this._translateService.instant('Unread Documents'),
        field: 'unreadDocuments', isSortable: false, width: 80
      },
      <ITableColumnDefinition<UserStatus>>
      {
        title: this._translateService.instant('Export'),
        field: 'export', isSortable: false, width: 80
      },
    ];
    // Only apply filters if allowedColumnsByTable.personViewTable has properties to filter by
    if (!generalSettings) {
      this.userTableColumns = userTableColumnsConfig;
    } else {
      this.userTableColumns = userTableColumnsConfig.filter((userTableConfigItem) => {
        return generalSettings.hasOwnProperty(userTableConfigItem.field);
      });
    }
  }

  private onTranslateChange() {
    // Translate column title properties in a programatical way
    this.userTableColumns.map((tableCol) => {
      tableCol.title = this._translateService.instant(TableColumns[tableCol.field]);
    });
    this.formatedTotalItems = formatNumberToLocalLangFormat(this._translateService.currentLang, this.totalItems);
  }

  public changePage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.getUsersData(false);
  }

  public getPageIndex() {
    return this.page - 1;
  }

  public openSearchDialog() {
    const position = document.getElementById('searchButton');
    const topPosition = position.getBoundingClientRect().top + window.pageYOffset;
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '474px',
      autoFocus: false,
      position: {
        'top': topPosition + position.scrollHeight + 'px',
        'right': '42px'
      },
      disableClose:true,
      data: this.filterValues || new UserSearchModel(),
    });
    // after dialog is closed succesfully set new filter values and getUsersData
    dialogRef.afterClosed().subscribe((filterValues: UserSearchModel) => {
        this.filterValues = filterValues;
        this._searchStore.saveSearch(this.filterValues, this.searchStoreType);
        this.getUsersData(true);
      
      // this.filterValues = filterValues
    });
  }
  // navigate based on the table row data
  public handleRowClick(row: { id: string, displayName: string }) {
    this._router.navigate(['/dashboard/users', row.id, 'account-data'], { queryParams: { displayName: row.displayName } });
  }
  action(event: { tableData; action: string }) {
    const userData = event.tableData;
    this.displayLoadingIndicator = true;
    this._usersService.downloadUnreadDocuments(userData.id).subscribe(data => {
        this.displayLoadingIndicator = false;
        const file = new File([data.body], 'attachments.zip', { type: data.body.type });
        FileSaver.saveAs(file);
    },err => {
      this.displayLoadingIndicator = false;
      this.statusMessage("snack-bar-fail", err.errorMessage);

    });
  }
  public statusMessage(panelClass: string, message: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }
}
