import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CustomTabService } from './services/custom-tab/custom-tab.service';
import { CustomTabComponent } from './components/custom-tab/custom-tab.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [CustomTabComponent],
  providers: [CustomTabService]
})
export class CustomTabModule { }
