export const PasswordValidation = {
    regexPasswordChars: '^(?=.*\\d)(?=.*[a-zA-Z]).{6,}$',
    regexPasswordSpecialChars: '^([a-zA-Z0-9\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\{\\}\\[\\]]+)$',
    // const passwordSymbols = '~!@#$%^&*_-+=|\\(){}[]:;"\'<>,.?/';
    LOWERCASE_UPPERCASE_NUMERIC_REGEXP: `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{1,64}$`,
    LOWERCASE_UPPERCASE_SYMBOL_REGEXP: `^(?=.*[a-z])(?=.*[A-Z])(?=.*[\\~\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\|\\\\\(\\)\\{\\}\\[\\]\\:\\;\\"\\'\\<\\>\\,\\.\\?\\/]).{1,64}$`,
    LOWERCASE_NUMERIC_SYMBOL_REGEXP: `^(?=.*[a-z])(?=.*[0-9])(?=.*[\\~\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\|\\\\\(\\)\\{\\}\\[\\]\\:\\;\\"\\'\\<\\>\\,\\.\\?\\/]).{1,64}$`,
    UPPERCASE_NUMERIC_SYMBOL_REGEXP: `^(?=.*[A-Z])(?=.*[0-9])(?=.*[\\~\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\|\\\\\(\\)\\{\\}\\[\\]\\:\\;\\"\\'\\<\\>\\,\\.\\?\\/]).{1,64}$`,
    LOWERCASE_REGEXP: `^(?=.*[a-z]).{1,64}$`,
    UPPERCASE_REGEXP: `^(?=.*[A-Z]).{1,64}$`,
    NUMERIC_REGEXP: `^(?=.*[0-9]).{1,64}$`,
    SYMBOL_REGEXP: `^(?=.*[\\~\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\|\\\\\(\\)\\{\\}\\[\\]\\:\\;\\"\\'\\<\\>\\,\\.\\?\\/]).{1,64}$`,
}