<div>
  <h3>Input fields</h3>
  <mat-form-field>
    <input matInput placeholder="Test input" [(ngModel)]="testValues.test1" />
  </mat-form-field>
  <mat-form-field color="accent">
    <input matInput placeholder="Test input" [(ngModel)]="testValues.test1" />
  </mat-form-field>
  {{ testValues.test1 }}
  <br />
  <mat-form-field>
    <mat-select placeholder="Some value" [(ngModel)]="testValues.test2">
      <mat-option [value]="1">
        First option
      </mat-option>
      <mat-option [value]="2">
        Second option
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <mat-checkbox [(ngModel)]="testValues.test3">asdasd</mat-checkbox>
  <mat-checkbox color="primary" [(ngModel)]="testValues.test3">asdasd</mat-checkbox>
  <br />

  <mat-form-field>
    <input matInput [matDatepicker]="picker" placeholder="Choose your date. Choose wisely." />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <h3>Buttons</h3>
  <h5>Normal</h5>
  <button mat-button>Basic</button>
  <button mat-button color="primary">Primary</button>
  <button mat-button color="accent">Accent</button>
  <button mat-button color="warn">Warn</button>
  <button mat-button disabled>Disabled</button>
  <a mat-button routerLink=".">Link</a>

  <h5>Raised</h5>
  <button mat-raised-button>Basic</button>
  <button mat-raised-button color="primary">Primary</button>
  <button mat-raised-button color="accent">Accent</button>
  <button mat-raised-button color="warn">Warn</button>
  <button mat-raised-button disabled>Disabled</button>
  <a mat-raised-button routerLink=".">Link</a>

  <h5>Icon</h5>
  <label>Material icons:</label>
  <button mat-icon-button>
    <mat-icon aria-label="Example icon-button with a heart icon">person</mat-icon>
  </button>
  <button mat-icon-button color="primary">
    <mat-icon aria-label="Example icon-button with a heart icon">chrome_reader_mode</mat-icon>
  </button>
  <button mat-icon-button color="accent">
    <mat-icon aria-label="Example icon-button with a heart icon">keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button color="warn">
    <mat-icon aria-label="Example icon-button with a heart icon">palette</mat-icon>
  </button>
  <button mat-icon-button disabled>
    <mat-icon aria-label="Example icon-button with a heart icon">search</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon aria-label="Example icon-button with a heart icon">check</mat-icon>
  </button>
  <br />

  <label>Custom icons:</label>
  <button mat-icon-button *ngFor="let icon of shIcons">
    <mat-icon aria-label="Example icon-button with a heart icon" [svgIcon]="'custom:' + icon"></mat-icon>
  </button>

  <h5>Fab Buttons</h5>
  <div class="button-row">
    <button mat-fab>Basic</button>
    <button mat-fab color="primary">Primary</button>
    <button mat-fab color="accent">Accent</button>
    <button mat-fab color="warn">Warn</button>
    <button mat-fab disabled>Disabled</button>
    <button mat-fab>
      <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
    </button>
    <a mat-fab routerLink=".">Link</a>
  </div>

  <h5>Mini Fab Buttons</h5>
  <div class="button-row">
    <button mat-mini-fab>Basic</button>
    <button mat-mini-fab color="primary">Primary</button>
    <button mat-mini-fab color="accent">Accent</button>
    <button mat-mini-fab color="warn">Warn</button>
    <button mat-mini-fab disabled>Disabled</button>
    <button mat-mini-fab>
      <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
    </button>
    <a mat-mini-fab routerLink=".">Link</a>
  </div>
</div>