import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatPaginatorModule } from "@angular/material/paginator";
import { PersonsComponent } from "./persons/persons.component";
import { GroupsComponent } from "./groups/groups.component";
import { CreateEditGroupComponent } from "./create-edit-group/create-edit-group.component";
import { CreateEditPersonComponent } from "./create-edit-person/create-edit-person.component";
import { TranslateModule } from "@ngx-translate/core";
import { DesignModule } from "../design/design.module";
import { CommonModule } from "@angular/common";
import { PersonService } from "./person/person.service";
import { GroupService } from "./group.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DocumentVisibilityComponent } from "./document-visibility/document-visibility.component";
import { SearchGroupDialogComponent } from "./search-group-dialog/search-group-dialog.component";
import { SearchPersonDialogComponent } from "./search-person-dialog/search-person-dialog.component";
import { UsersModule } from "../users/users.module";
import { SharedModule } from "../shared/shared.module";
import { FilterPipe } from "../stubs/pipes/filter/filter.pipe";
import { PaginatePipe } from "../stubs/pipes/paginate/paginate.pipe";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DictionaryPDFComponent } from "./dictionary-pdf/dictionary-pdf.component";
import { DictionaryXMLComponent } from "./dictionary-xml/dictionary-xml.component";
import { xUserComponent } from "./xUser/xUser.component";
import { xUserService } from "./xUserService/xUser.service";
import { AssignUsersToCompaniesComponent } from "./assign-users-to-companies/assign-users-to-companies.component";
import { CreateUserComponent } from "./create-user/create-user.component";
import { ExportAccountingCsvDialogComponent } from "./export-accounting-csv-dialog/export-accounting-csv-dialog.component";
import { DownloadUserCredentialsComponent } from "./download-user-credentials/download-user-credentials.component";
import { TenantService } from "./tenantService/tenant.service";
import { CreateEditGroupComponentDatapart } from "./create-edit-group-datapart/create-edit-group.component-datapart";
import { CreateEditPersonComponentDatapart } from "./create-edit-person-datapart/create-edit-person-datapart.component";
import { UploadCompanyLogoComponent } from "./upload-company-logo/upload-company-logo.component";
import { GroupPrefixesComponent } from "./group-prefixes/group-prefixes.component";
import { CreateEditAdminUserComponent } from "./create-edit-admin-user/create-edit-admin-user.component";
import { AssignAdminsToCompaniesComponent } from "./assing-admins-to-companies/assign-admins-to-companies.component";
import { DeliverDocsToUsersComponent } from "./deliver-docs-to-users/deliver-docs-to-users.component";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    DesignModule,
    MatSnackBarModule,
    MatCheckboxModule,
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    UsersModule,
    SharedModule,
    MatAutocompleteModule,
  ],
  declarations: [
    PersonsComponent,
    GroupsComponent,
    CreateUserComponent,
    CreateEditGroupComponent,
    CreateEditGroupComponentDatapart,
    CreateEditPersonComponentDatapart,
    CreateEditPersonComponent,
    DocumentVisibilityComponent,
    SearchGroupDialogComponent,
    SearchPersonDialogComponent,
    xUserComponent,
    FilterPipe,
    PaginatePipe,
    AssignUsersToCompaniesComponent,
    AssignAdminsToCompaniesComponent,
    DictionaryPDFComponent,
    DictionaryXMLComponent,
    AssignUsersToCompaniesComponent,
    ExportAccountingCsvDialogComponent,
    DownloadUserCredentialsComponent,
    UploadCompanyLogoComponent,
    GroupPrefixesComponent,
    CreateEditAdminUserComponent,
    DeliverDocsToUsersComponent
    // RolePipe
  ],

  providers: [PersonService, GroupService, xUserService, TenantService],
})
export class GroupModule {}
