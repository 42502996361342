import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import {
  ISelectedRow,
  ITableColumnDefinition,
  TableComponentActionList,
} from "../../design/components/table/table.component";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { DeleteDialogComponent } from "../../users/components/delete-dialog/delete-dialog.component";
import { AssignAdminsToCompaniesComponent } from "../assing-admins-to-companies/assign-admins-to-companies.component";
import { CreateEditAdminUserComponent } from "../create-edit-admin-user/create-edit-admin-user.component";
import { DocumentVisibilityComponent } from "../document-visibility/document-visibility.component";
import { PersonRole } from "../enum/personRole.enum";
import { GroupService } from "../group.service";
import { Pagination } from "../interfaces/pagination.interface";
import { IPerson, IPersonResponse } from "../interfaces/person.interface";
import { RESET_FILTER_PERSON } from "../person/person.service";
import { SearchPersonDialogComponent } from "../search-person-dialog/search-person-dialog.component";
import { xUserService } from "../xUserService/xUser.service";

enum TableColumns {
  id = "Id",
  firstName = "First Name",
  lastName = "Last Name",
  username = "Username",
  email = "Email",
  roles = "Function",
  xUser = "Actions",
}

@Component({
  selector: "sv-xUser",
  templateUrl: "./xUser.component.html",
  styleUrls: ["./xUser.component.scss"],
})
export class xUserComponent implements OnInit, AfterViewInit {
  public page = 1;
  public pageSize = 10;
  public sort = "";
  public selectedRows: ISelectedRow[] = [];
  private groupDisplayName = "";
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: { ascOrDesc: "asc", field: "id" },
  };
  public persons$: Observable<IPersonResponse>;
  public personAdditionalData;
  public selectedPerson: IPerson;
  public externalId = "";
  public switchButtonTitle = "";
  public paginationColor = "";
  public personsTableColumns = [
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Id"),
      field: "id",
      isSortable: true,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("First Name"),
      field: "firstName",
      isSortable: true,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Last Name"),
      field: "lastName",
      isSortable: true,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Username"),
      field: "username",
      isSortable: true,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Email"),
      field: "email",
      isSortable: true,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Function"),
      field: "roles",
      isSortable: false,
    },
    <ITableColumnDefinition<string>>{
      title: this._translateService.instant("Actions"),
      field: "xUser",
      isSortable: false,
    },
  ];

  public actions: Record<string, TableComponentActionList<IPerson>>;

  public displayName: string;
  public accountNumber: string;
  public accountNumberUser: string;

  public groupDescription;
  constructor(
    public _groupService: GroupService,
    private _translateService: TranslateService,
    public _personService: xUserService,
    public authService: AuthService,
    public xUserService: xUserService,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    public renderer: Renderer2,
    private _userPermissionService: UserPermissionService
  ) {
    this.groupDisplayName = this.router.snapshot.paramMap.get("displayName");

    this._groupService
      .getGroups(
        {
          page: 0,
          pageSize: 1000000,
          sort: { ascOrDesc: "asc", field: "name" },
        },
        { accountNumber: "", displayName: "", groupDescription: "" }
      )
      .subscribe((groups) => {
        this.groupDescription = groups.content.find(
          (group) => group.displayName === this.groupDisplayName
        )?.description;
      });

    this.xUserService.triggerDataReaload.subscribe((data) => {
      if (!data) {
        return;
      }

      this.persons$ = this._personService
        .getPersons(this.paginationAndSortConfig, this.groupDisplayName, {
          accountNumber: "",
          email: "",
          firstName: "",
          lastName: "",
        })
        .pipe();

      this._personService
        .getPersons(
          this.paginationAndSortConfig,
          this.groupDisplayName,
          RESET_FILTER_PERSON
        )
        .pipe(
          map((data) =>
            data.content.filter(
              (person) =>
                person.role === PersonRole.supporter ||
                person.role === PersonRole.instructor
            )
          )
        )
        .subscribe((switchTitle) => {
          this.switchButtonTitle = switchTitle
            ? "Activated Supporters and Instructors"
            : "Deactivated Supporters and Instructors";
        });

      this._translateService.onLangChange.subscribe(() => {
        this.onTranslateChange();
      });
    });

    this.persons$ = this._personService
      .getPersons(this.paginationAndSortConfig, this.groupDisplayName, {
        accountNumber: "",
        email: "",
        userName: "",
      })
      .pipe();

    this._personService
      .getPersons(
        this.paginationAndSortConfig,
        this.groupDisplayName,
        RESET_FILTER_PERSON
      )
      .pipe(
        map((data) =>
          data.content.filter(
            (person) =>
              person.role === PersonRole.supporter ||
              person.role === PersonRole.instructor
          )
        )
      )
      .subscribe((switchTitle) => {
        this.switchButtonTitle = switchTitle
          ? "Activated Supporters and Instructors"
          : "Deactivated Supporters and Instructors";
      });

    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });

    this.displayName = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )._resourceObject.displayName;

    this.accountNumber = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber?.substring(0, 3);

    this.accountNumberUser = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber;

    this.actions = {
      payslip: [
        {
          edit: {
            name: "Edit",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "edit",
          },
          delete: {
            name: "GDPR-Delete",
            showIf: () => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: PersonRole.admin,
                tentnatId: this.groupDisplayName,
              });

              return hasRights;
            },
            onClick: "delete",
          },
          rights: {
            name: "Rights",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "openDocumentVisibilityDialog",
          },
          companies: {
            name: "Companies",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "manageCompanies",
          },
          remove: {
            name: "Remove",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return (
                hasRights &&
                row.username !== this.displayName &&
                row.primaryGroup === false
              );
            },
            onClick: "remove",
          },
        },
      ],
      datapart: [{}],
    };
  }

  ngAfterViewInit(): void {
    // this.authService.infoTextColors.subscribe(resp => {
    //
    //   this.paginationColor = resp.pagination;
    // })
    const elements = document.querySelectorAll(
      ".mat-paginator-page-size-label, .mat-paginator-range-label"
    );
    const itemsPerPage = document.querySelectorAll(
      ".mat-paginator-page-size-value"
    );
    const icons = document.querySelectorAll(".mat-paginator-icon");
    const paginationColor = JSON.parse(localStorage.getItem("paginationColor"));

    // icons.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // itemsPerPage.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // elements.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
  }

  private onTranslateChange(): void {
    this.personsTableColumns.map((tableCol) => {
      if (TableColumns[tableCol.field]) {
        tableCol.title = this._translateService.instant(
          TableColumns[tableCol.field]
        );
        this.persons$ = this._personService.getPersons(
          this.paginationAndSortConfig,
          this.groupDisplayName,
          RESET_FILTER_PERSON
        );
      }
    });
  }

  ngOnInit(): void {}

  public editXUser() {
    this._personService
      .getPersoAdditionalData(this.selectedPerson.id)
      .subscribe((data) => {
        this._personService.editXUser.next({
          person: data,
          groupId: this.groupDisplayName,
          id: this.selectedPerson.id,
          activate: undefined,
          accountType: data["accountType"],
        });
      });
    this.openCreateEditDialog(false);
  }

  public createXUser(): void {
    this.openCreateEditDialog(true);
    this._personService.createPerson.next({
      externalId: localStorage.getItem("externalId"),
      groupId: this.groupDisplayName,
    });
  }

  public removeXUser(id: string) {
    const successMessage = this._translateService.instant("User removed");

    this._personService.removeUser(id, this.groupDisplayName).subscribe(() => {
      this._personService.statusMessage("snack-bar-success", successMessage);
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupDisplayName,
        RESET_FILTER_PERSON
      );
    });
  }

  public deleteXUser(): void {
    this._personService.deleteXUser(this.selectedPerson.id).subscribe(() => {
      this._groupService.statusMessage(
        "snack-bar-success",
        "User deleted succesfully"
      );
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupDisplayName,
        RESET_FILTER_PERSON
      );
    });
  }

  public selectPerson(event): void {
    this.selectedPerson = event;
  }

  public resetPass(): void {
    this._personService
      .resetPersonPassword(this.selectedPerson.id)
      .subscribe(() => {
        this._personService.statusMessage(
          "snack-bar-success",
          "Password request sent"
        );
      });
  }

  openEditDialog(event) {
    this._personService.editXUser.next({
      person: {
        firstName: event.firstName,
        lastName: event.lastName,
        email: event.email,
        username: event.username,
      },
      accountType: event.accountNumber,
      groupId: this.groupDisplayName,
      activate: undefined,
      id: event.id,
    });
    this.openCreateEditDialog(false);
  }

  handleRowClick(event) {
    const canEditAdminUser = this._userPermissionService.hasRightForTenant({
      right: [PersonRole.admin, PersonRole.administrator],
      tentnatId: this.groupDisplayName,
    });

    // Admin or Administrator that is not the current user can open edit
    if (!canEditAdminUser || event.username == this.displayName) {
      return;
    }

    this.openEditDialog(event);
  }

  public action(event: { tableData; action: string }): void {
    this.selectedPerson = event.tableData;

    const actionMap = {
      edit: () => this.openEditDialog(this.selectedPerson),
      create: () => this.createXUser(),
      delete: () => this.openDeleteDialog(),
      resetPass: () => this.resetPass(),
      openDocumentVisibilityDialog: () => this._openDocumentVisibilityDialog(),
      manageCompanies: () => this.openManageCompaniesDialog(),
      remove: () => this.openRemoveDialog(this.selectedPerson.id),
    };

    actionMap[event.action]();
  }

  private _openDocumentVisibilityDialog() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "delete-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "600px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      studentId: this.selectedPerson.id,
      groupName: this.groupDisplayName,
    };

    const dialogRef = this.dialog.open(
      DocumentVisibilityComponent,
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason) => {});
  }

  public getPageIndex(): number {
    return this.page - 1;
  }

  public changePage(event: PageEvent): void {
    this.paginationAndSortConfig.page = event.pageIndex;
    if (this._personService.searchPersonQuery.role === "") {
      delete this._personService.searchPersonQuery.role;
    }
    this.persons$ = this._personService.getPersons(
      this.paginationAndSortConfig,
      this.groupDisplayName,
      this._personService.searchPersonQuery
    );
  }
  // public sorted
  public sortEvent(event: string): void {
    const isAsc = /^-/.test(event) ? "asc" : "desc";
    const replace = event.replace("-", "");

    // if (replace === 'role') {
    //   this.persons$ = this._personService.getPersons(this.paginationAndSortConfig,this.groupDisplayName,this._personService.searchPersonQuery)
    //   .pipe((map(data => {
    //     const sortedContent = data.content.sort((a,b)=>isAsc === 'asc' ? a.role.localeCompare(b.role): b.role.localeCompare(a.role));
    //     return {...data,content: sortedContent};
    //   })));

    //   return;
    // }

    this.paginationAndSortConfig.sort.field = replace;
    this.paginationAndSortConfig.sort.ascOrDesc = isAsc;

    this.persons$ = this._personService.getPersons(
      this.paginationAndSortConfig,
      this.groupDisplayName,
      this._personService.searchPersonQuery
    );
  }

  public openDeleteDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "delete-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "600px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: this._translateService.instant(
        "Are you sure you want to delete user?"
      ),
    };
    const dialogRef = this.dialog.open(DeleteDialogComponent, _dialogConfig);
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason === undefined || reason === "") return;
      this.deleteXUser();
    });
  }

  public openRemoveDialog(id: string): void {
    const config = new MatDialogConfig<ConfirmationDialogData>();
    config.disableClose = true;
    config.autoFocus = false;
    config.data = {
      dialogTitle: this._translateService.instant("removeAdminUser.title"),
      onCancel: {
        label: this._translateService.instant("removeAdminUser.labelCancel"),
        cta: () => {},
      },
      onConfirm: {
        label: this._translateService.instant("removeAdminUser.labelConfirm"),
        cta: () => {
          this.removeXUser(id);
          dialogRef.close();
        },
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason === undefined || reason === "") return;
      this.removeXUser(id);
    });
  }

  public openManageCompaniesDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "companiesa-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "900px";
    _dialogConfig.minHeight = "700px";
    _dialogConfig.data = {
      title: this._translateService.instant("Assign admin to companies"),
      inputPlaceHolder: "Search for company name",
      personId: this.selectedPerson.id,
    };
    const dialogRef = this.dialog.open(
      AssignAdminsToCompaniesComponent,
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason: any) => {
      if (reason === "close") return;
      this._personService.searchPersonQuery = RESET_FILTER_PERSON;
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupDisplayName,
        RESET_FILTER_PERSON
      );
    });
  }

  public openSearchPersonDialog() {
    const position = document.getElementById("searchButton");
    const topPosition =
      position.getBoundingClientRect().top + window.pageYOffset;
    const dialogRef = this.dialog.open(SearchPersonDialogComponent, {
      width: "474px",
      autoFocus: false,
      position: {
        top: topPosition + position.scrollHeight + "px",
        right: "42px",
      },
      disableClose: true,
      data: {
        adminUsers: true,
      },
    });

    dialogRef.afterClosed().subscribe((fiterValues) => {
      this._personService.searchPersonQuery = fiterValues;
      if (!fiterValues) {
        this.persons$ = this._personService.getPersons(
          { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "id" } },
          this.groupDisplayName,
          RESET_FILTER_PERSON
        );
        this.page = this.getPageIndex() - this.page;

        return;
      }
      let params = {};
      if (fiterValues.role === "") {
        delete fiterValues.role;
      }
      this.page = 0;
      // this.paginationAndSortConfig = {page:0,pageSize: 0,sort: {ascOrDesc: 'asc', field:'name'}}
      this.persons$ = this._personService.getPersons(
        { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "id" } },
        this.groupDisplayName,
        fiterValues
      );
    });
  }

  public openCreateEditDialog(isCreate: boolean): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "edit-create-group-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "700px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: isCreate
        ? this._translateService.instant("Create user")
        : this._translateService.instant("Edit user"),
      isXUser: true,
      tenantName: this.groupDisplayName,
    };

    const dialogRef = this.dialog.open(
      CreateEditAdminUserComponent,
      _dialogConfig
    );

    dialogRef.afterClosed().subscribe((reason: any) => {
      if (reason === "close") return;
      this._personService.searchPersonQuery = RESET_FILTER_PERSON;
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupDisplayName,
        RESET_FILTER_PERSON
      );
    });
  }

  public filterValueChange($event): void {
    this.persons$ = this._personService.getPersons(
      { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "id" } },
      this.groupDisplayName,
      this._personService.searchPersonQuery
    );
  }
}
