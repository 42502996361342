// @ts-ignore
import * as _keys from 'lodash/keys';
// @ts-ignore
import * as _assign from 'lodash/assign';
// @ts-ignore
import * as _findKey from 'lodash/findKey';
import * as moment from 'moment';

import { getMetadata, saveMetadata } from '../utils/metadata.util';
import { evalValue } from '../utils/assignment.util';

const apiResourceValueMetadataKey = Symbol('api-resource-value');
const apiResourceDateValueMetadataKey = Symbol('api-resource-date-value');
const apiResourceMapMetadataKey = Symbol('api-resource-map');

/**
 * Classes that represent structure of objects
 * received from api calls should extend this class.
 *
 * As an example, TodoResource would be a class for objects
 * that are received from getAll/getById calls and should
 * extend this abstract class
 */
export abstract class ApiResource {

  /**
   * Using the provided resourceObject, copy values to the new instance of this class
   * Uses decorators to determine how property values are handled
   * @param _resourceObject
   */
  constructor(
    protected _resourceObject: any,
  ) {
    // handle resource value decorators
    const resourceValueMaps = getMetadata(Object.getPrototypeOf(this), apiResourceValueMetadataKey, true);
    if (resourceValueMaps.length) {
      const resourceValueMap = _assign({}, ...resourceValueMaps);
      const props = _keys(resourceValueMap);
      props.forEach((p) => {
        this[p] = evalValue(_resourceObject, resourceValueMap[p]);
      });
    }
    // handle resource date value decorators
    const resourceDateValueMaps = getMetadata(Object.getPrototypeOf(this), apiResourceDateValueMetadataKey, true);
    if (resourceDateValueMaps.length) {
      const resourceDateValueMap = _assign({}, ...resourceDateValueMaps);
      const props = _keys(resourceDateValueMap);
      props.forEach((p) => {
        const options = resourceDateValueMap[p];
        if (options) {
          const resourceField = options.from && evalValue(_resourceObject, options.from) || _resourceObject[p];
          this[p] = resourceField && moment(resourceField, options.format);
        } else {
          this[p] = _resourceObject[p] && moment(_resourceObject[p]);
        }
      });
    }
    // handle resource map decorators
    const resourceMapLists = getMetadata(Object.getPrototypeOf(this), apiResourceMapMetadataKey, true);
    if (resourceMapLists.length) {
      const resourceMapList = _assign({}, ...resourceMapLists);
      const props = _keys(resourceMapList);
      props.forEach((p) => {
        this[p] = resourceMapList[p][_resourceObject[p]];
      });
    }
  }

  /**
   * Gets the original field that was mapped to the provided field
   * @param   resourceClass ApiResource class
   * @param   resourceField Field from which original field is requested
   * @returns               Original field name of the provided field
   */
  static getOriginalField(resourceClass: any, resourceField: string) {
    let originalField = resourceField;
    // search normal resource value map
    const resourceValueMaps = getMetadata(resourceClass, apiResourceValueMetadataKey, true);
    if (resourceValueMaps.length) {
      const resourceValueMap = _assign({}, ...resourceValueMaps);
      const props = _keys(resourceValueMap);
      if (props.indexOf(resourceField) !== -1) {
        originalField = resourceValueMap[resourceField];
      }
    }
    // search resource date map
    const resourceDateValueMaps = getMetadata(resourceClass, apiResourceDateValueMetadataKey, true);
    if (resourceDateValueMaps.length) {
      const resourceDateValueMap = _assign({}, ...resourceDateValueMaps);
      const props = _keys(resourceDateValueMap);
      if (props.indexOf(resourceField) !== -1) {
        if (resourceDateValueMap[resourceField]) {
          originalField = resourceDateValueMap[resourceField].from || resourceField;
        } else {
          originalField = resourceField;
        }
      }
    }
    // search resource mappings map
    const resourceMapLists = getMetadata(resourceClass, apiResourceMapMetadataKey, true);
    if (resourceMapLists.length) {
      const resourceMapList = _assign({}, ...resourceMapLists);
      const props = _keys(resourceMapList);
      if (props.indexOf(resourceField) !== -1) {
        originalField = resourceField; // NOTE map will probably get a 'from' param at some point, for now it uses same field
      }
    }
    return originalField;
  }

  /**
   * Get a mapped field from an original field
   * @param   resourceClass ApiResource class
   * @param   originalField Field for which mapped field is requested
   * @returns               Mapped field name of the provided field
   */
  static getMappedField(resourceClass: any, originalField: string) {
    let resourceField = originalField;

    // search normal resource value map
    const resourceValueMaps = getMetadata(resourceClass, apiResourceValueMetadataKey, true);
    if (resourceValueMaps.length) {
      const resourceValueMap = _assign({}, ...resourceValueMaps);
      const foundField = _findKey(resourceValueMap, (v) => v === originalField);
      if (foundField) {
        resourceField = foundField;
      }
    }
    // search resource date map
    const resourceDateValueMaps = getMetadata(resourceClass, apiResourceDateValueMetadataKey, true);
    if (resourceDateValueMaps.length) {
      const resourceDateValueMap = _assign({}, ...resourceDateValueMaps);
      const foundField = _findKey(resourceDateValueMap, (v) => v && v.from === originalField);
      if (foundField) {
        resourceField = foundField;
      }
    }
    // search resource mappings map
    // NOTE for now ApiResourceMap doesn't map to different fields, this should be implemented when it does
    // const resourceMapLists = getMetadata(resourceClass, apiResourceMapMetadataKey, true);
    // if (resourceMapLists.length) {
    //   const resourceMapList = _assign({}, ...resourceMapLists);
    // }
    return resourceField;
  }

  /**
   * Gets the field name from the original object
   * based on the mapped field name
   * @param  resourceField Field name inside the resource object
   * @return               Field name from the original object
   */
  public getOriginalField(resourceField: string) {
    return ApiResource.getOriginalField(Object.getPrototypeOf(this), resourceField);
  }

  /**
   * Gets the mapped field name of a field from the original object
   * @param  resourceField Field name inside the original object
   * @return               Mapped field inside the resource object
   */
  public getMappedField(originalField: string) {
    return ApiResource.getMappedField(Object.getPrototypeOf(this), originalField);
  }
}

/**
 * Decorator factory that indicates that a property value is to be copied from a resourceObject
 * @param from Which field to copy the value from
 */
export function ApiResourceValue(from?: string) {
  return function (target: any, property: string) {
    let map: any = getMetadata(target, apiResourceValueMetadataKey);
    if (!map) {
      map = {};
      saveMetadata(target, apiResourceValueMetadataKey, map);
    }
    map[property] = from || property;
  };
}

/**
 * Decorator factory that indicated that a property value is to be copied from a resourceObject
 * The value will be parsed as a date
 * @param  options Options for the decorator
 */
export function ApiResourceDateValue(options?: { dateFormat?: string, from?: string }) {
  return function (target: any, property: string) {
    let map: any = getMetadata(target, apiResourceDateValueMetadataKey);
    if (!map) {
      map = {};
      saveMetadata(target, apiResourceDateValueMetadataKey, map);
    }
    map[property] = options;
  };
}

/**
 * Decorator factory that indicated that a property value is to be copied from a resourceObject
 * The values will be mapped using the provided map object
 * @param mapObject The map object to use when mapping values.
 */
export function ApiResourceMap(mapObject: { [value: string]: any }) {
  return function (target: any, property: string) {
    let mapList = getMetadata(target, apiResourceMapMetadataKey);
    if (!mapList) {
      mapList = {};
      saveMetadata(target, apiResourceMapMetadataKey, mapList);
    }
    mapList[property] = mapObject;
  };
}
