import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../auth/services/auth.service';
import { GroupService, RESET_FILTER } from '../group.service';
import { Pagination } from '../interfaces/pagination.interface';

@Component({
  selector: 'sv-search-group-dialog',
  templateUrl: './search-group-dialog.component.html',
  styleUrls: ['./search-group-dialog.component.scss']
})
export class SearchGroupDialogComponent implements OnInit {
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: {ascOrDesc: 'asc', field:'name'}
  };
  searchGroupForm: UntypedFormGroup;
  public displayAccountNumber = true;
  constructor(private _groupService: GroupService,public dialogRef: MatDialogRef<SearchGroupDialogComponent>,public authService: AuthService) { 
    this.searchGroupForm = new UntypedFormGroup({      
      accountNumber: new UntypedFormControl(this._groupService.filterValues.accountNumber),
      displayName: new UntypedFormControl(this._groupService.filterValues.displayName),
      groupDescription: new UntypedFormControl(this._groupService.filterValues.groupDescription),
    })
    this.authService.tenant.subscribe(data => {
      this.displayAccountNumber = data === 'datapart'
      if (data === 'payslip') {
        this.searchGroupForm.removeControl('accountNumber')
      }
    })
  }

  ngOnInit(): void {
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }
  public onSubmit(): void {
    // this._groupService.getGroups(this.paginationAndSortConfig,this.searchGroupForm.value).subscribe()
    this._groupService.filterValues = this.searchGroupForm.value;
    this.dialogRef.close(this.searchGroupForm.value);
  }
  public onReset() {
    this._groupService.filterValues = {accountNumber:'',displayName:'',groupDescription:''};
    this.searchGroupForm.reset();
    this.dialogRef.close(undefined);
    // for (const name in this.searchGroupForm.controls) {
    //   if (this.searchGroupForm.controls) {
    //     this.searchGroupForm.controls[name].setErrors(null);
    //   }
    // }
  }
}
