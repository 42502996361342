import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { Config } from '../../maintenance.config';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  public maintance: boolean;
  public maintanaceDate = new Subject()
  public maintenanceStart = new Date(); // Months are 0-indexed in JS Date
  public maintenanceEnd = new Date();
  public bannerStart = new Date(); // Months are 0-indexed in JS Date
  public bannerEnd = new Date();
  public displayBanner: boolean;

  public maintenanceMode: boolean;
  public fromToDate: string;



  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _authService: AuthService,
    ) { }

  public setMaintennace(mode) {
    this.maintance = mode;
  }
  public getMaintance() {
   return  this.maintance;
  }
  public getMaintananceConfig() {
    const timestamp = new Date().getTime(); // Get current timestamp
    return this._http.get(`assets/maintanance-config/maintanance-config.json?ts=${timestamp}`)

  }
  public checkMaintenanceMode(config: Config,interval) {
    const now = new Date();
    this.maintanaceDate.next(config)
    this.maintenanceStart = new Date(config.maintenancePageDisplay.startDate.year,config.maintenancePageDisplay.startDate.month,config.maintenancePageDisplay.startDate.day,config.maintenancePageDisplay.startDate.hours,config.maintenancePageDisplay.startDate.minutes,config.maintenancePageDisplay.startDate.seconds)
    this.maintenanceEnd = new Date(config.maintenancePageDisplay.endDate.year,config.maintenancePageDisplay.endDate.month,config.maintenancePageDisplay.endDate.day,config.maintenancePageDisplay.endDate.hours,config.maintenancePageDisplay.endDate.minutes,config.maintenancePageDisplay.endDate.seconds)

    if (now >= this.maintenanceStart && now <= this.maintenanceEnd) {
      this.maintenanceMode = true;
      this._router.navigate(['/maintance-page']);
      this.setMaintennace(true);
    } else {
      this.maintenanceMode = false;
 
      if (this._router.url.includes('/maintance-page')) {
        if (this._authService.isLoggedIn) {
          this._authService.logout();
        }
        localStorage.removeItem('maintance-page');
        this.setMaintennace(false);
        // const originalUrl = window.location.href;
        // const redirectToLandingPage = originalUrl.replace(/\/maintance-page$/, "");
        // window.location.href = redirectToLandingPage;
        this._router.navigate(['/login'])
        clearInterval(interval)
      }
    }
  }
  public setBannerDateFromToText(config: Config) {
    const startDate = new Date(config.maintenancePageDisplay.startDate.year,config.maintenancePageDisplay.startDate.month,config.maintenancePageDisplay.startDate.day,config.maintenancePageDisplay.startDate.hours,config.maintenancePageDisplay.startDate.minutes,config.maintenancePageDisplay.startDate.seconds)
    const endDate = new Date(config.maintenancePageDisplay.endDate.year,config.maintenancePageDisplay.endDate.month,config.maintenancePageDisplay.endDate.day,config.maintenancePageDisplay.endDate.hours,config.maintenancePageDisplay.endDate.minutes,config.maintenancePageDisplay.endDate.seconds)
    
    const maintanaceStartDay = ("0" + startDate.getDate()).slice(-2); // Add leading zero if necessary
    const maintanaceStartMonth = ("0" + (startDate.getMonth() + 1)).slice(-2); // Add leading zero, month is 0-indexed
    const maintanaceStartYear = startDate.getFullYear();
    
    const maintanaceEndDay = ("0" + endDate.getDate()).slice(-2); // Add leading zero if necessary
    const maintanaceEndMonth = ("0" + (endDate.getMonth() + 1)).slice(-2); // Add leading zero, month is 0-indexed
    const maintanaceEndYear = endDate.getFullYear();

    const maintananceStartHours = ("0" + startDate.getHours()).slice(-2); // Add leading zero if necessary
    const maintananceStartMinutes = ("0" + startDate.getMinutes()).slice(-2); // Add leading zero if necessary
    const maintananceStartSeconds = ("0" + startDate.getSeconds()).slice(-2); // Add leading zero if necessary

    const maintananceEndHours = ("0" + endDate.getHours()).slice(-2); // Add leading zero if necessary
    const maintananceEndMinutes = ("0" + endDate.getMinutes()).slice(-2); // Add leading zero if necessary
    const maintananceEndSeconds = ("0" + endDate.getSeconds()).slice(-2); // Add leading zero if necessary


    // Format the date as "dd.mm.yyyy"
    const maintanaceStartFormated = `${maintanaceStartDay}.${maintanaceStartMonth}.${maintanaceStartYear}`;

    const maintanaceEndFormated = `${maintanaceEndDay}.${maintanaceEndMonth}.${maintanaceEndYear}`;

    this.fromToDate = `${maintanaceStartFormated} ${maintananceStartHours}:${maintananceStartMinutes}:${maintananceStartSeconds} bis ${maintanaceEndFormated} ${maintananceEndHours}:${maintananceEndMinutes}:${maintananceEndSeconds}`
  }
    checkBanner(config: Config) {
    const now = new Date();
    this.bannerStart = new Date(config.banner.startDate.year,config.banner.startDate.month,config.banner.startDate.day,config.banner.startDate.hours,config.banner.startDate.minutes,config.banner.startDate.seconds); // Months are 0-indexed in JS Date
    this.bannerEnd = new Date(config.banner.endDate.year,config.banner.endDate.month,config.banner.endDate.day,config.banner.endDate.hours,config.banner.endDate.minutes,config.banner.endDate.seconds);
    if (now >= this.bannerStart && now <= this.bannerEnd) {
      this.displayBanner = true;
    } else {
      this.displayBanner = false;
    }
  }
}
