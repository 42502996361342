import { Component, OnInit, ViewChild, Output, EventEmitter, Inject, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
import * as _assign from 'lodash/assign';
// @ts-ignore
import * as _pickBy from 'lodash/pickBy';

import { UserStatus } from '../../enums/user-status.enum';
import { UserSearchModel } from '../../classes/user-search-model.class';
import { AuthService } from '../../../auth/services/auth.service';
import { GeneralTableSettings } from '../../../shared/classes/general-table-settings.class';

@Component({
  selector: 'sv-user-search',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.scss']
})
export class UserSearchComponent implements OnInit {
  @Output() searchSubmit = new EventEmitter();
  @ViewChild('userSearchForm', {static: true}) public form: NgForm;
  public tableUserModel: GeneralTableSettings;
  public filterValues: UserSearchModel;
  public statusDropdownOptions: { name: string, id: string }[];
  public filterModel: UserSearchModel;

  constructor(public dialogRef: MatDialogRef<UserSearchComponent>, private _translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: any, public authService: AuthService) {
    this.filterValues = data;
    this.statusDropdownOptions = [
      { name: this._translate.instant('All Statuses'), id: UserStatus.AllStatuses },
      { name: this._translate.instant('Open'), id: UserStatus.Open },
      { name: this._translate.instant('NotOpen'), id: UserStatus.NotOpen },
      { name: this._translate.instant('Closed'), id: UserStatus.Closed },
      { name: this._translate.instant('Disabled'), id: UserStatus.Disabled },
      { name: this._translate.instant('Inactive'), id: UserStatus.Inactive },
      { name: this._translate.instant('Unconfirmed'), id: UserStatus.Unconfirmed },

    ];
  }

  ngOnInit() {
    this.initFilterModel();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

  private initFilterModel() {
    this.tableUserModel = this.authService.getGeneralTableSettings();
    let filterMap = this.tableUserModel;
    if (!filterMap) {
      filterMap = {
        accountNumber: 'String',
        displayName: 'String',
        email: 'String',
        employee: 'boolean',
        status: 'String',
        userName: 'String',
        export: 'String',
        unreadDocuments:'Number'
      };
    }

    this.filterModel = _assign(this.filterModel, filterMap);
  }

  public onReset() {
    this.form.reset();
    for (const name in this.form.controls) {
      if (this.form.controls) {
        this.form.controls[name].setErrors(null);
      }
    }
    this.statusDropdownOptions = [
      { name: this._translate.instant('All Statuses'), id: UserStatus.AllStatuses },
      { name: this._translate.instant('Open'), id: UserStatus.Open },
      { name: this._translate.instant('NotOpen'), id: UserStatus.NotOpen },
      { name: this._translate.instant('Closed'), id: UserStatus.Closed },
      { name: this._translate.instant('Disabled'), id: UserStatus.Disabled },
      { name: this._translate.instant('Inactive'), id: UserStatus.Inactive },
      { name: this._translate.instant('Unconfirmed'), id: UserStatus.Unconfirmed },
    ];
    this.dialogRef.close(undefined);

  }

  public onSubmit() {
    this.filterValues = new UserSearchModel();
    const checkedValues = _pickBy(this.form.value, (value: number | string) => {
      if (value === 0) {
        return 'status: 0';
      } else {
        return value;
      }
    });
    this.filterValues = _assign(this.filterValues, checkedValues);
    this.dialogRef.close(this.filterValues);
  }
}
