import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { UploadUtilityService } from "./upload-utility.service";

@Injectable({
  providedIn: "root",
})
export class UploadViewService {
  constructor(
    private _snackbar: MatSnackBar,
    private _uploadUtilityService: UploadUtilityService
  ) {}

  public preUpload = true;
  public hasError = true;
  public displaySendBtn = false;
  public displayFileUpload = true;
  public displayPdfConversionCode = false;
  public disablePointerEvent = true;
  public disableUpload = true;
  public containsZip: boolean;
  public triggerChangeDetection = new Subject<boolean>();
}
