import { ApiResource, ApiResourceValue, ApiResourceMap } from '../../shared/classes/api-resource.class';
import { UserStatus } from '../enums/user-status.enum';

import { Moment } from 'moment';

export class UserAccountDataApiResource extends ApiResource {
  @ApiResourceValue() public id: string;
  @ApiResourceValue() public familyName: string;
  @ApiResourceValue() public givenName: string;
  @ApiResourceValue() public displayName: string;
  @ApiResourceValue() public email: string;
  @ApiResourceValue() public accountNumber: string;
  @ApiResourceValue() public userName: string;
  @ApiResourceValue() public phone: string;
  @ApiResourceValue() public optIn: boolean;
  @ApiResourceMap({
    'OPEN': UserStatus.Open,
    'NOT_OPEN': UserStatus.NotOpen,
    'CLOSED': UserStatus.Closed,
    'DISABLED': UserStatus.Disabled,
    'UNCONFIRMED': UserStatus.Unconfirmed,
    'INACTIVE': UserStatus.Inactive,
  }) public status: UserStatus;
  @ApiResourceValue() public corporateCustomer: boolean;
  @ApiResourceValue() public employee: boolean;
  @ApiResourceValue() public personalBranding: string;
  @ApiResourceValue() public additionalEmailList: string[];
  @ApiResourceValue() public birthDay: Moment;
}
