import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Inject,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../../auth/services/auth.service";

export type ConfirmationDialogData = {
  dialogTitle: string;
  content?: {
    component: any;
    componentData: Record<string, any>;
  };
  onConfirm: {
    label: string;
    cta: () => void;
  };
  onCancel: {
    label: string;
    cta: () => void;
  };
};

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrl: "./confirmation-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  @ViewChild("dynamicContent", { read: ViewContainerRef, static: true })
  dynamicContent: ViewContainerRef;

  constructor(
    public authService: AuthService,
    private matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  ngAfterViewInit(): void {
    if (this.data.content) {
      const { component, componentData } = this.data.content;
      const componentRef = this.dynamicContent.createComponent<any>(component);
      componentRef.instance.data = componentData;
      componentRef.hostView.detectChanges();
    }
  }

  public onCancel() {
    if (this.data.onCancel?.cta) {
      this.data.onCancel.cta();
    }
    this.matDialogRef.close();
  }
}
