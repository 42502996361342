<section class="dictionary-xml">
  <div class="user-selection">
    <div class="dictionary-xml__header">
      <h1>{{dialogTitle | translate}}</h1>
      <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
    </div>
  
    <div class="dictionary-xml__table">
      <div class="dictionary-xml__search-wrapper">
  
        <div class="dictionary-xml__search-container">
          <div class="dictionary-xml__search-container-input">
            <input matInput autocomplete="off" [(ngModel)]="filterValue"
              placeholder="{{filterLabelText | translate}}">
          </div>
          <div class="dictionary-xml__search-container-icon">
            <mat-icon class="icon-style">search</mat-icon>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleAll()">
            </th>
            <th *ngFor="let columns of columns">{{ columns | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let row of data | filter: filterValue: ['displayName','username','email','accountNumber']: handleFiltered | paginate: itemsPerPage : (pageIndex + 1); let i = index">
            <td>
              <input type="checkbox" [(ngModel)]="row.isSelected" (change)="handleSelected()">
            </td>
            <td>{{ row.displayName }}</td>
            <td>{{ row.accountNumbers }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.username }}</td>


          </tr>
        </tbody>
      </table>
      <div class="additional-elements">
        <mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageIndex]="pageIndex"
          (page)="handlePageEvent($event)"></mat-paginator>

      </div>
    </div>

  </div>
  <!-- <div class="upload-dialog" *ngIf="displaySteps.step2">
    <app-upload-dialog></app-upload-dialog>
  </div> -->
  <div class="dictionary-xml__actions">
    <button type="button" #btn (click)="closeDialog()" [ngStyle]="{
          'color': '#FFF',
          'background-color': '#9a9a9a',
        }">
      <div class="title back">
        {{'Cancel' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
        </div>
      </div>
    </button>
    <button type="button" #btn (click)="next()" [disabled]="isButtonDisabled"  [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': !isButtonDisabled ? (authService.backgroundColors | async)?.primaryButtonColor : '#0000001f',
          'pointer-events': isButtonDisabled ? 'none' : 'visible'


        }">
      <div class="title">
        {{'Next' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
  </div>
  </section>
  