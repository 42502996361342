import { Injectable } from '@angular/core';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class SidebarService {
  private sidebarSubmenuSource = new BehaviorSubject(false);
  currentSubmenuState = this.sidebarSubmenuSource.asObservable();

  isSidebarClosed$: Observable<boolean>;
  isSidebarSubmenuClosed$: Observable<boolean>;
  private isSidebarClosed: Subject<boolean>;
  private isSidebarSubmenuClosed: Subject<boolean>;

  constructor() {
    this.isSidebarClosed = new Subject<boolean>();
    this.isSidebarSubmenuClosed = new Subject<boolean>();

    this.isSidebarClosed$ = this.isSidebarClosed.asObservable();
    this.isSidebarSubmenuClosed$ = this.isSidebarSubmenuClosed.asObservable();

  }

  changeSidebarSubmenuVisibility(value: boolean) {
    this.sidebarSubmenuSource.next(value);
  }

  setSidebarSubmenuVisibility() {
    this.currentSubmenuState.subscribe((result: boolean) => {
      if (result) {
        this.openSidebarSubmenu();
      } else {
        this.closeSidebarSubmenu();
      }
    });
  }

  openSidebarSubmenu() {
    this.isSidebarSubmenuClosed.next(false);
  }

  closeSidebarSubmenu() {
    this.isSidebarSubmenuClosed.next(true);
  }

  openSidebar() {
    this.isSidebarClosed.next(false);
    this.setSidebarSubmenuVisibility();
  }

  closeSidebar() {
    this.isSidebarClosed.next(true);
    this.closeSidebarSubmenu();
  }
}
