import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'paginate',
})

export class PaginatePipe implements PipeTransform {
constructor() {

}

transform(array: any[], page_size: number, page_number: number): any[] {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }
}