import { Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'sv-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent {
  onLogout = new EventEmitter<boolean>();

  closeButtonLabel: string;

  constructor(
    private _translateService: TranslateService,
    public auth: AuthService
  ) {
    this.closeButtonLabel = this._translateService.instant('Close');
  }

  logout(isLogout: boolean) {
    this.onLogout.emit(isLogout);
    this.auth.logout();
  }

}
