
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/services/auth.service';
import { environment } from '../../../environments/environment';
import { MultipleNotificationsService } from '../components/multiple-notifications/multiple-notifications.service';


@Component({
    selector: 'app-custom-reset-password',
    templateUrl: './custom-reset-password.component.html',
    styleUrls: ['./custom-reset-password.component.scss']
  })
  export class CustomResetPassword implements OnInit, AfterViewInit {

      @ViewChild('oldPassword',{ static: true, read: ElementRef }) oldPassword: ElementRef;
      @ViewChild('resetPasswordDialogContainer') resetPasswordDialogContainer:ElementRef<HTMLDivElement>;

      private _LOWERCASE_REGEXP: any = new RegExp(environment.LOWERCASE_REGEXP);
      private _UPPERCASE_REGEXP: any = new RegExp(environment.UPPERCASE_REGEXP);
      private _NUMERIC_REGEXP: any = new RegExp(environment.NUMERIC_REGEXP);
      private _SYMBOL_REGEXP: any = new RegExp(environment.SYMBOL_REGEXP);
      
      public resetPasswordForm: UntypedFormGroup;

      public hide = {
          hideFirstInput: false,
          hideSecondInput: false,
          hideThirdInput: false
      };
      constructor(
        private _authService: AuthService, 
        private router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _dialogRef: MatDialogRef<CustomResetPassword>,
        private _notification: MultipleNotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {

          this.resetPasswordForm = new UntypedFormGroup({
            newPassword: new UntypedFormControl('', Validators.compose([Validators.minLength(8), Validators.required, this.confirmPassword.bind(this), this.validatePassword.bind(this)])),
            newPasswordConfirm: new UntypedFormControl('', Validators.compose([Validators.minLength(8), Validators.required, this.confirmPassword.bind(this)]))
          })

      }
      ngAfterViewInit(): void {
      }
      ngOnInit(): void {
      }
      public inputEvent(): void {
        this.resetPasswordForm.controls.newPassword.updateValueAndValidity();
        this.resetPasswordForm.controls.newPasswordConfirm.updateValueAndValidity();

      }
      private confirmPassword(): {[key:string]: any} | null {
        return this.resetPasswordForm?.controls?.newPassword?.value !== this.resetPasswordForm?.controls?.newPasswordConfirm?.value 
        ? { passwordMustMatch: true } : null;
        
      }
      private validatePassword(): {[key:string]: any} | null {

        const newPassword = this.resetPasswordForm?.controls?.newPassword?.value;

        const capitalLetterPolicy = (this._UPPERCASE_REGEXP.test(newPassword));
        const smallLetterPolicy = (this._LOWERCASE_REGEXP.test(newPassword));
        const symbolPolicy = (this._SYMBOL_REGEXP.test(newPassword));
        const numberPolicy = (this._NUMERIC_REGEXP.test(newPassword));

        const trueConditionsCount = [capitalLetterPolicy, smallLetterPolicy, symbolPolicy, numberPolicy].filter(Boolean).length;


        if(trueConditionsCount<3){
            return { invalidPassword: true};
        }
        return null;
      }
      public closeResetPassword(event: Event): void {
        event.preventDefault();  // Prevent default behavior
        event.stopPropagation(); // Stop event propagation   
        this.router.navigate(['/login']);
   
        this._dialog.closeAll();

      }
      public handlePasswordEye(e, hideInput: string): void {
          e.stopPropagation();
          this.hide[hideInput] = !this.hide[hideInput];
      }
      public submitForm(): void {


            this._authService.resetPasswordFromEmail(this.data.token,this.resetPasswordForm.get('newPassword').value).subscribe(res=>{
              this._dialog.closeAll();
              this._authService.handleSuccesOrError('snack-bar-success','Password successfully reset');
              this.router.navigate(['/login']);
            },err=>{
              this._dialog.closeAll();
              this._authService.handleSuccesOrError('snack-bar-fail','Restart password failed');
              this.router.navigate(['/login']);
            })
          
      }
  }