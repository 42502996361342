<sv-header></sv-header>
<div class="error-container">
  <div class="error-img">
    <img [src]="errorMessages.img">
  </div>
  <div class="errors-text">
    <div class="error-inline">
      <p class="error-title">{{errorMessages.title}}</p>
      <p class="error-title">{{errorMessages.titleStatusCode}}</p>
    </div>
    <p class="error-message">{{errorMessages.errorMessage}}</p>
    <p class="causes-title">{{errorMessages.causesTitle}}</p>
    <p class="causes-message">{{errorMessages.causesMessage}}</p>
  </div>
</div>
<sv-footer class="footer"></sv-footer>