import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../auth/services/auth.service";
import { PasswordValidation } from "./passwordValidations";
import { UsersService } from "../../users/services/users/users.service";
import { GroupService } from "../../../app/group/group.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MultipleNotificationsService } from "../components/multiple-notifications/multiple-notifications.service";

class ChangePassword {
  public oldPassword: string;
  public newPassword: string;
  public newPasswordConfirm: string;
}

@Component({
  selector: "sv-password-data",
  templateUrl: "./password-data.component.html",
  styleUrls: ["./password-data.component.scss"],
})
export class PasswordDataComponent implements OnInit {
  public form: UntypedFormGroup;
  public model: ChangePassword;
  public invalidOldPassword = false;
  public isInvalid: boolean;
  public hideCurrentPassword: boolean = true;
  public hideNewPassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public disableEdit: boolean;
  public capitalLetterPolicy: boolean;
  public smallLetterPolicy: boolean;
  public symbolPolicy: boolean;
  public numberPolicy: boolean;
  public passwordPolicyValid: boolean;
  public passwordsMatched: boolean = true;
  public samePasswords: boolean;
  public validForm: boolean = true;

  private _LOWERCASE_UPPERCASE_NUMERIC_REGEXP: any = new RegExp(
    PasswordValidation.LOWERCASE_UPPERCASE_NUMERIC_REGEXP
  );
  private _LOWERCASE_UPPERCASE_SYMBOL_REGEXP: any = new RegExp(
    PasswordValidation.LOWERCASE_UPPERCASE_SYMBOL_REGEXP
  );
  private _LOWERCASE_NUMERIC_SYMBOL_REGEXP: any = new RegExp(
    PasswordValidation.LOWERCASE_NUMERIC_SYMBOL_REGEXP
  );
  private _UPPERCASE_NUMERIC_SYMBOL_REGEXP: any = new RegExp(
    PasswordValidation.UPPERCASE_NUMERIC_SYMBOL_REGEXP
  );
  private _LOWERCASE_REGEXP: any = new RegExp(
    PasswordValidation.LOWERCASE_REGEXP
  );
  private _UPPERCASE_REGEXP: any = new RegExp(
    PasswordValidation.UPPERCASE_REGEXP
  );
  private _NUMERIC_REGEXP: any = new RegExp(PasswordValidation.NUMERIC_REGEXP);
  private _SYMBOL_REGEXP: any = new RegExp(PasswordValidation.SYMBOL_REGEXP);
  public isResetPasswordFromEmail: boolean;

  constructor(
    public router: Router,
    public authService: AuthService,
    public usersService: UsersService,
    public groupService: GroupService,
    private _translateService: TranslateService,
    private _dialog: MatDialog,

  ) {
    this.isResetPasswordFromEmail = this.authService?.passwordResetToken ? true : false;
    this.model = new ChangePassword();
    this.form = new UntypedFormGroup({
      oldPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(64),
      ]),
      newPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.validatePassword(),
      ]),
      confirmedNewPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
      ]),
    });

    if (this.isResetPasswordFromEmail) {
      this.form.removeControl('oldPassword')
    }
  }
  ngOnInit() {
    this.isFormValid();
  }

  public isFormValid() {
    const falseCount = [
      this.capitalLetterPolicy,
      this.smallLetterPolicy,
      this.symbolPolicy,
      this.numberPolicy,
    ].filter((policy) => !policy).length;
    this.validForm = falseCount < 2 && this.form.valid;
  }

  public onTouch() {
    this.validateConfirmPassword();
    this.oldAndNewPasswordsMatching();
    this.isFormValid();
  }

  validatePassword() {
    return (control: AbstractControl) => {
      this.capitalLetterPolicy = this._UPPERCASE_REGEXP.test(control.value);
      this.smallLetterPolicy = this._LOWERCASE_REGEXP.test(control.value);
      this.symbolPolicy = this._SYMBOL_REGEXP.test(control.value);
      this.numberPolicy = this._NUMERIC_REGEXP.test(control.value);
      if (
        !(
          this._LOWERCASE_UPPERCASE_NUMERIC_REGEXP.test(control.value) ||
          this._LOWERCASE_UPPERCASE_SYMBOL_REGEXP.test(control.value) ||
          this._LOWERCASE_NUMERIC_SYMBOL_REGEXP.test(control.value) ||
          this._UPPERCASE_NUMERIC_SYMBOL_REGEXP.test(control.value)
        )
      ) {
        this.passwordPolicyValid = false;
        return { invalidPattern: true };
      }

      this.passwordPolicyValid = true;
      return null;
    };
  }

  validateConfirmPassword() {
    return this.form.controls.newPassword.value.length > 0 &&
      this.form.controls.confirmedNewPassword.value.length > 0 &&
      this.form.controls.newPassword.value ===
        this.form.controls.confirmedNewPassword.value && this.form.controls?.oldPassword?.value !== this.form.controls.newPassword.value
      ? (this.passwordsMatched = true)
      : (this.passwordsMatched = false);
  }

  oldAndNewPasswordsMatching() {
    return this.form.controls?.oldPassword?.value &&
      this.form.controls.newPassword.value &&
      this.form.controls.confirmedNewPassword.value &&
      this.form.controls?.oldPassword?.value ===
        this.form.controls.newPassword.value &&
      this.form.controls?.oldPassword?.value ===
        this.form.controls.confirmedNewPassword.value
      ? (this.samePasswords = true)
      : (this.samePasswords = false);
  }

  submit() {
    if ( this.isResetPasswordFromEmail) {
      this.authService
      .resetPasswordFromEmail(this.authService?.passwordResetToken,this.form.controls.newPassword.value)
      .subscribe(() => {
        this._dialog.closeAll();
        this.authService.handleSuccesOrError('snack-bar-success','Password successfully reset');
        this.router.navigate(['/login']);

      },err => {
        this._dialog.closeAll();
        this.authService.handleSuccesOrError('snack-bar-fail','Restart password failed');
        this.router.navigate(['/login']);

      })
      return;
    }
    this.usersService
      .updatePassword({
        oldPassword: this.form.controls.oldPassword.value,
        newPassword: this.form.controls.newPassword.value,
        newPasswordConfirm: this.form.controls.confirmedNewPassword.value,
      })
      .subscribe(
        () => {
          this.groupService.statusMessage(
            "snack-bar-success",
            "Password changed successfully"
          );
        },
        (error) => {
          console.error("Error occurred:", error.errorMessage);
          this.groupService.statusMessage("snack-bar-fail", error.errorMessage);
        }
      );
  }

  toggleHideCurrentPassword(e) {
    e.preventDefault();
    this.hideCurrentPassword = !this.hideCurrentPassword;
  }

  toggleHideNewPassword(e) {
    e.preventDefault();
    this.hideNewPassword = !this.hideNewPassword;
  }

  toggleHideConfirmPassword(e) {
    e.preventDefault();
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
  closeDialog() {
    this.router.navigate(['/login']);
    this._dialog.closeAll();
  }
}
