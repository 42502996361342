/**
 * Assigns a property value to an object
 * @param object     The object the assignment is taking place on
 * @param expression Property to assign to. Can be dot (.) separated to indicate nesting.
 * @param value      Value to assign to the object property
 */
export function assign(object: object, expression: string, value: any) {
  const expressionSeparated = expression.split('\.');
  let currentObject = object;
  expressionSeparated.slice(0, -1).forEach((field) => {
    currentObject = currentObject[field] = currentObject[field] || {};
  });
  currentObject[expressionSeparated.pop()] = value;
}

/**
 * Evaluate a value from an object
 * @param   obj  The object from which to evaluate the value
 * @param   path Property to evaluate. Can be dot (.) separated to indicate nesting.
 * @returns      The evaluated value
 */
export function evalValue(obj: object, path: string) {
  const currentPath = path.split('.');
  let current = obj;
  while (currentPath.length) {
    if (typeof current !== 'object') {
      return undefined;
    }
    current = current[currentPath.shift()];
  }
  return current;
}
