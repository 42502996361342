<cdk-table [dataSource]="data">
  <ng-container *ngFor="let column of columns; let i = index" [cdkColumnDef]="column.field">
    <cdk-header-cell *cdkHeaderCellDef [ngSwitch]="getColumnSortState(column.field)" (click)="cycleSort(column)"
      [ngStyle]="{'width.px': column.width, 'min-width.px': column.minWidth, 'max-width.px': column.maxWidth}">
      <div class="header-wrap">
        <mat-checkbox class="selection-checkbox" *ngIf="selectable && i === 0" [checked]="areAllRowsSelected"
          (click)="toggleAllSelection(); $event.stopPropagation()"></mat-checkbox>
        <div [ngClass]="{'no-checkbox-padding': !selectable && i === 0}">
          {{ column.title }}
        </div>
        <div class="inline-div" *ngIf="column.isSortable && !column.reverseSortIndicator">
          <mat-icon *ngSwitchCase="sortState.Unsorted" class="unsorted" svgIcon="custom:sort"></mat-icon>
          <mat-icon *ngSwitchCase="sortState.Ascending" svgIcon="custom:sort"></mat-icon>
          <mat-icon *ngSwitchCase="sortState.Descending" svgIcon="custom:sort-up"></mat-icon>
        </div>
        <div class="inline-div" *ngIf="column.isSortable && column.reverseSortIndicator">
          <mat-icon *ngSwitchCase="sortState.Unsorted" class="unsorted" svgIcon="custom:sort"></mat-icon>
          <mat-icon *ngSwitchCase="sortState.Ascending" svgIcon="custom:sort-up"></mat-icon>
          <mat-icon *ngSwitchCase="sortState.Descending" svgIcon="custom:sort"></mat-icon>
        </div>
      </div>
    </cdk-header-cell>
    <cdk-cell *cdkCellDef="let row" [ngClass]="{'is-deleting': (selectionMap[row[selectIdField]] && isDeleting)}"
      (click)="rowClick(row);$event.stopPropagation()">
      <div class="cell-content-wrap">
        <mat-checkbox *ngIf="selectable && i === 0" class="selection-checkbox"
          [checked]="selectionMap[row[selectIdField]]" (click)="toggleSelection(row); $event.stopPropagation();">
        </mat-checkbox>
        <!-- Action buttons -->
        <mat-button *ngIf="actions && actions && i === columns.length - 1">
          <div *ngFor="let buttonGroup of actions[tenant]">
            <div *ngFor="let buttonKey of getButtonKeys(buttonGroup)" class="action-buttons">
              <button mat-button *ngIf="buttonGroup[buttonKey].showIf(row)"
                (click)="dispatchAction($event, row, buttonGroup[buttonKey].onClick)">
                {{ buttonGroup[buttonKey].name | translate }}
              </button>
            </div>
          </div>
        </mat-button>
        <div [ngClass]="{'no-checkbox-padding': !selectable && i === 0}">
          <div *ngIf="column.icons!==undefined" class="icon-wrap">
            <div *ngFor="let iconItem of column.icons">
              {{row[id]}}
              <mat-icon *ngIf="iconItem.valueCondition.toString() === row[column.field].toString()"
                [svgIcon]="iconItem.icon"></mat-icon>
            </div>
          </div>
          <div class="inline-div" *ngIf="column.icons===undefined">
            <div>{{ column.toDisplayString ? column.toDisplayString(row[column.field]) : row[column.field] }}</div>
          </div>
        </div>
      </div>
    </cdk-cell>
  </ng-container>

  <cdk-header-row *cdkHeaderRowDef="cdkColumnDefs"></cdk-header-row>
  <cdk-row [ngClass]="{'pointer':true}" #cdkRow *cdkRowDef="let row; columns: cdkColumnDefs; let i = index"
    (mouseenter)="row.isHovered = true" (mouseleave)="row.isHovered = false" [ngStyle]="getItemStyle(i,row.isHovered)">

  </cdk-row>
</cdk-table>