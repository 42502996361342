<div class="top-menu-container">
  <span class="top-menu-users" *ngIf="totalItems">
    <span *ngIf="totalLabel">
      {{totalLabel | translate}}:
    </span>
    <span *ngIf="!totalLabel">
      {{'Total users' | translate}}:
    </span>
    <span>
      {{totalItems || 0}}
    </span>
  </span>
</div>