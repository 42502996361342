import { AfterViewInit, Component, ElementRef, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CreateEditPersonComponent } from '../create-edit-person/create-edit-person.component';
import { PersonService } from '../person/person.service';
import { Pagination } from '../interfaces/pagination.interface';
import { UploadCSVDialogComponent } from '../../shared/components/upload-csv-files/upload-csv-files.component';
import { UploadDialogComponent } from '../../shared/components/upload/upload-dialog.component';
import { GroupService } from '../group.service';

@Component({
  selector: 'sv-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit,AfterViewInit {
  public groupId: string;
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: {ascOrDesc: 'asc', field:'name'}
  }
  public title = 'Create user'
  public groupPrefixes
  public einfachBriefEnabled: boolean;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private _translateService: TranslateService,
    public _personService: PersonService,
    public authService: AuthService,
    public groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) data,
    ) {
      this.groupId = data.groupId;
      this.einfachBriefEnabled = data.einfachBriefEnabled;
    }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.groupService.getGroupPrefixes(this.groupId).subscribe(data => {
      this.groupPrefixes = data
    })
  }

  public openManuelCreatingOfUser(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = 'edit-create-group-dialog';
    _dialogConfig.autoFocus = true;
    _dialogConfig.maxWidth = '700px';
    _dialogConfig.minWidth = '700px';
    _dialogConfig.height = 'auto';
    _dialogConfig.data = {
      title: this._translateService.instant('Create user'),
      isXUser: false,
      groupId: this.groupId,
      companyPrefixes: this.groupPrefixes,
      einfachBriefEnabled: this.einfachBriefEnabled

    }
    const dialogRef = this.dialog.open(CreateEditPersonComponent, _dialogConfig);
    dialogRef.afterClosed().subscribe((reason: any) => {
      if (reason === 'submit') {
        this._personService.updatePersons(); 
        this.dialogRef.close()
    }})
  }

  public openCSVUpload(): void {
    const dialogRef = this.dialog.open(UploadCSVDialogComponent, {
      disableClose:true,
      autoFocus: false,
      data: {
        isCSV: true,
        groupId: this.groupId,
        companyPrefixes: this.groupPrefixes
      }
     });
    dialogRef.afterClosed().subscribe((reason: any) => {
      if (reason === 'sendDocuments') {
        this._personService.updatePersons(); 
        this.dialogRef.close()
    }})
  }
  
  public closeDialog(): void {
    this.dialogRef.close('close');
  }
}
