<div class="create_user_dialog_content">
  <div class="create_user_dialog_content__header">
    <h1 mat-dialog-title>{{ title  | translate }}</h1>
    <mat-icon
      class="close"
      (click)="closeDialog()"
      [svgIcon]="'custom:close'"
    ></mat-icon>
  </div>

  <div class="buttons-wrapper">
    <button
      type="button"
      #btn
      (click)="openManuelCreatingOfUser()"
    >
      <div class="title">
        {{ "Manuel Creation" | translate }}
      </div>
      <div class="button__footer">
        <div class="button__subline"></div>
        <div class="button__icon" [ngStyle]="{ 'flex-basis.px': 10 }">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>

    <button
      type="button"
      #btn
      (click)="openCSVUpload()"
    >
      <div class="title">
        {{ "CSV uploading" | translate }}
      </div>
      <div class="button__footer">
        <div class="button__subline"></div>
        <div class="button__icon" [ngStyle]="{ 'flex-basis.px': 10 }">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
  </div>
</div>
