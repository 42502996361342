declare var require: any;
/**  The file contents for the current environment will overwrite these during build.
   * The build system defaults to the dev environment which uses `environment.ts`, but if you do
   * `ng build --env=prod` then `environment.prod.ts` will be used instead.
   * The list of which env maps to which file can be found in `.angular-cli.json`.
*/

export const environment = {
  production: false,
  apiUrl: 'https://e-gehaltszettel-t.post-business-solutions.at/be.e-gehaltszettel_t/supportView/v1',
  magicLink: 'https://e-gehaltszettel-t.post-business-solutions.at/be.e-gehaltszettel_t/supportView/v1/person/magicLink/createByGroup',
  version: require('../../package.json').version,
   // customiziable footer links, but in future should be implemented per tenant
  // so we should put these footerLinks in tenant config
  emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  LOWERCASE_REGEXP: `^(?=.*[a-z]).{1,64}$`,
  UPPERCASE_REGEXP: `^(?=.*[A-Z]).{1,64}$`,
  NUMERIC_REGEXP: `^(?=.*[0-9]).{1,64}$`,
  SYMBOL_REGEXP: `^(?=.*[\\~\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\|\\\\\(\\)\\{\\}\\[\\]\\:\\;\\"\\'\\<\\>\\,\\.\\?\\/]).{1,64}$`,

  // a-zA-Z0-9.!#$%&\'*+\\\\/=?
};
