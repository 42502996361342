import { Component, ChangeDetectionStrategy, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { merge } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { AccordionGroupComponent } from './accordion-group.component';

@Component({
  selector: 'tp-accordion',
  template: `
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements AfterContentInit {
  @ContentChildren(AccordionGroupComponent)
  groups: QueryList<AccordionGroupComponent>;

  ngAfterContentInit() {
    const headerClicks$ = this.groups.map(group =>
      group.header.click$.pipe(mapTo(group)),
    );

    merge(...headerClicks$).subscribe(group => group.toggle());
  }
}

