<div class="search-dialog">
  <div mat-dialog-content class="search-activity-form">
    <form #searchActivity="ngForm" (ngSubmit)="onFormSubmit()">
      <div class="form-fields">
        <div class="datePicker-wrap">
          <p class="label">{{ 'Date' | translate }}:</p>
          <span class="datePicker-label">{{ 'From' | translate }}:</span>
          <mat-form-field color="primary">
            <input matInput [max]="maxStartDate" autocomplete="off" (click)="startDatePicker.open();"
              [matDatepicker]="startDatePicker"  placeholder="{{ 'Choose a date' | translate }}" name="startDate"
              [(ngModel)]="model.startDate">
            <mat-datepicker-toggle  matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker  #startDatePicker></mat-datepicker>
            <mat-error>
              {{ 'Date format is invalid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="datePicker-wrap">
          <span class="datePicker-label">{{ 'To' | translate }}:</span>
          <mat-form-field color="primary">
            <input matInput [max]="currentDate" autocomplete="off" (click)="endDatePicker.open()"
              [min]="model.startDate" [matDatepicker]="endDatePicker" placeholder="{{ 'Choose a date' | translate }}"
              name="endDate" [(ngModel)]="model.endDate" (dateChange)="setStartDateMax()">
            <mat-datepicker-toggle  matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker  #endDatePicker></mat-datepicker>
            <mat-error>
              {{ 'Date format is invalid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{ 'Display Name' | translate }}:</p>
          <mat-form-field color="primary">
            <input matInput placeholder="{{ 'Enter the display name...' | translate }}" name="userName"
              [(ngModel)]="model.userName">
          </mat-form-field>
        </div>
      </div>
      <div class="form-fields">
        <p class="label">{{ 'Activity' | translate }}:</p>
        <div class="checkbox-wrap">
          <mat-checkbox color="primary" *ngFor="let checkbox of filteredCategoryCheckboxes" name="{{checkbox.value}}"
            [(ngModel)]="model.categories[checkbox.value]">{{checkbox.label}}</mat-checkbox>
        </div>
      </div>
      <div class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{ 'Text' | translate }}:</p>
          <mat-form-field color="primary">
            <input matInput placeholder="{{ 'Search text' | translate }}" name="text" [(ngModel)]="model.text">
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="form-actions">
          <button type="button" mat-button (click)="onReset()">{{ 'reset' | translate }}</button>
          <button type="submit" mat-button mat-raised-button color="primary"
            [disabled]="!searchActivity.valid">{{ 'search' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</div>