import { Component } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';

import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../../group/tenantService/tenant.service';

@Component({
  selector: 'sv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public version: string = environment.version;
  public footerLinks;
  constructor(
    public auth: AuthService, 
    private _translateService: TranslateService, 
    public tenantService: TenantService
  ) { 
    this.initFooterBrandingSubscription();
    this.initLanguageChangeSubscription();
  }
  
  private initFooterBrandingSubscription() {
    this.auth.footerBranding.subscribe(data => {
      this.updateFooterLinks(data);
    });
  }
  
  private initLanguageChangeSubscription() {
    this._translateService.onLangChange.subscribe(() => {
      this.initFooterBrandingSubscription();
    });
  }
  
  private updateFooterLinks(data: any) {
    this.footerLinks = {
      ...data,
      links: data?.links.map((link: { text: string, href: string }) => {
        return {
          ...link,
          text: this._translateService.instant(link.text)
        };
      })
    };
  }
}
