import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadUtilityService {

  constructor() { }

  public pendingDocuments

  public encodeFilesToBase64(files: File[]): Observable<{ name: string, mimeType: string, payload: string, size: number }[]> {
    const encodedFiles: Observable<{ name: string, mimeType: string, payload: string, size: number }>[] = []
    for (const file of files) {
      const encodedFile = this.encodeFileToBase64(file).pipe(
        map(base64 => {
          return {
            name: file.name,
            mimeType: file.type,
            payload: base64.split(",")[1],
            size: file.size
          };
        })
      );
      encodedFiles.push(encodedFile);
    }

    return forkJoin(encodedFiles);
  }

  public encodeFileToBase64(file: File): Observable<string> {
    return new Observable((observer) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        observer.next(fileReader.result as string);
        observer.complete();
      };

      fileReader.onerror = (error) => {
        observer.error(error);
      };

      fileReader.readAsDataURL(file);
    });
  }

  public hasZipFiles(files) {
    return files.some(doc => doc.type.startsWith('application/') && doc.type.endsWith('-compressed'))
  } 

  public IsZipFile(file) {
    return file.type === 'application/zip' || file.type === 'application/zip' || file.type ==='application/x-7z-compressed' || file.type === 'application/x-zip-compressed' || file.type ==='application/octet-stream' || file.mimeType === 'application/zip' || file.mimeType === 'application/zip' || file.mimeType ==='application/x-7z-compressed' || file.mimeType === 'application/x-zip-compressed' || file.mimeType ==='application/octet-stream';
  }

  public convertSetToArray(set) {
    return Array.from(set)
  }

  public convertSetToFiles(files): File[] {
    return Array.from(files)
  }

  public checkForUnsportedMediaFiles(data, allowedFileExtensionList: string[]) {
   return {...data,unsupportedMediaType:this.isUnsupportedMediaType(allowedFileExtensionList,data.name)}
  }

  public isUnsupportedMediaType(allowedFileExtensionList:string[],fileName:string) {
    return !allowedFileExtensionList.find(extension => {
      return this.checkFileExtension(fileName.toLowerCase(), extension);
    })
  }

  public checkFileExtension(fileName, extension) {
    if (!extension.startsWith('.')) {
      extension = '.' + extension;
    }

    return fileName.toLowerCase().endsWith(extension.toLowerCase());
  }
  public isPdf(fileName, extension) {
    if (!extension.startsWith('.')) {
      extension = '.' + extension;
    }

    return fileName.toLowerCase().endsWith(extension.toLowerCase());

  }
  public validateUploadFiles(files,numOfDocuments: number,allowedFileExtensionList: string[]) {
    const validFiles = new Set();
    const invalidFiles: {
      file: File,
      error: {
        extendion: boolean,
        pending: boolean,
      }
    }[] = [];
    let newNumOfDocuments = numOfDocuments;
    files.forEach((file) => {
      const isExtensionValid = allowedFileExtensionList.find(extension => {
        const fileName = file.name.toLowerCase();
        return this.checkFileExtension(fileName, extension);
      });
      
      newNumOfDocuments += isExtensionValid ? 1 : 0;
      
      if (isExtensionValid) {
        validFiles.add(file);
      } else {
        const isPendingDocument = isExtensionValid ? true : false;
        const invalidFile = {
          file,
          error: {
            extendion: !isExtensionValid,
            pending: isPendingDocument,
          }
        };
        invalidFiles.push(invalidFile);
        
        // this.pendingDocuments = isPendingDocument;
      }
    });
    
    
    return {
      validFiles,
      invalidFiles
    };
  }
}
