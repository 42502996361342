import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError, } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Pagination } from './interfaces/pagination.interface';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IGroup, IGroupResponseData } from './interfaces/group.interface';
import { Tenant } from './enum/tenant.enum';

export const RESET_FILTER = {accountNumber:'',displayName:'',groupDescription:''}

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
    private _translateService: TranslateService) {
      
    }
  public currentCount = new BehaviorSubject<number>(0)
  public filterValues = {accountNumber:'',displayName:'',groupDescription:''}
  public groupId = new Subject<string>();
  public editGroup = new Subject();
  public triggerDataReaload = new Subject<boolean>();
  public statusMessage(panelClass: string, message: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }
  public temporarilySavedTenantLogos;
  public temporarilySavedCompanyPrefixes;
  public totalGroupElements:number;
  public getGroups(params: Pagination,searchQuery: {accountNumber:string,displayName:string,groupDescription:string}): Observable<IGroupResponseData> {
    const httpParams = new HttpParams()
    .set('page', params.page)
    .set('size',params.pageSize)
    .set('sort', `${params.sort.field},${params.sort.ascOrDesc}`);

    return this.httpClient
    .post<IGroupResponseData>(`${environment.apiUrl}/group`,searchQuery ,{params: httpParams}).pipe(shareReplay()).pipe(
      map(((data)=> {
        this.totalGroupElements = data.totalElements
        return {...data, content: this.mapGroup(data.content)}
        }
        )),
      catchError((err)=>{
        this.statusMessage('snack-bar-fail',this._translateService.instant('Unable to fetch groups'));
        return throwError(err)
      }),
    );
  }
  public getGroupsForBroadcasting() {
    return this.httpClient
    .get<string[]>(`${environment.apiUrl}/group/getGroupsForBroadcasting`).pipe(shareReplay()).pipe(
      catchError((err)=>{
        this.statusMessage('snack-bar-fail',this._translateService.instant('Unable to fetch groups'));
        return throwError(err)
      }),
    );
  }
  public mapGroup(groups: IGroup[]) {
    switch (JSON.parse(localStorage.getItem('tenant'))) {
      case Tenant.datapart:
        return groups.map(group=>{
          return {
          ...group,
          numberOfMembers: group.membersIds.length,
          numberOfSupporters: group.groupSupporterIds.length,
          displayName: group.name,
          accountNumber: group.externalId.substring(0,3)
          }
      });
    
        break;

        case Tenant.payslip:
          return groups.map((group: any)=>{
            return {
            ...group,
            numberOfMembers: group.membersIds.length,
            numberOfSupporters: group.groupSupporterIds.length,
            displayName: group.name,
            numberOfDictionaries: group.numberOfDictionary,
            eGehaltszettelEnabled: group.additionalData.eGehaltszettelEnabled ? this._translateService.instant('Yes') : this._translateService.instant('No'),
            }
          });
        break;
    
      default:
        break;
    }
  }
  public getGroupAdditionalData(id: string) {
    return this.httpClient.get<IGroup>(`${environment.apiUrl}/group/getXTenant/${id}`)
    .pipe(catchError((err)=>{
      this.statusMessage('snack-bar-fail',this._translateService.instant('Unable to fetch a group data'));
      return throwError(err)
    }));
  }
  public deleteGroup(id: string) {
    return this.httpClient.delete<IGroup>(`${environment.apiUrl}/group/delete/${id}`)
    .pipe(catchError((err)=>{
      this.statusMessage('snack-bar-fail',this._translateService.instant('Unable to delete group'));
      return throwError(err)
    }));
  }

  public getGroupPrefixes(groupId: string) {
    return this.httpClient.get<IGroup>(`${environment.apiUrl}/group/prefixes/${groupId}`)
  }
  
  public createOrEditGroup(
    group: any,
    groupId: string,
    externalId: string,
    useXTenant: boolean = true
  ): Observable<IGroup> {
    const isEdit = groupId && externalId;
    const endpoint = useXTenant
      ? `${environment.apiUrl}/group/editXTenant`
      : `${environment.apiUrl}/group/edit`;
  
    const payload = isEdit 
      ? { ...group, id: groupId, externalId: externalId }
      : group;
  
    return this.httpClient.post<IGroup>(endpoint, payload).pipe(
      catchError((err) => {
        let errorMessage: string;

        if (isEdit) {
          errorMessage = this._translateService.instant('Unable to edit groups');
        } else if (err.errorMessage === 'Group already exists') {
          errorMessage = this._translateService.instant('Group already exists');
        } else {
          errorMessage = this._translateService.instant('Unable to create groups');
        }
  
        this.statusMessage('snack-bar-fail', errorMessage);
        return throwError(err);
      })
    );
  }
  
  

}
