<div class="reset-password-wrapper">
    <h2 class="dialog-title">{{ 'Forgot Password' | translate }}</h2>
    <div mat-dialog-content>
      <h4 class="dialog-query">
        {{ forgotPasswordText | translate }}
      </h4>
      <mat-form-field class="full-width" color="accent">
        <mat-label>{{'E-mail address / user name' | translate}}</mat-label>
        <input matInput  required [formControl]="email">
        <mat-error *ngIf="!email.hasError('required') && email.hasError('pattern') && email.touched">
          {{ 'Please enter a valid e-mail address.' | translate }}
        </mat-error>
        <mat-error *ngIf="email.hasError('required') && email.touched">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-actions" mat-dialog-actions>
      <button class="close" mat-button [mat-dialog-close]>{{ 'Cancel' | translate }}</button>
      <button
        color="primary"
        mat-button mat-raised-button
        type="button"
        [disabled]="email.hasError('pattern') || email.hasError('required')"
        classList="action_button"
        id="reset_password_dialog-reset"
        (click)="onFormSubmit(); $event.stopPropagation()"
      >
        {{'Send' | translate }}
      </button>
    </div>
  </div>
  