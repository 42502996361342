import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'tp-accordion-content',
  template: `
    <div *ngIf="isOpen">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./accordion-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionContentComponent {
  _isOpen = false;

  @Input()
  set isOpen(value: boolean) {
    if (this._isOpen !== value) {
      this._isOpen = value;
      this.cdr.markForCheck();
    }
  }

  get isOpen() {
    this.cdr.markForCheck();
    return this._isOpen;
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
