import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { UsersService } from './services/users/users.service';
import { SidebarService } from '../dashboard/services/sidebar-service/sidebar.service';
import { DesignModule } from '../design/design.module';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserSearchComponent } from './components/user-search-dialog/user-search-dialog.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { UserAccountDetailsComponent } from './components/user-account-details/user-account-details.component';
import { DeletedListComponent } from './components/deleted-list/deleted-list.component';
import { UserResetPasswordComponent } from './components/user-reset-password/user-reset-password.component';
import { UserSearchDisplayComponent } from './components/user-search-display/user-search-display.component';
import { DeletedUsersSearchDialogComponent } from './components/deleted-users-search-dialog/deleted-users-search-dialog.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';
import { SearchUserActivityComponent } from './components/search-user-activity/search-user-activity.component';
import { SharedModule } from '../shared/shared.module';
import { StubsModule } from '../stubs/stubs.module';
import { RolePipe } from '../stubs/pipes/translate/role/role.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        RouterModule,
        MatButtonModule,
        DesignModule,
        HttpClientModule,
        MatPaginatorModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatMenuModule,
        MatOptionModule,
        MatChipsModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        SharedModule
    ],
    exports: [
        TopMenuComponent,
        UserSearchDisplayComponent,
    ],
    declarations: [
        UserDetailComponent,
        UserListComponent,
        UserSearchComponent,
        UserInformationComponent,
        TopMenuComponent,
        UserAccountDetailsComponent,
        UserResetPasswordComponent,
        DeleteDialogComponent,
        DeletedListComponent,
        UserSearchDisplayComponent,
        DeletedUsersSearchDialogComponent,
        UserActivityLogComponent,
        SearchUserActivityComponent,
        RolePipe
    ],
    providers: [
        UsersService,
        SidebarService,
    ],
    bootstrap: [UserAccountDetailsComponent]
})
export class UsersModule { }
