<section class="assign-users-to-companies">
  <div class="assign-users-to-companies__header">
    <h1>{{config.title}}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>

  <div class="assign-users-to-companies__table"  [ngStyle]="{'maxHeight': steps.stepTwo ? '500px' : '0px','overflowY':steps.stepTwo ? 'scroll' : 'none'}">
    <div class="assign-users-to-companies__search__wrapper">

      <div class="assign-users-to-companies__search__container" *ngIf="steps.stepOne">
        <div class="assign-users-to-companies__search__container__input">
          <input matInput autocomplete="off" [(ngModel)]="filterValue"
            placeholder="{{config.inputPlaceHolder| translate}}">
        </div>
        <div class="assign-users-to-companies__search__container__icon">
          <mat-icon class="icon-style">search</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="displayErrorMessage && steps.stepTwo">
      <p class="empty-billersInvoiceRecipientID">{{'Please enter Company-Specific User ID for following companies:' |
        translate}}</p>
      <ng-container *ngFor="let company of isbillersInvoiceRecipientEmpty; let i = index">
        <span class="empty-billersInvoiceRecipientID">{{company.companyId}} </span>
        <span *ngIf="isbillersInvoiceRecipientEmpty.length - 1 !== i"> | </span>
      </ng-container>
    </div>
    <table>
      <thead>
        <tr>
          <th *ngIf="steps.stepOne">
            <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleAll()">
          </th>
          <th *ngFor="let column of columns">{{ (column | translate) }}</th>
          <th *ngIf="steps.stepTwo">{{ 'Company-Specific User ID' | translate }}</th>
          <th *ngIf="steps.stepTwo">{{'Company Prefix' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="steps.stepOne"></ng-container>
        <tr
          *ngFor="let row of  (steps.stepOne ? data : selectedData)  | filter: filterValue: ['companyId', 'companyName', 'companyStatus']: handleFiltered | paginate: itemsPerPage : (pageIndex + 1); let i = index">
          <td *ngIf="steps.stepOne">
            <input type="checkbox" [(ngModel)]="row.assigned" (change)="toggleSelection(row,i)">
          </td>
          <td>{{ row.companyId }}</td>
          <td>{{ row.companyName }}</td>
          <td>{{ row.companyStatus }}</td>
          <td style="padding: 0px 10px;" *ngIf="steps.stepTwo">
            <input style="width: 100%; outline: none;" autocomplete="off" *ngIf="row.billersInvoiceRecipientID === null"
              [(ngModel)]="row.userInput" (ngModelChange)="updateSaveButtonDisplay()"
              placeholder="{{config.billersInvoiceRecipientID| translate}}">
          </td>
          <td *ngIf="steps.stepTwo">
            <mat-form-field color="accent">
              <mat-label>{{'Company Prefix' | translate}}</mat-label>
              <mat-select [(ngModel)]="row.prefix" placeholder="{{'Company Prefix' | translate}}"
                (selectionChange)="selectCompanyPrefix($event, row)">
                <mat-option *ngFor="let prefix of row.prefixes" [value]="prefix">{{ prefix.subCompanyName + ' -' }} {{
                  prefix.userPrefix}} {{ prefix.subCompanyPrefix ? ' - ' + prefix.subCompanyPrefix : '' }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <mat-paginator *ngIf="steps.stepOne" [length]="totalItems" [pageSize]="itemsPerPage" [pageIndex]="pageIndex"
    (page)="handlePageEvent($event)"></mat-paginator>
  <mat-paginator *ngIf="steps.stepTwo" [length]="selectedData.length" [pageSize]="itemsPerPage"
    [pageIndex]="pageIndex" (page)="handlePageEvent($event)"></mat-paginator>


  <div class="assign-users-to-companies__actions">
    <button type="button" #btn (click)="closeDialog()" color="primary" [ngStyle]="{
          'color': '#FFF',
          'background-color': '#9a9a9a',
          'pointer-events': 'visible'
        }">
      <div class="title back">
        {{'Cancel' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
        </div>
      </div>
    </button>

    <button type="button" #btn *ngIf="steps.stepOne && displayNextButton" (click)="setStep()" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': selectedData.length > 0 ?  (authService.backgroundColors | async)?.primaryButtonColor : 'rgba(0,0,0,.12)' ,
          'pointer-events': selectedData.length > 0 ?  'visible' : 'none'

        }">
      <div class="title">
        {{ 'Next' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
    <button type="button" #btn (click)="submit()" *ngIf="steps.stepTwo || !displayNextButton"
      [disabled]="!displaySaveButton" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': !displaySaveButton ? 'rgba(0,0,0,.12)' : (authService.backgroundColors | async)?.primaryButtonColor ,
          'pointer-events': selectedData.length > 0 ?  'visible' : 'none',
        }">
      <div class="title">
        {{ 'Save' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
  </div>
</section>