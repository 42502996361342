import { Component, ChangeDetectionStrategy, ContentChild } from '@angular/core';
import { AccordionHeaderComponent } from './accordion-header.component';
import { AccordionContentComponent } from './accordion-content.component';

@Component({
  selector: 'tp-accordion-group',
  template: `
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionGroupComponent {
  @ContentChild(AccordionHeaderComponent, {static: false})
  header: AccordionHeaderComponent;

  @ContentChild(AccordionContentComponent, {static: false})
  content: AccordionContentComponent;

  toggle() {
    this.header.isOpen = !this.header.isOpen;
    this.content.isOpen = !this.content.isOpen;
  }
}
