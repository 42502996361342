import { Moment } from 'moment';
import * as moment from 'moment';

/**
 * Format a date
 * @param date   Date which to format
 * @param format What date format to use
 * @returns      Formated date
 */
export function formatToDate(date: Moment, format: string = 'MMM DD YYYY') {
  return moment(date).format(format);
}

/**
 * Format a dateTime
 * @param date   Date which to format
 * @param format What date time format to use
 * @returns      Formated date time
 */
export function formatToDateTime(date: Moment, format: string = 'MMMM Do YYYY, h:mm:ss a') {
  const copy = moment(date);
  copy.locale(moment.locale());
  return copy.format(format);
}

/**
 * Get current date/time
 * @returns Current date/time
 */
export function getCurrentDate() {
  return moment();
}
