import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
import * as _pickBy from 'lodash/pickBy';

import { getCurrentDate } from '../../../shared/utils/date.util';
import { ActivityLogCategory } from '../../../activity-log/enums/activity-log-category.enum';
import { AdminActivityLogSearchModel } from '../../classes/admin-activity-log-search.model';
import { Moment } from 'moment';
import { ISearchCategoryCheckbox } from '../../../shared/interfaces/search-category-checkbox.interface';

@Component({
  selector: 'sv-search-admin-log',
  templateUrl: './search-admin-log.component.html',
  styleUrls: ['./search-admin-log.component.scss']
})
export class SearchAdminLogComponent implements OnInit {
  @ViewChild('searchAdminActivity') public form: NgForm;
  public model: AdminActivityLogSearchModel = new AdminActivityLogSearchModel();
  public categoryCheckboxes: ISearchCategoryCheckbox[] = [
    { value: ActivityLogCategory.Login, label: this._translate.instant('Login') },
    { value: ActivityLogCategory.Logout, label: this._translate.instant('Logout') },
  ];
  public filteredCategoryCheckboxes: ISearchCategoryCheckbox[];
  public currentDate = getCurrentDate();
  public maxStartDate: Moment;

  constructor(
    public dialogRef: MatDialogRef<SearchAdminLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { searchData: AdminActivityLogSearchModel, categories: ActivityLogCategory[] },
    private _translate: TranslateService,
  ) { }


  ngOnInit() {
    this.model = this.data.searchData || new AdminActivityLogSearchModel();
    this.maxStartDate = this.model.endDate || this.currentDate;
    this.model.categories = this.model.categories || {};
    this.filteredCategoryCheckboxes = this.categoryCheckboxes
      .filter((c: ISearchCategoryCheckbox) => this.data.categories.indexOf(c.value) !== -1);
  }

  // For maxStartDate first we use selected endDate that user picked from datepicker,
  // if there is no endDate selected then take currentDate as maxstartDate.
  public setStartDateMax() {
    this.maxStartDate = this.model.endDate || this.currentDate;
  }

  public onReset() {
    this.form.reset();
    this.model.categories = {};
    this.maxStartDate = this.currentDate;
  }

  public onFormSubmit() {
    // Filter out the properties in the models categories (checkboxes) that have false (unchecked) values
    const data = {
      ...this.model,
      categories: _pickBy(this.model.categories, (value) => value),
    };
    this.dialogRef.close(data);
  }

}
