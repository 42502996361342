import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GroupService } from '../../../../app/group/group.service';
import { UploadCSVDialogComponent } from './upload-csv-files.component';
import { UploadViewService } from './upload-view.service';
import { Observable, Subject, throwError } from 'rxjs';
import { DocumentUploadModel } from '../../classes/document-upload-model.class';
import { UploadUtilityService } from './upload-utility.service';
import { IUploadErrors } from '../../../group/interfaces/upload-errors.interface';
import { IProcessedDocumentsResult } from '../../../group/interfaces/process-documents-result.interface';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public groupName: string;
  public status = [];
  public foreignIds: {fileName: string,foreignId: string,reason: string,sourceFile: string}[] = [];
  public foreignId: string;
  public documents: DocumentUploadModel[] = [];
  public templates = []
  public payloadForProcessDocuments; 
  public conversionCode = '';
  public zipContainsErrors = [];
  private _translateService: TranslateService;
  private snackBar: MatSnackBar
  constructor(
    private _groupsService: GroupService,
    private _http:HttpClient,
    private _uploadViewService: UploadViewService,
      private matDialogRef: MatDialogRef<UploadCSVDialogComponent>,
    private _zone: NgZone,
    private _uploadUtilityService: UploadUtilityService,
    ) { }

  public statusMessage(panelClass: string, message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }

  public sendCSVDocuments({csvFile, groupId, accountNumbers}) {
    return this._http.post(`${environment.apiUrl}/person/uploadCsv`, {
      csvFile: csvFile,
      groupId: groupId,
      accountNumberDTO: accountNumbers
    },).pipe(catchError(err=>{
      this.statusMessage('snack-bar-fail','Failed to upload Csv');
      return throwError(err);
    }))
  }

  public delete(fileName:string,foreignId: string) {
    this._http.post(`${environment.apiUrl}/deliveryHandler/removeDocument`,{fileName,foreignId}).subscribe()
  }

  uploadAsyncSingleTempFile(files) {
    // create a new multipart-form for every file

    // create a new progress-subject for every file
    const progress = new Subject<number>();
    const request = new Subject<any>();
    const uploadError = new Subject<IUploadErrors>();

    this._zone.runOutsideAngular(() => {
      // send the http-request and subscribe for progress-updates
      let currentProgress = 0;
      let nextTimeout;
      const ongoingReq = this.uploadTempDeliveryFilesReq(files).subscribe(
        (event) => {
          if (typeof event.body !== 'undefined' &&  event.body.success) {
            this.foreignId = event.body.foreignId
            this.foreignIds = event.body.documentForeignKey;
            // this.upload()
            // this.processDocumentsReq()
          }

          if (event.type === HttpEventType.UploadProgress) {

            // calculate the progress percentage
            const percentDone = Math.round(100 * event.loaded / event.total);

            // pass the percentage into the progress-stream
            currentProgress = percentDone;
            if (!nextTimeout) {
              nextTimeout = setTimeout(() => {
                nextTimeout = null;
                this._zone.run(() => {
                  progress.next(currentProgress);
                });
              }, 400);
            }
          }
          
          else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            this._zone.run(() => {
              setTimeout(() => {
                progress.complete();
                request.next(event.body);
                request.complete();
              }, 400);
            });
          }



        },
        (error) => {
          this._zone.run(() => {
            uploadError.next({
              hasVirus: error.status === 406,
              unsupportedMediaType: error.status === 415,
            });
            uploadError.complete();
            progress.complete();
            request.complete();
          });
        }
      );

      files.forEach(fileForStatus => {
        this.status.push({
          file: fileForStatus,
          progress: progress.asObservable(),
          cancel: () => ongoingReq.unsubscribe(),
          request,
          uploadError,
        });
      })
    });
  }
  uploadTempDeliveryFilesReq(formData): Observable<any> {
    let payload = []
    payload = [...formData]
    .map(data => ({ mimeType: data.mimeType, name: data.name, payload: data.payload }))
    if (this.documents.length > 0) {
      
      payload = this.documents
      .filter(data => data.valid)
      .map(data => {
        if (data.valid) {
          return {mimeType: data.type, name: data.fileName, payload: data.payload}
        }
      })
      const mappedFormData = [...formData]
      .map(data => ({ mimeType: data.mimeType, name: data.name, payload: data.payload }))
      payload.push(...mappedFormData)
    }
    // this.containsZip = payload.some(doc => doc.mimeType === 'application/zip' || doc.mimeType ==='application/x-7z-compressed' || doc.mimeType === 'application/x-zip-compressed' || doc.mimeType ==='application/octet-stream')
      this._uploadViewService.containsZip = payload.some(doc => doc.mimeType === 'application/zip' || doc.mimeType ==='application/x-7z-compressed' || doc.mimeType === 'application/x-zip-compressed' || doc.mimeType ==='application/octet-stream')
      // debugger
      const req = new HttpRequest('POST', `${environment.apiUrl}/deliveryHandler/upload`, { documents: payload }, {

      reportProgress: true
    });

    return this._http.request(req);
  }
  }  

