import { Routes } from "@angular/router";
import { GroupsComponent } from "./groups/groups.component";
import { PersonsComponent } from "./persons/persons.component";
import { xUserComponent } from "./xUser/xUser.component";
import { canActivateTenant } from "../auth/guards/tenant.guard";

export const groupRoutes: Routes = [ 
    {
        path: 'groups',
        component: GroupsComponent,
        canActivate:[canActivateTenant]
    },
    {
        path: 'person/:groupId',
        component: PersonsComponent
    },
    {
        path: 'xUser/:displayName',
        component: xUserComponent
    }
]