<div *ngIf="isLoadingDataPage" class="loading-overlay">
  <mat-progress-spinner class="loading-indicator" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="top-menu-wrap">
  <sv-top-menu totalLabel="{{'Total entries' | translate }}" [totalItems]="formatedTotalItems"></sv-top-menu>
</div>
<div class="activity-log-controls">
<!--  [searchType]="searchStoreType" -->
  <sv-user-search-display [totalItems]="totalItems" (filterValuesChange)="searchData = $event; getActivityLog(true)"
    [filterValues]="searchData"></sv-user-search-display>
  <div id="searchButton" class="search" (click)="openDialog()">
    <mat-icon color="accent"  class="search-icon" aria-label="">search</mat-icon>
    <span class="search-label"  translate>Search</span>
    <mat-icon color="accent"  class="mat-arrow-drop-down" aria-label="">arrow_drop_down</mat-icon>
  </div>
</div>
<div class="table-wrap">
  <sv-table [data]="userList" [columns]="userTableColumns" [(page)]="page" [(sort)]="sort"
    (sortChange)="sort=$event; getActivityLog()" [selectable]="false" [(selected)]="selectedRows" selectIdField="id"
    cursorOnHover="false"></sv-table>
  <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageIndex]="getPageIndex()" (page)="changePage($event)">
  </mat-paginator>
</div>
