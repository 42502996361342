<div class="header-container">
  <div class="header-inner">
    <div class="logo" [ngClass]="{'datapart': tenant === 'datapart'}">
      <a href="https://www.post.at/g/c/businesssolutions" target="_blank" class="logo-link" [ngClass]="{'datapart': tenant === 'datapart'}">
        <!-- <img src="http://ebox-datapart.edeja.com/datapart/assets/images/logo.svg" alt="{{ 'Support View logo' | translate }}"> -->
        <!-- <img src="../../../../assets/images/Posticon-E-Gehaltszettel.png" alt=""> -->
        <img class="logo-img" src="assets/images/Posticon-E-Gehaltszettel.png" alt="logo">
        <span class="logo-text" >support
          <strong>view</strong>
        </span>
      </a>
      <!-- TODO: Add Logo -->
    </div>
    <div class="user-menu" *ngIf="isUserMenuVisible" svSelectChildren>
      <div class="lagnuage-menu" >
        <mat-select color="priamry"  *ngIf="languages" [placeholder]="languages.selected" (selectionChange)="changeLanguage($event)">
          <mat-option   *ngFor="let option of languages.options" [value]="option.value">
            <span >{{ option.name }}</span> 
          </mat-option>
        </mat-select>
      </div>
      <div class="user-menu-control">
        <mat-menu #appMenu="matMenu"  xPosition="before" [overlapTrigger]="false">
          <div class="mat-menu">
            <a  *ngIf="!isMasterSupporter" routerLink="/dashboard/admin-log">
              <span class="mat-menu-title" >{{ 'Activity Log' | translate }}</span><br>
            </a>
            <div class="user-info">
              <span class="user-display-name" >{{ userDisplayName }}</span><br>
              <span>{{ email }}</span>
            </div>
            <a routerLink="/dashboard/password" *ngIf="tenant !== 'datapart'">
              {{(authService?.features | async)?.resetPassword}}
              <button class="password-bttn" *ngIf="(auth.features | async)?.resetPassword" (click)="openPasswordDataPage()">
                <span class="password-text">{{'Password' | translate}}</span>
                <span class="right-icon"><mat-icon class="error-icon" svgIcon="custom:icon-right"></mat-icon></span>
              </button>
            </a>
            <div class="login-info">
              <span *ngIf="tenant === 'datapart'">{{ 'Last Login' | translate}}, {{ lastLogin }}</span>
              <span>{{'Automatic logout in' | translate}} {{ automaticLogoutTime }} {{ 'Minutes' | translate }}</span>
            </div>
          </div>
        </mat-menu>
        <span class="menu-trigger" mat-icon-button>
          <span class="user-details" [matMenuTriggerFor]="appMenu">
            <mat-icon>person</mat-icon>
            {{ 'Welcome' | translate }}, 
            <span class="user-display-name" >{{ userDisplayName }}</span>
            <!-- <span class="mat-select-arrow-wrapper"> -->
              <mat-icon class="select-arrow"  [svgIcon]="'custom:sort'"></mat-icon>

            <!-- </span> -->
          </span>
          <mat-icon class="logout-icon" (click)="openLogoutDialog()" [svgIcon]="'custom:logout'"></mat-icon>
        </span>
      </div>
    </div>
  </div>
  <span class="header-border-like-box" ></span>
</div>