import { Component, OnInit } from '@angular/core';
import { MultipleNotificationsService } from './multiple-notifications.service';

@Component({
  selector: 'sv-multiple-notifications',
  templateUrl: './multiple-notifications.component.html',
  styleUrls: ['./multiple-notifications.component.scss']
})
export class MultipleNotificationsComponent implements OnInit {
  messages: string[] = [];

  constructor(public notificationsService: MultipleNotificationsService) {}

    ngOnInit() {
    }

}
