<div *ngIf="queryChips.length" class="search-display-wrap">
  <h3 class="result-number">{{totalItems}} </h3>
  <h3 *ngIf="!totalLabel"> {{'Search results' | translate}} : </h3>
  <h3 *ngIf="totalLabel"> {{totalLabel | translate}} : </h3>
  <mat-chip-listbox>
    <mat-chip *ngFor="let chip of queryChips" [removable]="removable" (removed)="remove(chip.field)">
      <div class="truncate-chip" [matTooltip]="chip.displayValue | role">{{queryFieldsDisplayMap[chip.field]}} : {{chip.displayValue | role}}</div>
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
</div>