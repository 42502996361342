import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpRequest, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';

const ignoreEndpoints = [
  `${environment.apiUrl}/login/user`,
  `${environment.apiUrl}/login/refreshToken`,
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // First we check url path, if it is '/login/user' or '/login/refreshToken' then we are not calling getAccessToken mothod.
    if (ignoreEndpoints.indexOf(request.url) === -1) {
      const auth: AuthService = this._injector.get(AuthService);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
