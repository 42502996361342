<div class="messages">
  <p
    *ngFor="let message of notificationsService.messages"
    class="message"
    [ngClass]="{
      info: message.info === true,
      success: message.isSuccess === true,
      fail: message.isSuccess === false,
      show: true
    }"
  >
    {{ message.message | translate }}
  </p>
</div>