import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../auth/services/auth.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Component({
  selector: "sv-download-user-credentials",
  templateUrl: "./download-user-credentials.component.html",
  styleUrls: ["./download-user-credentials.component.scss"],
})
export class DownloadUserCredentialsComponent implements OnInit {
  public pdfData: { userAccountPdfFile: string; fileName: string };
  public data
  constructor(
    private httpClient: HttpClient,
    public authService: AuthService,
    private matDialogRef: MatDialogRef<DownloadUserCredentialsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data
    this.pdfData = data.data;
  }

  ngOnInit(): void {}
  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  public downloadCredentials() {
    const url = `${environment.apiUrl}/person/exportUserAccountPdf`;

    this.httpClient
      .post(url, this.pdfData, { responseType: "blob" })
      .subscribe((pdfBlob) => {
        const blob = new Blob([pdfBlob], { type: "application/pdf" });
        const dataUrl = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = this.pdfData.fileName;
        a.style.display = "none";

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(dataUrl);

        this.matDialogRef.close();
      });
  }
}
