import { Moment } from 'moment';
import { ApiResource, ApiResourceDateValue, ApiResourceValue } from '../../shared/classes/api-resource.class';

export class UserInformationApiResource extends ApiResource {
  @ApiResourceDateValue() public accountCreated: Moment;
  @ApiResourceValue() public accountState: string;
  @ApiResourceDateValue() public accountStateChanged: Moment;
  public userAddresses: UserAddress[];

  @ApiResourceValue() public eBriefEnabled: string;
  @ApiResourceDateValue() public eBriefServiceEnabledChanged: Moment;
  @ApiResourceValue() public authSecLevel: string;
  @ApiResourceValue() public authSecLevelChanged: string;

  @ApiResourceValue() public ssoAccountState: string;
  @ApiResourceValue() public ssoPersonId: string;
  @ApiResourceDateValue() public ssoAccountStateChanged: Moment;
  @ApiResourceDateValue() public lastUpdateFromSsoDate: Moment;
  @ApiResourceDateValue() public welcomeLetterSent: Moment;
}

export class UserAddress extends ApiResource {
  // Address
  @ApiResourceValue('addressStreet') public street: string;
  @ApiResourceValue('addressHouseDoorNo') public addressHouseDoorNo: string;
  @ApiResourceValue('addressPostalCode') public postalCode: string;
  @ApiResourceValue('addressCity') public city: string;

  @ApiResourceDateValue({
    from: 'stateChanged',
  }) public addressStateChangedDate: Moment;
  @ApiResourceDateValue({
    from: 'activationLetterSentOn'
  }) public activationletterSentDate: Moment;
  @ApiResourceValue('activationLetterConfirmed') public activationLetterConfirmed: string;
  @ApiResourceValue('clearingCode') public clearingCode: string;
}
