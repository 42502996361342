import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';

// @ts-ignore
import * as _assign from 'lodash/assign';

import { AuthService } from '../../services/auth.service';
import { UserLoginModel } from '../../classes/user-login-model.class';
import { UserDataResource } from '../../classes/user-data-resource.class';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../../../shared/components/forgot-password/forgot-password.component';
import { CustomResetPassword } from '../../../shared/custom-reset-password-component/custom-reset-password.component';
import { PasswordDataComponent } from '../../../shared/password-data/password-data.component';
import { TenantService } from '../../../group/tenantService/tenant.service';
// import { LoginPage } from 'src/app/shared/interfaces/login-page-branding.interface';

@Component({
  selector: 'sv-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,AfterViewInit {

  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public errorMessage: string = null;
  private formValueChangesSubscription: Subscription;
  public loginButtonAriaText: string;
  public styles;
  public tenant = '';
  public passwordResetToken = '';

  constructor(
    private _translate: TranslateService,
    public auth: AuthService,
    private _router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _tenantService: TenantService
    
  ) { 

    this.auth.getStyles();

    /* TO DO: since tenant emits couple of times the dialogs open two times. Investigate alternative **/
    this.auth.tenant.subscribe(tenant => {
      if (!tenant) return;
    
      this.tenant = tenant;
    
      this.route.queryParams.pipe(take(1)).subscribe(params => {
        this.passwordResetToken = params['passwordResetToken'];
    
        if (this.passwordResetToken) {
          const dialogConfig = new MatDialogConfig<any>();
          dialogConfig.disableClose = true;
          dialogConfig.panelClass = 'panel-custom-password';
          dialogConfig.data = { token: this.passwordResetToken };
    
          if (this.tenant === 'datapart') {
            dialogConfig.minWidth = '500px';
            dialogConfig.minHeight = '380px';  
            this.dialog.open(CustomResetPassword, dialogConfig);
          } else if (['post', 'payslip'].includes(this.tenant)) {
            dialogConfig.minWidth = '500px';
            dialogConfig.minHeight = '500px';  
            this.auth.passwordResetToken = this.passwordResetToken;
            this.dialog.open(PasswordDataComponent, dialogConfig);
          }
        }
      });
    });
  }
  ngAfterViewInit(): void {
  }

  ngOnInit() {
    const accessToken = this.auth.isLoggedIn;

    if (accessToken) {
      this._router.navigate(['/dashboard/groups'])
    }
    
    this.isSubmitting = false;
    this.form = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
    this.setLoginButtonAriaText();
    this.formValueChangesSubscription = this.form.valueChanges.subscribe(() => {
      this.errorMessage = null;
      this.setLoginButtonAriaText();
    });
  }
  // On set the button aria text for screen readers, if form is valid then button aria text is login,
  // if not then button aria text is login disabled
  setLoginButtonAriaText() {
    this.loginButtonAriaText = this.form.valid ? this._translate.instant('Login') : this._translate.instant('Login disabled');
  }

  public onSubmit() {
    this.isSubmitting = true;
    const model: UserLoginModel = _assign(new UserLoginModel(), {
      ...this.form.value,
      username : this.form.value.username.trim()
    });

    this.auth.login(model)
      .subscribe(() => {
        const userData: UserDataResource = this.auth.getUserData();

        if (userData && userData.userLanguage) {
          this._translate.use(userData.userLanguage);
        }

        this.auth.setGeneralSettings().subscribe(() => {
          if (this._tenantService.tenant ===  'post') {
            this._router.navigate(['/dashboard/users']);
            return;
          }
          this._router.navigate(['/dashboard/groups']);
        });
      }, (error: HttpErrorResponse) => {
        this.isSubmitting = false;
        // if there is error in response and error status is '401' then set error message as 'Credentials error',
        // if error status is differernt than 401 then set error message to 'Server error'.
        
        if ( error.status === 401 ) {
          return this.errorMessage = this._translate.instant('Credentials error');
        }
        if(error.status==404){
          return this.errorMessage = this._translate.instant('Credentials error');
        }
        if( error.status === 403 ){
          let duration = +error.error.reason;
          let minutesOrMinute = this.minuteOrMinutes(duration);
          return  this.errorMessage = this._translate.instant('Lock account after unsuccessful login attempts',{duration:duration, minutes:minutesOrMinute});
        }


      this.errorMessage = this._translate.instant('Server error');
    });
  }

  minuteOrMinutes(duration:number){

    let minuteOrMinutes;

    if(duration>1 && this._translate.currentLang=='en'){
      minuteOrMinutes = 'minutes';
    }
    if(duration==1 && this._translate.currentLang=='en'){
      minuteOrMinutes = 'minute';
    }
    if(duration>1 && this._translate.currentLang!=='en'){
      minuteOrMinutes = 'Minuten';
    }
    if(duration==1 && this._translate.currentLang!=='en'){
      minuteOrMinutes='Minute'
    }
    return minuteOrMinutes;

  }
  openForgotPasswordDialog() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "forgot-password-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.maxWidth = "700px";
    _dialogConfig.minWidth = "700px";
    _dialogConfig.height = "auto";
    const dialogText = this._getDialogText()
    _dialogConfig.data = {text: dialogText}
    const dialogRef = this.dialog.open(ForgotPasswordComponent, _dialogConfig);
  }
  private _getDialogText() {
    const messages = {
      datapart: 'To which e-mail address / user name should the password reset e-mail be sent?',
      payslip: 'Please enter your user name. Please note that you will only receive the information to reset your password if you have a verified e-mail address. If this is not the case, please contact your HR department.',
      post: 'You can reset your password to regain access to your account. What is your e-mail address or user name?'
    };
    
    return messages[this.tenant] || '';
    
  }
  OnDestroy() {
    this.formValueChangesSubscription.unsubscribe();
  }
}
