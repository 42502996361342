<div class="login-container">
  <div class="login-form-container">
    <div  class="login-headline" translate>Support View - Login</div>
    <div class="login-form-wrapper">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="{'has-error': errorMessage}">
        <div class="form-group">
          <input type="text" class="form-control username" formControlName="username"
            placeholder="{{ 'Username' | translate }}" aria-required="true" />
        </div>
        <div class="form-group">
          <input type="password" class="form-control password" formControlName="password"
            placeholder="{{ 'Password' | translate }}" aria-required="true" />
        </div>
        <div class="error-text" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
        <section class="col-xs-12" aria-hidden="false" [attr.aria-label]="loginButtonAriaText">
          <button aria-hidden="true" type="submit" class="btn login-button" 
            [disabled]="form.invalid || isSubmitting">
            {{ 'Login' | translate }}
            <mat-icon class="arrow_right">arrow_drop_down</mat-icon>
          </button>
        </section>
      </form>
      <button class="forgot-password-button" (click)="openForgotPasswordDialog()">{{'Forgot Password' | translate}}</button>
    </div>
  </div>
</div>
<sv-footer></sv-footer>

