import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';

import { UsersService } from '../../services/users/users.service';


@Component({
  selector: 'sv-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @Input() displayName: string;
  public entries: string;
  public userId: string;
  public backRoute = '/dashboard/users';
  private currentEntriesSub: Subscription;
  private queryParamsSub: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _userService: UsersService,
    public auth: AuthService
    ) {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.userId = params.id;
    });
    this.queryParamsSub = _activatedRoute.queryParams.subscribe((params) => {
      this.displayName = params.displayName;
    });
  }

  ngOnInit() {
    this.currentEntriesSub = this._userService.currentEntries.subscribe(entries => this.entries = entries);
  }

  ngOnDestroy() {
    if (this.currentEntriesSub) {
      this.currentEntriesSub.unsubscribe();
    }
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }
}
