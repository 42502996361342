import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/services/auth.service";
import countriesDe from "../../shared/data/countries-de";
import countriesEn from "../../shared/data/countries-en";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import {
  zipValidationOtherCountries,
  zipValidationforAt,
} from "../../shared/utils/custom-validation.util";
import { PersonRole } from "../enum/personRole.enum";
import { GroupPrefixesComponent } from "../group-prefixes/group-prefixes.component";
import { GroupService } from "../group.service";
import { UploadCompanyLogoComponent } from "../upload-company-logo/upload-company-logo.component";

@Component({
  selector: "sv-create-edit-group",
  templateUrl: "./create-edit-group.component.html",
  styleUrls: ["./create-edit-group.component.scss"],
})
export class CreateEditGroupComponent implements OnInit, AfterViewInit {
  public createOrEditForm: UntypedFormGroup;
  public data;
  public groupId: string;
  public externalid: string;
  public title: string;
  public isLoading: boolean;
  public allCountries: {
    id: number;
    alpha2: string;
    alpha3: string;
    name: string;
  }[];
  public isSuperAdmin: boolean;
  public materialNumbers: string[] = ["60107009", "60107026"];
  public accountingModels: string[] = [
    "Staffel Vinzenzgruppe",
    "Staffel Infoniqa",
    "Direktverrechnung",
  ];
  public logos;
  public selectedLogo: string;
  public companyPrefixes;
  public allowDuplicate;

  @ViewChild("field1") myFormField: ElementRef;
  constructor(
    public groupService: GroupService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateEditGroupComponent>,
    public authService: AuthService,
    private _translate: TranslateService,
    private _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    this.title = data.title;
    const userData = this.authService.getUserData();
    this.allCountries =
      userData.userLanguage === "de" ? countriesDe : countriesEn;

    this.isSuperAdmin = this._userPermissionService.hasRight(PersonRole.admin);

    this.createOrEditForm = new UntypedFormGroup({
      logo: new UntypedFormControl(""),
      name: new UntypedFormControl("", Validators.required),
      description: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(environment.emailRegex),
        ])
      ),
      sapCustomerNumber: new UntypedFormControl(
        "",
        this.isSuperAdmin ? Validators.required : null
      ),
      materialNumber: new UntypedFormControl(
        "",
        this.isSuperAdmin ? Validators.required : null
      ),
      accountingModel: new UntypedFormControl(
        "",
        this.isSuperAdmin ? Validators.required : null
      ),
      streetName: new UntypedFormControl(""),
      doorNumber: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      country: new UntypedFormControl("at", Validators.required),
      secureCode: new UntypedFormControl(false),
      einfachBriefEnabled: new UntypedFormControl(false),
      postSapNumber: new UntypedFormControl(""),
    });
    this.isSuperAdmin &&
      this.createOrEditForm.addControl(
        "smsNotificationsEnabled",
        new UntypedFormControl(false)
      );
    this.isSuperAdmin &&
      this.createOrEditForm.addControl(
        "eGehaltszettelEnabled",
        new UntypedFormControl(true)
      );
    this.createOrEditForm.addControl(
      "zipCode",
      new UntypedFormControl("", [
        this.createOrEditForm.value.country === "at"
          ? zipValidationforAt()
          : zipValidationOtherCountries(),
      ])
    );

    // Update validation on einfachBriefEnabled toggle
    this.createOrEditForm.controls.einfachBriefEnabled.valueChanges.subscribe(
      this._updateSapNumberValidation
    );

    this.groupService.editGroup.subscribe((data: any) => {
      this.createOrEditForm.controls.name.setValue(data.name);
      this.createOrEditForm.controls.description.setValue(data.description);
      this.createOrEditForm.controls.email.setValue(data.email);
      this.createOrEditForm.controls.sapCustomerNumber.setValue(
        data.sapCustomerNumber
      );
      this.createOrEditForm.controls.materialNumber.setValue(
        data.materialNumber
      );
      this.createOrEditForm.controls.accountingModel.setValue(
        data.accountingModel
      );
      this.createOrEditForm.controls.streetName.setValue(data.streetName);
      this.createOrEditForm.controls.doorNumber.setValue(data.doorNumber);
      this.createOrEditForm.controls.zipCode.setValue(data.zipCode);
      this.createOrEditForm.controls.city.setValue(data.city);
      this.createOrEditForm.controls.country.setValue(
        data.country?.toLowerCase() || "at"
      );
      this.createOrEditForm.controls.secureCode.setValue(
        data.secureCode || false
      );

      this.allowDuplicate = data.allowDuplicatePrefix;

      this.logos = data.logos;
      this.groupService.temporarilySavedTenantLogos = data.logos;

      this.selectedLogo = this.logos.find((img) => img.selected)?.logoUrl;
      this.selectedLogo = this.groupService.temporarilySavedTenantLogos.find(
        (img) => img.selected
      )?.logoUrl;

      this.companyPrefixes = data.tenantPrefixes;
      this.groupService.temporarilySavedCompanyPrefixes = data.tenantPrefixes;

      if (this.isSuperAdmin) {
        this.createOrEditForm.controls.smsNotificationsEnabled.setValue(
          data.smsNotificationsEnabled
        );
        this.createOrEditForm.controls.eGehaltszettelEnabled.setValue(
          data.eGehaltszettelEnabled
        );
        this.createOrEditForm.controls.einfachBriefEnabled.setValue(
          data.einfachBriefEnabled
        );
        this.createOrEditForm.controls.postSapNumber.setValue(
          data.postSapNumber
        );
        this._updateSapNumberValidation(data.einfachBriefEnabled);
      }
      this.groupId = data.groupId;

      if (this._userPermissionService.hasRight(PersonRole.supporter)) {
        this.createOrEditForm.controls.name.disable({ onlySelf: true });
        this.createOrEditForm.controls.externalId.disable({ onlySelf: true });
      }

      this.createOrEditForm.get("name").disable({ emitEvent: false });
    });

    this.createOrEditForm.controls.country.valueChanges.subscribe(
      (selectedCountry) => {
        if (selectedCountry === "at") {
          this.createOrEditForm.controls.zipCode.setValidators([
            zipValidationforAt(),
          ]);
        } else {
          this.createOrEditForm.controls.zipCode.setValidators([
            zipValidationOtherCountries(),
          ]);
        }

        this.createOrEditForm.controls.zipCode.updateValueAndValidity();
      }
    );
  }
  ngAfterViewInit(): void {
    // this.renderer.setStyle(document.querySelector('.mat-form-field-label'),'color','red')
  }

  ngOnInit(): void {}

  public openUploadCompanyLogoDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "edit-create-group-dialog";
    _dialogConfig.autoFocus = false;
    _dialogConfig.maxWidth = "500px";
    _dialogConfig.minWidth = "500px";
    _dialogConfig.maxHeight = "80vh";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      logos: this.groupService.temporarilySavedTenantLogos,
    };
    const dialogRef = this.dialog.open(
      UploadCompanyLogoComponent,
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason: any) => {
      if (reason === "save") {
        this.selectedLogo =
          this.groupService.temporarilySavedTenantLogos.find(
            (img) => img.selected
          )?.logoUrl ||
          this.groupService.temporarilySavedTenantLogos.find(
            (img) => img.selected
          )?.temporaryLogoUrl ||
          "";
      }
    });
  }

  public openImageDialog(e: Event) {
    e.preventDefault();
    this.openUploadCompanyLogoDialog();
  }

  public openCompanyPrefixesDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "edit-create-group-dialog";
    _dialogConfig.autoFocus = false;
    _dialogConfig.maxWidth = "500px";
    _dialogConfig.minWidth = "500px";
    _dialogConfig.maxHeight = "80vh";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      data: this.groupService.temporarilySavedCompanyPrefixes || [],
      allowDuplicate: this.allowDuplicate,
    };
    const dialogRef = this.dialog.open(GroupPrefixesComponent, _dialogConfig);
    dialogRef.afterClosed().subscribe((reason: any) => {});
  }

  public openPrefixesDialog(e: Event) {
    e.preventDefault();
    this.openCompanyPrefixesDialog();
  }
  public submit(): void {
    if (!this.groupService.temporarilySavedCompanyPrefixes) {
      return;
    }
    this.groupService.temporarilySavedTenantLogos.forEach((img) => {
      if (img.temporaryLogoUrl) {
        delete img.temporaryLogoUrl;
      }
    });

    this.groupService.filterValues = {
      accountNumber: "",
      displayName: "",
      groupDescription: "",
    };
    this.isLoading = true;

    if (this.createOrEditForm) {
      this.isLoading = true;
      this.groupService
        .createOrEditGroup(
          {
            id: this.groupId,
            name: this.createOrEditForm.get("name").value,
            companyName: this.createOrEditForm.value.description,
            adminEmail: this.createOrEditForm.value.email,
            sapCustomerNumber: this.isSuperAdmin
              ? this.createOrEditForm.value.sapCustomerNumber
              : null,
            materialNumber: this.isSuperAdmin
              ? this.createOrEditForm.value.materialNumber
              : null,
            accountingModel: this.isSuperAdmin
              ? this.createOrEditForm.value.accountingModel
              : null,
            eGehaltszettelEnabled: this.isSuperAdmin
              ? this.createOrEditForm.value.eGehaltszettelEnabled
              : false,
            smsNotificationsEnabled: this.isSuperAdmin
              ? this.createOrEditForm.value.smsNotificationsEnabled
              : false,
            logos: this.groupService.temporarilySavedTenantLogos,
            address: {
              country: this.createOrEditForm.value.country,
              city: this.createOrEditForm.value.city,
              streetName: this.createOrEditForm.value.streetName,
              doorNumber: this.createOrEditForm.value.doorNumber,
              zipCode: this.createOrEditForm.value.zipCode,
              state: "UNCONFIRMED",
            },
            companyPrefix: this.createOrEditForm.value.companyPrefix,
            secureCode: this.createOrEditForm.value.secureCode,
            tenantPrefixes: this.groupService.temporarilySavedCompanyPrefixes,
            einfachBriefEnabled:
              this.createOrEditForm.value.einfachBriefEnabled,
            postSapNumber: this.createOrEditForm.value.postSapNumber,
          },
          this.groupId,
          this.externalid
        )
        .subscribe(
          () => {
            // Request was successful
            this.isLoading = false;
            if (this.groupId) {
              this.groupService.statusMessage(
                "snack-bar-success",
                "Group edited"
              );
              this.groupService.temporarilySavedCompanyPrefixes = undefined;
              this.dialog.closeAll();
              return;
            }
            this.groupService.temporarilySavedCompanyPrefixes = undefined;
            this.dialog.closeAll();
            this.groupService.statusMessage(
              "snack-bar-success",
              "Group created"
            );
          },
          (error) => {
            this.isLoading = false;
            if (error.errorMessage === "Group already exists") {
              this.createOrEditForm.controls.externalId.setErrors({
                notUnique: true,
              });
            }
            this._translate.instant(error.errorMessage);
          }
        );
    }
  }

  public closeDialog(): void {
    this.dialogRef.close("close");
    this.groupService.temporarilySavedCompanyPrefixes = undefined;
  }

  private _updateSapNumberValidation = (checked): void => {
    if (checked) {
      this.createOrEditForm.controls.postSapNumber.setValidators([
        Validators.compose([this.tenDigitsValidator(), Validators.required]),
      ]);

      this.createOrEditForm.controls.postSapNumber.enable();
    } else {
      this.createOrEditForm.controls.postSapNumber.clearValidators();
      this.createOrEditForm.controls.postSapNumber.disable();
    }
    this.createOrEditForm.controls.postSapNumber.value
      ? this.createOrEditForm.controls.postSapNumber.markAsTouched()
      : this.createOrEditForm.controls.postSapNumber.markAsUntouched();

    this.createOrEditForm.controls.postSapNumber.updateValueAndValidity();
  };
  tenDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^\d{10}$/.test(control.value);
      return isValid ? null : { tenDigits: true };
    };
  }
}
