import { Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'sv-auto-logout-dialog',
  templateUrl: './auto-logout-dialog.component.html',
  styleUrls: ['./auto-logout-dialog.component.scss']
})
export class AutoLogoutDialogComponent {
  public closeButtonLabel: string;
  public confirmation = new EventEmitter<boolean>();

  constructor(
    private _translateService: TranslateService,
    public authService: AuthService
  ) {
    
  }
  
  public onClose() {
    this.closeButtonLabel = this._translateService.instant('Close');
    this._translateService.instant('Close');
  }

  public onConfirm() {
    this.confirmation.emit(true);
  }
}
