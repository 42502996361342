<div class="search-dialog">
  <div mat-dialog-content class="search-activity-form">
    <form #userSearchForm="ngForm" (ngSubmit)="onSubmit()">
      <div *ngIf="filterModel.displayName" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Display Name' | translate}}:</p>
          <mat-form-field color="primary">
            <input [(ngModel)]="filterValues.displayName" name="displayName" matInput
              placeholder="{{ 'Enter the displayname...' | translate }}">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="filterModel.userName" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Username' | translate}}:</p>
          <mat-form-field color="primary">
            <input [(ngModel)]="filterValues.userName" name="userName" matInput
              placeholder="{{ 'Enter the username...' | translate }}">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="filterModel.email" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Email' | translate}}:</p>
          <mat-form-field color="primary">
            <input [(ngModel)]="filterValues.email" name="email"
              #email="ngModel"
              matInput
              placeholder="{{ 'Enter E-Mail Address...' | translate }}">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="filterModel.accountNumber" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Account Number' | translate}}:</p>
          <mat-form-field color="primary">
            <input [(ngModel)]="filterValues.accountNumber" name="accountNumber" matInput
              placeholder="{{ 'Enter the account number..' | translate }}">
          </mat-form-field>
        </div>
      </div>
      <!-- <div *ngIf="filterModel.status" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Kundennummer' | translate}}:</p>
          <mat-form-field color="primary">
            <mat-select *ngIf="statusDropdownOptions" [(ngModel)]="filterValues.status" name="status"
              placeholder="{{ 'Select Status' | translate }}">
              <mat-option *ngFor="let option of statusDropdownOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <div *ngIf="filterModel.status" class="form-fields">
        <div class="searchText-wrap">
          <p class="label">{{'Status' | translate}}:</p>
          <mat-form-field color="primary">
            <mat-select *ngIf="statusDropdownOptions" [(ngModel)]="filterValues.status" name="status"
              placeholder="{{ 'Select Status' | translate }}">
              <mat-option *ngFor="let option of statusDropdownOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="checkbox-wrap">
        <div *ngIf="filterModel.inboxWithoutEmail">
          <mat-checkbox [(ngModel)]="filterValues.inboxWithoutEmail" name="inboxWithoutEmail">
            {{'Inbox Without Email Address' | translate}}</mat-checkbox>
        </div>
        <!-- <div *ngIf="filterModel.optIn">
          <mat-checkbox [(ngModel)]="filterValues.optIn" name="optIn">{{'Opt Ins' | translate}}</mat-checkbox>
        </div>
        <div *ngIf="filterModel.corporateCustomer">
          <mat-checkbox [(ngModel)]="filterValues.corporateCustomer" name="corporateCustomer">
            {{'Corporate customer' | translate}}</mat-checkbox>
        </div> -->
        <!-- <div *ngIf="filterModel.employee">
          <mat-checkbox [(ngModel)]="filterValues.employee" name="employee">{{'Employee' | translate}}</mat-checkbox>
        </div> -->
      </div>
      <div>
        <div class="form-actions">
          <button type="button" mat-button (click)="onReset()">{{'resetUserSearch' | translate}}</button>
          <button color="primary"
          type="submit" mat-button mat-raised-button [disabled]="!userSearchForm.valid"
            color="primary">{{'Search' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>