const valuesMap = new WeakMap();

export function OnSet(func: string, ...additionalParams) {
  // @ts-ignore
  return <any>(function (target: any, property: string) {
    // this assign value is put because build will not pass through(error => value is never used)
    target = target;
    return <any>{
      configurable: true,
      get() {
        let map = valuesMap.get(this);
        if (!map) {
          map = {};
          valuesMap.set(this, map);
        }
        return map[property];
      },
      set(newValue) {
        // get a map of saved values for this object
        let map = valuesMap.get(this);
        if (!map) {
          // create empty if it doesn't exist
          map = {};
          valuesMap.set(this, map);
        }
        // set new value to the value map
        const oldValue = map[property];
        map[property] = newValue;
        // call the OnSet callback
        this[func].call(this, newValue, oldValue, ...additionalParams);
        return newValue;
      },
    };
  });
}
