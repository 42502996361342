import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Pagination } from "../interfaces/pagination.interface";
import { IPersonResponse, IPerson } from "../interfaces/person.interface";
import { IGroup } from "../interfaces/group.interface";
import { DocumentVisibility } from "../interfaces/visibility";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";
import { TenantService } from "../tenantService/tenant.service";
import { Tenant } from "../enum/tenant.enum";
export const RESET_FILTER_PERSON = {
  accountNumber: "",
  email: "",
  userName: "",
  firstName: "",
  lastName: "",
  role: null,
};

@Injectable({
  providedIn: "root",
})
export class PersonService {
  public createPerson = new Subject<{ externalId: any; groupId: string }>();
  public searchPersonQuery: {
    email: string;
    userName: string;
    accountNumber: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    personFunction?: string;
  } = {
    accountNumber: "",
    displayName: "",
    email: "",
    userName: "",
    firstName: "",
    lastName: "",
    personFunction: "",
  };
  public triggerDataReaload = new Subject<boolean>();

  public editPerson = new Subject<{
    person: any;
    groupId: string;
    id: string;
    accountNumber: string;
    accountNumbers?: { accountNumber: string; prefix: string }[];
    activate: boolean | string;
    accountType: string;
    sendToPrint?: boolean;
  }>();
  constructor(
    private httpClient: HttpClient,
    private _translateService: TranslateService,
    private snackBar: MatSnackBar,
    private _tenantService: TenantService,
    public notificationService: MultipleNotificationsService
  ) {}

  public statusMessage(panelClass: string, message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }

  public getPersons(
    params: Pagination,
    groupId: string,
    searchPersonQuery: {
      accountNumber: string;
      displayName?: string;
      email: string;
      userName: string;
      role?: string;
      firstName?: string;
      lastName?: string;
      personFunction?: string;
    }
  ): Observable<IPersonResponse> {
    const httpParams = new HttpParams()
      .set("page", params.page)
      .set("size", params.pageSize)
      .set("sort", `${params.sort.field},${params.sort.ascOrDesc}`);
    return this.httpClient
      .post<IPersonResponse>(
        `${environment.apiUrl}/person/fromGroup/${groupId}`,
        searchPersonQuery,
        { params: httpParams }
      )
      .pipe(shareReplay())
      .pipe(
        map((persons) => ({
          ...persons,
          content: this._tenantService.mapPersonRole(persons.content),
        }))
      );
  }
  updatePersons(): void {
    this.triggerDataReaload.next(true);
  }
  public createOrEditPErson(
    data,
    accountNumber,
    groupId: string,
    personId: string,
    accountNumbers?,
    sendToPrint?: boolean
  ) {
    const update = personId;
    if (update) {
      return this.httpClient
        .post<IPerson>(
          `${environment.apiUrl}/person/editPerson`,
          this._tenantService.mapCreateEditPersonPayload(
            data,
            accountNumber,
            accountNumbers,
            groupId,
            personId,
            sendToPrint
          )
        )
        .pipe(
          catchError((err) => {
            this.notificationService.showNotifications({
              message: "Failed to update person",
              isSuccess: false,
            });
            return throwError(err);
          })
        );
    }
    return this.httpClient
      .post<IPerson>(`${environment.apiUrl}/person/editPerson`, {
        accountDataDto: {
          ...data,
          accountType: undefined,
          accountNumber: undefined,
          groupsForSupporter: undefined,
          activate: undefined,
          sendToPrint: undefined
        },
        accountType: data.accountType,
        accountNumber: "123",
        ...(accountNumbers && {
          accountNumbers: accountNumbers || [`${accountNumber}`],
        }),
        groupId: groupId,
        sendToPrint
      })
      .pipe(
        catchError((err) => {
          if (err.errorMessage === "Person already exists") {
            this.notificationService.showNotifications({
              message: "Person already exists",
              isSuccess: false,
            });
            return throwError(err);
          }
          this.notificationService.showNotifications({
            message: "Failed to create person",
            isSuccess: false,
          });

          return throwError(err);
        })
      );
  }

  public createOrEditXUser(data, tenantName: string, personId: string) {
    const update = personId;
    if (update) {
      return this.httpClient
        .post<IPerson>(`${environment.apiUrl}/person/editXUser`, {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          email: data.email,
          tenantName: tenantName,
          id: personId,
        })
        .pipe(
          catchError((err) => {
            this.statusMessage("snack-bar-fail", "Failed to update person");
            return throwError(err);
          })
        );
    }
    return this.httpClient
      .post<IPerson>(`${environment.apiUrl}/person/editXUser`, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        username: data.username,
        roles: [],
        active: true,
        tenantName: tenantName,
      })
      .pipe(
        catchError((err) => {
          if (err.errorMessage === "Person already exists") {
            this.statusMessage("snack-bar-fail", "Person already exists");
            return throwError(err);
          }
          this.statusMessage("snack-bar-fail", "Failed to create person");
          return throwError(err);
        })
      );
  }

  public getPersoAdditionalData(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/person/${id}`);
  }
  public getPersonAdditionalDataPayslip(id: string, groupId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/person/${id}/group/${groupId}`
    );
  }
  public deletePerson(id: string) {
    return this.httpClient
      .delete<IPerson>(`${environment.apiUrl}/person/delete/${id}`)
      .pipe(
        catchError((err) => {
          this.notificationService.showNotifications({
            message: "Failed to delete person",
            isSuccess: false,
          });
          return throwError(err);
        })
      );
  }
  public resetPersonPassword(personId: string, groupId: string) {
    let url = "";
    let body = {};

    switch (this._tenantService.tenant) {
      case Tenant.datapart:
        url = `${environment.apiUrl}/person/resetPassword/${personId}`;
        body = {};
        break;

      case Tenant.payslip:
        url = `${environment.apiUrl}/person/resetPersonPassword`;
        body = { personId, groupId };
    }

    return this.httpClient.post(url, body).pipe(this.handleError());
  }

  private handleError() {
    return catchError((err) => {
      this.notificationService.showNotifications({
        message: "Failed to reset password",
        isSuccess: false,
      });
      return throwError(err);
    });
  }

  public getGroupsForSupporter(supporterId: string) {
    return this.httpClient.get<IGroup[]>(
      `${environment.apiUrl}/person/getGroups/${supporterId}`
    );
  }

  public addSupporterToGroup(supporterId: string, groupsId: string[]) {
    return this.httpClient.post<string[]>(
      `${environment.apiUrl}/person/addToGroup/${supporterId}`,
      groupsId
    );
  }

  public activateDeactivate(groupId: string, userId: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/group/activate/${groupId}`, userId)
      .pipe(shareReplay());
  }
  public getPermisions(id: string) {
    return this.httpClient.get<DocumentVisibility[]>(
      `${environment.apiUrl}/person/permission/${id}`
    );
  }

  public postPermisions(
    studentId: string,
    documentsVisibility: DocumentVisibility[]
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/person/permission/${studentId}`,
      documentsVisibility
    );
  }
  public magicLink(personId: string, groupId: string) {
    // return this.httpClient.post(`http://10.5.10.34:8085/be.e-gehaltszettel_t/rest/v2/magicLink/createByGroup`,{personId, groupId})
    // return this.httpClient.post(`https://supportviewpayslip.edeja.com/be/rest/v2/magicLink/createByGroup`,{personId, groupId})
    //e-gehaltszettel-t.post-business-solutions.at/be.e-gehaltszettel_t/supportView/v1
    // return this.httpClient.post(`https://supportviewpayslip.edeja.com/be/rest/v2/magicLink/createByGroup`,{personId, groupId})
    https: return this.httpClient.post(`${environment.magicLink}`, {
      personId,
      groupId,
    });
  }
}
