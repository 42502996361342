import { Component, OnInit, Input } from '@angular/core';
import { IStatisticsData } from '../../../statistics/services/statistics/statistics.service';
import { Router } from '@angular/router';
import { GroupService } from '../../../group/group.service';

@Component({
  selector: 'sv-details-display',
  templateUrl: './details-display.component.html',
  styleUrls: ['./details-display.component.scss']
})
export class DetailsDisplayComponent implements OnInit {
  @Input() displayData: IStatisticsData[];
  @Input() loading: boolean;

  constructor(
    private _router: Router,
    private _groupService: GroupService
  ) { }

  ngOnInit() {
  }

  handleItemClick(item) {
    this._router.navigate(['/dashboard/groups'])
    this._groupService.filterValues = {accountNumber:'',displayName: item, groupDescription: ''}
  }

}
