import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import {
  ISelectedRow,
  TableComponentActionList,
} from "../../design/components/table/table.component";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { DeleteDialogComponent } from "../../users/components/delete-dialog/delete-dialog.component";
import { AssignUsersToCompaniesComponent } from "../assign-users-to-companies/assign-users-to-companies.component";
import { CreateUserComponent } from "../create-user/create-user.component";
import { DocumentVisibilityComponent } from "../document-visibility/document-visibility.component";
import { DownloadUserCredentialsComponent } from "../download-user-credentials/download-user-credentials.component";
import { PersonRole } from "../enum/personRole.enum";
import { Tenant } from "../enum/tenant.enum";
import { GroupService } from "../group.service";
import { Pagination } from "../interfaces/pagination.interface";
import { IPerson, IPersonResponse } from "../interfaces/person.interface";
import { PersonService, RESET_FILTER_PERSON } from "../person/person.service";
import { SearchPersonDialogComponent } from "../search-person-dialog/search-person-dialog.component";
import { TenantService } from "../tenantService/tenant.service";
import { UploadDialogComponent } from "../../shared/components/upload/upload-dialog.component";
import { UploadService } from "../../shared/components/upload/upload.service";
import { DeliverDocsToUsersComponent } from "../deliver-docs-to-users/deliver-docs-to-users.component";

enum TableColumns {
  displayName = "Display Name",
  accountNumbers = "Account Numbers",
  accountNumber = "Account Number",
  email = "Email",
  username = "Username",
  active = "Active",
  actions = "Actions",
  role = "Function",
  sendToPrint = "Send to Print Channel",
}

@Component({
  selector: "sv-persons",
  templateUrl: "./persons.component.html",
  styleUrls: ["./persons.component.scss"],
})
export class PersonsComponent implements OnInit, AfterViewInit {
  public userData = this.authService.getUserData();
  public page = 1;
  public pageSize = 10;
  public sort = "";
  public isLoading = false;
  public selectedRows: ISelectedRow[] = [];
  private groupId = "";
  private groupDisplayName;
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: { ascOrDesc: "asc", field: "name" },
  };
  public persons$: Observable<IPersonResponse>;
  public personAdditionalData;
  public selectedPerson: IPerson;
  public externalId = "";
  public switchButtonTitle = "";
  public paginationColor = "";
  public personsTableColumns = []
  public displayName: string;
  public accountNumber: string;
  public accountNumberUser: string;

  public isAdministrator: boolean;

  public actions: Record<string, TableComponentActionList<IPerson>>;
  public allPersons = []

  public isSupporter: boolean;
  public isUserAdmin: boolean;
  public masterAdmin: boolean;
  public userAdministrator;
  public isDeliverySubmitter: any;

  public groupDescription: string;
  public einfachBriefEnabled: boolean;
  public tenant: string;

  deliverDocsToMultipleUsers: boolean;
  isGroupActive: boolean;
  dataForSelectAll: Observable<IPersonResponse>;
  totalUsersLength: number;
  constructor(
    public _groupService: GroupService,
    private _translateService: TranslateService,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    public _personService: PersonService,
    public notificationService: MultipleNotificationsService,
    public authService: AuthService,
    public renderer: Renderer2,
    public downloadDialog: MatDialog,
    private _tenantService: TenantService,
    private _userPermissionService: UserPermissionService,
    private _uploadService: UploadService
  ) {
    this.tenant = this.userData.tenantId;
    this.groupId = this.router.snapshot.paramMap.get("groupId");

    this._groupService
      .getGroups(
        {
          page: 0,
          pageSize: 1000000,
          sort: { ascOrDesc: "asc", field: "name" },
        },
        { accountNumber: "", displayName: "", groupDescription: "" }
      )
      .subscribe((groups) => {
        this.groupDescription = groups.content.find(
          (group) => group.id === this.groupId
        )?.description;
        this.groupDisplayName = groups.content.find(
          (group) => group.id === this.groupId
        )?.displayName;
        this.einfachBriefEnabled = groups.content.find(
          (group) => group.id === this.groupId
        )?.additionalData?.einfachBriefEnabled;
        this._uploadService.companyDisplayName = [this.groupDisplayName]
        this.isGroupActive = ['Yes', 'Ja'].includes(
          (groups.content.find((group) => group.id === this.groupId)?.eGehaltszettelEnabled ?? '') as string
        );

        this.totalUsersLength = groups.content.find(
          (group) => group.id === this.groupId
        )?.groupSupporterIds.length  + groups.content.find(
          (group) => group.id === this.groupId
        )?.membersIds.length
        

        this.isSupporter = this._userPermissionService.hasRightForTenant({
          right: PersonRole.bertSupporter,
          tentnatId: this.groupDisplayName,
        });

        this.isAdministrator = this._userPermissionService.hasRightForTenant({
          right: PersonRole.administrator,
          tentnatId: this.groupDisplayName,
        });

        this.isUserAdmin = this._userPermissionService.hasRightForTenant({
          right: PersonRole.userAdministrator,
          tentnatId: this.groupDisplayName,
        });

        this.masterAdmin = this._userPermissionService.hasRightForTenant({
          right: PersonRole.admin,
          tentnatId: this.groupDisplayName,
        });

        this.userAdministrator = this._userPermissionService.hasRightForTenant({
          right: PersonRole.userAdministrator,
          tentnatId: this.groupDisplayName,
        });

        this.isDeliverySubmitter =
          this._userPermissionService.hasRightForTenant({
            right: PersonRole.deliverySubmitter,
            tentnatId: this.groupDisplayName,
          });
          this.personsTableColumns = this._tenantService.configureTableColumnsPerson();
      });

    // this._groupService.getGroups()
    this._personService.triggerDataReaload.subscribe((reload) => {
      if (!reload) return;

      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupId,
        {
          accountNumber: "",
          displayName: "",
          email: "",
          userName: "",
          role: null,
        }
      ).pipe(map(data => {
        const fromSessionStorage = JSON.parse(sessionStorage.getItem('users'))
        const content = data.content.map(ssData => {
          const match = fromSessionStorage?.find(item2 => item2.id === ssData.id);
          if (match) {
            return {...ssData,isSelected : true};
          }
          return ssData
        })
        return {...data,content: content}
      }));
    });
    this.displayName = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )._resourceObject.displayName;
    this.accountNumber = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber?.substring(0, 3);
    this.accountNumberUser = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber;

    const rights = this._userPermissionService.getAllRights();

    this.actions = {
      payslip: [
        {
          edit: {
            name: "Edit",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [
                  PersonRole.admin,
                  PersonRole.administrator,
                  PersonRole.userAdministrator,
                ],
                tentnatId: this.groupDisplayName,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "edit",
          },
          delete: {
            name: "GDPR-Delete",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: PersonRole.admin,
                tentnatId: this.groupDisplayName,
              });

              return (
                hasRights &&
                row.accountNumber !== this.accountNumber &&
                row.username !== this.displayName
              );
            },
            onClick: "delete",
          },
          deactivate: {
            name: "Deactivate",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return (
                hasRights &&
                (row.active === "Yes" || row.active === "Ja") &&
                row.accountNumber !== this.accountNumber
              );
            },
            onClick: "activate",
          },
          activate: {
            name: "Activate",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return (
                hasRights && (row.active === "No" || row.active === "Nein")
              );
            },
            onClick: "activate",
          },
          passwordReset: {
            name: "Password reset",
            showIf: (row: any) => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [
                  PersonRole.admin,
                  PersonRole.administrator,
                  PersonRole.userAdministrator,
                ],
                tentnatId: this.groupDisplayName,
              });

              return hasRights && (row.active === "Yes" || row.active === "Ja");
            },
            onClick: "resetPass",
          },
          assignToCompany: {
            name: "Companies",
            showIf: () => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: this.groupDisplayName,
              });

              return hasRights;
            },
            onClick: "openAssignToCompanyDialog",
          },
          openEbox: {
            name: "Open E-Box",
            showIf: () => {
              if (!this.groupDisplayName) {
                return false;
              }

              const hasRights = this._userPermissionService.hasRightForTenant({
                right: PersonRole.eboxViewer,
                tentnatId: this.groupDisplayName,
              });

              return hasRights;
            },
            onClick: "openEbox",
          },
        },
      ],
      datapart: [
        {
          edit: {
            name: "edit",
            showIf: () =>
              this._userPermissionService.hasRight([
                PersonRole.admin,
                PersonRole.administrator,
                PersonRole.bertSupporter,
              ]),
            onClick: "edit",
          },
          delete: {
            name: "GDPR-Delete",
            showIf: (row: any) => {
              const isMiniSupporter = this._userPermissionService.hasRight(
                PersonRole.supporter
              );

              return (
                !(isMiniSupporter && row.role === "Student") &&
                !(isMiniSupporter && row.externalId) &&
                row.accountNumber !== this.accountNumber &&
                row.accountNumber !== this.accountNumberUser
              );
            },
            onClick: "delete",
          },
          deactivate: {
            name: "Deactivate",
            showIf: (row: any) => {
              const canActivate = this._userPermissionService.hasRight([
                PersonRole.admin,
                PersonRole.bertSupporter,
              ]);

              return (
                canActivate &&
                row.active &&
                row.role !== "Student" &&
                row.role !== "Schüler" &&
                row.accountNumber !== this.accountNumber
              );
            },
            onClick: "activate",
          },
          activate: {
            name: "Activate",
            showIf: (row: any) => {
              const canActivate = this._userPermissionService.hasRight([
                PersonRole.admin,
                PersonRole.bertSupporter,
              ]);

              return (
                canActivate &&
                !row.active &&
                row.role !== "Student" &&
                row.role !== "Schüler"
              );
            },
            onClick: "activate",
          },
          resetPassword: {
            name: "Password reset",
            showIf: (row: any) => {
              const canResetPassword = this._userPermissionService.hasRight([
                PersonRole.admin,
                PersonRole.bertSupporter,
              ]);

              return canResetPassword && row.active;
            },
            onClick: "resetPass",
          },
          rights: {
            name: "Rights",
            showIf: (row: any) =>
              row.role === "User" || row.role === "Benutzer",
            onClick: "openDocumentVisibilityDialog",
          },
        },
      ],
    };
    this.persons$ = this._personService.getPersons(
      this.paginationAndSortConfig,
      this.groupId,
      {
        accountNumber: "",
        displayName: "",
        email: "",
        userName: "",
        role: null,
      }
    );
    this._personService
      .getPersons(this.paginationAndSortConfig, this.groupId, {
        ...RESET_FILTER_PERSON,
        role: null,
      })
      .pipe(
        map((data) =>
          data.content.filter(
            (person) =>
              person.role === PersonRole.supporter ||
              person.role === PersonRole.instructor
          )
        ),
        map((supportersAndInstructors) => {
          const deactivateOrActivate = supportersAndInstructors.every(
            (suppOrInst) => suppOrInst.active
          );
          const switchTitle = !deactivateOrActivate;
          return switchTitle;
        })
      )
      .subscribe((switchTitle) => {
        this.switchButtonTitle = switchTitle
          ? "Activated Supporters and Instructors"
          : "Deactivated Supporters and Instructors";
      });
    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });
  }
  ngAfterViewInit(): void {
    // this.authService.infoTextColors.subscribe(resp => {
    //
    //   this.paginationColor = resp.pagination;
    // })
    const elements = document.querySelectorAll(
      ".mat-paginator-page-size-label, .mat-paginator-range-label"
    );
    const itemsPerPage = document.querySelectorAll(
      ".mat-paginator-page-size-value"
    );
    const icons = document.querySelectorAll(".mat-paginator-icon");
    const paginationColor = JSON.parse(localStorage.getItem("paginationColor"));

    // icons.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // itemsPerPage.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // elements.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
  }
  private onTranslateChange(): void {
    this.personsTableColumns.map((tableCol) => {
      tableCol.title = this._translateService.instant(
        TableColumns[tableCol.field]
      );
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupId,
        RESET_FILTER_PERSON
      );
    });
  }
  ngOnInit(): void {}
  public editPerson(selectedPerson) {
    if (this.authService.tenant.value === "payslip") {
      this._personService
        .getPersonAdditionalDataPayslip(selectedPerson.id, this.groupId)
        .subscribe((data: any) => {
          this._personService.editPerson.next({
            person: data,
            groupId: this.groupId,
            accountNumber: selectedPerson.accountNumber,
            accountNumbers: data.accountNumbers,
            id: selectedPerson.id,
            activate: selectedPerson.active,
            accountType: data["accountType"],
            sendToPrint: data.sendToPrint,
          });
        });
    } else {
      this._personService
        .getPersoAdditionalData(selectedPerson.id)
        .subscribe((data) => {
          this._personService.editPerson.next({
            person: data,
            groupId: this.groupId,
            accountNumber: selectedPerson.accountNumber,
            id: selectedPerson.id,
            activate: selectedPerson.active,
            accountType: data["accountType"],
          });
        });
    }

    this.openCreateEditDialog(false);
  }
  public createPerson(): void {
    this.openCreateEditDialog(true);
    this._personService.createPerson.next({
      externalId: localStorage.getItem("externalId"),
      groupId: this.groupId,
    });
  }
  public deletePerson(): void {
    this._personService.deletePerson(this.selectedPerson.id).subscribe(() => {
      this.notificationService.showNotifications({
        message: "User deleted succesfully",
        isSuccess: true,
      });
      this.persons$ = this._personService.getPersons(
        this.paginationAndSortConfig,
        this.groupId,
        RESET_FILTER_PERSON
      );
    });
  }
  public selectPerson(event): void {
    this.selectedPerson = event;
  }
  public resetPass(): void {
    this.isLoading = true;

    this._personService
      .resetPersonPassword(this.selectedPerson.id, this.groupId)
      .subscribe(
        (data: {
          userAccountPdfFile: string;
          fileName: string;
          success: boolean;
        }) => {
          this.isLoading = false;
          // Downloading of user credentials is related for payslip tenant
          if (data?.userAccountPdfFile) {
            const _dialogConfig = new MatDialogConfig<any>();
            _dialogConfig.disableClose = true;
            _dialogConfig.panelClass = "custom-dialog";
            _dialogConfig.autoFocus = true;
            _dialogConfig.maxWidth = "700px";
            _dialogConfig.minWidth = "350px";
            _dialogConfig.height = "auto";
            _dialogConfig.data = {
              data: data,
            };
            this.downloadDialog.open(
              DownloadUserCredentialsComponent,
              _dialogConfig
            );
          }
          this.notificationService.showNotifications({
            message: "Password request sent",
            isSuccess: true,
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  handleRowClick(event) {
    const canOpen = this._userPermissionService.hasRightForTenant({
      right: [
        PersonRole.admin,
        PersonRole.administrator,
        PersonRole.userAdministrator,
        PersonRole.bertSupporter,
      ],
      tentnatId: this.groupDisplayName,
    });

    if (canOpen) {
      this.editPerson(event);
    }
  }

  public action(event: { tableData; action: string }): void {
    this.selectedPerson = event.tableData;
    if (event.action === "edit") {
      this.editPerson(this.selectedPerson);
    } else if (event.action === "delete") {
      this.openDeleteDialog();
    } else if (event.action === "create") {
      this.createPerson();
    } else if (event.action === "resetPass") {
      this.resetPass();
    } else if (event.action === "activate") {
      this.toggleSupporterAndGroupActivation();
    } else if (event.action === "openDocumentVisibilityDialog") {
      this._openDocumentVisibilityDialog();
    } else if (event.action === "openAssignToCompanyDialog") {
      this._openAssignToCompanyDialog();
    } else if (event.action === "openEbox") {
      this.isLoading = true;
      this._personService
        .magicLink(this.selectedPerson.id, this.groupId)
        .subscribe((response: { magicLink: string }) => {
          window.open(response.magicLink, "_blank");
          this.isLoading = false;
        });
    }
  }
  private _openAssignToCompanyDialog() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "companiesa-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "900px";
    _dialogConfig.minHeight = "700px";
    _dialogConfig.data = {
      title: this._translateService.instant("Assign user to companies"),
      inputPlaceHolder: "Search for company name",
      billersInvoiceRecipientID: this._translateService.instant(
        "Company-Specific User ID"
      ),

      personId: this.selectedPerson.id,
    };
    const dialogRef = this.dialog.open(
      AssignUsersToCompaniesComponent,
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason) => {});
  }

  private _openDocumentVisibilityDialog() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "delete-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "600px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      studentId: this.selectedPerson.id,
      groupName: this.groupDisplayName,
    };
    const dialogRef = this.dialog.open(
      DocumentVisibilityComponent,
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason) => {});
  }

  public getPageIndex(): number {
    return this.page - 1;
  }

  public changePage(event: PageEvent): void {
    this.paginationAndSortConfig.page = event.pageIndex;
    if (this._personService.searchPersonQuery.role === "") {
      delete this._personService.searchPersonQuery.role;
    }
    this.persons$ = this._personService.getPersons(
      this.paginationAndSortConfig,
      this.groupId,
      this._personService.searchPersonQuery
    );
  }
  // public sorted
  public sortEvent(event: string): void {
    const isAsc = /^-/.test(event) ? "asc" : "desc";
    const replace = event.replace("-", "");

    // if (replace === 'role') {
    //   this.persons$ = this._personService.getPersons(this.paginationAndSortConfig,this.groupId,this._personService.searchPersonQuery)
    //   .pipe((map(data => {
    //     const sortedContent = data.content.sort((a,b)=>isAsc === 'asc' ? a.role.localeCompare(b.role): b.role.localeCompare(a.role));
    //     return {...data,content: sortedContent};
    //   })));

    //   return;
    // }

    this.paginationAndSortConfig.sort.field = replace;
    this.paginationAndSortConfig.sort.ascOrDesc = isAsc;

    this.persons$ = this._personService.getPersons(
      this.paginationAndSortConfig,
      this.groupId,
      this._personService.searchPersonQuery
    );
  }

  public openDeleteDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = false;
    _dialogConfig.panelClass = "delete-person-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "600px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: this._translateService.instant(
        "Are you sure you want to delete user?"
      ),
    };
    const dialogRef = this.dialog.open(DeleteDialogComponent, _dialogConfig);
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason === undefined || reason === "") return;
      this.deletePerson();
    });
  }
  public openSearchPersonDialog() {
    const position = document.getElementById("searchButton");
    const topPosition =
      position.getBoundingClientRect().top + window.pageYOffset;
    const dialogRef = this.dialog.open(SearchPersonDialogComponent, {
      width: "474px",
      autoFocus: false,
      position: {
        top: topPosition + position.scrollHeight + "px",
        right: "42px",
      },
      disableClose: true,
      data: {
        adminUsers: false,
      },
    });
    dialogRef.afterClosed().subscribe((fiterValues) => {
      if (!fiterValues) {
        // this.paginationAndSortConfig = {page:0,pageSize: 10,sort: {ascOrDesc: 'asc', field:'name'}}
        // this.page = 0;s
        // this.paginationAndSortConfig = {page:0,pageSize: 0,sort: {ascOrDesc: 'asc', field:'name'}}
        this.persons$ = this._personService.getPersons(
          { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
          this.groupId,
          RESET_FILTER_PERSON
        );
        this.page = this.getPageIndex() - this.page;

        return;
      }
      let params = {};
      if (fiterValues.role === "") {
        delete fiterValues.role;
      }
      this.page = 0;
      // this.paginationAndSortConfig = {page:0,pageSize: 0,sort: {ascOrDesc: 'asc', field:'name'}}
      this.persons$ = this._personService.getPersons(
        { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
        this.groupId,
        fiterValues
      ).pipe(map(data => {
        const fromSessionStorage = JSON.parse(sessionStorage.getItem('users'))
        const content = data.content.map(ssData => {
          const match = fromSessionStorage?.find(item2 => item2.id === ssData.id);
          if (match) {
            return {...ssData,isSelected : true};
          }
          return ssData
        })
        return {...data,content: content}
      }));;
    });
  }
  public openCreateEditDialog(isCreate: boolean): void {
    console.log("this.einfachBriefEnabled 762", this.einfachBriefEnabled);
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "edit-create-group-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "700px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: isCreate
        ? this._translateService.instant("Create user")
        : this._translateService.instant("Edit user"),
      isXUser: false,
      groupId: this.groupId,
      einfachBriefEnabled: this.einfachBriefEnabled,
    };
    if (
      isCreate &&
      (this.masterAdmin || this.isAdministrator || this.isUserAdmin) &&
      this._tenantService.tenant === Tenant.payslip
    ) {
      this.dialog.open(CreateUserComponent, _dialogConfig);
    } else {
      const dialogRef = this.dialog.open(
        this._tenantService.openPersonDialog(),
        _dialogConfig
      );
      dialogRef.afterClosed().subscribe((reason: any) => {
        if (reason === "close") return;
        this._personService.searchPersonQuery = RESET_FILTER_PERSON;
        this.persons$ = this._personService.getPersons(
          this.paginationAndSortConfig,
          this.groupId,
          RESET_FILTER_PERSON
        );
      });
    }
  }
  public filterValueChange($event): void {
    this.persons$ = this._personService.getPersons(
      { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
      this.groupId,
      this._personService.searchPersonQuery
    );
  }
  public toggleSupporterAndGroupActivation(): void {
    this._personService
      .getPersons(
        this.paginationAndSortConfig,
        this.groupId,
        RESET_FILTER_PERSON
      )
      .pipe(
        map((data) =>
          data.content.filter(
            (person) =>
              person.role === PersonRole.supporter ||
              person.role === PersonRole.instructor
          )
        ),
        switchMap((supportersAndInstructors) => {
          // const supporterAndInstructorIds = supportersAndInstructors.map(suppAndIns => suppAndIns.id);
          // const areAllActivated = supportersAndInstructors.every(suppOrInst => suppOrInst.active);
          // const toogleActivation = !areAllActivated;
          // this.switchButtonTitle = areAllActivated ? 'Activate Supporters and Instructors' : 'Deactivate Supporters and Instructors';
          return this._personService.activateDeactivate(
            this.groupId,
            this.selectedPerson.id
          );
        })
      )
      .subscribe(() => {
        //  const notificationMessage = this.switchButtonTitle === 'Deactivate Supporters and Instructors' ? 'Activate Supporters and Instructors' : 'Deactivate Supporters and Instructors';
        const notificationMessage =
          this.selectedPerson.active === this._translateService.instant("Yes")
            ? "Succesfully deactivated"
            : "Succesfully activated";
        this.notificationService.showNotifications({
          message: notificationMessage,
          isSuccess: true,
        });
        this._personService.searchPersonQuery = RESET_FILTER_PERSON;
        this.persons$ = this._personService.getPersons(
          this.paginationAndSortConfig,
          this.groupId,
          RESET_FILTER_PERSON
        );
      });
  }
  openSelectUsersDialog() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = false;
    
    config.data = { 
      deliverDocsToMultipleUsers: true,
      groupId: this.groupId,
      usersLength:this.totalUsersLength,
      title:'Select users to deliver documents',
      filterLabelText:'Search by Display Name, Account Number, E-mail, Username'
    };
    this._uploadService.userIds = this.userIds
    const dialogRef = this.dialog.open(DeliverDocsToUsersComponent, config);
    dialogRef.afterClosed().subscribe(reason => {
      if (reason === "sendDocuments") {
        dialogRef.close();
      }
    })
  }
  public userIds = []

  selectUserForDocDelivery(event) {
    this.userIds = event.data.map(data => data.id);
    this.deliverDocsToMultipleUsers = this.userIds.length === 0 ? false : true;
  }
}
