import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AssignUsersToCompaniesService {
  constructor(
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar,
    private _http: HttpClient
  ) {}

  public getCompanies(personId) {
    return this._http.get(
      `${environment.apiUrl}/person/getCompaniesForPerson/${personId}`
    );
  }

  public submitCompanies(personId: string, payload, allSelected) {
    return this._http.post(
      `${environment.apiUrl}/person/assignPersonToCompany/${personId}`,
      { companyDescription: payload, existInAllCompanies: allSelected }
    );
  }

  public handleSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-success";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }

  public handleError(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-fail";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }
}
