const metadataMap = new WeakMap();

export function getMetadata(classProto: any, metadataKey: symbol, travelProtoChain = false) {
  let metadata, metadataObj;
  if (!travelProtoChain) {
    metadataObj = metadataMap.get(classProto);
    metadata = metadataObj && metadataObj[metadataKey];
  } else {
    metadata = [];
    while (classProto) {
      metadataObj = metadataMap.get(classProto);
      const currentProtoMetadata = metadataObj && metadataObj[metadataKey];
      if (currentProtoMetadata) {
        metadata.push(currentProtoMetadata);
      }
      classProto = Object.getPrototypeOf(classProto);
    }
  }

  return metadata;
}

export function saveMetadata(classProto: any, metadataKey: symbol, value: any) {
  let metadataObj = metadataMap.get(classProto);
  if (!metadataObj) {
    metadataObj = {};
    metadataMap.set(classProto, metadataObj);
  }
  metadataObj[metadataKey] = value;
}
export type TransformFunc<T, O> = (value: T) => O;

export const _modelValueSymbol = Symbol('api-model-value');

export function ModelValue(toField?: string, transformFunc?: TransformFunc<any, any>): any;
export function ModelValue(transformFunc: TransformFunc<any, any>): any;

export function ModelValue(...args: any[]) {
  let toField: string;
  let transformFunc: TransformFunc<any, any>;

  // if first arg is string, then it's the toField param
  if (typeof args[0] === 'string') {
    toField = args[0];
    args.shift();
  }

  if (typeof args[0] === 'function') {
    transformFunc = args[0];
    args.shift();
  }

  return function (target, property) {
    let modelValueMap = getMetadata(target, _modelValueSymbol);
    if (!modelValueMap) {
      modelValueMap = {};
      saveMetadata(target, _modelValueSymbol, modelValueMap);
    }

    modelValueMap[property] = {
      toField,
      transformFunc,
    };
  };
}
