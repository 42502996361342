import { Routes } from '@angular/router';

import { UserListComponent } from './components/user-list/user-list.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { UserAccountDetailsComponent } from './components/user-account-details/user-account-details.component';
// import { DeletedListComponent } from './components/deleted-list/deleted-list.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';
import { RoleGuard } from './role.guard';

export const usersRoutes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [RoleGuard]
  },
  // {
  //   path: 'users/deleted',
  //   component: DeletedListComponent,
  // },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    children: [
      {
        path: 'information',
        component: UserInformationComponent,
      },
      {
        path: 'account-data',
        component: UserAccountDetailsComponent,
      },
      {
        path: 'user-activity',
        component: UserActivityLogComponent,
      }
    ]
  },
];
