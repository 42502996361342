<div *ngIf="displayLoadingIndicator" class="loading-container-temporary">
  <mat-progress-spinner class="loading-indicator" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="top-menu-wrap">
  <sv-top-menu [totalItems]="totalItems"></sv-top-menu>
</div>
<div>
  <sv-user-search-display [totalItems]="totalItems" (filterValuesChange)="filterValues = $event; getUsersData(true)"
    [filterValues]="filterValues" [searchType]="searchStoreType"></sv-user-search-display>
</div>
<div id="searchButton" class="search" (click)="openSearchDialog()">
  <mat-icon class="search-icon"  aria-label="">search</mat-icon>
  <span  class="search-label" translate>Search</span>
  <mat-icon  color="accent" class="mat-arrow-drop-down" aria-label="">arrow_drop_down</mat-icon>
</div>
<div class="table-wrap">
  <sv-table [data]="userList" [columns]="userTableColumns" [(page)]="page" [(sort)]="sort"
    (sortChange)="sort=$event; getUsersData(true)" [actions]="actions"
    (handleRowClick)="handleRowClick($event)"
    (actionEvent)="action($event)"
    cursorOnHover="true"></sv-table>
  <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageIndex]="getPageIndex()" (page)="changePage($event)"></mat-paginator>
</div>

<div *ngIf="isLoadingDataPage" class="loading-overlay">
  <mat-progress-spinner class="loading-indicator" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>