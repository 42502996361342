import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PersonRole } from "../../../group/enum/personRole.enum";
import { AuthService } from "../../../auth/services/auth.service";

import { IStaticPage } from "../../../custom-tab/services/custom-tab/custom-tab.service";
import { SidebarService } from "../../services/sidebar-service/sidebar.service";
import { UserPermissionService } from "../../../shared/services/user-permission/user-permission.service";

@Component({
  selector: "sv-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnDestroy {
  @Input() staticPages: IStaticPage[];
  private routerSubscription: Subscription;
  private isSidebarClosedSubSub: Subscription;
  private isSidebarSubmenuClosedSub: Subscription;
  public isSidebarClosed: boolean;
  public isSubmenuClosed: boolean;
  public currentRoute: string;
  public isAdmin: boolean;
  public isAdministrator: boolean;
  public tenant: string;
  @ViewChildren("navigationLinks") navigationLinks: QueryList<ElementRef>;
  constructor(
    public auth: AuthService,
    private _sidebarService: SidebarService,
    private _router: Router,
    private _userPermissionService: UserPermissionService,
    private renderer: Renderer2
  ) {
    this.tenant = JSON.parse(window.localStorage.getItem("tenant"));
    // this.auth.navigationBranding.subscribe(res =>
    // this.auth.getStyles();
    this.isSidebarClosedSubSub =
      this._sidebarService.isSidebarClosed$.subscribe(
        (isSidebarClosed: boolean) => {
          this.isSidebarClosed = isSidebarClosed;
        }
      );
    this.isAdmin = this._userPermissionService.hasRight(PersonRole.admin);
    this.isAdministrator = this._userPermissionService.hasRight(
      PersonRole.administrator
    );
    this.isSidebarSubmenuClosedSub =
      this._sidebarService.isSidebarSubmenuClosed$.subscribe(
        (isSubmenuClosed: boolean) => {
          this.isSubmenuClosed = isSubmenuClosed;
        }
      );
    // disable collapsing of user's submenu if we are on deleted user route or user route.
    this.routerSubscription = this._router.events.subscribe(() => {
      this.currentRoute = this._router.url;

      if (
        this.currentRoute &&
        this.currentRoute.indexOf("/dashboard/users") !== -1
      ) {
        this.toggleSidebarSubmenu(true);
      } else {
        this.toggleSidebarSubmenu(false);
      }
    });
  }

  private toggleSidebarSubmenu(value: boolean) {
    this._sidebarService.changeSidebarSubmenuVisibility(value);
  }

  public openSidebarSubmenu() {
    // this.navigationLinks.toArray().map(link => {
    //   this.renderer.removeClass(link.nativeElement,'active')
    //   this.renderer.removeStyle(link.nativeElement,'border-left')

    // })
    // this.renderer.addClass(this.navigationLinks.toArray()[i].nativeElement,'active')
    // this.renderer.setStyle(this.navigationLinks.toArray()[i].nativeElement,'border-left',`5px solid ${textColor}`)

    this._sidebarService.openSidebar();
    this._sidebarService.openSidebarSubmenu();
  }

  public toggleSidebar() {
    this.isSidebarClosed
      ? this._sidebarService.openSidebar()
      : this._sidebarService.closeSidebar();
  }

  ngOnDestroy() {
    this.isSidebarClosedSubSub.unsubscribe();
    this.isSidebarSubmenuClosedSub.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
