import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AuthService } from '../../auth/services/auth.service';
import { PersonService } from '../person/person.service';
import { Pagination } from '../interfaces/pagination.interface';
import { IPerson } from '../interfaces/person.interface';
import { UploadDialogComponent } from '../../shared/components/upload/upload-dialog.component';
import { UploadService } from '../../shared/components/upload/upload.service';

@Component({
  selector: "app-deliver-docs-to-users",
  templateUrl: "./deliver-docs-to-users.component.html",
  styleUrls: ["./deliver-docs-to-users.component.scss"],
})
export class DeliverDocsToUsersComponent { 
  filterValue = "";
  data:IPerson[] = [];
  pageIndex = 0;
  totalItems = 0;
  public pageSize = 5;
  itemsPerPage = 6;
  public allSelected = false;
  public displaySteps = {
    step1: true,
    step2: false
  }
  filteredItems = [];
  public dialogTitle = ''
  columns = ['Display Name','Account Numbers','E-mail','Username']
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: { ascOrDesc: "asc", field: "name" },
  };
  filterLabelText: any;
  isButtonDisabled = true;
  public toggleAll() {
    this.data = this.data.map((data) => {
      if (
        this.allSelected &&
        this.filteredItems.length &&
        !this.filteredItems.some((item) => item === data)
      ) {
        return data;
      }

      return { ...data, isSelected: this.allSelected };
    });
    this.isButtonDisabled = this.data.filter(users => users.isSelected).length === 0;

    // this._validateSelection();
  }
  constructor(
    private _dialogRef: MatDialogRef<DeliverDocsToUsersComponent>,
    public authService: AuthService,
    private _personService: PersonService,
    private _uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) dialogData,
    public dialog: MatDialog,
  ) {
    this.dialogTitle = dialogData.title;
    this.filterLabelText = dialogData.filterLabelText
    this.paginationAndSortConfig.pageSize = dialogData.usersLength
    this._uploadService.closeDeliverDocsToMultipleUserDialog.subscribe(data => {
      this._dialogRef.close()
      this._uploadService.broadcastDeliverySubject.broadcastDeliveryReceiverCompanies = [];
      this._uploadService.broadcastDeliverySubject.broadcastDeliverySubject = '';
      this._uploadService.broadcastDelivery = false;


    })
    this._personService.getPersons(this.paginationAndSortConfig,dialogData.groupId,{
      accountNumber: "",
      displayName: "",
      email: "",
      userName: "",
      role: null,
    }).subscribe(persons => {
      this.data = persons.content.filter(users => users.active !== 'No' && users.active !== 'Nein')
    })
  }
  public closeDialog() {
    this._dialogRef.close();
  }
  private _validateSelection() {
    const idList = this._selectedData().map((data) => data.id);
    const idListSet = new Set(idList);

    const hasDuplicates = idList.length !== idListSet.size;

  }
  private _selectedData = () => this.data.filter((item) => item.isSelected);
  public handleFiltered = (filteredData) => {
    this.filteredItems = filteredData;
    this.totalItems = filteredData.length;
    this.pageIndex = 0;

    if (this.totalItems / this.pageSize < this.pageIndex) {
      this.pageIndex = 0;
    }
  };
  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }
  public next() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = false;
    config.data = {
      title: 'Upload document for selected users',
      subtitle: '',
      buttonText: 'Upload',
      deliverDocsToMultipleUsers: true,
      isBroadcastDelivery: true
    }
    this._uploadService.userIds = this.data.filter(users => users.isSelected).map(person => person.id)
    // config.minWidth = '980px'
    const dialogRef = this.dialog.open(UploadDialogComponent, config);

  }
  public handleSelected() {
    this.isButtonDisabled = this.data.filter(users => users.isSelected).length  === 0
    this._updateAllSelected();

  }
  private _updateAllSelected(): void {
    if (this.data.length === 0) {
      this.allSelected = false;
    }

    this.allSelected = this.data.every((data) => data.isSelected);
  }

}
