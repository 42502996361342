import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { xUserService } from "../xUserService/xUser.service";

@Injectable({
  providedIn: "root",
})
export class AssignAdminsToCompaniesService {
  constructor(
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar,
    private _xUsersService: xUserService
  ) {}

  public getCompanies(personId) {
    return this._xUsersService.getCompanies(personId);
  }

  public submitCompanies(personId: string, payload) {
    return this._xUsersService.postCompanies(personId, payload);
  }

  public handleSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-success";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }

  public handleError(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-fail";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }
}
