<div>
  <div class="dialog-header">
    <h1 mat-dialog-title>{{title}} </h1>
    <button mat-icon-button mat-dialog-close>
      <mat-icon [matTooltip]="closeButtonLabel" matTooltipPosition="right" aria-label="">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <h4>{{selectedNumber}} {{text | translate}}</h4>
    <div class="confirm-buttons">
      <button mat-button mat-dialog-close color="primary" (click)="close()"><span >{{'NO' | translate}}</span></button>
      <button mat-button mat-dialog-close color="primary" (click)="delete()"><span > {{'YES' | translate}}</span></button>
    </div>
  </div>
</div>