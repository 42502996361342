export enum ActivityLogCategory {
  Notification = 'Notifications',
  DeliveryDeleted = 'Delivery deleted',
  Document = 'Document',
  Login = 'Login',
  Logout = 'Logout',
  Payment = 'Payment',
  Email = 'Email',
  SMS = 'SMS',
  UserAccount = 'UserAccount',
  Delivery = 'Delivery',
  Postbox = 'Inbox',
  Delete = 'Delete',
  Update = 'Update',
}
