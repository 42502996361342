
import { CustomMaintanacePageComponent } from './custom-maintanance-page/custom-maintanance-page.component';
import { BannerComponent } from './shared/banner/banner.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { SupportViewConfig } from "../config/config";
import { AuthModule, authInterceptorProviders } from "./auth/auth.module";
import { DesignModule } from "./design/design.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { ServerStatusModule } from "./server-status/server-status.module";
import { SidebarService } from "./dashboard/services/sidebar-service/sidebar.service";
import { StoreService } from "./shared/services/store/store.service";
import { appRoutes } from "./app.routes";
import { AppComponent } from "./app.component";
import { CustomMatPaginatorIntl } from "./design/classes/custom-mat-paginator-intl";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DictionariesTableComponent } from "./dashboard/dictionaries-table/dictionaries-table.component";
import { UserPermissionService } from "./shared/services/user-permission/user-permission.service";

// AoT requires an exported function for factories
export function PoLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function ConfigFactory() {
  return new SupportViewConfig();
}

@NgModule({
  declarations: [AppComponent, DictionariesTableComponent,CustomMaintanacePageComponent,BannerComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatIconModule,
    RouterModule.forRoot(appRoutes, {}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: PoLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    DashboardModule,
    DesignModule,
    ServerStatusModule,
    BrowserAnimationsModule,
  ],
  providers: [
    SidebarService,
    StoreService,
    UserPermissionService,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: SupportViewConfig, useFactory: ConfigFactory },
    ...authInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
