import { Moment } from 'moment';

import { ApiResource, ApiResourceValue, ApiResourceMap } from '../../shared/classes/api-resource.class';
import { ActivityLogCategory } from '../../activity-log/enums/activity-log-category.enum';

export class UserActivityLogResource extends ApiResource {
  @ApiResourceValue('displayName') public username: string;
  @ApiResourceValue('date') public date: Moment;
  @ApiResourceMap({
    'REMINDER': ActivityLogCategory.Notification,
    'DELIVERY_DELETED': ActivityLogCategory.DeliveryDeleted,
    'DOCUMENT': ActivityLogCategory.Document,
    'AUTHENTICATION': ActivityLogCategory.Login,
    'PAYMENT': ActivityLogCategory.Payment,
    'EMAIL': ActivityLogCategory.Email,
    'INBOX': ActivityLogCategory.Postbox,
    'SMS': ActivityLogCategory.SMS,
    'ACCOUNT_SETTING': ActivityLogCategory.UserAccount,
    'DELIVERY': ActivityLogCategory.Delivery,
    'UPDATE': ActivityLogCategory.Update,
    'DELETE': ActivityLogCategory.Delete,
  }) category: ActivityLogCategory;
  @ApiResourceValue('text') public details: string;
}

