<form (ngSubmit)="onSaveEditAccountData($event)" #accDetailsForm="ngForm" *ngIf="accountDetailsData">
  <div class="e-brief-section">
    <h2 translate>Account</h2>
    <div class="e-brief-row">
      <span>{{ 'Display Name' | translate }}:</span>
      <span>{{ accountDetailsData.displayName }}</span>
    </div>
    <div class="e-brief-row">
      <span>{{ 'Username' | translate }}:</span>
      <span>{{ accountDetailsData.userName }}</span>
    </div>
    <div class="e-brief-row">
      <span>{{ 'Email' | translate }}:</span>
      <span>{{ accountDetailsData.email }}</span>
    </div>
    <div class="e-brief-row">
      <span>{{ 'Phone' | translate }}:</span>
      <span>{{ accountDetailsData.phone }}</span>
    </div>
    <div class="e-brief-row">
      <span>{{ 'Birthday' | translate }}:</span>
      <span>{{ accountDetailsData.birthDay }}</span>
    </div>
  </div>
  <div class="user-controls">
    <button mat-button color="primary" type="button" routerLink="/dashboard/users">{{'Back' | translate}}</button>
    <button mat-button  class="user-controls-save" [disabled]="isSaving || !statusChanged">{{'Save'
      | translate}}</button>
  </div>
</form>
<div class="e-brief-section">
  <h2 translate>Extras</h2>
  <div class="e-brief-row">
    <span>{{ 'Status' | translate }}:</span>
    <div class="change-user-status">
      <button class="mat-menu-button" mat-button [matMenuTriggerFor]="userStatus" [disabled]="disableStatusModifications && !statusChanged"
        *ngIf="accountDetailsData">{{accountDetailsData.status | translate }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #userStatus="matMenu">
        <button *ngFor="let status of statusList" mat-menu-item (click)="setStatus(status.name)">{{status.name |
          translate }} ({{status.description | translate }})</button>
      </mat-menu>
    </div>
  </div>
</div>