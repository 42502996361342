import { Moment } from 'moment';
// @ts-ignore
import * as _keys from 'lodash/keys';

import { ActivityLogCategory } from '../../activity-log/enums/activity-log-category.enum';
import { IActivityLogSelectedCategory } from '../../shared/interfaces/activity-log-selected-category.interface';
import { ApiModel, Serialize, SerializeDate } from '../../shared/classes/api-model.class';

export class AdminActivityLogSearchModel extends ApiModel {
  @Serialize() public searchText: string;
  @SerializeDate({ format: 'YYYY-MM-DDT[00:00:00.000]' }) public startDate: Moment;
  @SerializeDate({ format: 'YYYY-MM-DDT[23:59:59.000]' }) public endDate: Moment;

  public categories: IActivityLogSelectedCategory;

  toSerializableObject() {
    const serializableObject = ApiModel.prototype.toSerializableObject.call(this);
    const categoryMap = {
      [ActivityLogCategory.Login]: 'LOGIN',
      [ActivityLogCategory.Logout]: 'LOGOUT',
    };
    serializableObject.category = _keys(this.categories).filter((cat) => this.categories[cat]).map((cat) => categoryMap[cat]);
    return serializableObject;
  }
}
