// @ts-ignore
import * as _invert from 'lodash/invert';

import { ApiResource } from '../../shared/classes/api-resource.class';
import { ActivityLogCategory } from '../../activity-log/enums/activity-log-category.enum';

export class AdminActivityLogCategoriesResource extends ApiResource {
  public categories: ActivityLogCategory[];

  constructor(_resourceObject: any) {
    super(_resourceObject);

    const enumMap = _invert({
      [ActivityLogCategory.Login]: 'LOGIN',
      [ActivityLogCategory.Logout]: 'LOGOUT',
    });
    this.categories = _resourceObject.categories.map((v) => enumMap[v]);
  }
}
