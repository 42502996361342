import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';

import { CustomTabService, IStaticPage } from '../../services/custom-tab/custom-tab.service';

@Component({
  selector: 'sv-custom-tab',
  templateUrl: './custom-tab.component.html',
  styleUrls: ['./custom-tab.component.scss']
})
export class CustomTabComponent {
  public tabName: string;
  public tabPage: IStaticPage;
  public loading: boolean;

  private loadingTimeout: number = null;

  constructor(public _customTabService: CustomTabService,
    public sanitizer: DomSanitizer,
    public _activatedRoute: ActivatedRoute) {
    _activatedRoute.params.subscribe((params: Params) => {

      if (params && params.tabname) {
        this.tabName = params.tabname;
        this.loading = true;
        this.tabPage = null;
        this.getTabPages();
      }

    });
  }

  private getTabPages() {
    // check is custom tabs exists in sesion storage
    if (!this._customTabService.getStaticPage()) {
      this._customTabService.fetchUserStaticPages().subscribe(() => {
        this.tabPage = this.getTabPage();
      });
    } else {
      this.tabPage = this.getTabPage();
    }
  }

  private getTabPage() {
    const tabPage = this._customTabService.getStaticPageByTabName(this.tabName);

    return {
      ...tabPage,
      // sanitizer is added because of external Iframe implementation.
      externalUrl: <string>this.sanitizer.bypassSecurityTrustResourceUrl(tabPage.externalUrl)
    };
  }

  public onLoad() {

    if (this.loadingTimeout) {
      window.clearTimeout(this.loadingTimeout);
    }

    this.loadingTimeout = window.setTimeout(() => { this.loading = false; }, 1000);
  }
}
