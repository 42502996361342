import { Routes } from '@angular/router';

import { AuthGuard } from '../auth/guards/auth.guard';
import { usersRoutes } from '../users/users.routes';
// import { statisticsRoutes } from '../statistics/statistics.routes';
import { customTabRoutes } from '../custom-tab/custom-tab.routes';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersActivityLogComponent } from '../activity-log/components/users-activity-log/users-activity-log.component';
import { AdminActivityLogComponent } from './components/admin-activity-log/admin-activity-log.component';
import { groupRoutes } from '../group/group.routes';
import { PasswordDataComponent } from '../shared/password-data/password-data.component';
import { FaqComponent } from './components/faq/faq.component';

export const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      ...usersRoutes,
      // Remove "E-Brief statistics page" Link
      //  https://dev.sendhybrid.com/jira/browse/SUPNEW-172
      // ...statisticsRoutes,
      ...customTabRoutes,
      ...groupRoutes,
      {
        path: 'activity',
        component: UsersActivityLogComponent
      },
      {
        path: 'admin-log',
        component: AdminActivityLogComponent
      },
      {
        path: 'password',
        component: PasswordDataComponent
      },
      {
        path: 'help',
        component: FaqComponent
      }
    ]
  }
];
