import { ApiModel, Serialize, SerializeEnum } from '../../shared/classes/api-model.class';
import { UserStatus } from '../enums/user-status.enum';

export class UserAccountDataModelResource extends ApiModel {
  @Serialize() public id: string;
  @Serialize() public familyName: string;
  @Serialize() public givenName: string;
  @Serialize() public displayName: string;
  @Serialize() public email: string;
  @Serialize() public accountNumber: string;
  @Serialize() public userName: string;
  @Serialize() public phone: string;
  @Serialize() public optIn: boolean;
  @SerializeEnum({
    [UserStatus.Open]: 'OPEN',
    [UserStatus.NotOpen]: 'NOT_OPEN' ,
    [UserStatus.Closed]: 'CLOSED',
    [UserStatus.Disabled]: 'DISABLED',
    [UserStatus.Unconfirmed]: 'UNCONFIRMED',
    [UserStatus.Inactive]: 'INACTIVE',
  }) public status: UserStatus;
  @Serialize() public corporateCustomer: boolean;
  @Serialize() public employee: boolean;
  @Serialize() public personalBranding: string;
}
