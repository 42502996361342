export enum PersonRole {
  // datapart
  user = "BERT_USER", //student
  supporter = "BERT_GROUP_SUPPORT", // administrator
  bertSupporter = "BERT_SUPPORTER",
  instructor = "BERT_GROUP_PRINCIPAL", // user
  // payslip
  admin = "ADMIN",
  administrator = "Administrator", // company admin
  userAdministrator = "UserAdministrator", // customer creator
  deliverySubmitter = "DeliverySubmitter", // data submitter
  entry = "Entry",
  eboxViewer = "EBoxViewer",
}

export enum PersonRoleDatapart {
  user = "BERT_USER", //student
  supporter = "BERT_GROUP_SUPPORT", // administrator
  instructor = "BERT_GROUP_PRINCIPAL", // user
}
