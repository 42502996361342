<div class="sidenav" [ngClass]="{'isSidebarClosed': isSidebarClosed}" >
  <a (click)="toggleSidebar()">
    <mat-icon class="icon-navigate sidebar" >{{isSidebarClosed ? 'navigate_next' : 'navigate_before'}}</mat-icon>
    <span [hidden]="isSidebarClosed" class="triger-navigation"  translate>Close navigation</span>
  </a>
  <div class="side-menu" >
    <ul>
      <!-- *NGIF ADMIN !!!!!!!!! -->
      <!-- <li *ngFor="let nav of (auth.navigationBranding | async) let i = index"  >
        <a [routerLink]="nav.routerLink"
           [routerLinkActiveOptions]="{exact: true}"
           (click)="openSidebarSubmenu(i,nav.textColor)"
           [routerLinkActive]
           #navigationLinks
           >
          <mat-icon class="icon-style" svgIcon=""></mat-icon>
          <span class="user-label-wrap">
            <span [hidden]="isSidebarClosed" class="link" [ngStyle]="{'color':nav.textColor }" translate>{{(nav.text | translate)}}</span>
          </span>
        </a>
      </li> -->
      <li class="user-list-navigation">        
        <a routerLink="/dashboard/users" class="sidenav-link" #usersLink="routerLinkActive" routerLinkActive="active-sidebar"
          [routerLinkActiveOptions]="{exact: true}"
          *ngIf="isAdmin"
          [ngClass]="{'active': usersLink.isActive, 'activeChild': !usersLink.isActive && usersLink.router.url.indexOf('/dashboard/users') !== -1}"
          (click)="openSidebarSubmenu()">
          <mat-icon class="icon-style " [svgIcon]="'custom:account'"></mat-icon>
          <span class="user-label-wrap">
            <span [hidden]="isSidebarClosed" class="link" translate>User</span>
          </span>
        </a>
        <!-- <ul class="submenu" [hidden]="isSubmenuClosed">
          <li>
            <a class="submenu-link" routerLink="users/deleted" routerLinkActive="active"><span
                [hidden]="isSidebarClosed" translate>Deleted User</span></a>
          </li>
        </ul> -->
      </li>
      <li class="group-tab" *ngIf="(auth?.features | async)?.displayGroup">
        <a routerLink="/dashboard/groups" class="sidenav-link" #groupLink="routerLinkActive" routerLinkActive="active-sidebar"
        [routerLinkActiveOptions]="{exact: true}"
        [ngClass]="{'active': groupLink.isActive, 'activeChild': !groupLink.isActive && groupLink.router.url.indexOf('/dashboard/groups') !== -1}"
        (click)="openSidebarSubmenu()">
        <mat-icon class="icon-style"  [svgIcon]="'custom:arrow-accounts'"></mat-icon>

        <span class="user-label-wrap">
          <span [hidden]="isSidebarClosed" class="link" *ngIf="tenant === 'payslip'">{{ 'Companies' | translate}}</span>
          <span [hidden]="isSidebarClosed" class="link" *ngIf="tenant === 'datapart'">{{ 'Groups' | translate}}</span>
        </span>
      </a>
      </li> 
       
      <!-- <li>
        <a routerLink="/dashboard/statistics" routerLinkActive="active">
          <mat-icon class="icon-style" [svgIcon]="'custom:bar_chart'"></mat-icon>
          <span [hidden]="isSidebarClosed" class="link" translate>Statistics</span>
        </a>
      </li> -->
      <li>
        <a routerLink="/dashboard/activity" class="sidenav-link" routerLinkActive="active-sidebar" #activityLink="routerLinkActive" [ngClass]="{'active': activityLink.isActive, 'activeChild': !activityLink.isActive && activityLink.router.url.indexOf('/dashboard/activity') !== -1}">
          <mat-icon class="icon-style">av_timer</mat-icon>
          <span  [hidden]="isSidebarClosed" class="link" translate>Activity Log</span>
        </a>
      </li>
      <li *ngFor="let staticPage of staticPages">
        <a [routerLink]="['customtab', staticPage.tabName]" class="sidenav-link" routerLinkActive="active-sidebar">
          <mat-icon class="icon-style" [svgIcon]="'custom:' + staticPage.iconSrc"></mat-icon>
          <span [hidden]="isSidebarClosed" class="link">{{staticPage.tabName}}</span>
        </a>
      </li>
      <li *ngIf="tenant === 'payslip'">
        <a routerLink="/dashboard/help" class="sidenav-link"  routerLinkActive="active-sidebar">
          <mat-icon class="icon-style"  [svgIcon]="'custom:icon-information'"></mat-icon>
          <span  [hidden]="isSidebarClosed" class="link">{{'Help' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="triger-navigation-bottom">
    <a (click)="toggleSidebar()">
      <mat-icon class="icon-navigate sidebar">{{isSidebarClosed ? 'navigate_next' : 'navigate_before'}}</mat-icon>
      <span [hidden]="isSidebarClosed" class="triger-navigation" translate>Close navigation</span>
    </a>
  </div>
</div>