
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ApiError } from '../../shared/classes/errors/api-error.class';

const ignoreEndpoints = [
  `${environment.apiUrl}/login/user`,
  `${environment.apiUrl}/login/logout`
];

@Injectable()
export class HandleApiErrorInterceptor implements HttpInterceptor {

  constructor(

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // First we check url path, if it is '/login/user' or 'login/logout' then do not handle api error intercept.
    // if we catch error on request then navigate to error page with errorCode that we take from request's error status.
    if (ignoreEndpoints.indexOf(request.url) === -1) {
      return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
        if (error) {
          // this._router.navigate(['error', { errorCode: error.status.toString() }]);

        }
        return observableThrowError(new ApiError(
          request.url,
          error.status.toString(),
          error.error.message
        ));
      }));
    }
    return next.handle(request);
  }
}
