import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'sv-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  @Input() totalItems: string;
  @Input() totalLabel: string;
  public lastLogin: string;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.lastLogin = moment(this.auth.getUserData().lastLogin).format('DD.MM.YYYY, HH:mm:ss');
  }
}
