import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';

const ignoreEndpoints = [
  `${environment.apiUrl}/login/logout`,
];

@Injectable()
export class AutoLogoutInterceptor implements HttpInterceptor {
  constructor(
    private _auth: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // First we check url path, if it is 'login/logout' then do not apply resetAutoLogoutTimer method.
    if (ignoreEndpoints.indexOf(request.url) === -1) {
      this._auth.resetAutoLogoutTimer();
    }
    return next.handle(request);
  }
}
