import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "../../auth/services/auth.service";
import { GroupService } from "../group.service";
import { IEditGroupDatapart } from "../interfaces/group.interface";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { PersonRole } from "../enum/personRole.enum";

@Component({
  selector: "sv-create-edit-group-datapart",
  templateUrl: "./create-edit-group-datapart.component.html",
  styleUrls: ["./create-edit-group-datapart.component.scss"],
})
export class CreateEditGroupComponentDatapart implements OnInit, AfterViewInit {
  public createOrEditForm: UntypedFormGroup;
  public groupId: string;
  public externalid: string;
  public title: string;
  public isLoading: boolean;
  @ViewChild("field1") myFormField: ElementRef;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateEditGroupComponentDatapart>,
    public authService: AuthService,
    private groupService: GroupService,
    private renderer: Renderer2,
    private _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.createOrEditForm = new UntypedFormGroup({
      name: new UntypedFormControl("", Validators.required),
      description: new UntypedFormControl("", Validators.required),
      externalId: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]{5}$/),
        ])
      ),
    });
    this.groupService.editGroup.subscribe((data: IEditGroupDatapart) => {
      this.createOrEditForm.controls.name.setValue(data.name);
      this.createOrEditForm.controls.description.setValue(data.description);
      this.createOrEditForm.controls.externalId.setValue(data.externalId);
      this.groupId = data.groupId;
      this.externalid = data.externalId;
      this.createOrEditForm.controls.externalId.disable({ onlySelf: true });
      // localStorage.getItem(userDataStorageKey)
      if (this._userPermissionService.hasRight(PersonRole.bertSupporter)) {
        this.createOrEditForm.controls.name.disable({ onlySelf: true });
        this.createOrEditForm.controls.externalId.disable({ onlySelf: true });
      }
    });
  }
  ngAfterViewInit(): void {
    // this.renderer.setStyle(document.querySelector('.mat-form-field-label'),'color','red')
  }

  ngOnInit(): void {}
  public submit(): void {
    this.groupService.filterValues = {
      accountNumber: "",
      displayName: "",
      groupDescription: "",
    };
    this.isLoading = true;
    this.groupService
      .createOrEditGroup(
        this.createOrEditForm.value,
        this.groupId,
        this.externalid,
        false
      )
      .subscribe(
        () => {
          this.isLoading = false;
          if (this.groupId && this.externalid) {
            this.groupService.statusMessage(
              "snack-bar-success",
              "Group edited"
            );
            this.dialog.closeAll();
            return;
          }
          this.dialog.closeAll();
          this.groupService.statusMessage("snack-bar-success", "Group created");
        },
        (error) => {
          if (error.errorMessage === "Group already exists") {
            this.createOrEditForm.controls.externalId.setErrors({
              notUnique: true,
            });
          }
          this.isLoading = false;
          // this.dialog.closeAll();
        }
      );
  }
  public closeDialog(): void {
    this.dialogRef.close("close");
  }
}
