import { Moment } from 'moment';

import { ApiResource, ApiResourceValue } from '../../shared/classes/api-resource.class';

export class AdminActivityLogResource extends ApiResource {
  @ApiResourceValue('username') public username: string;
  @ApiResourceValue('logDate') public logDate: Moment;
  @ApiResourceValue('category') public category: string;
  @ApiResourceValue('details') public details: string;
}
