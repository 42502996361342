import { ApiResource, ApiResourceValue } from '../../shared/classes/api-resource.class';

export class StatisticsEBriefApiResources extends ApiResource {
  // Statistics for
  @ApiResourceValue('statisticsOn') public statisticsOn: string;
  @ApiResourceValue('usersInSystem') public eBriefUsersInSystem: number;
  // Recieve ready users(activated users)
  @ApiResourceValue('countActiveActivatedUsers') public activatedUsers: number;
  // User who set E-Brief to active
  @ApiResourceValue('countUsersEBriefServiceOn') public userThatSetEBriefToActive: number;
  // Users who confirmed their main address
  @ApiResourceValue('countUsersWithConfirmedPrimaryAddress') public usersWithConfirmedMainAddress: number;
  // Activation letter sent
  @ApiResourceValue('countActivationLetters') public activationLettersSent: number;
  // Users who at least want password security
  @ApiResourceValue('countUsersWithSecClass1') public usersWhoAtLeastWantPasswordSecurity: number;
  // Added by PIN campaign
  @ApiResourceValue('countAddedBecauseOfPinCampagn') public addedByPINCampaign: number;

  // One address

  // Users with one address
  @ApiResourceValue('countUsersWithAtLeastOneAddresses') public usersWithOneAddress: number;
  // Users with 1st address confirmed as main address
  @ApiResourceValue('countUsersWithConfirmedPrimaryAddress') public usersWithFirstAddressAsMainAddress: number;
  // From which address confirmation is pending
  @ApiResourceValue('countUsersWithAtLeastOneAddressesAndUnconfirmed') public fromWhichAddressConfirmationIsPending: number;

  // Two addresses

  // Users with 2 addresses
  @ApiResourceValue('countUsersWithAtLeastTwoAddresses') public usersWithTwoAddresses: number;
  // Users with 2nd address confirmed as main address
  @ApiResourceValue('countUsersWithAtLeastTwoAddressesAndPrimaryIsOn') public usersWithSecondAddressConfirmedAsMainAddress: number;
  // From which address confirmation is pending
  @ApiResourceValue('countUsersWithAtLeastTwoAddressesAndUnconfirmed') public fromWhichAddressConfirmationIsPending2: number;

  // Three addresses

  // Users with 3 addresses
  @ApiResourceValue('countUsersWithAtLeastThreeAddresses') public usersWithThreeAddresses: number;
  // Users with 3rd address confirmed as main address
  @ApiResourceValue('countUsersWithAtLeastThreeAddressesAndPrimaryIsOn') public usersWithThirdAddressConfirmedAsMainAddress: number;
  // From which address confirmation is pending
  @ApiResourceValue('countUsersWithAtLeastThreeAddressesAndUnconfirmed') public fromWhichAddressConfirmationIsPending3: number;

  // De-/Inactive

  // Holiday mode (de-activated but receive-ready)
  @ApiResourceValue('countActiveDeactivatedUsers') public deActivatedButReceiveReady: number;
  // Users who set E-Brief to inactive
  @ApiResourceValue('countUsersEBriefServiceOff') public usersWhoSetEBriefToInactive: number;
  // Users who have none or none confirmed addresses
  @ApiResourceValue('countUsersWithoutConfirmedAddress') public usersWithNonConfirmedAddress: number;
  // Deleted users
  @ApiResourceValue('countDeletedEBriefUsers') public deletedUsers: number;
}
