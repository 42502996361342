<div *ngIf="isLoadingDataPage" class="loading-overlay">
  <mat-progress-spinner class="loading-indicator" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="group-person-container">
  <div class="group-person-container__header">
    <h1 *ngIf="tenant === 'payslip'">{{ "Companies" | translate }}</h1>
    <h1 *ngIf="tenant === 'datapart'">{{ "Groups" | translate }}</h1>
    <div class="button-wraper">
      <button class="upload__document" [ngStyle]="{
          'background-color': 'white',
          color: (authService.backgroundColors | async)?.primaryButtonColor
        }" *ngIf="
          deliverySubmiter && (authService.features | async)?.uploadDocuments
        " mat-button mat-raised-button (click)="openUploadDialog()">
        <span class="upload__document__text">{{
          "Upload Document" | translate
          }}</span>
        <mat-icon [svgIcon]="'custom:post-icon-upload'"></mat-icon>
      </button>
      <button class="upload__document" [ngStyle]="{
        'background-color': 'white',
        color: (authService.backgroundColors | async)?.primaryButtonColor
      }"
        *ngIf="(hasMembersIds$ | async) && deliverySubmiter && (authService.features | async)?.uploadDocuments"
        mat-button mat-raised-button (click)="openUploadDialog(true)">
        <span class="upload__document__text">{{
          "Mass Upload" | translate
          }}</span>
        <mat-icon [svgIcon]="'custom:post-icon-upload'"></mat-icon>
      </button>


      <button class="export-csv-bttn" color="primary" mat-button mat-raised-button
        *ngIf="isAdmin && (authService.features | async)?.exportCsvUsers" (click)="openExportCsvDialog()">
        {{ "Export CSV" | translate }}
      </button>
      <button color="primary" *ngIf="isAdmin && tenant === 'datapart'" mat-button mat-raised-button
        (click)="createGroup()">
        {{ "Create Group" | translate }}
      </button>
      <button color="primary" *ngIf="isAdmin && tenant === 'payslip'" mat-button mat-raised-button
        (click)="createGroup()">
        {{ "Create Company" | translate }}
      </button>
      <div id="searchButton" class="search" (click)="openSearchGroupDialog()">
        <mat-icon class="search-icon" aria-label="">search</mat-icon>
        <span class="search-label" translate>Search</span>
        <mat-icon class="mat-arrow-drop-down" aria-label="">arrow_drop_down</mat-icon>
      </div>
    </div>
  </div>
  <sv-user-search-display [totalItems]="(groups$ | async)?.content?.length" [filterValues]="_groupService.filterValues"
    (filterValuesChange)="filterValueChange($event)"></sv-user-search-display>
  <sv-table [ngClass]="authService.tenant | async" [data]="(groups$ | async)?.content" [columns]="groupTableColumns"
    [page]="(groups$ | async)?.number" [(selected)]="selectedRows" cursorOnHover="true" [actions]="actions"
    (actionEvent)="action($event)" [(sort)]="sort" (sortChange)="sortEvent($event)"
    (handleRowClick)="handleRowClick($event)"></sv-table>
  <mat-paginator [length]="(groups$ | async)?.totalElements" [pageSize]="pageSize"
    [pageIndex]="(groups$ | async)?.number" (page)="changePage($event)"></mat-paginator>
</div>