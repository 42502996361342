<div class="create_edit_group_dialog_content">
  <div class="create_edit_group_dialog_content__header">
    <h1 mat-dialog-title>{{ title | translate }}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>
  <div mat-dialog-content class="create_edit_group_dialog__form">
    <form [formGroup]="createOrEditForm" (ngSubmit)="submit()">
      <div class="logo-field">
        <mat-label>{{ 'Company logo' | translate }}</mat-label>
        <div class="company-logo" (click)="openImageDialog($event)">
          <img [src]="selectedLogo" alt="" class="selected-logo">
          <mat-icon class="icon-upload" [svgIcon]="'custom:post-icon-upload'"
            [style.backgroundImage]="selectedLogo"></mat-icon>
        </div>

      </div>
      <div>
        <button type="button" (click)="openPrefixesDialog($event)" class="prefix-bttn" color="primary" mat-button
          mat-raised-button>{{'Company Prefixes' | translate}}</button>
        <div *ngIf="!groupService.temporarilySavedCompanyPrefixes" class="error-message">
          <div *ngIf="!groupService.temporarilySavedCompanyPrefixes">
            {{'Required' | translate}}
          </div>
        </div>
      </div>
      <mat-form-field color="primary">
        <mat-label>{{'Display Name' | translate}} </mat-label>
        <input matInput autocomplete="off" formControlName="name">
        <mat-error
          *ngIf="createOrEditForm.controls.name.touched && createOrEditForm.controls.name.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'Description'| translate}} </mat-label>
        <input matInput autocomplete="off" formControlName="description">
        <mat-error
          *ngIf="createOrEditForm.controls.description.touched && createOrEditForm.controls.description.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email *</mat-label>
        <input matInput autocomplete="off" formControlName="email">
        <mat-error
          *ngIf="createOrEditForm.controls.email.touched && createOrEditForm.controls.email.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
        <mat-error *ngIf="createOrEditForm.controls.email.invalid">
          {{'email-format' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [ngClass]="{ 'hidden': !isSuperAdmin }">
        <mat-label>
          {{ 'SAP Customer' | translate }}
        </mat-label>
        <input matInput autocomplete="off" formControlName="sapCustomerNumber">
        <mat-error
          *ngIf="createOrEditForm.controls.sapCustomerNumber.touched && createOrEditForm.controls.sapCustomerNumber.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary" [ngClass]="{ 'hidden': !isSuperAdmin }">
        <mat-label>{{'Material Number' | translate}} </mat-label>
        <mat-select formControlName="materialNumber">
          <mat-option *ngFor="let number of materialNumbers" [value]="number">
            {{ number }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="createOrEditForm.controls.materialNumber.touched && createOrEditForm.controls.materialNumber.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary" [ngClass]="{ 'hidden': !isSuperAdmin }">
        <mat-label>{{'Accounting Model' | translate}} </mat-label>
        <mat-select formControlName="accountingModel">
          <mat-option *ngFor="let accountingModel of accountingModels" [value]="accountingModel">
            {{ accountingModel }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="createOrEditForm.controls.accountingModel.touched && createOrEditForm.controls.accountingModel.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{'Street' | translate}}
        </mat-label>
        <input matInput autocomplete="off" formControlName="streetName">
        <mat-error
          *ngIf="createOrEditForm.controls.streetName.touched && createOrEditForm.controls.streetName.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{'Door Number' | translate}}
        </mat-label>
        <input matInput autocomplete="off" formControlName="doorNumber">
        <mat-error
          *ngIf="createOrEditForm.controls.doorNumber.touched && createOrEditForm.controls.doorNumber.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{'ZIP' | translate}}
        </mat-label>
        <input matInput autocomplete="off" formControlName="zipCode">
        <mat-error
          *ngIf="createOrEditForm.controls.zipCode.touched && createOrEditForm.controls.zipCode.hasError('invalidZip')">
          {{'Invalid format' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{'Town' | translate}}
        </mat-label>
        <input matInput autocomplete="off" formControlName="city">
        <mat-error
          *ngIf="createOrEditForm.controls.city.touched && createOrEditForm.controls.city.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Country' | translate}}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of allCountries" [value]="country.alpha2"
            [ngStyle]="{ 'border-bottom': country.alpha2 === 'ch' && '2px solid #E0E0E0' }">
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="createOrEditForm.controls.country.touched && createOrEditForm.controls.country.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <div *ngIf="isSuperAdmin" class="checkbox-wrapper">
        <input type="checkbox" id="eGehaltszettelEnabled" formControlName="eGehaltszettelEnabled">
        <label for="eGehaltszettelEnabled">{{"Company is active" | translate}}</label>
      </div>
      <div *ngIf="isSuperAdmin" class="checkbox-wrapper">
        <input type="checkbox" id="sms" formControlName="smsNotificationsEnabled" placeholder="SMS">
        <label for="sms">{{"SMS" | translate}}</label>
      </div>
      <div *ngIf="isSuperAdmin" class="checkbox-wrapper">
        <input type="checkbox" id="secureCode" formControlName="secureCode" placeholder="Secure Code">
        <label for="secureCode">{{'Secure Code' | translate}}</label>
      </div>
      <div class="einfach-brief-group" *ngIf="isSuperAdmin">
        <div class="checkbox-wrapper">
          <input type="checkbox" id="einfachBriefEnabled" formControlName="einfachBriefEnabled">
          <label for="einfachBriefEnabled">{{'Einfach Brief Enabled' | translate}}</label>
        </div>
        <mat-form-field *ngIf="createOrEditForm.controls.einfachBriefEnabled.value">
          <mat-label>
            {{ 'Post SAP Number' | translate }}
          </mat-label>
          <input matInput autocomplete="off" formControlName="postSapNumber">
          <mat-error
            *ngIf="createOrEditForm.controls.postSapNumber.touched && createOrEditForm.controls.postSapNumber.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error
          *ngIf="createOrEditForm.controls.postSapNumber.touched && createOrEditForm.controls.postSapNumber.errors?.tenDigits">
          {{'Post SAP Number must have 10 numbers' | translate}}
        </mat-error>
        </mat-form-field>
      </div>

      <button [disabled]="createOrEditForm.invalid" color="primary" type="submit" mat-button
        mat-raised-button>{{'Submit' | translate}}</button>
    </form>
  </div>
</div>

<div class="loading-container" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>