import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../auth/services/auth.service";
import { PersonRole } from "../enum/personRole.enum";
import { PersonService } from "../person/person.service";
import {
  AssignAdminToCompaniesResponse,
  CompanyDescription,
} from "../xUserService/xUser.service";
import { AssignAdminsToCompaniesService } from "./assign-admins-to-companies.service";
interface Config {
  title: string;
  inputPlaceHolder: string;
  personId: string;
  billersInvoiceRecipientID: string;
}
interface StepState {
  stepOne: boolean;
  stepTwo: boolean;
}
@Component({
  selector: "sv-assign-admins-to-companies",
  templateUrl: "./assign-admins-to-companies.component.html",
  styleUrls: ["./assign-admins-to-companies.component.scss"],
})
export class AssignAdminsToCompaniesComponent implements OnInit {
  public config: Config = {
    title: "",
    inputPlaceHolder: "",
    personId: "",
    billersInvoiceRecipientID: "",
  };
  public steps: StepState = {
    stepOne: true,
    stepTwo: false,
  };

  public userChange = false;
  @ViewChildren("billersInvoiceRecipientID")
  billersInvoiceRecipientID: QueryList<ElementRef>;
  columns = ["Company UID", "Company Name", "Status"];
  data = [];
  selection = new Map();
  filterValue = "";
  pageIndex = 0;
  totalItems = 0;
  itemsPerPage = 6;
  activeOnLoad = [];
  public pagedData = [];
  public filteredData = [];
  public pageSize = 5;
  public selectedData = [];
  public allSelected: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayNextButton: any;
  public companyPrefix;
  private rightsMap;

  constructor(
    private _dialogRef: MatDialogRef<AssignAdminsToCompaniesComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data: Config,
    private _service: AssignAdminsToCompaniesService,
    private userService: PersonService,
    private translateService: TranslateService
  ) {
    this.config = data;
    this.rightsMap = {
      [PersonRole.administrator]:
        this.translateService.instant("Company Admin"),
      [PersonRole.userAdministrator]:
        this.translateService.instant("Customer Creator"),
      [PersonRole.deliverySubmitter]:
        this.translateService.instant("Data Submitter"),
      [PersonRole.eboxViewer]: this.translateService.instant("EBoxViewer"),
      [PersonRole.entry]: "Entry",
    };

    this._getCompanies();
  }

  ngOnInit(): void {}

  public isPreselected(row): boolean {
    return this.selectedData.includes(row.column1);
  }

  public toggleSelection(row) {
    let index = this.selectedData.findIndex(
      (item) => row.companyId == item.companyId
    );
    if (index > -1) {
      this.selectedData.splice(index, 1);
    } else {
      this.selectedData = [{ ...row }, ...this.selectedData];
    }
    this.updateNextButtonDisplay();
  }

  private updateNextButtonDisplay(): void {
    this.displayNextButton = !this._wereCompaniesOnlyRemoved();
  }

  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.paginateData();
  }
  public paginateData() {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    this.pagedData = this.filteredData.slice(start, end);
  }

  public submit() {
    const payload = {
      companyDescription: this.data.map((data) => ({
        ...data,
        companyStatus: data.column3 === "Aktiv",
      })),
      existInAllCompanies: this.allSelected,
    };

    this._service.submitCompanies(this.config.personId, payload).subscribe(
      (res) => {
        this._service.handleSuccess(
          "User assignment for company administration was successfully taken over"
        );
        this.userService.triggerDataReaload.next(true);
        this.closeDialog();
      },
      (err) => {
        this._service.handleError(err.errorMessage);
      }
    );
  }
  public closeDialog() {
    this._dialogRef.close();
  }

  public handleFiltered = (filteredData) => {
    this.pageIndex = 0;
    this.totalItems = filteredData.length;
    this.filteredData = filteredData;
  };

  private _getCompanies() {
    this._service
      .getCompanies(this.config.personId)
      .subscribe((data: AssignAdminToCompaniesResponse) => {
        this.data = data.companyDescription
          .map((data) => ({
            ...data,
            companyStatus: data.companyStatus
              ? this.translateService.instant("Active")
              : this.translateService.instant("Deactivated"),
          }))
          .sort((a, b) => (b.assigned ? 1 : -1) - (a.assigned ? 1 : -1));

        const assigned = this.data.filter((selected) => selected.assigned);
        this.activeOnLoad = assigned.map(
          (company: CompanyDescription) => company.companyId
        );

        this.selectedData = [...assigned];
        this.allSelected = data.existInAllCompanies;
        this.totalItems = this.data.length;
      });
  }

  // Check if companies were only removed
  private _wereCompaniesOnlyRemoved(): boolean {
    const initialSet = new Set(this.activeOnLoad.map((item) => item));
    const updatedSet = new Set(this.selectedData.map((item) => item.companyId));

    const onlyInFirst = Array.from(initialSet).filter(
      (item) => !updatedSet.has(item)
    );
    const onlyInSecond = Array.from(updatedSet).filter(
      (item) => !initialSet.has(item)
    );

    return onlyInFirst.length > 0 && !(onlyInSecond.length > 0);
  }

  private _isCompanyAdded(companyId: string): boolean {
    return !this.activeOnLoad.includes(companyId);
  }

  public setStep() {
    this.steps.stepOne = false;
    this.steps.stepTwo = true;
    this.selectedData = this.selectedData.filter(
      (data) =>
        data.billersInvoiceRecipientID === null && data.assigned === true
    );
    this.pageIndex = 0;
    this.updateNextButtonDisplay();
  }
}
