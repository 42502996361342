
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// @ts-ignore
import * as _find from 'lodash/find';

import { environment } from '../../../../environments/environment';

export enum StaticPageIconType {
  AT = 'at',
  CLOUD = 'cloud',
  DATABASE = 'database',
  EMAIL = 'email',
  FILEDOUMENT = 'file-document',
  LIFEBUOY = 'lifebuoy',
  OFFICEBUILDINGS = 'office-buildings',
  STAR = 'star',
  WEB = 'web'
}

export interface IStaticPage {
  tabName: string;
  externalUrl: string;
  iconSrc?: string;
  icon: StaticPageIconType;
}

@Injectable()
export class CustomTabService {
  public staticPages: IStaticPage[];

  constructor(private _http: HttpClient) { }

  fetchUserStaticPages() {
    return this._http.get(`${environment.apiUrl}/generalInfo/tab`).pipe(map((response: IStaticPage[]) => {
      this.saveStaticPages(response);
      return response;
    }));
  }

  saveStaticPages(staticPages: IStaticPage[]) {
    sessionStorage.setItem('custom-tab', JSON.stringify(staticPages));
  }

  getStaticPage(): IStaticPage[] {
    return JSON.parse(window.sessionStorage.getItem('custom-tab'));
  }

  getStaticPageIconByType(iconType) {
    return StaticPageIconType[iconType];
  }

  getStaticPageByTabName(tabName: string): IStaticPage {
    this.staticPages = this.getStaticPage();
    const staticPage: IStaticPage | any = _find(this.staticPages, (o: IStaticPage) => {
      if (o.tabName === tabName) {
        return o;
      }
    });

    if (typeof(staticPage) === 'object') {
      return staticPage;
    }

    return null;
  }
}
