import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DictionaryPDFService {
  public currentCount = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private _translateService: TranslateService,
    private _snackbar: MatSnackBar
  ) {}

  public getPdfDictionaries() {
    return this.http.get<string[]>(`${environment.apiUrl}/group/dictionary`);
  }

  public assignPDFDictionary(companyName: string, payload) {
    return this.http.put(
      `${environment.apiUrl}/group/assignDictionary/${companyName}`,
      payload
    );
  }
  public getSelectedPDFDictionaryForCompany(companyName) {
    return this.http.get<string[]>(
      `${environment.apiUrl}/group/dictionary/tenant/${companyName}`
    );
  }

  public getSelectedPDFDictionariesForUser() {
    return this.http.get<string[]>(
      `${environment.apiUrl}/group/dictionary/tenant`
    );
  }

  public handleSuccess(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = "snack-bar-success";
    let messageSnackBar = this._translateService.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);
  }
}
