<div class="dialog-container">
  <div class="dialog-header">
    <h2 class="dialog-title">{{data?.title | translate}}</h2>
    <span class="close">
      <mat-icon [svgIcon]="'custom:close'" (click)="closeDialog()"></mat-icon>
    </span>
    <h3 class="dialog-subtitle"> {{data?.subtitle | translate }}
    </h3>
  </div>
  <div class="pre-upload" *ngIf="viewService.preUpload">
    <ng-container *ngFor="let document of preUploadDocuments;let i = index">
      <div class="list-item" style="margin-top: 10px;">
        <div class="list-item-header">
          <div class="list-item-name">
            {{document.name}}
          </div>
          <div class="list-item-status">
            <span style="color: red; margin-right: 10px;" *ngIf="!isBroadcastDelivery && document.unsupportedMediaType">
              {{'The file format is not supported' | translate}}<br />
            </span>
            <span style="color: red; margin-right: 10px;" *ngIf="isBroadcastDelivery && document.unsupportedMediaType">
              {{'Only pdf files are supported' | translate}}<br />
            </span>
          </div>
          <div class="list-item-actions" style="cursor:pointer;">
            <mat-icon [svgIcon]="'custom:close'" (click)="onDeletePreUpload(i)"></mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <span style="color: red; margin-right: 10px; text-align: center;" *ngIf="isBroadcastDelivery && moreThenOneDocument">
    {{'Only one document is allowed to be uploaded' | translate}}<br />
  </span>

  <div class="documents-upload" #document
    [ngStyle]="{'height':uploadService.documents.length > 5  ? '300px' : '100%','overflow-y': uploadService.documents.length > 5 ? 'scroll' : 'hidden'}">
    <div class="dialog-list" *ngIf="uploadService.documents">
      <div class="folders-list">
        <mat-list-item *ngFor="let document of uploadService.documents; let i = index">
          <div class="list-item">
            <div class="list-item-header">
              <div class="list-item-name">
                {{document.fileName}}
              </div>
              <div class="list-item-status">
                <span *ngIf="!document.finishedUpload &&
                    !document.hasVirus &&
                    !document.unsupportedMediaType &&
                    !document.pending &&
                    !document.isBeingProcessed
                    ">{{'Loading document' | translate}}...</span>
                <!-- Temporary loading container -->
                <div class="loading-container-temporary" *ngIf="!uploadService.isProcessingOver">
                  <p>{{ 'Please be patient' | translate}}</p>
                  <div class="loading-spinner">
                    <mat-progress-spinner color="secondary" mode="indeterminate">
                    </mat-progress-spinner>
                  </div>
                </div>

                <span class="success" *ngIf="document.finishedUpload &&
                    !document.hasVirus &&
                    !document.unsupportedMediaType &&
                    !document.isInvalidDictionary &&
                    !document.isXMLTemplateInvalid &&
                    !document.isInvalidHashValue &&
                    !document.isBeingProcessed &&
                    !document.invalidZipFile &&
                    !document.isXMLTemplateInvalidForTenant &&
                    !document.invalidMassUpload &&
                    !document.serviceUnavailable &&
                    !document.isInvalidFileType &&
                    !document.isWarnig &&
                    !document.uploadProhibited 
                    ">{{'Document successfully uploaded' | translate}}&nbsp;
                </span>
                <span class="warning" *ngIf="document.isWarnig && !document.invalidZipFile">
                  {{'File contain non valid invoices' | translate}}<br />
                </span>

                <span class="danger" *ngIf="document.uploadProhibited">
                  {{'Uploaded document prohibited as it belongs to other company' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.hasVirus">
                  {{'Virus found. Document rejected' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.isInvalidDictionary">
                  {{'Meta data could not be extracted' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.serviceUnavailable">
                  {{'A problem occurred during processing. Please try again later' | translate}}.<br />
                </span>
                <span class="danger" *ngIf="document.isXMLTemplateInvalid">
                  {{'XML template not valid' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.unsupportedMediaType">
                  {{'The file format is not supported' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.invalidMassUpload">
                  {{'Personalized documents (metadata/serviceline) cannot be upload here' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.isXMLTemplateInvalidForTenant">
                  {{'XML template not supported for current tenant' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.invalidZipFile">
                  {{'ZIP file is not valid' | translate}}<br />
                </span>
                <span *ngIf="document.isBeingProcessed">
                  {{'Document is processing' | translate}}<br />
                </span>
                <span class="danger" *ngIf="document.isInvalidHashValue">
                  {{'Hash verification failed! Please check the document or hash' | translate}}
                </span>
                <span class="danger" *ngIf="document.isInvalidFileType">
                  {{'The file format is not supported' | translate}}
                </span>
              </div>
              <div class="list-item-size">
                <span>
                  <span>{{document.uploadedBytes | displayDataSize}}</span>
                  <span>{{'of' | translate}}</span>
                  <span>{{document.fileSize | displayDataSize}}</span>
                </span>
              </div>
              <div class="list-item-control">
                <mat-icon *ngIf="document.finishedUpload && !document.isBeingProcessed" [svgIcon]="'custom:trash'"
                  (click)="onDelete(document)">
                </mat-icon>
                <mat-icon *ngIf="!document.finishedUpload && document.isBeingProcessed" [svgIcon]="'custom:close'"
                  (click)="onDelete(document)">
                </mat-icon>

              </div>
            </div>
            <div class="dialog-list">
              <div class="list-item-progress-bar">
                <mat-progress-bar color="accent" mode="determinate" [value]="document.uploadedPercentage">
                </mat-progress-bar>
              </div>
            </div>
          </div>
          <div class="invalid-zip-files"
            *ngIf="
            ((uploadService.zipContainsErrors && uploadService.zipContainsErrors.length > 0)
             || uploadService.skippedFiles.length > 0) 
             && _uploadUtilityService.IsZipFile(document) 
             && document.zipHasErrors"
            [ngStyle]="{'height': uploadService.zipContainsErrors.length > 5 ? '150px' : '100%','overflow-y' : (uploadService.zipContainsErrors.length > 2 || uploadService.skippedFiles.length > 2 || ((uploadService.skippedFiles.length + uploadService.zipContainsErrors.length) >= 2)) ? 'scroll' : 'hidden'}">

            <div class="invalid-files-list"
              *ngFor="let errorFileInZip of uploadService.zipContainsErrors;let i = index">
              <div class="error-file" *ngIf="errorFileInZip.sourceFile === document.fileName">
                <p>{{errorFileInZip.fileName | slice:0:50}}{{ errorFileInZip.fileName?.length > 20 ? '...' : '' }}</p>
                <p class="error-file-reason">{{errorFileInZip.reason | translate}}</p>
              </div>
            </div>
            <div class="invalid-files-list" *ngFor="let skippedFile of uploadService.skippedFiles;let i = index">
              <div class="error-file" *ngIf="skippedFile.sourceFile === document.fileName">
                <p>{{ skippedFile.fileName | slice:0:50 }}{{ skippedFile.fileName?.length > 20 ? '...' : '' }}</p>
                <p class="error-file-reason">{{skippedFile.reason | translate}}</p>
              </div>
            </div>
          </div>
        </mat-list-item>
      </div>
    </div>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="upload-section" *ngIf="viewService.displayFileUpload">
      <div (drop)="onFilesAdded($event); $event.stopPropagation();" (dragover)="onDragOver($event)">
        <div class="top-left-corner"></div>
        <div class="top-right-corner"></div>
        <div class="bottom-left-corner"></div>
        <div class="bottom-right-corner"></div>
        <form>
          <input id="input-file" #fileInput style="border:none" name="files" type="file" (change)="onFilesAdded($event)"
            [multiple]="!isBroadcastDelivery" title="" />
        </form>
        <span>{{'Drag documents to this area or click here to upload' | translate}}</span>
      </div>
    </div>
    <div>
    </div>

  </div>
  <div class="two-col-grid-controls separated select-input select-input-custom label-pos titles"
    style="margin-top: 20px; margin-left: 24px; margin-right: 24px;" (click)="selectConversionCode()"
    *ngIf="!isBroadcastDelivery && viewService.displayPdfConversionCode">
    <h4>{{'Please choose' | translate}}</h4>
    <mat-form-field appearance="outline">
      <mat-label>PDF Conversion Code</mat-label>
      <mat-select #salutationSelect (selectionChange)="selectConversionCode()" placeholder="PDF Conversion Code*"
        [(value)]="templates.length === 1 && templates[0]">
        <mat-option *ngFor="let template of templates" [value]="template">{{template}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <form *ngIf="isBroadcastDelivery && viewService.displayPdfConversionCode" class="subject" [formGroup]="subjectForm">
    <mat-form-field color="primary" class="subject-mat-field">
      <input matInput formControlName="subject" placeholder="{{ 'Enter the subject' | translate }}*">
    </mat-form-field>

    <mat-error *ngIf="subjectForm.controls.subject.hasError('required')">
      {{ 'Subject field is mandatory' | translate }}
    </mat-error>
    <div class="broadcast-companies">
      <mat-form-field appearance="fill" *ngIf="(isBroadcastDelivery && !deliverDocsToMultipleUsers)">
        <mat-label>{{'Select companies' | translate}}</mat-label>
        <mat-select formControlName="companies" multiple>
          <mat-option *ngFor="let option of companies" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="subjectForm?.controls?.companies?.hasError('required')">
        {{ 'Subject field is mandatory' | translate }}
      </mat-error>

    </div>
  </form>

  <div class="dialog-footer">
    <div class="controls dialog-actions">
      <button type="button" #btn (click)="closeDialog()" [ngStyle]="{
        'color': '#FFF',
        'background-color': '#9a9a9a',
        'pointer-events': 'visible'
      }">
        <div class="title back">
          {{'Cancel' | translate}}
        </div>
        <div class="button__footer">
          <div class="button__subline">

          </div>
          <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
            <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
          </div>
        </div>
      </button>

      <button type="button" #btn (click)="uploadFiles()" *ngIf="viewService.displayFileUpload" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': viewService.hasError || (isBroadcastDelivery && subjectForm?.invalid) || preUploadDocuments.length === 0 ? 'rgba(0,0,0,.12)' : (authService.backgroundColors | async)?.primaryButtonColor,
          'pointer-events': viewService.hasError  ||  (isBroadcastDelivery && subjectForm?.invalid) || preUploadDocuments.length === 0? 'none' : 'visible'

        }">
        <div class="title">
          {{data?.buttonText | translate }}
        </div>
        <div class="button__footer">
          <div class="button__subline">

          </div>
          <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
            <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
          </div>
        </div>
      </button>
      <button type="button" #btn (click)="onSendDocuments()" *ngIf="viewService.displaySendBtn"
        [disabled]="viewService.disablePointerEvent " [ngStyle]="{
        'color': (authService.backgroundColors | async)?.buttonTextColor,
        'background-color': viewService.disablePointerEvent || uploadService.zipContainsErrors.length > 0  || viewService.hasError ? 'rgba(0,0,0,.12)' : (authService.backgroundColors | async)?.primaryButtonColor,
        'pointer-events': viewService.disablePointerEvent || uploadService.zipContainsErrors.length > 0  || viewService.hasError  ? 'none' : 'visible'

      }">
        <div class="title">
          {{'Send' | translate}}
        </div>
        <div class="button__footer">
          <div class="button__subline">

          </div>
          <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
            <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
          </div>
        </div>
      </button>

    </div>
  </div>

</div>