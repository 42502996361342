import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import { environment } from "../../../environments/environment";
import { PersonRole } from "../enum/personRole.enum";
import { GroupService, RESET_FILTER } from "../group.service";
import { IGroup } from "../interfaces/group.interface";
import { PersonService } from "../person/person.service";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";

@Component({
  selector: "sv-create-edit-person",
  templateUrl: "./create-edit-person-datapart.component.html",
  styleUrls: ["./create-edit-person-datapart.component.scss"],
})
export class CreateEditPersonComponentDatapart {
  public cereateEditPersonForm: UntypedFormGroup;
  private accountNumber: string;
  private groupId: string;
  public personId: string;
  public accountType: string;
  public newAccNumber: string;
  public displayGroupsToAddSupporter: boolean;
  public groupsForSupporter = [];
  private matching: IGroup[] = [];
  public isLoading = false;
  public title: string;
  public userSelectedGroup: boolean;
  public defaultDeactivateValue: boolean | string;
  private personObject: any;
  private personData: any;
  public messageActivate = "";
  public messageDeactivate = "";
  groupAccountNumber;
  @ViewChildren("activateOptions") activateOptions: QueryList<MatOption>;

  constructor(
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public notificationService: MultipleNotificationsService,
    private _personService: PersonService,
    private _matDialogRef: MatDialogRef<CreateEditPersonComponentDatapart>,
    private _groupService: GroupService,
    private _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.initialiseForm();
    this.title = data.title;
    this.groupAccountNumber = data.accountNumber;
    this._personService.editPerson.pipe(take(1)).subscribe((editPersonData) => {
      this.accountType = editPersonData.accountType;
      this.personObject = editPersonData.person;
      this.updateFormOnEdit(editPersonData);
      this.groupId = editPersonData.groupId;
      this.personId = editPersonData.id;
      combineLatest([
        this._personService.getGroupsForSupporter(this.personId),
        this._groupService
          .getGroups(
            {
              page: 0,
              pageSize: this._groupService.totalGroupElements,
              sort: { ascOrDesc: "asc", field: "name" },
            },
            RESET_FILTER
          )
          .pipe(map((data) => data.content)),
      ])
        .pipe(
          map((data) => {
            const [supporterInGroups, allGroups] = data;
            this.matching = allGroups.filter(({ id: id1 }) =>
              supporterInGroups.some(({ id: id2 }) => id2 === id1)
            );
            const otherGroups = allGroups.filter((group) =>
              this.matching.every((group2) => group.id !== group2.id)
            );
            return [...this.matching, ...otherGroups];
          })
        )
        .subscribe((groups) => {
          if (
            editPersonData.person.accountType == PersonRole.supporter ||
            editPersonData.person.accountType == PersonRole.instructor
          ) {
            // if ( editPersonData.person.accountType === PersonRole.admin) {

            this.groupsForSupporter = groups;
            /** dynamicly add form controls for supporter and employee*/
            const supporterIsInGroups = this.matching.map((group) => group.id);
            const formControl = new UntypedFormControl(
              supporterIsInGroups,
              Validators.compose([Validators.required])
            );
            this.cereateEditPersonForm.addControl(
              "groupsForSupporter",
              formControl
            );
            this.cereateEditPersonForm.addControl(
              "activate",
              new UntypedFormControl(editPersonData.activate)
            );

            this.defaultDeactivateValue = editPersonData.activate;
            this.messageActivate = this.defaultDeactivateValue
              ? "Activate"
              : "Deactivate";
            this.messageDeactivate = !this.defaultDeactivateValue
              ? "Activate"
              : "Deactivate";
            this.displayGroupsToAddSupporter = true;
            // }
          }
        });
    });
    this._personService.createPerson.subscribe((value) => {
      this.groupId = value.groupId;
      this.accountNumber = value.externalId;
    });
  }

  private initialiseForm() {
    this.cereateEditPersonForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
      lastName: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
      email: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(environment.emailRegex),
        ])
      ),
      prefixedTitle: new UntypedFormControl(null),
      mobileNumber: new UntypedFormControl(
        null,
        Validators.compose([Validators.pattern(/^\+?\d*$/)])
      ),
      salutation: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
      accountType: new UntypedFormControl(null),
      // accountNumber: new FormControl(null)
      // accountNumber: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^\d{6}$/)])),
    });
  }
  private updateFormOnEdit(data): void {
    const accountDataDTO = data.person.accountDataDto;
    this.cereateEditPersonForm.patchValue({
      firstName: accountDataDTO.firstName,
      lastName: accountDataDTO.lastName,
      email: accountDataDTO.email,
      prefixedTitle: accountDataDTO.prefixedTitle,
      mobileNumber: accountDataDTO.mobileNumber,
      salutation: accountDataDTO.salutation,
      accountType: data.person.accountType,
      // accountNumber: data.person.accountNumber,
    });
    this.accountNumber = data.person.accountNumber;
    if (this.accountType === "BERT_USER") {
      this.cereateEditPersonForm.controls.accountType.disable({
        onlySelf: true,
      });
    }
    // this.cereateEditPersonForm.controls.accountNumber.disable();
    // this.cereateEditPersonForm.controls.accountType.disable({ onlySelf: true });
  }

  public supporterInGroups() {}

  public selectRole(role: { source: MatSelect; value: string }): void {
    const isSupporterOrInstructor =
      role.value === PersonRole.supporter ||
      role.value === PersonRole.instructor;
    const canEdit = this.personId;
    const isAdmin = this._userPermissionService.hasRight(PersonRole.admin);
    if (isSupporterOrInstructor && canEdit && isAdmin) {
      this.displayGroupsToAddSupporter = true;
      return;
    }
    this.cereateEditPersonForm.removeControl("groupsForSupporter");
    this.displayGroupsToAddSupporter = false;
  }
  public closeDialog(): void {
    this._matDialogRef.close("close");
  }

  public submit(): void {
    this.isLoading = true;
    if (this.personId) {
      this.personObject.activate = this.cereateEditPersonForm.value.activate;
      this.personObject.accountDataDto.email =
        this.cereateEditPersonForm.value.email;
      this.personObject.accountDataDto.firstName =
        this.cereateEditPersonForm.value.firstName;
      this.personObject.accountDataDto.lastName =
        this.cereateEditPersonForm.value.lastName;
      this.personObject.accountDataDto.mobileNumber =
        this.cereateEditPersonForm.value.mobileNumber;
      this.personObject.accountDataDto.prefixedTitle =
        this.cereateEditPersonForm.value.prefixedTitle;
      this.personObject.accountDataDto.salutation =
        this.cereateEditPersonForm.value.salutation;
      this.personObject.accountType =
        this.cereateEditPersonForm.value.accountType;
      // this.personObject.accountNumber = this.groupAccountNumber;
    }
    const isAdmin = this._userPermissionService.hasRight(PersonRole.admin);

    this.personId
      ? (this.personData = this.personObject)
      : (this.personData = this.cereateEditPersonForm.value);
    this._personService
      .createOrEditPErson(
        this.personData,
        this.groupAccountNumber,
        this.groupId,
        this.personId
      )
      .subscribe(
        () => {
          this._matDialogRef.close("submit");
          const canAddToGroup = this.personId;
          this.notificationService.showNotifications({
            message: this.notificationMessage(),
            isSuccess: true,
          });
          // this.notificationMessage();
          this.isLoading = false;
          if (
            !canAddToGroup &&
            !isAdmin &&
            !this.cereateEditPersonForm.controls.groupsForSupporter
          ) {
            return;
          }

          const groupsToAddSupporter =
            this.cereateEditPersonForm.controls.groupsForSupporter.value;
          this._personService
            .addSupporterToGroup(this.personId, groupsToAddSupporter)
            .subscribe(() => {
              this.notificationService.showNotifications({
                message: this.notificationMessage(),
                isSuccess: true,
              });
              // this.notificationMessage();
            });
        },
        (err) => {
          this.isLoading = false;
          if (err.errorMessage === "Person already exists") {
            this.notificationService.showNotifications({
              message: "Person already exists",
              isSuccess: false,
            });
            this.cereateEditPersonForm.controls.accountNumber.setErrors({
              notUnique: true,
            });
          }
        }
      );
    const isSupporterOrInstructor =
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.supporter ||
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.instructor;
    const isEdit = this.personId;
    if (
      this.defaultDeactivateValue !==
        this.cereateEditPersonForm.value.activate &&
      isSupporterOrInstructor &&
      isEdit
    ) {
      this._personService
        .activateDeactivate(this.groupId, this.personId)
        .subscribe(() => () => {
          this.notificationService.showNotifications({
            message: "Cannot deactivate or activate",
            isSuccess: false,
          });
        });
    }
  }

  public notificationMessage(): string {
    if (
      this.personId &&
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.supporter
    ) {
      return "Administrator edited successfully";
    } else if (
      this.personId &&
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.instructor
    ) {
      return "User edited successfully";
    } else if (
      this.personId &&
      this.cereateEditPersonForm.controls.accountType.value === PersonRole.user
    ) {
      return "Student edited successfully";
    } else if (
      !this.personId &&
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.instructor
    ) {
      return "Added user successfully";
    } else if (
      !this.personId &&
      this.cereateEditPersonForm.controls.accountType.value ===
        PersonRole.supporter
    ) {
      return "Added Administrator successfully";
    } else if (
      !this.personId &&
      this.cereateEditPersonForm.controls.accountType.value === PersonRole.user
    ) {
      return "Added student successfully";
    }
  }
}
