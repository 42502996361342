import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../auth/services/auth.service';
import { Dialog } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MultipleNotificationsService } from '../multiple-notifications/multiple-notifications.service';

@Component({
  selector: 'sv-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  public forgotPasswordText = '';

  email = new UntypedFormControl('', [
    Validators.required,
  ]);

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    private _dialog: Dialog
  ) {
    this.forgotPasswordText = data.text;
  }

  ngOnInit() {
  }

  onFormSubmit() {
    this._authService.requestResetPassword(this.email.value.trim().toLowerCase()).subscribe(data => {
      this._dialog.closeAll();
      this._authService.handleSuccesOrError('snack-bar-success','E-Mail for resetting the password was sent');
    },err => {
      this._dialog.closeAll();
      this._authService.handleSuccesOrError('snack-bar-fail','Restart password failed');
    })
    ;
  }

}
