
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { StatisticsEBriefApiResources } from '../../classes/statistics-api-resources.class';
import { GeneralStatisticsApiResources } from '../../classes/general-statistics-api-resources.class';

export interface IEbriefStatistics {
  statisticsOn: string;
  eBriefUsersInSystem: number;
  activatedUsers: number;
  userThatSetEBriefToActive: number;
  usersWithConfirmedMainAddress: number;
  activationLettersSent: number;
  usersWhoAtLeastWantPasswordSecurity: number;
  addedByPINCampaign: number;
  usersWithOneAddress: number;
  usersWithFirstAddressAsMainAddress: number;
  fromWhichAddressConfirmationIsPending: number;
  usersWithTwoAddresses: number;
  usersWithSecondAddressConfirmedAsMainAddress: number;
  fromWhichAddressConfirmationIsPending2: number;
  usersWithThreeAddresses: number;
  usersWithThirdAddressConfirmedAsMainAddress: number;
  fromWhichAddressConfirmationIsPending3: number;
  deActivatedButReceiveReady: number;
  usersWhoSetEBriefToInactive: number;
  usersWithNonConfirmedAddress: number;
  deletedUsers: number;
}

export interface IStatisticsData {
  title: string;
  body: { key: string, value: string }[];
}

@Injectable()
export class StatisticsService {

  constructor(private _http: HttpClient) { }

  fetchEbriefStatistics() {
    return this._http.get(`${environment.apiUrl}/statistics/ebriefStatistics`).pipe(map((res) => {
      return new StatisticsEBriefApiResources(res);
    }));
  }

  fetchGeneralStatistics() {
    return this._http.get(`${environment.apiUrl}/statistics/statisticsGlobal`).pipe(map((res) => {
      return new GeneralStatisticsApiResources(res);
    }));
  }
}
