import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatOption } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../auth/services/auth.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";
import countriesDe from "../../shared/data/countries-de";
import countriesEn from "../../shared/data/countries-en";
import {
  emailValidation,
  onlySpacesValidator,
  zipValidationOtherCountries,
  zipValidationforAt,
} from "../../shared/utils/custom-validation.util";
import { DownloadUserCredentialsComponent } from "../download-user-credentials/download-user-credentials.component";
import { PersonRole } from "../enum/personRole.enum";
import { GroupService } from "../group.service";
import { PersonService } from "../person/person.service";
import { xUserService } from "../xUserService/xUser.service";
import { TranslateService } from "@ngx-translate/core";
import { SendToPrintPrompt } from "./send-to-print-promt/send-to-print-promt.component";

@Component({
  selector: "sv-create-edit-person",
  templateUrl: "./create-edit-person.component.html",
  styleUrls: ["./create-edit-person.component.scss"],
})
export class CreateEditPersonComponent {
  public cereateEditPersonForm: UntypedFormGroup;
  public accountNumber: string;
  public accountNumbers: {
    accountNumber: string;
    prefix: string;
    usedPrefixID?: string;
  }[];
  public additionalAccountNumbers = 0;
  private groupId: string;
  public personId: string;
  public accountType: string;
  public newAccNumber: string;
  public isLoading = false;
  public title: string;
  private personObject: any;
  private personData: any;
  public adresses = [];
  public tenantName: string;
  public allCountries: {
    id: number;
    alpha2: string;
    alpha3: string;
    name: string;
  }[];
  private counter: number;
  public createCompanyPrefixes;
  public groupPrefixes;
  public einfachBriefEnabled: boolean;

  @ViewChildren("activateOptions") activateOptions: QueryList<MatOption>;
  constructor(
    private _personService: PersonService,
    private _xUserService: xUserService,
    private _groupService: GroupService,
    public _authService: AuthService,
    public notificationService: MultipleNotificationsService,
    private matDialogRef: MatDialogRef<CreateEditPersonComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.counter = 0;
    this.groupId = data.groupId;
    this.title = data.title;
    this.tenantName = data.tenantName;
    this.createCompanyPrefixes = data.companyPrefixes;
    this.einfachBriefEnabled = data.einfachBriefEnabled;
    const userData = this._authService.getUserData();
    this.allCountries =
      userData.userLanguage === "de" ? countriesDe : countriesEn;

    this._personService.editPerson.subscribe((editPersonData) => {
      this.personObject = editPersonData;
      this.accountNumbers = editPersonData.accountNumbers;
      this.counter = this.accountNumbers.length;
      this.groupId = editPersonData.groupId;
      this.personId = String(editPersonData.id);
      this._groupService.getGroupPrefixes(this.groupId).subscribe((data) => {
        this.groupPrefixes = data;
        this.updateUserFormOnEdit(editPersonData);
      });
      this.initialiseForm();
    });

    if (!this.personId) {
      this._xUserService.editXUser.next(null);
      this.initialiseForm();
    }

    this._personService.createPerson.subscribe((value) => {
      this.groupId = value.groupId;
      this.accountNumbers = [
        { accountNumber: "", prefix: "", usedPrefixID: "" },
      ];
    });
  }

  onCountryCodePhoneNumInput(event: any) {
    let inputValue: string = event.target.value;

    inputValue = inputValue.replace(/[^\d+]/g, "");

    if (inputValue.length > 0 && inputValue.charAt(0) !== "+") {
      inputValue = "+" + inputValue;
    }

    inputValue = inputValue.replace(/\++/g, "+");

    this.cereateEditPersonForm
      .get("countryCodePhoneNum")
      .setValue(inputValue, { emitEvent: false });
  }

  private initialiseForm() {
    this.cereateEditPersonForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(null, Validators.required),
      streetName: new UntypedFormControl(""),
      doorNumber: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      email: new UntypedFormControl("", [emailValidation]),
      countryCodePhoneNum: new UntypedFormControl(""),
      netNumberPhoneNum: new UntypedFormControl("", [
        Validators.maxLength(3),
        Validators.minLength(2),
        Validators.pattern(/^[^a-zA-Z]*$/),
      ]),
      subscriberNumberPhoneNum: new UntypedFormControl("", [
        Validators.minLength(6),
        Validators.pattern(/^[^a-zA-Z]*$/),
      ]),
      country: new UntypedFormControl("at"),
      streetNumber: new UntypedFormControl(""),
      prefixedTitle: new UntypedFormControl(""),
      salutation: new UntypedFormControl(null),
      sendToPrint: new UntypedFormControl(false),
    });

    this.cereateEditPersonForm.addControl(
      "zipCode",
      new UntypedFormControl("", [
        this.cereateEditPersonForm.value.country === "at"
          ? zipValidationforAt()
          : zipValidationOtherCountries(),
      ])
    );

    if (!this.accountNumbers) {
      this.accountNumbers = [{ accountNumber: "", prefix: "" }];
    }
    for (let i = 0; i < this.accountNumbers.length; i++) {
      this.cereateEditPersonForm.addControl(
        !this.personId ? "accountNumber" : `accountNumber${i}`,
        new UntypedFormControl(
          { value: null, disabled: this.personId && true },
          Validators.compose([Validators.required, Validators.minLength(2)])
        )
      );
      this.cereateEditPersonForm.addControl(
        !this.personId ? "companyPrefix" : `companyPrefix${i}`,
        new UntypedFormControl(
          { value: null, disabled: this.personId && true },
          Validators.compose([Validators.required])
        )
      );
      this.counter += 1;
    }

    this.cereateEditPersonForm?.controls?.country?.valueChanges?.subscribe(
      (selectedCountry) => {
        if (selectedCountry === "at") {
          this.cereateEditPersonForm.controls.zipCode.setValidators([
            zipValidationforAt(),
          ]);
        } else {
          this.cereateEditPersonForm.controls.zipCode.setValidators([
            zipValidationOtherCountries(),
          ]);
        }

        this.cereateEditPersonForm.controls.zipCode.updateValueAndValidity();
      }
    );
  }

  public addAccountNumberField(e) {
    e.preventDefault();
    e.stopPropagation();
    this.additionalAccountNumbers += 1;
    this.cereateEditPersonForm.addControl(
      `accountNumber${
        this.accountNumbers.length + this.additionalAccountNumbers - 1
      }`,
      new UntypedFormControl("", [
        Validators.required,
        onlySpacesValidator(),
        Validators.minLength(2),
      ])
    );
    this.cereateEditPersonForm.addControl(
      `companyPrefix${
        this.accountNumbers.length + this.additionalAccountNumbers - 1
      }`,
      new UntypedFormControl("", Validators.required)
    );
    this.counter += 1;
  }

  private updateUserFormOnEdit(data): void {
    const accountDataDTO = data.person.accountDataDto;
    this.cereateEditPersonForm.patchValue({
      firstName: accountDataDTO.firstName,
      lastName: accountDataDTO.lastName,
      email: accountDataDTO.email,
      prefixedTitle: accountDataDTO.prefixedTitle,
      countryCodePhoneNum: accountDataDTO.phoneNumber.countryCode,
      netNumberPhoneNum: accountDataDTO.phoneNumber.netCode,
      subscriberNumberPhoneNum: accountDataDTO.phoneNumber.subscriberNumber,
      salutation: accountDataDTO.salutation || "OTHER",
      streetName: accountDataDTO.addresses[0]?.streetName || "",
      doorNumber: accountDataDTO.addresses[0]?.doorNumber || "",
      zipCode: accountDataDTO.addresses[0]?.zipCode || "",
      city: accountDataDTO.addresses[0]?.city || "",
      country:
        (accountDataDTO.addresses[0]?.country &&
          accountDataDTO.addresses[0]?.country.toLowerCase()) ||
        "",
      streetNumber: accountDataDTO.addresses[0]?.streetNumber || "",
      accountNumber: data.accountNumber,
      sendToPrint: data.sendToPrint,
    });

    this.accountNumber = data.person.accountNumber;
    for (let i = 0; i < this.accountNumbers.length; i++) {
      this.cereateEditPersonForm.patchValue({
        [`accountNumber${i}`]: this.accountNumbers[i].accountNumber,
        [`companyPrefix${i}`]:
          this.groupPrefixes.find(
            (prefix) =>
              prefix.prefixID &&
              prefix.prefixID === this.accountNumbers[i].usedPrefixID
          ) || null,
      });

      this.cereateEditPersonForm.controls[`companyPrefix${i}`].disable();
    }
  }

  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  public submitWithPropt(): void {
    if (this.cereateEditPersonForm.controls.sendToPrint.value) {
      return this._openAdressProptDialog();
    }

    this.submit();
  }

  private _openAdressProptDialog(): void {
    const config = new MatDialogConfig<ConfirmationDialogData>();
    config.disableClose = true;
    config.autoFocus = false;
    config.data = {
      dialogTitle: this._translateService.instant("sendToPrintPrompt.title"),
      content: {
        component: SendToPrintPrompt,
        componentData: {
          description: this._translateService.instant(
            "sendToPrintPrompt.description"
          ),
        },
      },
      onCancel: {
        label: this._translateService.instant("sendToPrintPrompt.labelCancel"),
        cta: () => {},
      },
      onConfirm: {
        label: this._translateService.instant("sendToPrintPrompt.labelConfirm"),
        cta: () => {
          this.submit();
          dialogRef.close();
        },
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
  }

  // TODO: Cleanup
  public submit(): void {
    const accountNumber =
      this.cereateEditPersonForm.value.accountNumber &&
      this.cereateEditPersonForm.value.accountNumber.trim();

    let addresses = {
      city: this.cereateEditPersonForm.value.city || "",
      country: this.cereateEditPersonForm.value.country || "",
      doorNumber: this.cereateEditPersonForm.value.doorNumber || "",
      streetName: this.cereateEditPersonForm.value.streetName || "",
      streetNumber: this.cereateEditPersonForm.value.streetNumber || "",
      zipCode: this.cereateEditPersonForm.value.zipCode || "",
    };

    if (this.personId) {
      this.personObject = this.personObject.person
        ? { ...this.personObject.person.accountDataDto }
        : { ...this.personObject };
      this.personObject.activate = this.cereateEditPersonForm.value.activate;
      this.personObject.email = this.cereateEditPersonForm.value.email;
      this.personObject.firstName = this.cereateEditPersonForm.value.firstName;
      this.personObject.lastName = this.cereateEditPersonForm.value.lastName;
      this.personObject.mobileNumber =
        this.cereateEditPersonForm.value.mobileNumber;
      this.personObject.prefixedTitle =
        this.cereateEditPersonForm.value.prefixedTitle;
      this.personObject.salutation =
        this.cereateEditPersonForm.value.salutation;
      this.personObject.addresses = [addresses];
      this.personObject.phoneNumber = {
        countryCode: this.cereateEditPersonForm.value.countryCodePhoneNum,
        netCode: this.cereateEditPersonForm.value.netNumberPhoneNum,
        subscriberNumber:
          this.cereateEditPersonForm.value.subscriberNumberPhoneNum,
        type: "0",
      };
      delete this.personObject.person;
      delete this.personObject.additionalData;

      this.personData = this.personObject;
    } else {
      this.personData = {
        ...this.cereateEditPersonForm.value,
        ...{
          addresses: [
            {
              ...addresses,
              foreignId: null,
              isNewAddressConfirmed: null,
              pac: null,
              remark: null,
              state: "UNCONFIRMED",
              streetAdditional: null,
              addressType: "MAIN",
            },
          ],
          phoneNumber: {
            countryCode: this.cereateEditPersonForm.value.countryCodePhoneNum,
            netCode: this.cereateEditPersonForm.value.netNumberPhoneNum,
            subscriberNumber:
              this.cereateEditPersonForm.value.subscriberNumberPhoneNum,
            type: "0",
          },
          trailingTitle: null,
          legalPerson: null,
          authSecurityLevel: "SecClass1",
          birthday: null,
          allowsNewsletter: null,
          language: "de",
          lastLogin: "2023-06-09T09:31:33.342+00:00",
          senderCategoryAllowed: true,
          mandatorDto: [],
          representativeDto: null,
          authSecurityLevelFlag: true,
          additionalData: {},
          role: PersonRole.user,
          showMobileAppNotification: true,
          enabledChatbot: null,
          showSepaButton: false,
          showSwitchButton: false,
          accountNumber: [accountNumber],
          accountType: this.accountType,
        },
      };

      delete this.personData.city;
      delete this.personData.country;
      delete this.personData.doorNumber;
      delete this.personData.streetName;
      delete this.personData.streetNumber;
      delete this.personData.zipCode;

      delete this.personData.countryCodePhoneNum;
      delete this.personData.netNumberPhoneNum;
      delete this.personData.subscriberNumberPhoneNum;
      delete this.personData.companyPrefix;
    }

    let accNumFields = Object.keys(this.cereateEditPersonForm.controls).filter(
      (controlName) => controlName.startsWith("accountNumber")
    );
    let prefixesFields = Object.keys(
      this.cereateEditPersonForm.controls
    ).filter((controlName) => controlName.startsWith("companyPrefix"));

    let accountNumbers: any = accNumFields.map((accNumField, i) => ({
      accountNumber: this.cereateEditPersonForm.controls[accNumField].value,
      prefix:
        this.cereateEditPersonForm.controls[prefixesFields[i]].value ||
        this.groupPrefixes[0],
    }));

    // Add flag to the accountNumbers that already exist
    if (this.personId) {
      for (let i = 0; i < this.accountNumbers.length; i++) {
        accountNumbers[i].alreadyExist = true;
      }
    }

    for (let i = 0; i < accNumFields.length; i++) {
      delete this.personData[accNumFields[i]];
    }
    !this.personData.salutation && (this.personData.salutation = "OTHER");
    if (this.cereateEditPersonForm.valid) {
      this.isLoading = true;
      this._personService
        .createOrEditPErson(
          this.personData,
          "123",
          this.groupId,
          this.personId,
          accountNumbers,
          this.cereateEditPersonForm.value.sendToPrint
        )
        .subscribe(
          (data) => {
            this.matDialogRef.close("submit");

            if (this.personId) {
              this.notificationService.showNotifications({
                message: "User edited",
                isSuccess: true,
              });
            } else {
              const _dialogConfig = new MatDialogConfig<any>();
              _dialogConfig.disableClose = true;
              _dialogConfig.panelClass = "custom-dialog";
              _dialogConfig.autoFocus = true;
              _dialogConfig.maxWidth = "700px";
              _dialogConfig.minWidth = "350px";
              _dialogConfig.height = "auto";
              _dialogConfig.data = {
                data: data,
              };

              this.dialog.open(DownloadUserCredentialsComponent, _dialogConfig);
              this.notificationService.showNotifications({
                message: "User created",
                isSuccess: true,
              });
            }

            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            if (err.errorMessage === "Person already exists") {
              this.notificationService.showNotifications(
                "Person already exists"
              );
              this.cereateEditPersonForm.controls.accountNumber.setErrors({
                notUnique: true,
              });
            }
          }
        );
    } else {
      this.isLoading = false;

      Object.values(this.cereateEditPersonForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }
}
