<div class="download-user-credentials-dialog">
  <div
    class="title-box">
    <mat-icon class="info-icon">info</mat-icon>
    <p class="title">{{ "New user's credentials" | translate }}</p>
  </div>
  <div class="wrapper">
    <div class="download-box">
      <div class="download-data">
        <p>{{ "Download user's data" | translate }}</p>
        <span>{{pdfData.fileName}}</span>
      </div>
      <button
        (click)="downloadCredentials()" class="download-bttn">
        <mat-icon svgIcon="custom:download" class="download-icon"></mat-icon>
      </button>
    </div>
    <button
      class="cancel-bttn" (click)="closeDialog()">
      {{ "Cancel" | translate }}
      <mat-icon class="close-icon" [svgIcon]="'custom:close'"></mat-icon>
    </button>
  </div>
</div>
