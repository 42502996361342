import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../auth/services/auth.service";
import { MultipleNotificationsService } from "../../shared/components/multiple-notifications/multiple-notifications.service";
import { emailValidation } from "../../shared/utils/custom-validation.util";
import { PersonService } from "../person/person.service";
import { xUserService } from "../xUserService/xUser.service";

@Component({
  selector: "sv-create-edit-admin-user",
  templateUrl: "./create-edit-admin-user.component.html",
  styleUrls: ["./create-edit-admin-user.component.scss"],
})
export class CreateEditAdminUserComponent {
  public createEditAdminUserForm: UntypedFormGroup;
  public personId: string;
  public isLoading = false;
  public title: string;
  private personObject: any;
  public tenantName: string;
  private createDialog: boolean;
  constructor(
    private _personService: PersonService,
    private _xUserService: xUserService,
    public notificationService: MultipleNotificationsService,
    private matDialogRef: MatDialogRef<CreateEditAdminUserComponent>,
    public downloadDialog: MatDialog,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.tenantName = data.tenantName;
    this.createDialog =
      this.title === "Create user" || this.title === "Neuen Benutzer Anlegen";

    this._xUserService.editXUser.subscribe((editPersonData) => {
      this.personObject = editPersonData;
      this.personId = editPersonData?.id.toString();
      this.initialiseForm();
      editPersonData && this.updateXUserFormOnEdit(editPersonData);
    });

    if (this.createDialog) {
      this._xUserService.editXUser.next(null);
      this.initialiseForm();
    }

    if (this.personId) {
      this.createEditAdminUserForm.get("username").disable();
    }
  }

  private initialiseForm() {
    this.createEditAdminUserForm = new UntypedFormGroup({
      firstName: new UntypedFormControl("", Validators.compose([Validators.required])),
      lastName: new UntypedFormControl("", Validators.compose([Validators.required])),
      username: new UntypedFormControl(null, Validators.compose([Validators.required])),
      email: new UntypedFormControl("",Validators.compose([Validators.required, emailValidation])),
    });
    this.createEditAdminUserForm.addControl("email", new UntypedFormControl("", Validators.compose([Validators.required, emailValidation])));
    this.createEditAdminUserForm.addControl("username", new UntypedFormControl(null, Validators.compose([Validators.required])));
    this.createEditAdminUserForm.addControl("firstName", new UntypedFormControl("", [Validators.required]));
  }

  private updateXUserFormOnEdit(data): void {
    this.createEditAdminUserForm.patchValue({
      firstName: data.person.firstName,
      lastName: data.person.lastName,
      username: data.person.username,
      email: data.person.email,
    });
  }

  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  public submit(): void {
    if (this.personId) {
      this.personObject.activate = this.createEditAdminUserForm.value.activate;
      this.personObject.email = this.createEditAdminUserForm.value.email;
      this.personObject.firstName = this.createEditAdminUserForm.value.firstName;
      this.personObject.lastName = this.createEditAdminUserForm.value.lastName;
      this.personObject.username = this.createEditAdminUserForm.value.username;
    }

    if (this.createEditAdminUserForm.valid) {
      this.isLoading = true;
      this._personService
        .createOrEditXUser(
          this.createEditAdminUserForm.value,
          this.tenantName,
          this.personId
        )
        .subscribe(
          () => {
            this.matDialogRef.close("submit");

            if (this.personId) {
              this._personService.statusMessage(
                "snack-bar-success",
                "Admin User edited"
              );
            } else {
              this._personService.statusMessage(
                "snack-bar-success",
                "Admin User created"
              );
            }
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            if (err.errorMessage === "Person with that username already exists") {
              this._personService.statusMessage(
                "snack-bar-fail",
                "Person already exists"
              );
              this.createEditAdminUserForm.controls.accountNumber.setErrors({
                notUnique: true,
              });
            }
          }
        );
    } else {
      this.isLoading = false;

      Object.values(this.createEditAdminUserForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }
}
