<div class="logout-header-container">
  <h1 mat-dialog-title translate>Automatic Logout</h1>
  <mat-icon
    class="close"
    (click)="onClose()"
    [svgIcon]="'custom:close'"
  ></mat-icon>
</div>
<div mat-dialog-content class="logout-content">
  <p translate>
    For security reasons, you were logged out of Support View automatically.
    Please, log in again.
  </p>
  <div class="confirm-buttons">
    <button mat-button mat-dialog-close (click)="onConfirm()">
      <span translate>OK</span>
    </button>
  </div>
</div>
