// @ts-ignore
import * as _keys from 'lodash/keys';
// @ts-ignore
import * as _assign from 'lodash/assign';
import * as moment from 'moment';

import { getMetadata, saveMetadata } from '../utils/metadata.util';
import { assign } from '../utils/assignment.util';

const serializeMetadataKey = Symbol('api-model-serialize');
const serializeEnumMetadataKey = Symbol('api-model-serialize-enum');
const serializeDateMetadataKey = Symbol('api-model-serialize-date');

/**
 * Classes that represent structure of objects
 * that are sent to API as a param should extend this class.
 *
 * As an example a TodoModel would be a class for objects
 * that are sent for create/update calls and should extends this
 * abstract class.
 */
export abstract class ApiModel {
  public toJSON() {
    return JSON.stringify(this.toSerializableObject());
  }

  /**
   * Converts the model to a serializable object (can easily be converted to JSON)
   * Uses decorators to determine how property values are handled
   */
  public toSerializableObject() {
    const serializableObject = {};
    // get normal serializables
    const serializeMaps = getMetadata(Object.getPrototypeOf(this), serializeMetadataKey, true);
    if (serializeMaps.length) {
      const serializeMap = _assign({}, ...serializeMaps);
      const serializablePropertyList = _keys(serializeMap);
      // map every decorated property
      serializablePropertyList.forEach((serializableProperty) => {
        const serializedField = serializeMap[serializableProperty] || serializableProperty;
        assign(serializableObject, serializedField, this[serializableProperty]);
      });
    }
    // get enum map serializables
    const serializeEnumMaps = getMetadata(Object.getPrototypeOf(this), serializeEnumMetadataKey, true);
    if (serializeEnumMaps.length) {
      const serializeEnumMap = _assign({}, ...serializeEnumMaps);
      const serializablePropertyList = _keys(serializeEnumMap);
      // map every decorated property by using the provided map object
      serializablePropertyList.forEach((serializableProperty) => {
        serializableObject[serializableProperty] = serializeEnumMap[serializableProperty][this[serializableProperty]];
      });
    }
    // get date serializables
    const serializeDateMaps = getMetadata(Object.getPrototypeOf(this), serializeDateMetadataKey, true);
    if (serializeDateMaps.length) {
      const serializeDateMap = _assign({}, ...serializeDateMaps);
      const serializablePropertyList = _keys(serializeDateMap);
      serializablePropertyList.forEach((serializableProperty) => {
        const options = serializeDateMap[serializableProperty];
        if (options) {
          const serializedField = options.to || serializableProperty;
          let date = this[serializableProperty];
          if (date && options.convertToUtc) {
            date = moment(date).utc(false);
          }
          assign(serializableObject, serializedField, date && date.format(options.format));
        } else {
          serializableObject[serializableProperty] = this[serializableProperty] && this[serializableProperty].format();
        }
      });
    }
    return serializableObject;
  }
}

/**
 * Decorator factory that indicates that a property value is to be copied to a serialized object
 * @param to What property to copy the value to
 */
export function Serialize(to?: string) {
  return function (target, property) {
    let serializeMap = getMetadata(target, serializeMetadataKey);
    if (!serializeMap) {
      serializeMap = {};
      saveMetadata(target, serializeMetadataKey, serializeMap);
    }
    serializeMap[property] = to;
  };
}

/**
 * Decorator factory that indicates that a property value is to be copied to a serialized object
 * The value is mapped using the provided map object
 * @param map The map to use when coping values
 */
export function SerializeEnum(map: { [enumValue: string]: string }) {
  return function (target, property) {
    let serializeEnumMap = getMetadata(target, serializeEnumMetadataKey);
    if (!serializeEnumMap) {
      serializeEnumMap = {};
      saveMetadata(target, serializeEnumMetadataKey, serializeEnumMap);
    }
    serializeEnumMap[property] = map;
  };
}

/**
 * Decorator factory that indicates that a property value is to be copied to a serialized object
 * The value is considered a date and is formated using the provided format, or by default using iso8601 standard
 * @param options Options for the coping process
 */
export function SerializeDate(options?: { convertToUtc?: boolean, format?: string, to?: string }) {
  return function (target, property) {
    let serializeDateMap = getMetadata(target, serializeDateMetadataKey);
    if (!serializeDateMap) {
      serializeDateMap = {};
      saveMetadata(target, serializeDateMetadataKey, serializeDateMap);
    }
    serializeDateMap[property] = options;
  };
}
