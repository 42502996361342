import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
// @ts-ignore
import * as _assign from 'lodash/assign';
import * as moment from 'moment';

import { IAccountData } from '../../interfaces/account-data.interface';
import { UsersService } from '../../services/users/users.service';
import { AuthService } from '../../../auth/services/auth.service';
import { UserAccountDataModelResource } from '../../classes/user-account-data-model-resource.class';
import { UserResetPasswordComponent } from '../user-reset-password/user-reset-password.component';
import { UserStatus } from '../../enums/user-status.enum';
import { TranslateService } from '@ngx-translate/core';

export interface IStatus {
  name: string;
  description: string;
}

@Component({
  selector: 'sv-user-account-details',
  templateUrl: './user-account-details.component.html',
  styleUrls: ['./user-account-details.component.scss']
})

export class UserAccountDetailsComponent implements OnDestroy {
  // @ViewChild('accDetailsForm', { static: true }) form: NgForm;
  public profileForm: FormGroup;
  public accountDetailsData: IAccountData;
  public userId: string;
  // Accout data select options
  public statusList: IStatus[];
  // subscriptions
  public fetchUsersAccountDataSubscription: Subscription;
  public onLangChangeSubscription: Subscription;
  // test property
  public statusChanged = false;
  public isSaving = false;
  public disableStatusModifications = false;

  constructor(public auth: AuthService,
    private _router: ActivatedRoute,
    private _usersService: UsersService,
    private _translateService: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this._router.parent.params.subscribe((params: Params) => {
      this.userId = params.id;
    });
    this.fetchAccountData();
    this.updateTranslations();

    this.statusList = [
      { name: UserStatus.Open, description: 'receive ready text' },
      { name: UserStatus.NotOpen, description: 'delivery inactive text' },
      { name: UserStatus.Closed, description: 'closed text' },
      { name: UserStatus.Disabled, description: 'disabled text' },
      { name: UserStatus.Unconfirmed, description: 'unconfirmed text' },
      { name: UserStatus.Inactive, description: 'inactive text' },
    ];

  }

  private fetchAccountData() {
    this.fetchUsersAccountDataSubscription = this._usersService.getUsersAccountData(this.userId).subscribe((res) => {
      this.accountDetailsData = res;
      if (this.accountDetailsData.status === UserStatus.Unconfirmed
        || this.accountDetailsData.status === UserStatus.Inactive) {
        this.disableStatusModifications = true;
      }
      this.accountDetailsData.birthDay = this.accountDetailsData.birthDay ? moment(this.accountDetailsData.birthDay).format('DD.MM.YYYY') : '';
      this.profileForm = new FormGroup({
        displayName: new FormControl(this.accountDetailsData.displayName),
        userName: new FormControl(this.accountDetailsData.userName),
        email: new FormControl(this.accountDetailsData.email),
        phone: new FormControl(this.accountDetailsData.phone),
        birthDay: new FormControl(this.accountDetailsData.birthDay),
        status: new FormControl(this.accountDetailsData.status)
      })
      this._usersService.changeEntries('');
    });
  }

  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = 15000;
    return config;
  }

  private updateTranslations() {
    this.onLangChangeSubscription = this._translateService.onLangChange.subscribe(() => {
      if (!this.accountDetailsData) {
        return;
      }
    });
  }

  public onSaveEditAccountData(event) {
    const formValue = this.profileForm;
    let accountData = new UserAccountDataModelResource();
    // add static values
    accountData = _assign(accountData, this.accountDetailsData);
    // add editable values
    accountData = _assign(accountData, this.profileForm.value);
    if (!('corporateCustomer' in formValue) && this.profileForm.controls?.corporateCustomer?.value === '') {
      accountData = _assign(accountData, { corporateCustomer: this.accountDetailsData.corporateCustomer });
    }
    if (!('employee' in formValue) && this.profileForm.controls?.employee?.value === '') {
      accountData = _assign(accountData, { employee: this.accountDetailsData.employee });
    }
    // we set flag isSaving to true to prevent form to send multiply form request
    this.isSaving = true;
    this.statusChanged = false;
    this._usersService.editUserAccountData(accountData).subscribe((response) => {
      const config = this._createConfig();
      let message = '';
      if (response.status === 200) {
        config.panelClass = 'snack-bar-success';
        message = this._translateService.instant('Account Data saved');
        this.fetchAccountData();
      } else {
        config.panelClass = 'snack-bar-fail';
        message = this._translateService.instant('Account Data save failed');
      }
      this.snackBar.open(message, undefined, config);
      this.isSaving = false;
      this.profileForm.markAsPristine();
    });
  }

  public setStatus(status) {
    this.accountDetailsData.status = status;
    this.profileForm.controls.status.setValue(status);
    this.statusChanged = true;
  }

  public openDialog(): void {
    const optionsEmail = [
      {
        'email': this.accountDetailsData.email
      }
    ];
    if (this.accountDetailsData.additionalEmailList) {
      this.accountDetailsData.additionalEmailList.forEach(element => {
        optionsEmail.push({ 'email': element });
      });
    }
    const dialogRef = this.dialog.open(UserResetPasswordComponent, {
      data: optionsEmail,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.auth.requestResetPassword(result).subscribe((response) => {
          const config = this._createConfig();
          let message = '';
          if (response.status === 200) {
            config.panelClass = 'snack-bar-success';
            message = this._translateService.instant('Email successfully send');
          } else {
            config.panelClass = 'snack-bar-fail';
            message = this._translateService.instant('Email send failed');
          }
          this.snackBar.open(message, undefined, config);
        });
      }
    });
  }

  ngOnDestroy() {
    this.fetchUsersAccountDataSubscription.unsubscribe();
    this.onLangChangeSubscription.unsubscribe();
    this.dialog.closeAll();
  }
}
