<div class="reset-password-dialog">
  <div #resetPasswordDialogContainer class="reset-password-dialog__inputs__container">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()" action="">
      <div class="header-reset-dialog">
        <h1>{{'Reset password' | translate}}</h1>
        <button matType="mat-icon-button" class="close-icon" type="button" (click)="closeResetPassword($event)"
          matTooltipClass="primary-tooltip">
          <mat-icon [svgIcon]="'custom:close'"></mat-icon>
        </button>
      </div>
      <mat-error
        *ngIf="resetPasswordForm.controls?.oldPassword?.touched && resetPasswordForm.controls?.oldPassword?.errors">
        <mat-error *ngIf="resetPasswordForm.controls?.oldPassword.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
        <mat-error
          *ngIf="resetPasswordForm.controls?.oldPassword.hasError('oldPasswordIncorect') && !resetPasswordForm.controls?.oldPassword.hasError('required')">
          {{'Wrong password.' | translate}}
        </mat-error>
        <mat-error
          *ngIf="resetPasswordForm.controls?.oldPassword.hasError('minlength') && !resetPasswordForm.controls?.oldPassword.hasError('required')">
          {{'The minimum length is' | translate}} 8.
        </mat-error>
      </mat-error>
      <mat-form-field class="reset-password-dialog__inputs__container__input">
        <input (input)="inputEvent()" [type]="hide.hideSecondInput ? 'text' : 'password'" matInput
          formControlName="newPassword" type="password" placeholder="{{'New password' | translate}}"
          autocomplete="new-password">
        <mat-icon matSuffix class="input-hoverable-icon" (click)="handlePasswordEye($event,'hideSecondInput')"
          [svgIcon]="hide.hideSecondInput ? 'custom:password_visible' : 'custom:password_invisible'"></mat-icon>
      </mat-form-field>
      <mat-error
        *ngIf="resetPasswordForm.controls.newPassword.touched && resetPasswordForm.controls.newPassword.errors">
        <mat-error
          *ngIf="resetPasswordForm.controls.newPassword.hasError('minlength') && !resetPasswordForm.controls.newPassword.hasError('required')">
          {{'The minimum length is' | translate}} 8.
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls.newPassword.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
        <mat-error
          *ngIf="(!resetPasswordForm.controls.newPassword.hasError('minlength') && !resetPasswordForm.controls.newPassword.hasError('required')) && resetPasswordForm.controls.newPassword.hasError('invalidPassword')">
          {{'The password must fulfill 3 of 4 points: at least 1 upper case letter, 1 lower case letter, 1 special character, 1 number.' | translate}}
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls.newPassword.hasError('passwordMustMatch')">{{'The passwords must match.' | translate}}</mat-error>

      </mat-error>
      <mat-form-field class="reset-password-dialog__inputs__container__input">
        <input (input)="inputEvent()" matInput type="password" [type]="hide.hideThirdInput ? 'text' : 'password'"
          formControlName="newPasswordConfirm" placeholder="{{'New password confirm' | translate}}"
          autocomplete="new-password">
        <mat-icon matSuffix class="input-hoverable-icon" (click)="handlePasswordEye($event,'hideThirdInput')"
          [svgIcon]="hide.hideThirdInput ? 'custom:password_visible' : 'custom:password_invisible'"></mat-icon>
      </mat-form-field>
      <mat-error
        *ngIf="resetPasswordForm.controls.newPasswordConfirm.touched && resetPasswordForm.controls.newPasswordConfirm.errors">
        <mat-error
          *ngIf="resetPasswordForm.controls.newPasswordConfirm.hasError('minlength') && !resetPasswordForm.controls.newPasswordConfirm.hasError('required')">
          {{'The minimum length is' | translate}} 8.
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls.newPasswordConfirm.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls.newPasswordConfirm.hasError('passwordMustMatch')">{{'The passwords must match.' | translate}}</mat-error>

      </mat-error>
      <div class="reset-password-wrapper">
        <button type="submit" color="primary" mat-button mat-raised-button [disabled]="!resetPasswordForm.valid">{{'Reset password' | translate}}</button>
      </div>
    </form>
  </div>
</div>