import { ModelValue } from '../decorators/model-value/model-value.decorator';
import { DocumentUploadModel } from './document-upload-model.class';

export class CreateDeliveryModel {

  @ModelValue('uploadIdentifiers', (documents) => {
    const documentsToDownload = {};
    documents.forEach((document) => {
      if (document.finishedUpload && !(document.id in documentsToDownload)) {
        documentsToDownload[document.id] = document.fileName;
      }
    });

    return documentsToDownload;
  })
  public documents: DocumentUploadModel[];

  @ModelValue('title')
  public title: string;

  constructor() {
    this.documents = [];
  }
}
