<div class="container">
  
  <div class="header">
    <sv-header isUserMenuVisible="true"></sv-header>
  </div>
  <div class="main-content">
    <sv-sidebar [staticPages]="staticPages"></sv-sidebar>
    <div class="main">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <div class="footer">
        <sv-footer></sv-footer>
      </div>
    </div>
  </div>
</div>