import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  email: string;
}

@Component({
  selector: 'sv-app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent {
  public resetEmail: string = this.data[0].email;
  public closeButtonLabel: string;

  constructor(
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<UserResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.closeButtonLabel = this._translateService.instant('Close');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
