import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { UsersModule } from '../users/users.module';
import { DesignModule } from '../design/design.module';
import { UsersActivityLogComponent } from './components/users-activity-log/users-activity-log.component';
import { SearchActivityLogComponent } from './components/search-users-activity-log/search-activity-log.component';
import { UserSearchDisplayComponent } from '../users/components/user-search-display/user-search-display.component';


@NgModule({
    imports: [
        CommonModule,
        UsersModule,
        DesignModule,
        MatPaginatorModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatMomentDateModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatMenuModule,
        MatTableModule,
        TranslateModule.forChild(),
    ],
    declarations: [UsersActivityLogComponent, SearchActivityLogComponent]
})
export class ActivityLogModule { }
