import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { PersonRole } from "../enum/personRole.enum";
import { Pagination } from "../interfaces/pagination.interface";
import { IPersonResponse, IPerson } from "../interfaces/person.interface";
import { IGroup, IGroupResponseData } from "../interfaces/group.interface";
import { DocumentVisibility } from "../interfaces/visibility";
export const RESET_FILTER_PERSON = {
  accountNumber: "",
  displayName: "",
  email: "",
  userName: "",
};

export type AssignAdminToCompaniesResponse = {
  companyDescription: CompanyDescription[];
  existInAllCompanies: boolean;
};

export type CompanyPrefix = {
  identPrefixLength: number;
  keepZeros: boolean;
  prefixID: string | number;
  subCompanyName: string;
  subCompanyPrefix: string;
  userPrefix: string;
};

export type CompanyUserRight = Record<PersonRole, boolean>;

export type CompanyDescription = {
  assigned: boolean;
  billersInvoiceRecipientID: string;
  companyId: string;
  companyName: string;
  companyStatus: boolean;
  primary: boolean;
  prefix: CompanyPrefix;
  prefixes: CompanyPrefix[];
  userRights: CompanyUserRight;
};

@Injectable({
  providedIn: "root",
})
export class xUserService {
  public createPerson = new Subject<{ externalId: any; groupId: string }>();
  public triggerDataReaload = new Subject<boolean>();
  public searchPersonQuery: {
    accountNumber: string;
    email: string;
    userName: string;
    role?: string;
    firstName: string;
    lastName: string;
  } = {
    accountNumber: "",
    email: "",
    userName: "",
    firstName: "",
    lastName: "",
  };
  public editXUser = new BehaviorSubject<{
    person: any;
    groupId: string;
    id: string;
    activate: boolean;
    accountType: string;
  } | null>(null);
  constructor(
    private httpClient: HttpClient,
    private _translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  public statusMessage(panelClass: string, message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }

  public getPersons(
    params: Pagination,
    groupId: string,
    searchPersonQuery: {
      accountNumber: string;
      email: string;
      userName?: string;
      role?: string;
      firstName?: string;
      lastName?: string;
    }
  ): Observable<IPersonResponse> {
    const httpParams = new HttpParams()
      .set("page", params.page)
      .set("size", params.pageSize)
      .set("sort", `${params.sort.field},${params.sort.ascOrDesc}`);
    return this.httpClient
      .post<IPersonResponse>(
        `${environment.apiUrl}/person/fromGroup/xUser/${groupId}`,
        searchPersonQuery,
        { params: httpParams }
      )
      .pipe(shareReplay())
      .pipe(
        map((persons) => ({
          ...persons,
          content: this.mapPersonRole(persons.content),
        }))
      );
  }
  private mapPersonRole(persons) {
    const roleMappings = {
      [PersonRole.administrator]:
        this._translateService.instant("Company Admin"),
      [PersonRole.userAdministrator]:
        this._translateService.instant("Customer Creator"),
      [PersonRole.deliverySubmitter]:
        this._translateService.instant("Data Submitter"),
      [PersonRole.entry]: "Entry",
    };

    return persons.map((person) => {
      const mappedRoles = person.roles.map(
        (role) => roleMappings[role] || role
      );
      const formattedRoles = mappedRoles.join(", ");
      return { ...person, roles: formattedRoles };
    });
  }
  public createOreditXUser(
    data,
    accountNumber,
    groupId: string,
    personId: string
  ) {
    const update = personId;
    if (update) {
      return this.httpClient
        .post<IPerson>(`${environment.apiUrl}/person/editXUser`, {
          accountDataDto: { ...data.accountDataDto, accountType: undefined },
          accountType: data.accountType,
          groupId: groupId,
          id: personId,
        })
        .pipe(
          catchError((err) => {
            this.statusMessage("snack-bar-fail", "Failed to update person");
            return throwError(err);
          })
        );
    }
    return this.httpClient
      .post<IPerson>(`${environment.apiUrl}/person/editXUser`, {
        accountDataDto: {
          ...data,
          accountType: undefined,
          accountNumber: undefined,
          groupsForSupporter: undefined,
          activate: undefined,
        },
        accountType: data.accountType,
        accountNumber: `${accountNumber}`,
        groupId: groupId,
      })
      .pipe(
        catchError((err) => {
          if (err.errorMessage === "Person already exists") {
            this.statusMessage("snack-bar-fail", "Person already exists");
            return throwError(err);
          }
          this.statusMessage("snack-bar-fail", "Failed to create person");
          return throwError(err);
        })
      );
  }

  public getPersoAdditionalData(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/person/${id}`);
  }
  public deletePerson(id: string) {
    return this.httpClient
      .delete<IPerson>(`${environment.apiUrl}/person/delete/${id}`)
      .pipe(
        catchError((err) => {
          this.statusMessage("snack-bar-fail", "Failed to delete person");
          return throwError(err);
        })
      );
  }
  public deleteXUser(id: string) {
    return this.httpClient
      .delete<IPerson>(`${environment.apiUrl}/person/delete/xUser/${id}`)
      .pipe(
        catchError((err) => {
          this.statusMessage("snack-bar-fail", "Failed to delete Admin User");
          return throwError(err);
        })
      );
  }
  public resetPersonPassword(id: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/person/resetPassword/${id}`, {})
      .pipe(
        catchError((err) => {
          this.statusMessage("snack-bar-fail", "Failed to reset password");
          return throwError(err);
        })
      );
  }

  public getGroupsForSupporter(supporterId: string) {
    return this.httpClient.get<IGroup[]>(
      `${environment.apiUrl}/person/getGroups/${supporterId}`
    );
  }

  public addSupporterToGroup(supporterId: string, groupsId: string[]) {
    return this.httpClient.post<string[]>(
      `${environment.apiUrl}/person/addToGroup/${supporterId}`,
      groupsId
    );
  }

  public activateDeactivate(groupId: string, userId: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/group/activate/${groupId}`, userId)
      .pipe(shareReplay());
  }

  public getRoles(id: string, groupName?: string) {
    return this.httpClient.get<DocumentVisibility[]>(
      `${environment.apiUrl}/person/getXUserRoles?id=${id}&tenantId=${groupName}`
    );
  }

  public postRoles(id: string, documentsVisibility, groupName?: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/person/editXUserRoles?id=${id}&tenantId=${groupName}`,
      documentsVisibility
    );
  }

  public getCompanies(id: string) {
    return this.httpClient.get<AssignAdminToCompaniesResponse>(
      `${environment.apiUrl}/person/getAdminCompanies?id=${id}`
    );
  }

  public postCompanies(id: string, companies: AssignAdminToCompaniesResponse) {
    return this.httpClient.post<AssignAdminToCompaniesResponse>(
      `${environment.apiUrl}/person/assignAdminToCompany?id=${id}`,
      companies
    );
  }

  public removeUser(id: string, groupName?: string) {
    return this.httpClient.delete<AssignAdminToCompaniesResponse>(
      `${environment.apiUrl}/person/remove/xUser?id=${id}&tenantId=${groupName}`
    );
  }
}
