import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../auth/services/auth.service";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { PersonRole } from "../enum/personRole.enum";
import { GroupService } from "../group.service";

@Component({
  selector: "sv-group-prefixes",
  templateUrl: "./group-prefixes.component.html",
  styleUrls: ["./group-prefixes.component.scss"],
})
export class GroupPrefixesComponent implements OnInit {
  public companyPrefixes: UntypedFormGroup;
  public allPrefixes = [];
  public data;
  public prefixIndex = 0;
  public keepZeros = false;
  public numberOfOldData;
  public isSuperAdmin: boolean;
  public subCompanyPrefixValidators: ValidatorFn[];
  constructor(
    public authService: AuthService,
    public groupService: GroupService,
    private matDialogRef: MatDialogRef<GroupPrefixesComponent>,
    private _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.isSuperAdmin = this._userPermissionService.hasRight(PersonRole.admin);
    this.data = data ? [...data.data] : [];
    const allowDuplicate = data.allowDuplicate;
    this.subCompanyPrefixValidators = !allowDuplicate
      ? [this.subCompanyPrefixValidator()]
      : [];
    this.companyPrefixes = new UntypedFormGroup({});
    this.initialiseForm();
  }

  ngOnInit(): void {}

  subCompanyPrefixValidator(): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      // Get all form controls that start with "subCompanyPrefix"
      const subCompanyPrefixControls = Object.keys(
        this.companyPrefixes.controls
      )
        .filter((key) => key.startsWith("subCompanyPrefix"))
        .map((key) => this.companyPrefixes.get(key));

      // Get the values of these controls
      const subCompanyPrefixValues = subCompanyPrefixControls.map(
        (control) => control.value
      );

      // Filter out empty strings from the values
      const nonEmptySubCompanyPrefixValues = subCompanyPrefixValues.filter(
        (value) => value.trim() !== ""
      );

      // Check for duplicates among non-empty strings
      const uniqueNonEmptySubCompanyPrefixes = new Set(
        nonEmptySubCompanyPrefixValues
      );

      if (
        nonEmptySubCompanyPrefixValues.length !==
        uniqueNonEmptySubCompanyPrefixes.size
      ) {
        return { sameSubCompanyPrefix: true };
      }

      // Check if there's more than one empty string
      const emptyStringCount = subCompanyPrefixValues.filter(
        (value) => value.trim() === ""
      ).length;

      if (emptyStringCount > 1) {
        return { sameSubCompanyPrefix: true };
      }

      return null;
    };
  }

  public closeDialog(): void {
    this.matDialogRef.close("close");
  }

  private initialiseForm() {
    if (this.data.length) {
      for (let i = 0; i < this.data.length; i++) {
        this.companyPrefixes.addControl(
          `userPrefix${i}`,
          new UntypedFormControl(
            { value: this.data[i].userPrefix, disabled: true },
            [Validators.required]
          )
        );
        this.companyPrefixes.addControl(
          `subCompanyPrefix${i}`,
          new UntypedFormControl(
            { value: this.data[i].subCompanyPrefix, disabled: true },
            [this.subCompanyPrefixValidator()]
          )
        );
        this.companyPrefixes.addControl(
          `subCompanyName${i}`,
          new UntypedFormControl(
            { value: this.data[i].subCompanyName, disabled: true },
            [Validators.required]
          )
        );
        this.companyPrefixes.addControl(
          `identPrefixLength${i}`,
          new UntypedFormControl(
            { value: this.data[i].identPrefixLength, disabled: true },
            [Validators.required]
          )
        );
        this.companyPrefixes.addControl(
          `keepZeros${i}`,
          new UntypedFormControl({
            value: this.data[i].keepZeros || false,
            disabled: true,
          })
        );

        this.prefixIndex = i;
        this.numberOfOldData = i;
      }
    } else {
      this.companyPrefixes.addControl(
        `userPrefix${this.prefixIndex}`,
        new UntypedFormControl(null, [Validators.required])
      );
      this.companyPrefixes.addControl(
        `subCompanyPrefix${this.prefixIndex}`,
        new UntypedFormControl("", [this.subCompanyPrefixValidator()])
      );
      this.companyPrefixes.addControl(
        `subCompanyName${this.prefixIndex}`,
        new UntypedFormControl(null, [Validators.required])
      );
      this.companyPrefixes.addControl(
        `identPrefixLength${this.prefixIndex}`,
        new UntypedFormControl(null, [Validators.required])
      );
      this.companyPrefixes.addControl(
        `keepZeros${this.prefixIndex}`,
        new UntypedFormControl(false)
      );

      this.data.push(this.companyPrefixes.value);
    }
  }

  public addCompanyPrefix(e) {
    e.preventDefault();
    this.companyPrefixes.markAllAsTouched();
    this.companyPrefixes.updateValueAndValidity();
    if (
      !this.companyPrefixes.valid &&
      this.companyPrefixes.status !== "DISABLED"
    ) {
      return;
    }
    this.prefixIndex += 1;
    this.companyPrefixes.addControl(
      `userPrefix${this.prefixIndex}`,
      new UntypedFormControl(null, [Validators.required])
    );
    this.companyPrefixes.addControl(
      `subCompanyPrefix${this.prefixIndex}`,
      new UntypedFormControl("", [this.subCompanyPrefixValidator()])
    );
    this.companyPrefixes.addControl(
      `subCompanyName${this.prefixIndex}`,
      new UntypedFormControl(null, [Validators.required])
    );
    this.companyPrefixes.addControl(
      `identPrefixLength${this.prefixIndex}`,
      new UntypedFormControl(null, [Validators.required])
    );
    this.companyPrefixes.addControl(
      `keepZeros${this.prefixIndex}`,
      new UntypedFormControl(false)
    );

    this.data.push({
      ["userPrefix" + this.prefixIndex]: "",
      ["subCompanyPrefix" + this.prefixIndex]: "",
      ["subCompanyName" + this.prefixIndex]: "",
      ["identPrefixLength" + this.prefixIndex]: 0,
      ["keepZeros" + this.prefixIndex]: false,
    });
  }

  public submit() {
    this.companyPrefixes.markAllAsTouched();
    this.companyPrefixes.updateValueAndValidity();
    if (this.companyPrefixes.valid) {
      for (let i = 0; i <= this.prefixIndex; i++) {
        this.allPrefixes.push({
          userPrefix: this.companyPrefixes.get(`userPrefix${i}`).value,
          subCompanyPrefix: this.companyPrefixes.get(`subCompanyPrefix${i}`)
            .value,
          subCompanyName: this.companyPrefixes.get(`subCompanyName${i}`).value,
          identPrefixLength: Number(
            this.companyPrefixes.get(`identPrefixLength${i}`).value
          ),
          keepZeros: this.companyPrefixes.get(`keepZeros${i}`).value,
        });
      }
      this.groupService.temporarilySavedCompanyPrefixes = this.allPrefixes;
      this.closeDialog();
    }
  }
}
