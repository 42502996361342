import { CdkRow } from "@angular/cdk/table";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { AuthService } from "../../../auth/services/auth.service";
import { OnSet } from "../../../shared/decorators/on-set/on-set.decorator";
export interface ITableColumnDefinition<T> {
  title: string;
  field: string;
  isSortable: boolean;
  reverseSortIndicator?: boolean;
  icons?: [
    {
      icon: string;
      valueCondition: any;
    }
  ];
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  toDisplayString?: (value: T) => string;
}

export interface ISelectedRow {
  [rowId: string]: boolean;
}

export type TableComponentActionItem<T> = {
  name: string;
  showIf: (row: T) => boolean;
  onClick: string;
};

export type TableComponentActionList<T> = Array<
  Record<string, TableComponentActionItem<T>>
>;

@Component({
  selector: "sv-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: "noop" },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "accent" } },
  ],
})
export class TableComponent<T> {
  viewPersonActionShow = true;
  resetPassActionShow = true;
  toggleActivatinActionShow = true;
  @ViewChildren("cdkRow") cdkRow: QueryList<CdkRow>;
  @OnSet("updateSelectionMap") @Input() data: T[];
  @Input() @OnSet("updateCdkColumnDefs") columns: ITableColumnDefinition<any>[];
  @Input() page: number;
  @Output() pageChange: EventEmitter<number>;
  @Input() sort: string;
  @Output() sortChange: EventEmitter<string>;
  @Input() selectable: boolean;
  @Input() cursorOnHover = false;
  @Input() @OnSet("updateSelectionMap") selected: any[];
  @Output() selectedChange: EventEmitter<any[]>;
  @Input() @OnSet("updateSelectionMap") selectIdField: string;
  @Input() isDeleting: boolean;
  @Input() selectGroup: boolean;
  // @Input() @OnSet("updateActions") actions: {
  //   actions: boolean;
  //   hideAction: [string];
  // };
  @Input() actions: TableComponentActionList<T>;
  @Output() handleRowClick: EventEmitter<T>;
  @Output() selectedGroup = new EventEmitter();
  @Output() actionEvent = new EventEmitter<{ tableData; action: string }>();
  styleTag;
  public isMasterSupporter: boolean;
  public isMiniSupporter: boolean;
  public isAdmin: boolean;
  public administrator: boolean;
  public bertSupporter: boolean;
  public accountNumber: string;
  public displayName: string;
  public selectedRow = -1;
  public areAllRowsSelected = false;
  public tableCellBackground = "";
  public tableCellTextColor = "";
  public addXml = false;
  public addPDF = false;
  public addXuser = false;
  public deactivate = false;
  public openEbox = false;
  // NOTE: For now we require selectIdField because of this optimization
  public selectionMap: ISelectedRow = {};

  public sortState = {
    Unsorted: "unsorted",
    Ascending: "ascending",
    Descending: "descending",
  };
  public cdkColumnDefs: string[] = [];
  public id;
  public displayActions: boolean;
  public tenant: string;
  public canEdit: boolean;
  public isAdminPayslip: boolean;
  public userAdmin: boolean;
  public tableHover;
  public toggleActivatinActionShowDatapart: any;
  public canEditDatapart: any;
  public resetPassActionShowDatapart: any;
  edit: boolean;
  create: boolean;
  delete: boolean;
  canDelete: boolean;
  rights: boolean;
  companies: boolean;
  canDeleteDatapart: any;
  deleteGroup: any;
  accountNumberUser: any;
  constructor(public auth: AuthService, private cdr: ChangeDetectorRef) {
    this.pageChange = new EventEmitter<number>();
    this.sortChange = new EventEmitter<string>();
    this.selectedChange = new EventEmitter<any[]>();
    this.handleRowClick = new EventEmitter<T>();
    this.auth.tenant.subscribe((res) => {
      this.tenant = res;
    });
  }

  public getButtonKeys(buttonGroup: any): string[] {
    return Object.keys(buttonGroup);
  }

  public updateCdkColumnDefs() {
    if (this.columns && Array.isArray(this.columns)) {
      this.cdkColumnDefs = this.columns.map((col) => col.field);
    }
  }
  public onHover(i) {
    this.cursorOnHover = !this.cursorOnHover;
  }
  public cycleSort(column: ITableColumnDefinition<any>) {
    // Disable sort by configuration for field
    if (!column.isSortable) {
      return;
    }
    const state = this.getColumnSortState(column.field);
    let newSort;
    switch (state) {
      case this.sortState.Unsorted:
      case this.sortState.Descending: {
        newSort = column.field;
        break;
      }
      case this.sortState.Ascending: {
        newSort = `-${column.field}`;
        break;
      }
    }
    this.sortChange.emit(newSort);
  }

  public getColumnSortState(field: string) {
    let state;
    if (this.sort && field === this.sort) {
      state = this.sortState.Ascending;
    } else if (this.sort && field === this.sort.slice(1)) {
      state = this.sortState.Descending;
    } else {
      state = this.sortState.Unsorted;
    }
    return state;
  }

  // select/deselect all of deliveries
  public toggleAllSelection() {
    if (this.data.length === 0) {
      return;
    }
    if (!this.areAllRowsSelected) {
      this.selectedChange.emit(
        this.data.map((dataItem) => dataItem[this.selectIdField])
      );
      this.areAllRowsSelected = true;
    } else {
      this.selectedChange.emit([]);
    }
  }
  // select/deselect single delivery
  public toggleSelection(row: any) {
    const rowId = row[this.selectIdField];
    const currentState = this.selectionMap[rowId];
    if (currentState) {
      const index = this.selected.indexOf(rowId);
      if (index !== -1) {
        // keep the selected value immutable, create new arrays
        const newSelected = [].concat(this.selected);
        newSelected.splice(index, 1);
        this.selectedChange.emit(newSelected);
      }
    } else {
      this.selectedChange.emit(this.selected.concat([rowId]));
    }
  }

  public updateSelectionMap() {
    Array.from({ length: this.data?.length }, (_, i) => i);

    this.selectionMap = {};
    if (this.selected && Array.isArray(this.selected) && this.selectIdField) {
      // also update whether all items are selected
      if (!this.data.length) {
        return;
      }
      this.data.forEach((dataItem) => {
        const rowId = dataItem[this.selectIdField];
        this.selectionMap[rowId] = this.selected.indexOf(rowId) !== -1;
        this.areAllRowsSelected =
          this.areAllRowsSelected && this.selectionMap[rowId];
      });
    }
  }

  public emitSelectGroup(group): void {
    this.selectedGroup.emit(group);
  }
  public dispatchAction(event, tableData, action: string): void {
    event.stopPropagation();
    this.actionEvent.emit({ tableData, action });
  }
  public rowClick(row: T) {
    this.handleRowClick.emit(row);
  }

  getItemStyle(index: number, isHovered: boolean): any {
    this.auth.backgroundColors.subscribe((res) => {
      this.tableCellBackground = res?.tableCellBackground;
      this.tableCellTextColor = res?.tableCellTextColor;
      this.tableHover = res?.tableHoverColor;
    });

    let backgroundColor;
    if (index % 2 === 0) {
      backgroundColor = this.tableCellBackground;
    }
    if (isHovered) {
      backgroundColor = this.tableHover;
    }

    return { "background-color": backgroundColor };
  }
}
