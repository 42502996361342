<div class="page-container">
    <div class="page-content">
      <h1>FAQ</h1>
    <!-- Super Admin -->
      <tp-accordion *ngIf="isSuperAdmin">
        <!-- Group 1 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_1="accordionHeader">
            <strong>Wie kann ich eine Firma anlegen?</strong>
            <div *ngIf="!group_1.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_1.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_1.isOpen ? 'open' : 'closed'">
            <p>
                Eine Firma kann angelegt werden, indem man auf der Startseite oben rechts den blauen Button „Firma erstellen“ klickt. Es öffnet sich ein Eingabefeld bei dem diverse Felder (z.B. Anzeigename Firma, SAP Auftraggeber, Beschreibung, E-Mail Adresse etc.) befüllt werden können. Mit Klick auf „absenden“ wird die Firma erstellt und ist somit in der Liste auf der Startseite unter „Firmen“ ersichtlich. Für die SAP Auftragsnummer muss die Firma zuerst in SAP angelegt werden. Das Firmen-Prefix wird mit der jeweiligen Firma vereinbart und wird für die Erstellung der Benutzernamen der Firmen-Mitarbeiter verwendet (Firmen-Prefix + Personalnumme z.B. POSTx350). 
            </p>
            <p>
              <img [src]="isEdeja ? temporaryEnvironment.apiUrl  + '/resources/branding/create-company-button.png' : newUrl + '/resources/branding/create-tenant.png'" alt="">
              <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/create-tenant.png' : newUrl + '/resources/branding/create-tenant.png'" alt="">
            </p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 2 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_2="accordionHeader">
            <strong>Wie kann ich eine Firma bearbeiten? </strong>
            <div *ngIf="!group_2.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_2.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_2.isOpen ? 'open' : 'closed'">
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Nun können die Daten der Firmen bearbeitet werden. Kleiner Tipp: Man kann auch die Suchfunktion verwenden, um nach einer Firma zu suchen. Dafür z.B. den Namen der Firma bei der Suchleiste „Anzeigename“ eingeben und auf „Suche“ klicken.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-company-button.png' : newUrl + '/resources/branding/edit-company-button.png' " alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 3 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_3="accordionHeader">
            <strong>Wie kann ich ein XML Template zuweisen?</strong>
            <div *ngIf="!group_3.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_3.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_3.isOpen ? 'open' : 'closed'">
            <p>
              Für alle Firmen, die ihre e-Gehaltszettel im XML-Format einliefern (abhängig von der verwendeten Lohverrechnungssoftware z.B. SAP, DPW, LOGA, CPU) muss das entsprechende XML-Template zugewiesen werden. Dafür bei den benötigten Templates (es können auch mehrere ausgewählt werden z.B. für Lohnzettel und Zeitabrechnung) ein Hakerl setzen. 
            </p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 4 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_4="accordionHeader">
            <strong>Wie kann ich ein PDF Dictionary zuweisen?</strong>
            <div *ngIf="!group_4.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_4.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_4.isOpen ? 'open' : 'closed'">
            <p>Für alle Firmen, die ihre e-Gehaltszettel im PDF-Format mittels Mapping einliefern (abhängig von der verwendeten Lohverrechnungssoftware z.B. BMD) muss das entsprechende PDF Dictionary(Mappingcode) zugewiesen werden. Dafür bei den benötigten Dictionary ein Hakerl setzen. </p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 5 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_5="accordionHeader">
            <strong>Wie kann ich neue Mitarbeiter/Benutzer für eine Firma anlegen?</strong>
            <div *ngIf="!group_5.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_5.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_5.isOpen ? 'open' : 'closed'">
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Oben rechts befindet sich ein blauer Button „Neuen Benutzer anlegen“. Mit Klick auf diesen Button öffnet sich eine Maske, um neue Benutzer für die Firma anzulegen. Nun gibt es zwei Optionen. Entweder können neue Benutzer manuell oder via CSV-Anlage angelegt werden. Bei der manuellen Anlage müssen alle Daten des neuen Benutzers eingegeben werden.
            </p>
            <p>
              Bei der CSV-Anlage kann ein CSV-File hochgeladen werden und die Benutzer werden automatisch angelegt. Die letztere Option ist sinnvoll, wenn mehrere Benutzer angelegt werden müssen, z.B. im Zuge eines Onboardings eines neuen Kunden.
            </p>
            <p>
              Bei der Benutzeranlage ist es wichtig, im Feld „Personalnummer“ die Personalnummer exakt so einzugeben, wie sie uns vom Personalverrechnungssystem übermittelt wird.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/user-page-button.png' : newUrl + '/resources/branding/user-page-button.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/new-user-button.png' : newUrl + '/resources/branding/new-user-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 6 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_6="accordionHeader">
            <strong>Welches Format muss das CSV-File haben, falls ich die Mitarbeiter/Benutzer Anlage per CSV-Upload wähle?</strong>
            <div *ngIf="!group_6.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_6.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_6.isOpen ? 'open' : 'closed'">
            <p>Das CSV-File muss ein bestimmtes Format erfüllen, um verarbeitet werden zu können:</p>
            <ul>
              <li><strong>givenName:</strong> Der Vorname einer Person.</li>
              <li><strong>familyName:</strong> Der Nachname einer Person.</li>
              <li><strong>streetName:</strong> Der Name der Straße, in der die Person wohnt.</li>
              <li><strong>houseNumber:</strong> Die Hausnummer der Adresse der Person.</li>
              <li><strong>zipCode:</strong> Die Postleitzahl des Wohnortes der Person.</li>
              <li><strong>city:</strong> Die Stadt, in der die Person wohnt.</li>
              <li><strong>country:</strong> Das Landeskürzel, in dem die Person wohnt, als ISO-Code (z.B. "AT" für Österreich).</li>
              <li><strong>dateOfBirth:</strong> Das Geburtsdatum der Person, im Format JJJJ-MM-TT (Jahr-Monat-Tag).</li>
              <li><strong>sex:</strong> Das Geschlecht der Person, "male" für männlich oder "female" für weiblich.</li>
              <li><strong>accountNumber:</strong> Die Personalnummer (oder eindeutiger Identifikator) der Person.</li>
              <li><strong>phoneNumber:</strong> Die Telefonnummer der Person, diese Angabe ist optional, aber empfohlen.</li>
              <li><strong>email:</strong> Die E-Mail-Adresse der Person, diese Angabe ist ebenfalls optional, aber empfohlen.</li>
            </ul>
            <p>Wir empfehlen, zumindest entweder phoneNumber oder email anzugeben, damit der automatisierte Versand der Anmeldeinformationen stattfinden kann. Das CSV sollte UTF-8 codiert sein, damit Sonderzeichen usw. korrekt übertragen werden.</p>
            <p><a [href]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/example.csv' : newUrl + '/resources/branding/example.csv'">Beispiel Herunterladen</a></p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 7 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_7="accordionHeader">
              <strong>Wie kann ich neue Admin Benutzer für eine Firma anlegen?</strong>
            <div *ngIf="!group_7.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_7.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_7.isOpen ? 'open' : 'closed'">
            <p class="pb-20">
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Admin Benutzer“. Nun öffnen sich alle Admin-Benutzer der jeweiligen Firma. Oben rechts befindet sich ein blauer Button „Admin Benutzer anlegen“. Mit Klick auf diesen Button öffnet sich eine Maske, um neue Admin Benutzer für die Firma anzulegen. Sobald der Admin Benutzer angelegt wurde, erscheint dieser in der Admin Benutzer Liste der jeweiligen Firma. Nun kann der Admin Benutzer bearbeitet, gelöscht oder seine Admin Rechte bearbeitet werden.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/new-company-admin-button.png': newUrl + '/resources/branding/new-company-admin-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 8 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_8="accordionHeader">
            <strong>Wie kann ich Admin Benutzer einer Firma verwalten/bearbeiten?</strong>
            <div *ngIf="!group_8.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_8.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_8.isOpen ? 'open' : 'closed'">
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Admin Benutzer“. Nun öffnen sich alle Admin-Benutzer der jeweiligen Firma. Nun kann ein beliebiger Admin Benutzer angeklickt werden und es öffnet sich automatisch die Bearbeitungsmaske. Alternativ kann man auch auf die Schaltfläche „bearbeiten“ klicken.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/company-admins-button.png' : newUrl + '/resources/branding/company-admins-button.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-company-admin-button.png' : newUrl + '/resources/branding/edit-company-admin-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 10 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_10="accordionHeader">
            <strong>Wie kann ich Mitarbeiter/Benutzer einer Firma bearbeiten?</strong>
            <div *ngIf="!group_10.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_10.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_10.isOpen ? 'open' : 'closed'"
          >
          <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Es kann ein beliebiger Benutzer angeklickt werden woraufhin sich eine Maske öffnet, mit der man den Benutzer bearbeiten kann. Alternativ kann man auf die Schaltfläche „bearbeiten“ klicken.</p> 
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/user-page-button.png' : newUrl + '/resources/branding/user-page-button.png'" alt="">
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-user-button.png' : newUrl + '/resources/branding/edit-user-button.png'" alt="">
        </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 11 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_11="accordionHeader">
            <strong>Wie kann ich ein Passwort für die Benutzer/Mitarbeiter einer Firma wieder herstellen?</strong>
            <div *ngIf="!group_11.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_11.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_11.isOpen ? 'open' : 'closed'"
          >
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Nachdem man den richtigen Benutzer in der Liste gefunden hat, kann man per Klick auf „Passwort wiederherstellen“ das Passwort des Benutzers wiederherstellen. Der Benutzer bekommt ein E-Mail auf seine hinterlegte E-Mail-Adresse einem Passwort-Reset Link.</p>
            <p>Sollte weder E-Mail Adresse noch Telefonnummer hinterlegt sein, bekommt der Admin Benutzer die Möglichkeit ein PDF-Dokument herunterzuladen, in dem der Benutzername und das Passwort abgebildet sind. Kleiner Tipp: Benutzer einer Firma können auch über die Suchfunktion ganz einfach gefunden werden.</p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 12 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_12="accordionHeader">
            <strong>Wie kann ich Benutzer/Mitarbeiter deaktivieren?</strong>
            <div *ngIf="!group_12.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_12.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_12.isOpen ? 'open' : 'closed'"
          >
          <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Nachdem man den richtigen Benutzer*in in der Liste gefunden hat, kann man per Klick auf „deaktivieren“ den Benutzer*in deaktivieren.</p>
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/deactivate-user-button.png' : newUrl + '/resources/branding/deactivate-user-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 13 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_13="accordionHeader">
            <strong>Wie kann ich eine Firma aktivieren/deaktivieren?</strong>
            <div *ngIf="!group_13.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_13.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_13.isOpen ? 'open' : 'closed'"
          >
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Nun scrollt man ganz herunter und kann dort die Checkbox „Firma ist aktiv“ anklicken. Ist die Firma auf inaktiv gestellt, können keine Einlieferungen seitens des Kunden getätigt werden.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/company-is-deactivated-checkbox.png' : newUrl + '/resources/branding/company-is-deactivated-checkbox.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 14 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_14="accordionHeader">
            <strong>Wie kann ich das SMS-Benachrichtigungsfeature für eine Firma aktivieren? </strong>
            <div *ngIf="!group_14.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_14.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_14.isOpen ? 'open' : 'closed'"
          >
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Nun scrollt man ganz herunter und kann eine die Checkbox <strong>„SMS“</strong> anklicken.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/sms-is-activated-checkbox.png' : newUrl + '/resources/branding/sms-is-activated-checkbox.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 15 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_15="accordionHeader">
             <strong>Wie kann ich einen Secure Code aktivieren?</strong>
            <div *ngIf="!group_15.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_15.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_15.isOpen ? 'open' : 'closed'"
          >
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Nun scrollt man ganz herunter und kann eine die Checkbox <strong>„Secure-Code“</strong> anklicken. Wenn das Feld Secure-Code aktiviert ist wird zusätzlich bei der Erstellung des Benutzernamens eines Mitarbeiters eine 4-stellige zufällig generierte Zahlenkombination zwischen Firmen-Prefix und Personalnummer eingefügt (z.B. für die Personalnummer 560 Benutzername POSTx6489560). Es handelt sich dabei um eine zusätzliche Sicherheit damit der Benutzername durch das Wissen der Personalnummer einer Person nicht so leicht rekonstruiert werden kann.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/secure-code-checkbox.png' : newUrl + '/resources/branding/secure-code-checkbox.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 16 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_16="accordionHeader">
            <strong>Wie kann ich ein Logo für einen Kunden hochladen?</strong> 
            <div *ngIf="!group_16.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_16.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_16.isOpen ? 'open' : 'closed'"
          >
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Dort kann das Logo eines Kunden geändert, oder neu hochgeladen werden. Dafür einfach auf die Schaltfläche beim Firmenlogo klicken und das entsprechende Logo hochladen. Tipp: Man kann auch die Suchfunktion verwenden, um nach einer Firma zu suchen. Dafür z.B. den Namen der Firma bei der Suchleiste „Anzeigename“ eingeben und auf „Suche“ klicken.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-company-button.png' : newUrl + '/resources/branding/edit-company-button.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/upload-logo.png' : newUrl + '/resources/branding/upload-logo.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 15 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_17="accordionHeader">
            <strong>Wie kann ich eine bestehende Person zu einer weiteren Firmen hinzufügen?</strong>
            <div *ngIf="!group_17.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_17.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_17.isOpen ? 'open' : 'closed'"
          >
            <ol>
              <li>Firma auswählen</li>
              <li>„Benutzer“ klicken</li>
              <li>Gesuchten Benutzer suchen und rechts „Firmen“ klicken</li>
              <li>Neue Firma suchen und Häkchen setzen</li>
              <li>Personalnummer eintragen</li>
              <li>Abschließen</li>
            </ol>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/assign-user-to-companies.png' : newUrl + '/resources/branding/assign-user-to-companies.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
      </tp-accordion>

      <!-- Other roles -->
      <tp-accordion *ngIf="!isSuperAdmin">
        <!-- Group 1 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_1="accordionHeader">
            <strong>Wie kann ich einen e-Gehaltszettel einliefern?</strong>
            <div *ngIf="!group_1.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_1.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_1.isOpen ? 'open' : 'closed'">
            <p>
              e-Gehaltszettel können ganz einfach über den Button „Dokumente hochladen“ eingeliefert werden. Dafür den Button oben rechts klicken und die Dokumente per Drag and Drop, oder per Klick hochladen. Sollen die Dokumente per Serviceline hochgeladen werden, muss diese Einstellung im Drop-Down Menü angeklickt werden. Alternativ können die Dokumente per PDF Dictionary hochgeladen werden, diese Option muss dann ebenfalls im Drop-Down Menü gewählt werden. Sobald die Dokumente hochgeladen worden sind, klickt man auf „senden“ und die Verarbeitung läuft im Hintergrund. Die Dokumente werden den Mitarbeitern/Benutzern direkt zugestellt.
            </p>
            <p>
              Mehrere Dokumente können gemeinsam in einer ZIP-Datei hochgeladen werden. Wenn es sich um PDF-Dokumente im ZIP-Ordner handelt, muss wieder im Drop-Down Menü Serviceline oder das entsprechende PDF Dictionary ausgewählt werden.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/upload-documents.png' : newUrl + '/resources/branding/upload-documents.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/pdf-dictionary-select.png' : newUrl + '/resources/branding/pdf-dictionary-select.png'" alt="">
            <p>Es besteht auch die Möglichkeit, eine Massenzustellung durchzuführen, bei der ein Dokument an alle Mitarbeiter verteilt wird. Klicken Sie dazu auf die Schaltfläche "Massenupload".
              Laden Sie das Dokument durch Ziehen und Ablegen oder durch Klicken hoch. Geben Sie auch den Betreff für die Zustellung an, die die Mitarbeiter erhalten sollen. Sobald die Dokumente hochgeladen sind, klicken Sie auf "Senden" und die Verarbeitung läuft im Hintergrund. Die Dokumente werden direkt an alle Mitarbeiter/Nutzer zugestellt.</p>
              <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/mass-upload.png' : newUrl + '/resources/branding/mass-upload.png'" alt="">
              <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/subject-field.png' : newUrl + '/resources/branding/subject-field.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 2 -->
        <tp-accordion-group *ngIf="isAdministrator">
          <tp-accordion-header class="inner-header" #group_2="accordionHeader">
            <strong>Welche Dokumente können vom System zur Dokumenten Einlieferung verarbeitet werden?</strong>
            <div *ngIf="!group_2.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_2.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_2.isOpen ? 'open' : 'closed'">
            <p><strong>PDF-Dokumente:</strong> Diese Dokumentenart kann vom e-Gehaltszettelportal verarbeitet werden. Damit die Dokumente den passenden Benutzern zugeordnet werden können müssen sie entsprechend vorbereitet werden (siehe Frage „Wie muss ich ein PDF-Dokument vorbereiten, um es im e-Gehaltszettelportal zuzustellen?“).</p>
            <p><strong>XML-File:</strong> Diese Dokumentenart kann vom e-Gehaltszettelportal verarbeitet werden. Ganz einfach das XML-File unter der Funktion „Dokument hochladen“ absenden. Im Hintergrund wird das XML-File verarbeitet und den passenden Benutzern als PDF-Dokument in ihrer E-Box zugestellt.</p>
            <p><strong>TXT-Files:</strong> Diese Dokumentenart kann vom e-Gehaltszettelportal verarbeitet werden. Die Funktionsweise funktioniert genau wie bei den XML-Files.</p>
          </tp-accordion-content>
        </tp-accordion-group>
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_23="accordionHeader">
            <strong>Wie sehe ich welche e-Gehaltszettel eingeliefert worden sind (Upload-Statistik)?</strong>
            <div *ngIf="!group_23.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_23.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_23.isOpen ? 'open' : 'closed'">
            <p>
              Sobald ein Admin die e-Gehaltszettel eingeliefert hat, bekommt derjenige Admin auf seine hinterlegte E-Mail-Adresse eine E-Mail mit einer Zusammenfassung über die eingelieferten Gehaltszettel. Wichtig dabei ist, dass die E-Mail-Adresse des Admins richtig hinterlegt ist. Die E-Mail mit der Zusammenfassung wird direkt nach dem Upload versendet.
            </p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 24 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_24="accordionHeader">
            <strong>Wie muss ich ein PDF-Dokument vorbereiten, um es im e-Gehaltszettelportal zuzustellen?</strong>
            <div *ngIf="!group_24.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_24.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_24.isOpen ? 'open' : 'closed'">
            <p>
              Um ein PDF einer Person zuordnen zu können, muss dem PDF eine eindeutige Identifikation beigefügt werden – im Normalfall handelt es sich um die Personalnummer. Damit diese eindeutige Identifikation von unserem System ausgelesen werden kann, muss sie in einem strukturierten Datenformat aufgebracht werden. Hierzu gibt es zwei Möglichkeiten: „Serviceline“ oder „PDF-Keywords“.  
            </p>
            <ol>
              <li>Service-Line (bevorzugt)
                <p>Die Serviceline ist eine Datenstruktur, die in weißer Schrift auf dem PDF aufgebracht werden kann. Ein Beispiel für eine Serviceline sieht folgendermaßen aus:</p>
                <p>##sh#v="4.10.1";gid="123456";gn="Maria";fn="Musterfrau";st="Teststraße";dnr="1";zip=4810;cty="Testdorf";cc=AT;ds="Weihnachtsgrüße";m="maria.musterfrau&#64;test.at";$$sh$</p>
                <p>Die detaillierte Beschreibung der Serviceline können Sie hier herunterladen.</p>
                <p>Ein großer Vorteil der Serviceline ist, dass sie mit jedem beliebigen Programm erstellt werden kann, das auch PDFs erstellen kann (z.B. Microsoft Word). </p>
              </li>
              <li>PDF Meta-Daten 
                <p>Eine weitere Variante ist die Nutzung von PDF-Metadaten. Für weitere Informationen hierzu kontaktieren sie uns bitte via <a href="mailto:e-Gehaltszettel@post.at">e-Gehaltszettel&#64;post.at</a>.</p>
              </li>
            </ol>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 4 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_5="accordionHeader">
            <strong>Wie kann ich einen neuen Firmen Admin anlegen?</strong>
            <div *ngIf="!group_5.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_5.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_5.isOpen ? 'open' : 'closed'">
            <p>Auf der Startseite befindet sich die Liste mit den Firmen, die im e-Gehaltszettelportal angelegt sind. Bei jener Firma, bei der man den Admin Benutzer anlegen möchte, klickt man auf den Button „Admin Benutzer“. Nun öffnen sich alle Admin Benutzer der jeweiligen Firma. Oben rechts befindet sich ein blauer Button „Admin Benutzer anlegen“. Mit Klick auf diesen Button öffnet sich eine Maske, um neue Admin Benutzer für die Firma anzulegen. Sobald der Admin Benutzer angelegt wurde, erscheint dieser in der Admin Benutzer Liste der jeweiligen Firma. Nun kann der Admin Benutzer bearbeitet, gelöscht oder seine Admin Rechte bearbeitet werden. Möchte man wieder zu seiner Firmenansicht zurück, kann man einfach die „Zurücktaste im Browser verwenden“, oder auf „Firmen“ klicken.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/company-admins-button-for-company-admin.png' : newUrl + '/resources/branding/company-admins-button-for-company-admin.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/new-company-admin-button.png' : newUrl + '/resources/branding/new-company-admin-button.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/create-company-admin-form.png' : newUrl + '/resources/branding/create-company-admin-form.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 5 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_6="accordionHeader">
            <strong>Wie kann ich einen neuen Mitarbeiter/Benutzer anlegen?</strong>
            <div *ngIf="!group_6.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_6.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_6.isOpen ? 'open' : 'closed'">
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Mitarbeiter/Benutzer der jeweiligen Firma. Oben rechts befindet sich ein blauer Button „Neuen Benutzer anlegen“. Mit Klick auf diesen Button öffnet sich eine Maske, um neue Mitarbeiter/Benutzer für die Firma anzulegen. Nun gibt es zwei Optionen. Entweder können neue Mitarbeiter/Benutzer manuell oder via CSV-Anlage angelegt werden. Bei der manuellen Anlage müssen alle Daten des neuen Mitarbeiters/Benutzers eingegeben werden. Bei der CSV-Anlage kann ein CSV-File hochgeladen werden und die Mitarbeiter/Benutzer werden automatisch angelegt. Die letztere Option ist sinnvoll, wenn mehrere Mitarbeiter/Benutzer gleichzeitig angelegt werden sollen. Möchte man wieder zu seiner Firmenansicht zurück, kann man einfach die „Zurücktaste im Browser verwenden“, oder auf „Firmen“ klicken.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/user-page-button-for-company-admin.png' : newUrl + '/resources/branding/user-page-button-for-company-admin.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/new-user-button.png' : newUrl + '/resources/branding/new-user-button.png'" alt="">
            <p><strong>Manuelle Anlage: </strong>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/manuel-creation-of-user.png' : newUrl + '/resources/branding/manuel-creation-of-user.png'" alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/create-user-form.png' : newUrl +'/resources/branding/create-user-form.png' " alt="">
            </p>
            <p><strong>CSV Anlage: </strong>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/csv-creation-of-user.png' : newUrl +'/resources/branding/csv-creation-of-user.png' " alt="">
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/csv-form-for-user-creation.png' : newUrl + '/resources/branding/csv-form-for-user-creation.png'" alt="">
            </p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 6 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_7="accordionHeader">
            <strong>Wie bekommt der Mitarbeiter/Benutzer das Passwort?</strong>
            <div *ngIf="!group_7.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_7.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_7.isOpen ? 'open' : 'closed'">
            <p>Ist bei dem Mitarbeiter/Benutzer eine E-Mail-Adresse hinterlegt, erhält dieser sein Benutzername und Passwort automatisch per E-Mail. Ist keine E-Mail-Adresse hinterlegt, aber eine Telefonnummer wird Benutzername und Passwort per SMS direkt dem neuen Mitarbeiter/Benutzer zugestellt. Ist keines von beiden hinterlegt, erhält der Firmen Admin den Benutzernamen und Passwort per E-Mail. Zusätzlich öffnet sich automatisch ein Pop-Up Fenster, bei dem ein PDF-Dokument heruntergeladen werden kann, welches Benutzername und Passwort enthält.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/save-user-credetials.png' : newUrl + '/resources/branding/save-user-credetials.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 7 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_8="accordionHeader">
              <strong>Welches Format muss das CSV-File haben, falls ich die Mitarbeiter/Benutzer Anlage per CSV-Upload wähle?</strong>
            <div *ngIf="!group_8.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_8.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_8.isOpen ? 'open' : 'closed'">
            <p>
              Das CSV-File muss ein bestimmtes Format erfüllen, um verarbeitet werden zu können: 
            </p>
            <ul>
              <li><strong>givenName:</strong> Der Vorname einer Person.</li>
              <li><strong>familyName:</strong> Der Nachname einer Person.</li>
              <li><strong>streetName:</strong> Der Name der Straße, in der die Person wohnt.</li>
              <li><strong>houseNumber:</strong> Die Hausnummer der Adresse der Person.</li>
              <li><strong>zipCode:</strong> Die Postleitzahl des Wohnortes der Person.</li>
              <li><strong>city:</strong> Die Stadt, in der die Person wohnt.</li>
              <li><strong>country:</strong> Das Landeskürzel, in dem die Person wohnt, als ISO-Code (z.B. "AT" für Österreich).</li>
              <li><strong>dateOfBirth:</strong> Das Geburtsdatum der Person, im Format JJJJ-MM-TT (Jahr-Monat-Tag).</li>
              <li><strong>sex:</strong> Das Geschlecht der Person, "male" für männlich oder "female" für weiblich.</li>
              <li><strong>accountNumber:</strong> Die Personalnummer (oder eindeutiger Identifikator) der Person.</li>
              <li><strong>phoneNumber:</strong> Die Telefonnummer der Person, diese Angabe ist optional, aber empfohlen.</li>
              <li><strong>email:</strong> Die E-Mail-Adresse der Person, diese Angabe ist ebenfalls optional, aber empfohlen.</li>
            </ul>
            <p>Wir empfehlen, zumindest entweder phoneNumber oder email anzugeben, damit der automatisierte Versand der Anmeldeinformationen stattfinden kann. Das CSV sollte UTF-8 codiert sein, damit Sonderzeichen usw. korrekt übertragen werden.</p>
            <p><a [href]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/example.csv' : newUrl + '/resources/branding/example.csv'">Beispiel Herunterladen</a></p>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 8 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_9="accordionHeader">
            <strong>Wie kann ich Daten meiner Firma ändern?</strong>
            <div *ngIf="!group_9.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_9.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content [@openClose]="group_9.isOpen ? 'open' : 'closed'">
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, kann diese einfach angeklickt werden und es öffnet sich die „Firma bearbeiten“ Maske. Nun können die Daten der Firmen bearbeitet werden. Alternativ kann man auch auf „bearbeiten“ klicken.
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-company-for-company-admin.png' : newUrl + '/resources/branding/edit-company-for-company-admin.png' " alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 10 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_11="accordionHeader">
            <strong>Wie kann ich ein Passwort für meine Mitarbeiter/Benutzer wiederherstellen?</strong>
            <div *ngIf="!group_11.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_11.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_11.isOpen ? 'open' : 'closed'"
          >
          <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal für Sie zugänglich sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Nachdem man den richtigen Benutzer in der Liste gefunden hat, kann man per Klick auf „Passwort wiederherstellen“ das Passwort des Benutzers wiederherstellen. Der Benutzer bekommt ein E-Mail auf seine hinterlegte E-Mail-Adresse mit seinem Passwort. Bitter vorher überprüfen, ob die gewünschte E-Mail-Adresse beim Mitarbeiter hinterlegt ist und gegebenenfalls über den Menüpunkt „Bearbeiten“ ändern.  Sollte keine E-Mail-Adresse oder Telefonnummer hinterlegt sein, bekommt der Admin zusätzlich die Möglichkeit ein PDF-Dokument herunterzuladen, in dem der Benutzername und das Passwort abgebildet sind. Kleiner Tipp: Benutzer einer Firma können auch über die Suchfunktion ganz einfach gefunden werden. Es kann nach Anzeigename, Personalnummer, E-Mail oder Benutzername gesucht werden.</p> 
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/user-page-button-for-company-admin.png' : newUrl + '/resources/branding/user-page-button-for-company-admin.png'" alt="">
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/password-change-for-company-admin.png' : newUrl + '/resources/branding/password-change-for-company-admin.png'" alt="">
          <p>Verwendung der Suchfunktion: </p>
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/search-companies-for-company-admin.png' : newUrl + '/resources/branding/search-companies-for-company-admin.png'" alt="">
        </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 11 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_12="accordionHeader">
            <strong>Wie kann ich Daten von meinen Mitarbeitern/Benutzern bearbeiten?</strong>
            <div *ngIf="!group_12.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_12.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_12.isOpen ? 'open' : 'closed'"
          >
            <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Es kann ein beliebiger Benutzer angeklickt werden woraufhin sich eine Maske öffnet, mit der man den Mitarbeiter/Benutzer bearbeiten kann. Alternativ kann man auch auf „Bearbeiten“ klicken. Kleiner Tipp: Mitarbeiter/Benutzer einer Firma können auch über die Suchfunktion ganz einfach gefunden werden. Es kann nach Anzeigename, Personalnummer, E-Mail oder Benutzername gesucht werden.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/edit-user-button.png' : newUrl + '/resources/branding/edit-user-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 12 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_13="accordionHeader">
            <strong>Wie kann ich Mitarbeiter/Benutzer deaktivieren/aktivieren?</strong>
            <div *ngIf="!group_13.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_13.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_13.isOpen ? 'open' : 'closed'"
          >
          <p>Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Benutzer der jeweiligen Firma. Nachdem man den richtigen Mitarbeiter/Benutzer in der Liste gefunden hat, kann man per Klick auf „Deaktivieren“ den Mitarbeiter/Benutzer deaktivieren/aktivieren.</p>
          <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/deactivate-user-button.png' : newUrl + '/resources/branding/deactivate-user-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 13 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_14="accordionHeader">
            <strong>Wie kann ich eine weitere Personalnummer zu einem beliebigen Mitarbeiter/Benutzer hinzufügen?</strong>
            <div *ngIf="!group_14.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_14.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_14.isOpen ? 'open' : 'closed'"
          >
            <p>In manchen Fällen ist es von Vorteil, wenn einem Mitarbeiter/Benutzer mehrere Personalnummer zugeordnet werden können z.B., wenn derselbe Mitarbeiter/Benutzer im gleichen Unternehmen eine neue Personalnummer bekommt. Dafür muss der Mitarbeiter/Benutzer gesucht werden und die <strong>„bearbeiten“</strong> Maske geöffnet werden. Unter dem Feld Personalnummer kann auf das „+“ geklickt werden und weitere Personalnummern hinzugefügt werden. Das hat den Vorteil, dass der Mitarbeiter/Benutzer nur eine E-Box benötigt, egal wie viele Personalnummer er hat.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/add-account-number-button.png' : newUrl + '/resources/branding/add-account-number-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 14 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_15="accordionHeader">
            <strong>Wie kann ich überprüfen, ob ein e-Gehaltszettel zugestellt wurde?</strong>
            <div *ngIf="!group_15.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_15.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_15.isOpen ? 'open' : 'closed'"
          >
            <p>Um Aktivitäten im e-Gehaltszettelportal zu beobachten, eignet sich das Aktivitäten-Log. Dort finden sich alle Aktivitäten, die sich bei Ihrer Firmen in Bezug auf das e-Gehaltszettelportal abgespielt haben. Möchte man die Aktivität (z.B. Nutzer hat sich eingeloggt, Gehaltszettel wurde zugestellt etc.) eines einzelnen Nutzers anschauen, kann man ganz einfach die Suchfunktion verwenden und nach dem jeweiligen Mitarbeiter/Benutzer suchen. Nun erhält man eine Ansicht, bei der alle Aktivitäten aufgelistet sind, die dem Mitarbeiter/Benutzer zugeordnet werden können.</p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/activity-log.png' : newUrl + '/resources/branding/activity-log.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 15 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_16="accordionHeader">
             <strong>Wie kann ich als Admin den Inhalt der E-Box meiner Mitarbeiter/Benutzer einsehen?</strong>
            <div *ngIf="!group_16.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_16.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_16.isOpen ? 'open' : 'closed'"
          >
            <p>
              Auf der Startseite befindet sich die Liste mit allen Firmen, die im e-Gehaltszettelportal angelegt sind. Nachdem man eine beliebige Firma gefunden hat, klickt man auf den Button „Benutzer“. Nun öffnen sich alle Mitarbeiter/Benutzer der jeweiligen Firma. Nun klickt man auf einen beliebigen Mitarbeiter/Benutzer und klickt auf das Feld „E-Box“ öffnen, nun öffnet sich die E-Box des Mitarbeiters/Benutzers und kann eingesehen werden. Kleiner Tipp: Mitarbeiter/Benutzer einer Firma können auch über die Suchfunktion ganz einfach gefunden werden. 
            </p>
            <img [src]="isEdeja ? temporaryEnvironment.apiUrl + '/resources/branding/open-e-box-button.png' : newUrl + '/resources/branding/open-e-box-button.png'" alt="">
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 16 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_17="accordionHeader">
            <strong>Welche Schnittstellen bietet der e-Gehaltszettel an?</strong> 
            <div *ngIf="!group_17.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_17.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_17.isOpen ? 'open' : 'closed'"
          >
            <p>Ich schreibe eine Nachricht an Sevice Delivery Management (E-Mail: <a href="mailto:e-Gehaltszettel@post.at">e-Gehaltszettel&#64;post.at</a>) , mit den benötigten Infos (Personalnummer bestehende Firma, sechsstelliges Firmenkürzel der Firma, zu der der Benutzer hinzugefügt werden soll, sowie Personalnummer in der hinzuzufügenden Firma). </p>
            <ul>
              <li>SFTP</li>
              <li>SOAP / REST</li>
              <li>Legacy XML API (CURL)</li>
            </ul>
          </tp-accordion-content>
        </tp-accordion-group>
        <!-- Group 15 -->
        <tp-accordion-group>
          <tp-accordion-header class="inner-header" #group_18="accordionHeader">
            <strong>Ich habe mehrere Firmen. Wie kann ich einen bestehenden Mitarbeiter zu einer meiner weiteren Firmen hinzufügen? </strong>
            <div *ngIf="!group_18.isOpen">
              <mat-icon svgIcon="custom:angle-down" class="angle-icon"></mat-icon>
            </div>
            <div *ngIf="group_18.isOpen">
              <mat-icon svgIcon="custom:angle-up" class="angle-icon"></mat-icon>
            </div>
          </tp-accordion-header>
          <tp-accordion-content
            [@openClose]="group_18.isOpen ? 'open' : 'closed'"
          >
            <p>Ich schreibe eine Nachricht an Sevice Delivery Management (E-Mail: <a href="mailto:e-Gehaltszettel@post.at">e-Gehaltszettel&#64;post.at</a>) , mit den benötigten Infos (Personalnummer bestehende Firma, sechsstelliges Firmenkürzel der Firma, zu der der Benutzer hinzugefügt werden soll, sowie Personalnummer in der hinzuzufügenden Firma). </p>
          </tp-accordion-content>
        </tp-accordion-group>
      </tp-accordion>
    </div>
  </div>
  