import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { Header } from '../../../shared/interfaces/branding';

import { CustomTabService, IStaticPage } from '../../../custom-tab/services/custom-tab/custom-tab.service';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  selector: 'sv-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  public staticPages: IStaticPage[];
  public headerBranding: Observable<Header>

  constructor(
    private _customTabService: CustomTabService,
    public auth:AuthService,
    ) {
     
    this.auth.getStyles();
    // check is custom tabs exists in sesion storage
    if (!this._customTabService.getStaticPage()) {
      this._customTabService.fetchUserStaticPages().subscribe((res: IStaticPage[]) => {
        this.staticPages = res.map((staticPage: IStaticPage) => {
          return {
            ...staticPage,
            iconSrc: this._customTabService.getStaticPageIconByType(staticPage.icon),
          };
        });
      });
    } else {
      this.staticPages = this._customTabService.getStaticPage().map((staticPage: IStaticPage) => {
        return {
          ...staticPage,
          iconSrc: this._customTabService.getStaticPageIconByType(staticPage.icon),
        };
      });
    }
  }
}
