import { NgZone, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  constructor(private ngZone: NgZone) {}

  transform(
    items: any[],
    filter: string,
    customColumnProps: string[],
    handleFiltered: (fiteredData: any[]) => void
  ): string[] {
    let filteredItems = items;
    const hasOnlyWhiteSpace = filter.trim() === "";

    if (items && filter && !hasOnlyWhiteSpace) {
      const lowerCaseFilter = filter.toLowerCase();

      filteredItems = items.filter((item) =>
        customColumnProps.some((prop) =>
          item[prop]?.toLowerCase().includes(lowerCaseFilter)
        )
      );
    }

    if (handleFiltered && typeof handleFiltered === "function") {
      setTimeout(() => {
        this.ngZone.run(() => {
          handleFiltered(filteredItems);
        });
      }, 0);
    }

    return filteredItems;
  }
}
