import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, Subscription } from "rxjs";
// @ts-ignore
import * as _find from "lodash/find";

import { SupportViewConfig } from "../../../../config/config";
import { UserDataResource } from "../../../auth/classes/user-data-resource.class";
import { AuthService } from "../../../auth/services/auth.service";
import { PersonRole } from "../../../group/enum/personRole.enum";
import { Header } from "../../../shared/interfaces/branding";
import { ILanguageOption } from "../../../shared/interfaces/language-option.interface";
import { ILanguage } from "../../../shared/interfaces/language.interface";
import { UserPermissionService } from "../../../shared/services/user-permission/user-permission.service";
import { LogoutDialogComponent } from "../logout-dialog/logout-dialog.component";

@Component({
  selector: "sv-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy, AfterViewInit {
  @Input() public isUserMenuVisible: boolean;
  public languages: ILanguage;
  public userData: UserDataResource = this.auth.getUserData();
  // user
  public tenant: string;
  public userDisplayName: string;
  public email: string;
  public urlLogo: string;
  public lastLogin: string;
  public automaticLogoutTime: string;
  public logoutInterval: number;
  public headerBranding: Observable<Header>;
  // subscriptions
  public autoLogoutTimerResetedSubscription: Subscription;
  public onLangChangeSubscription: Subscription;
  // language
  public languageValues: ILanguageOption[] = [
    { value: "de", name: this._translateService.instant("German") },
    { value: "en", name: this._translateService.instant("English") },
  ];
  // set default browser languages
  private _language: ILanguageOption =
    _find(this.languageValues, {
      value:
        this.userData.userLanguage || this._translateService.getBrowserLang(),
    }) || this.languageValues[0];
  public isMasterSupporter: boolean;

  public get language() {
    return this._language;
  }

  public set language(newLanguage: ILanguageOption) {
    if (this._language !== newLanguage) {
      this.auth.saveUserLanguage(newLanguage.value).subscribe();
    }
    this._language = newLanguage;

    this._translateService.use(newLanguage.value.toLowerCase());
  }

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    public renderer: Renderer2,
    private _config: SupportViewConfig,
    private _translateService: TranslateService,
    private _userPermissionService: UserPermissionService
  ) {
    this.tenant = this.userData.tenantId;
    if (this.userData.userLanguage !== this._translateService.currentLang) {
      this.language =
        _find(this.languageValues, {
          value:
            this.userData.userLanguage ||
            this._translateService.getBrowserLang(),
        }) || this.languageValues[0];
    }
    this.languages = {
      selected:
        this._language && this._translateService.instant(this.language.name),
      options: this.languageValues,
    };
    this.isMasterSupporter = this._userPermissionService.hasRight(
      PersonRole.bertSupporter
    );
    this.lastLogin = moment(this.auth.getUserData().lastLogin).format(
      "DD.MM.YYYY, HH:mm:ss"
    );
    this.handleLogoutCountdown();
    this.setUserData();
    this.updateTranslations();
  }
  ngAfterViewInit(): void {
    const languagePlaceHolder = document.querySelector(
      ".mat-select-placeholder"
    );
    languagePlaceHolder &&
      this.renderer.setStyle(
        languagePlaceHolder,
        "color",
        JSON.parse(localStorage.getItem("languagePlaceHolderColor"))
      );
  }
  // method to get automatic logout countdown
  private handleLogoutCountdown() {
    this.autoLogoutTimerResetedSubscription =
      this.auth.autoLogoutTimerReseted.subscribe(() => {
        let timeToLogout = this._config.autoLogoutTimeOut;

        if (this.logoutInterval !== undefined) {
          window.clearInterval(this.logoutInterval);
        }

        this.logoutInterval = window.setInterval(() => {
          timeToLogout -= 1000;
          let autoLogoutMinutes = Math.floor(
            timeToLogout / 1000 / 60
          ).toString();
          let autoLogoutSeconds = Math.floor(
            (timeToLogout / 1000) % 60
          ).toString();

          if (parseInt(autoLogoutMinutes, 10) < 10) {
            autoLogoutMinutes = "0" + autoLogoutMinutes;
          }

          if (parseInt(autoLogoutSeconds, 10) < 10) {
            autoLogoutSeconds = "0" + autoLogoutSeconds;
          }

          this.automaticLogoutTime =
            autoLogoutMinutes + ":" + autoLogoutSeconds;
          // if logout countdown time expire, then stop countDown.
          if (this.automaticLogoutTime === "00:00") {
            window.clearInterval(this.logoutInterval);
          }
        }, 1000);
      });
  }
  // Setting user initial data
  private setUserData() {
    if (this.userData) {
      this.userDisplayName = this.userData.displayName;
      this.email = this.userData.email;
      this.urlLogo = this.auth.getLogoUrl();
    }
  }

  private updateTranslations() {
    this.onLangChangeSubscription =
      this._translateService.onLangChange.subscribe(() => {
        this.languageValues = this._config.availableLocales.map((local) => {
          return {
            name:
              local === "en"
                ? this._translateService.instant("English")
                : this._translateService.instant("German"),
            value: local,
          };
        });

        this.languages = {
          selected:
            this._language &&
            this._translateService.instant(this.language.name),
          options: this.languageValues,
        };

        // Set global moment localization
        moment.locale(this._language.value);
      });
  }

  public openLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      autoFocus: false,
    });

    const logoutDialogsubscription =
      dialogRef.componentInstance.onLogout.subscribe((isLogout) => {
        if (isLogout) {
          if (this.logoutInterval) {
            window.clearInterval(this.logoutInterval);
          }

          if (this.autoLogoutTimerResetedSubscription) {
            this.autoLogoutTimerResetedSubscription.unsubscribe();
          }
        }
      });
    dialogRef.afterClosed().subscribe(() => {
      logoutDialogsubscription.unsubscribe();
    });
  }

  public changeLanguage(lang: MatSelectChange) {
    const selectedLang: ILanguageOption = <ILanguageOption>(
      _find(this.languageValues, { value: lang.value })
    );
    this.language = selectedLang;
    this.userData.userLanguage = lang.value;
    this.auth.saveUserData(this.userData);
  }

  public openPasswordDataPage() {}

  ngOnDestroy() {
    if (this.autoLogoutTimerResetedSubscription) {
      this.autoLogoutTimerResetedSubscription.unsubscribe();
    }

    if (this.onLangChangeSubscription) {
      this.onLangChangeSubscription.unsubscribe();
    }

    this.dialog.closeAll();
  }
}
