import {
  throwError as observableThrowError,
  of as observableOf,
  Observable,
  Subscription,
  Subject,
  BehaviorSubject,
  pipe,
} from "rxjs";

import { tap, catchError, map, refCount, publish } from "rxjs/operators";
import { Injectable, EventEmitter, Renderer2 } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { MatDialog } from "@angular/material/dialog";

// @ts-ignore
import * as _assign from "lodash/assign";
import * as moment from "moment";

import { TokenRefreshResponseBody } from "../interfaces/api/token-refresh.interface";
import { environment } from "../../../environments/environment";
import { SupportViewConfig } from "../../../config/config";
import { UserLoginModel } from "../classes/user-login-model.class";
import { UserDataResource } from "../classes/user-data-resource.class";
import { ApiError } from "../../shared/classes/errors/api-error.class";
import { GeneralTableSettings } from "../../shared/classes/general-table-settings.class";
import { CustomTabs } from "../../shared/classes/custom-tabs.class";
import { GeneralSettings } from "../../shared/classes/general-settings.class";
import { AutoLogoutDialogComponent } from "../../dashboard/components/auto-logout-dialog/auto-logout-dialog.component";
import {
  BackgroundColors,
  Features,
  Footer,
  Header,
  InfoTextColors,
  LoginPage,
  Navigation,
  TextColors,
} from "../../shared/interfaces/branding";
import { PersonRole } from "../../group/enum/personRole.enum";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

const accessTokenStorageKey = "sv-access-token";
const refreshTokenStorageKey = "sv-refresh-token";
const userDataStorageKey = "sv-user-data";

@Injectable()
export class AuthService {
  public landingPageBranding = new BehaviorSubject<LoginPage>(null);
  public headerBranding = new BehaviorSubject<Header>(null);
  public navigationBranding = new BehaviorSubject<Navigation[]>(null);
  public footerBranding = new BehaviorSubject<Footer>(null);
  public backgroundColors = new BehaviorSubject<BackgroundColors>(null);
  public infoTextColors = new BehaviorSubject<InfoTextColors>(null);
  public textColors = new BehaviorSubject<TextColors>(null);
  public tenant = new BehaviorSubject<string>(null);
  public features = new BehaviorSubject<Features>(null);
  public timeout: number;
  public logoutSubscription: Subscription;
  public timeWhenUserLogedIn: string;
  public autoLogoutTimerReseted = new Subject();
  private _tokenRefreshObservable: Observable<string>;
  public onUserDataChange: EventEmitter<UserDataResource>;
  passwordResetToken: string;
  get isLoggedIn() {
    // Set the time when user loged in from local storage if timeWhenUserLogedIn does't exist
    if (
      !this.timeWhenUserLogedIn &&
      window.localStorage.getItem("login-time")
    ) {
      this.timeWhenUserLogedIn = window.localStorage.getItem("login-time");
    }
    return this.getAccessToken();
  }

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _config: SupportViewConfig,
    private _translate: TranslateService,
    private _snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.onUserDataChange = new EventEmitter<UserDataResource>();
  }

  getAccessToken() {
    return sessionStorage.getItem(accessTokenStorageKey);
  }

  saveUserLanguage(language: string) {
    return this._http.post(`${environment.apiUrl}/login/changeLanguage`, {
      userLanguage: language,
    });
  }

  login(user: UserLoginModel) {
    // setting the time when user loged in
    this.timeWhenUserLogedIn = moment().format("HH:mm");
    window.localStorage.setItem("login-time", this.timeWhenUserLogedIn);
    const url = `${environment.apiUrl}/login/user`;

    return this._http
      .post<UserLoginModel>(url, user, { observe: "response" })
      .pipe(
        map((response: HttpResponse<UserLoginModel>) => {
          const accessToken = response.headers.get("X-Auth-Token-Update");
          const refreshToken = response.headers.get("Refresh-Token");
          this.saveAccessToken(accessToken);
          this.saveRefreshToken(refreshToken);
          this.saveUserData(new UserDataResource(response.body));
          this.setGeneralSettings();
          return observableOf(null);
        }),
        catchError((response: ApiError) => {
          if (response.statusCode === "401") {
            response.errorMessage = this._translate.instant(
              "Username and/or password incorrect"
            );
          }
          return observableThrowError(response);
        })
      );
  }

  setGeneralSettings() {
    // We have two endpoints for setup custom tabs
    // Reason for this is because we don't know which endpoint we'll be use
    // The first place where we set up custom tabs is on generalInfo enpoint
    // If generalInfo don't have that property, we use generalInfo/tab endpoint
    // on custom-tab.service to set up custom tabs
    // Custom Tabs exist on the level of Tenants (API instances)
    return this._http.get(`${environment.apiUrl}/generalInfo`).pipe(
      map((res: GeneralSettings) => {
        this.savelogoUrl(res.logoUrl);
        this.saveCustomTabs(res.customTabList);
        this.saveGeneralTableSettings(res.allowedColumnsDTO.personViewTable);
      })
    );
  }

  getStyles() {
    // return this._http.get(`${environment.apiUrl}/generalInfo`).pipe(map((res:GeneralSettings) => {
    //   return {
    //     backgroundColor: res.brandingContainer.loginPage.backgroundColor,
    //     backgroundImage:res.brandingContainer.loginPage.backgroundImage,
    //     buttonColor:res.brandingContainer.loginPage.buttonColor
    //   }
    // }))
    this._http
      .get(`${environment.apiUrl}/generalInfo`)
      .subscribe((res: GeneralSettings) => {
        this.landingPageBranding.next(res.brandingContainer.loginPage);
        this.headerBranding.next(res.brandingContainer.header);
        this.navigationBranding.next(res.brandingContainer.navigation);
        this.footerBranding.next(res.brandingContainer.footer);
        this.backgroundColors.next(res.brandingContainer.backgroundColors);
        this.features.next(res.brandingContainer.features);
        this.tenant.next(res.brandingContainer.tenant);
        localStorage.setItem(
          "tenant",
          JSON.stringify(res.brandingContainer.tenant)
        );
        this.infoTextColors.next(res.brandingContainer.infoTextColors);
        this.textColors.next(res.brandingContainer.textColors);
        localStorage.removeItem("paginationColor");
        localStorage.removeItem("languagePlaceHolderColor");

        localStorage.setItem(
          "paginationColor",
          JSON.stringify(res.brandingContainer.infoTextColors.pagination)
        );
        localStorage.setItem(
          "languagePlaceHolderColor",
          JSON.stringify(
            res.brandingContainer.infoTextColors.languagePlaceHolder
          )
        );
      });
  }

  savelogoUrl(logoUrl: string) {
    sessionStorage.setItem("logoUrl", logoUrl);
  }

  saveGeneralTableSettings(generalInfo: GeneralTableSettings) {
    sessionStorage.setItem("general-settings", JSON.stringify(generalInfo));
  }

  saveCustomTabs(customTabList: CustomTabs[]) {
    sessionStorage.setItem("custom-tab", JSON.stringify(customTabList));
  }

  getUserData(): UserDataResource {
    const userData =
      JSON.parse(window.sessionStorage.getItem(userDataStorageKey)) || {};

    return _assign(new UserDataResource(userData), { ...userData });
  }

  getGeneralTableSettings(): GeneralTableSettings {
    const generalSettings =
      JSON.parse(window.sessionStorage.getItem("general-settings")) || {};

    return _assign(new GeneralTableSettings(), generalSettings);
  }

  getLogoUrl() {
    return window.sessionStorage.getItem("logoUrl");
  }

  saveAccessToken(newAccessToken: string) {
    sessionStorage.setItem(accessTokenStorageKey, newAccessToken);
  }

  saveRefreshToken(newRefreshToken: string) {
    sessionStorage.setItem(refreshTokenStorageKey, newRefreshToken);
  }
  public isMasterSupporter: boolean;
  saveUserData(userData: UserDataResource) {
    if (userData.userLanguage === "null" || !userData.userLanguage) {
      userData.userLanguage = "de";
    }
    sessionStorage.setItem(userDataStorageKey, JSON.stringify(userData));
    this.isMasterSupporter = userData?.rights?.includes(PersonRole.supporter);
    this.onUserDataChange.emit(userData);
  }

  getRefreshToken() {
    return sessionStorage.getItem(refreshTokenStorageKey);
  }

  refreshToken() {
    if (!this._tokenRefreshObservable) {
      let requestHeaders = new HttpHeaders();
      requestHeaders = requestHeaders.append(
        "Authorization",
        `Bearer ${this.getRefreshToken()}`
      );
      this._tokenRefreshObservable = this._http
        .get<HttpResponse<TokenRefreshResponseBody>>(
          `${environment.apiUrl}/login/refreshToken`,
          { headers: requestHeaders, observe: "response" }
        )
        .pipe(
          tap((response: HttpResponse<any>) => {
            // since this observable is an indicator whether token is refreshing or not
            // we need to reset it to null after finishing with token refresh
            this._tokenRefreshObservable = null;
            this.saveAccessToken(response.headers.get("X-Auth-Token-Update"));
            this.saveRefreshToken(response.headers.get("Refresh-Token"));
          }),
          catchError(() => {
            // cannot refresh token, return to login
            this._clearSessionStorage();
            this._router.navigate(["/login"]);
            this.logoutSubscription.unsubscribe();
            return observableThrowError(null);
          }),
          publish(),
          refCount()
        );
    }
    return this._tokenRefreshObservable;
  }

  logout() {
    this.logoutSubscription = this._http
      .get(`${environment.apiUrl}/login/logout`)
      .subscribe(
        () => {
          this._clearSessionStorage();
          this._router.navigate(["/login"]);
          window.clearTimeout(this.timeout);
          this.logoutSubscription.unsubscribe();
          window.location.reload();
        },
        (err) => {
          this._clearSessionStorage();
          this._router.navigate(["/login"]);
          window.clearTimeout(this.timeout);
          this.logoutSubscription.unsubscribe();
          window.location.reload();
        }
      );
  }

  openAutoLogoutDialog() {
    const dialogRef = this.dialog.open(AutoLogoutDialogComponent, {
      autoFocus: false,
      id: "autoLogoutDialog",
    });
    const autoLogoutDialogsubscription =
      dialogRef.componentInstance.confirmation.subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.logout();
          this._clearSessionStorage();
        }
      });
    dialogRef.afterClosed().subscribe(() => {
      this.logout();
      this._clearSessionStorage();
      autoLogoutDialogsubscription.unsubscribe();
    });
  }

  // On every htpp request (ignoring route on '/login/logout') first we check if have set accessToken,
  // if timeout is set before then just clearTimeout(reset timeout), if it is not set before,
  // then setTimeout with number of miliseconds that we take from config, after the timeout has expired,
  // if there was no other http requests, clear user session and open AutoLogout dialog.
  resetAutoLogoutTimer() {
    if (this.getAccessToken()) {
      if (this.timeout !== undefined) {
        window.clearTimeout(this.timeout);
      }
      this.autoLogoutTimerReseted.next({});
      this.timeout = window.setTimeout(() => {
        this.openAutoLogoutDialog();
      }, this._config.autoLogoutTimeOut);
    }
  }

  private _clearSessionStorage() {
    sessionStorage.clear();
  }

  requestResetPassword(email: string) {
    const url = `${environment.apiUrl}/person/supportViewRequestPasswordReset`;
    const data = { email };
    return this._http
      .post<{ email: string }>(url, data, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }


  resetPasswordFromEmail(token: string, newPassword: string) {
    const url = `${environment.apiUrl}/person/supportViewResetPassword`;
    const data = { token,newPassword };
    return this._http.put<any>(url, data, { observe: 'response' });

  }

  public handleSuccesOrError(panelClass: string,message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "right";
    config.duration = 3000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translate.instant(message);
    this._snackbar.open(messageSnackBar, undefined, config);

  }
}
