import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "send-to-print-promt",
  standalone: true,
  imports: [CommonModule],
  template: `<div>
    <h2>{{ data.title }}</h2>
    <p>{{ data.description }}</p>
    <!-- Add any dynamic content here -->
  </div>`,
  styleUrl: "./send-to-print-promt.component.scss",
})
export class SendToPrintPrompt {
  @Input() public data;
}
