import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'sv-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent {
  public testValues: any;
  public shIcons: string[];

  constructor(
    _http: HttpClient,
  ) {
    this.testValues = {
      test1: '',
    };
    this.shIcons = [];
    _http.get('assets/icons/custom-icons.svg', { responseType: 'text' }).subscribe((svg) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
      const rootSvgNode = svgDoc.childNodes[0];
      const defsNode = this._nodeListToArray(rootSvgNode.childNodes)
        .filter((node) => node.nodeName.toLowerCase() === 'defs')[0];
      this.shIcons = this._nodeListToArray(defsNode.childNodes)
        .filter((node) => node.nodeName.toLowerCase() === 'svg').map((defNode) => defNode.getAttribute('id'));
    });
  }

  private _nodeListToArray(nodeList: NodeList) {
    const array = [];
    for (let i = 0; i < nodeList.length; i += 1) {
      array.push(nodeList[i]);
    }
    return array;
  }
}
