<div class="dialog-container">
  <div class="dialog-header">
    <h2 class="dialog-title">{{data.dialogTitle}}</h2>
  </div>
  <div class="dialog-content">
    <ng-template #dynamicContent></ng-template>
  </div>
  <div class="dialog-footer">
    <div class="controls dialog-actions">
      <button type="button" #btn (click)="onCancel()" [ngStyle]="{
        'color': '#FFF',
        'background-color': '#9a9a9a',
        'pointer-events': 'visible'
      }">
        <div class="title back">
          {{data.onCancel.label}}
        </div>
        <div class="button__footer">
          <div class="button__subline">

          </div>
          <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
            <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
          </div>
        </div>
      </button>
      <button type="button" #btn (click)="data.onConfirm.cta()" [ngStyle]="{
        'color': (authService.backgroundColors | async)?.buttonTextColor,
        'background-color': (authService.backgroundColors | async)?.primaryButtonColor,
        'pointer-events': 'visible'
      }">
        <div class="title back">
          {{data.onConfirm.label}}
        </div>
        <div class="button__footer">
          <div class="button__subline">

          </div>
          <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
            <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>