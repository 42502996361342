import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ITableColumnDefinition } from "../../design/components/table/table.component";
import { CreateEditGroupComponentDatapart } from "../create-edit-group-datapart/create-edit-group.component-datapart";
import { CreateEditGroupComponent } from "../create-edit-group/create-edit-group.component";
import { CreateEditPersonComponentDatapart } from "../create-edit-person-datapart/create-edit-person-datapart.component";
import { CreateEditPersonComponent } from "../create-edit-person/create-edit-person.component";
import { PersonRole } from "../enum/personRole.enum";
import { Tenant } from "../enum/tenant.enum";
import { GroupService } from "../group.service";
import { IGroup } from "../interfaces/group.interface";
import { IPerson } from "../interfaces/person.interface";

enum TableColumnsGroups {
  externalId = "externalId",
  displayName = "displayName",
  description = "description",
  numberOfMembers = "numberOfMembers",
  numberOfSupporters = "numberOfSupporters",
  numberOfDictionaries = "numberOfDictionaries",
  eGehaltszettelEnabled = "eGehaltszettelEnabled",
  actions = "actions",
}

@Injectable({
  providedIn: "root",
})
export class TenantService {
  public tenant = "";
  constructor(
    private _groupService: GroupService,
    private _translateService: TranslateService
  ) {
    this.getTenant();
  }

  public passDataToDialog(selectGroup: IGroup) {
    switch (this.tenant) {
      case Tenant.datapart:
        this._groupService.editGroup.next({
          name: selectGroup.displayName,
          description: selectGroup.description,
          groupId: selectGroup.id,
          externalId: selectGroup.externalId,
        });
        break;
      case Tenant.payslip:
        this._groupService
          .getGroupAdditionalData(selectGroup.id)
          .subscribe((data: any) => {
            this._groupService.editGroup.next({
              name: data.name,
              description: data.companyName,
              groupId: data.id,
              email: data.adminEmail,
              sapCustomerNumber: data.sapCustomerNumber,
              materialNumber: data.materialNumber,
              accountingModel: data.accountingModel,
              streetName: data.address.streetName,
              doorNumber: data.address.doorNumber,
              zipCode: data.address.zipCode,
              city: data.address.city,
              country: data.address.country,
              companyPrefix: data.companyPrefix,
              eGehaltszettelEnabled: data.eGehaltszettelEnabled,
              smsNotificationsEnabled: data.smsNotificationsEnabled,
              secureCode: data.secureCode,
              logos: data.logos,
              tenantPrefixes: data.tenantPrefixes,
              allowDuplicatePrefix: data.allowDuplicatePrefix,
              einfachBriefEnabled: data.einfachBriefEnabled,
              postSapNumber: data.postSapNumber,
            });
          });

        break;

      default:
        break;
    }
  }

  public openGroupDialog(): any {
    switch (this.tenant) {
      case Tenant.datapart:
        return CreateEditGroupComponentDatapart;
      case Tenant.payslip:
        return CreateEditGroupComponent;
    }
  }
  public openPersonDialog(): any {
    switch (this.tenant) {
      case Tenant.datapart:
        return CreateEditPersonComponentDatapart;
      case Tenant.payslip:
        return CreateEditPersonComponent;
    }
  }

  public translationsForTableColumnsGroups() {
    this.getTenant();

    const translationMap: Record<
      Tenant,
      Partial<Record<TableColumnsGroups, string>>
    > = {
      [Tenant.datapart]: {
        externalId: "Account Number",
        displayName: "Display Name",
        description: "Description",
        numberOfMembers: "Students",
        numberOfSupporters: "Administrators",
        actions: "Actions",
      },
      [Tenant.payslip]: {
        displayName: "Display Name",
        description: "Company Name",
        numberOfMembers: "Users",
        numberOfSupporters: "Administrators",
        numberOfDictionaries: "Dictionaries",
        eGehaltszettelEnabled: "Active",
        actions: "Actions",
      },
    };

    return translationMap[this.tenant];
  }

  public configureTableColumnsGroups() {
    this.getTenant();

    switch (this.tenant) {
      case Tenant.datapart:
        return [
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Account Number"),
            field: "externalId",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Display Name"),
            field: "displayName",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Description"),
            field: "description",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Students"),
            field: "numberOfMembers",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Administrators"),
            field: "numberOfSupporters",
            isSortable: true,
          },
          <ITableColumnDefinition<number>>{
            title: this._translateService.instant("Actions"),
            field: "actions",
            isSortable: false,
          },
        ];
      case Tenant.payslip:
        return [
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Company Name"),
            field: "description",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Display Name"),
            field: "displayName",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Users"),
            field: "numberOfMembers",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Administrators"),
            field: "numberOfSupporters",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Dictionaries"),
            field: "numberOfDictionaries",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Active"),
            field: "eGehaltszettelEnabled",
            isSortable: false,
          },
          <ITableColumnDefinition<number>>{
            title: this._translateService.instant("Actions"),
            field: "actions",
            isSortable: false,
          },
        ];
    }
  }

  public configureTableColumnsPerson() {
    this.getTenant();
    switch (this.tenant) {
      case Tenant.datapart:
        return [
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Display Name"),
            field: "displayName",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Account Number"),
            field: "accountNumber",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Function"),
            field: "role",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Email"),
            field: "email",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Username"),
            field: "username",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Actions"),
            field: "actions",
            isSortable: false,
          },
        ];
      case Tenant.payslip:
        return [
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Display Name"),
            field: "displayName",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Account Numbers"),
            field: "accountNumbers",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Email"),
            field: "email",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Username"),
            field: "username",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Active"),
            field: "active",
            isSortable: true,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Send to Print Channel"),
            field: "sendToPrint",
            isSortable: false,
          },
          <ITableColumnDefinition<string>>{
            title: this._translateService.instant("Actions"),
            field: "actions",
            isSortable: false,
          },
        ];
    }
  }

  public mapPersonRole(persons: IPerson[]) {
    switch (this.tenant) {
      case Tenant.datapart:
        return persons.map((person: IPerson) => {
          if (person.role === PersonRole.supporter) {
            return {
              ...person,
              role: this._translateService.instant("Administrator"),
            };
          } else if (person.role === PersonRole.user) {
            return {
              ...person,
              role: this._translateService.instant("Student"),
            };
          } else if (person.role === PersonRole.instructor) {
            return { ...person, role: this._translateService.instant("User") };
          }
        });
      case Tenant.payslip:
        return persons.map((person) => {
          return {
            ...person,
            active: person.active
              ? this._translateService.instant("Yes")
              : this._translateService.instant("No"),
            accountNumbers: person.accountNumbers.map(
              (num: string) => " " + num
            ),
            sendToPrint: person.sendToPrint
              ? this._translateService.instant("Yes")
              : this._translateService.instant("No"),
          };
        });
    }
  }

  public mapGroup(groups: IGroup[]) {
    switch (this.tenant) {
      case Tenant.datapart:
        return groups.map((group) => {
          return {
            ...group,
            numberOfMembers: group.membersIds.length,
            numberOfSupporters: group.groupSupporterIds.length,
            displayName: group.name,
          };
        });
      case Tenant.payslip:
        return groups.map((group: any) => {
          return {
            ...group,
            numberOfMembers: group.membersIds.length,
            numberOfSupporters: group.groupSupporterIds.length,
            displayName: group.name,
            numberOfDictionaries: group.numberOfDictionary,
            eGehaltszettelEnabled: group.additionalData.eGehaltszettelEnabled
              ? this._translateService.instant("Yes")
              : this._translateService.instant("No"),
          };
        });
    }
  }
  public mapCreateEditPersonPayload(
    data,
    accountNumber,
    accountNumbers,
    groupId: string,
    personId: string,
    sendToPrint = false
  ) {
    const createEditPersonPayloadMap = {
      [Tenant.payslip]: {
        accountDataDto: { ...data },
        accountType: undefined,
        accountNumber: accountNumber,
        accountNumbers: accountNumbers,
        groupId: groupId,
        id: personId,
        sendToPrint,
      },
      [Tenant.datapart]: {
        accountDataDto: data.accountDataDto,
        accountType: data.accountType,
        accountNumber: accountNumber,
        groupId: groupId,
        id: personId,
      },
    };

    return createEditPersonPayloadMap[this.tenant]
      ? createEditPersonPayloadMap[this.tenant]
      : {};
  }

  public getTenant() {
    this.tenant = JSON.parse(localStorage.getItem("tenant"));
  }
}
