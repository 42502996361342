import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DetailsDisplayComponent } from "./components/details-display/details-display.component";
import { EmailValidatorDirective } from "./directives/email-validator/email-validator.directive";
import { UploadDialogComponent } from "./components/upload/upload-dialog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { DisplayDataSizePipe } from "../stubs/pipes/display-data-size.pipe";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { UploadCSVDialogComponent } from "./components/upload-csv-files/upload-csv-files.component";
import { MultipleNotificationsComponent } from "./components/multiple-notifications/multiple-notifications.component";
import { PasswordDataComponent } from "./password-data/password-data.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { CustomResetPassword } from "./custom-reset-password-component/custom-reset-password.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatListModule,
    MatSnackBarModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    UploadDialogComponent,
    UploadCSVDialogComponent,
    DetailsDisplayComponent,
    EmailValidatorDirective,
    DisplayDataSizePipe,
    MultipleNotificationsComponent,
    PasswordDataComponent,
    ConfirmationDialogComponent,
    ForgotPasswordComponent,
    CustomResetPassword
  ],
  exports: [
    UploadDialogComponent,
    UploadCSVDialogComponent,
    DetailsDisplayComponent,
    EmailValidatorDirective,
    DisplayDataSizePipe,
    MultipleNotificationsComponent,
    ConfirmationDialogComponent,
    ForgotPasswordComponent,
    CustomResetPassword
  ],
})
export class SharedModule {}
