import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GroupService } from '../../../../app/group/group.service';
import { UploadDialogComponent } from './upload-dialog.component';
import { UploadViewService } from './upload-view.service';
import { Observable, Subject, throwError, interval, Subscription } from 'rxjs';
import { DocumentUploadModel } from '../../classes/document-upload-model.class';
import { UploadUtilityService } from './upload-utility.service';
import { IUploadErrors } from '../../../group/interfaces/upload-errors.interface';
import { IProcessedDocumentsResult } from '../../../group/interfaces/process-documents-result.interface';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { uniqueId } from 'lodash';
import { takeWhile } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public groupName: string;
  public status = [];
  public foreignIds: {fileName: string,foreignId: string,reason: string,sourceFile: string, sourceFileUID: string}[] = [];
  public foreignId: string;
  public documents: DocumentUploadModel[] = [];
  public templates = []
  public payloadForProcessDocuments; 
  public conversionCode = '';
  public zipContainsErrors = [];
  public skippedFiles = [];
  private _translateService: TranslateService;
  private snackBar: MatSnackBar;
  private payloadWithSourceFileUID: any[] = [];
  public shServiceLineUsed = false;
  public uploadResult: any = {processingOver: false}
  public interval$: Observable<number>;
  public subscriptionInterval: Subscription;
  public isBroadCastDelivery = false;
  public broadcastDeliverySubject = {broadcastDeliverySubject: '',broadcastDeliveryReceiverCompanies:[]};
  public broadcastDelivery:boolean;
  public isZipFile: boolean;
  public isProcessingOver: boolean;
  userIds: string[];
  companyDisplayName: string[];
  deliverDocsToMultipleUsers: any;
  public closeDeliverDocsToMultipleUserDialog = new Subject()

  constructor(
    private _groupsService: GroupService,
    private _http:HttpClient,
    private _uploadViewService: UploadViewService,
      private matDialogRef: MatDialogRef<UploadDialogComponent>,
    private _zone: NgZone,
    private _uploadUtilityService: UploadUtilityService,
    ) { }

  public statusMessage(panelClass: string, message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 15000;
    config.panelClass = panelClass;
    let messageSnackBar = this._translateService.instant(message);
    this.snackBar.open(messageSnackBar, undefined, config);
  }

  public getGroupName() {
    return this._groupsService
    .getGroups(
    {page: 0,pageSize: 10,sort: {ascOrDesc: 'asc', field:'name'}},
    {accountNumber:'',displayName:'',groupDescription:''}).pipe(map(data => data.content[0].displayName))
  }

  public sendDocuments(foreignId: string) {
    return this._http.post(`${environment.apiUrl}/deliveryHandler/sendDocuments`,{foreignId})

  }

  public sendCSVDocuments({csvFile, groupId}) {
    return this._http.post(`${environment.apiUrl}/supportView/v1/person/uploadCsv`, {
      csvFile: csvFile,
      groupId: groupId
    }).pipe(catchError(err=>{
      this.statusMessage('snack-bar-fail','Failed to upload Csv');
      return throwError(err);
    }))
  }

  public delete(removeBySourceFileUID:string, foreignId: string): any {
    return this._http.post(`${environment.apiUrl}/deliveryHandler/removeDocument`,{removeBySourceFileUID, foreignId})

  }

  uploadAsyncSingleTempFile(files) {
    // create a new multipart-form for every file

    // create a new progress-subject for every file
    const progress = new Subject<number>();
    const request = new Subject<any>();
    const uploadError = new Subject<IUploadErrors>();

      // send the http-request and subscribe for progress-updates
      let currentProgress = 0;
      let nextTimeout;
      const ongoingReq = this.uploadTempDeliveryFilesReq(files).subscribe(
        (event) => {
          if (typeof event.body !== 'undefined' && event.body.success) {
            this.foreignId = event.body.foreignId
            this.foreignIds = event.body.documentForeignKey;
            // this.upload()
            this.processDocumentsReq()
          }

          if (event.type === HttpEventType.UploadProgress) {

            // calculate the progress percentage
            const percentDone = Math.round(100 * event.loaded / event.total);

            // pass the percentage into the progress-stream
            currentProgress = percentDone;
            if (!nextTimeout) {
              nextTimeout = setTimeout(() => {
                nextTimeout = null;
                this._zone.run(() => {
                  progress.next(currentProgress);
                });
              }, 400);
            }
          }
          
          else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            this._zone.run(() => {
              setTimeout(() => {
                progress.complete();
                request.next(event.body);
                request.complete();
              }, 400);
            });
          }



        },
        (error) => {
          this._zone.run(() => {
            uploadError.next({
              hasVirus: error.status === 406,
              unsupportedMediaType: error.status === 415,
            });
            uploadError.complete();
            progress.complete();
            request.complete();
          });
        }
      );

      files.forEach(fileForStatus => {
        this.status.push({
          file: fileForStatus,
          progress: progress.asObservable(),
          cancel: () => ongoingReq.unsubscribe(),
          request,
          uploadError,
        });
      })
  }
  uploadTempDeliveryFilesReq(formData): Observable<any> {
    let payload = []
    payload = [...formData]
    .map(data => ({ mimeType: data.mimeType, name: data.name, payload: data.payload, sourceFileUID: uniqueId() }))
    if (this.documents.length > 0) {
      
      payload = this.documents
      .filter(data => data.valid)
      .map(data => {
        if (data.valid) {
          return {mimeType: data.type, name: data.fileName, payload: data.payload, sourceFileUID: uniqueId()}
        }
      })
      this.payloadWithSourceFileUID = payload

      const mappedFormData = [...formData]
      .map(data => ({ mimeType: data.mimeType, name: data.name, payload: data.payload, sourceFileUID: uniqueId() }))
      payload.push(...mappedFormData)
    }
    this.payloadWithSourceFileUID = payload
      this._uploadViewService.containsZip = payload.some(doc => doc.mimeType === 'application/zip' || doc.mimeType ==='application/x-7z-compressed' || doc.mimeType === 'application/x-zip-compressed' || doc.mimeType ==='application/octet-stream')
      const req = new HttpRequest('POST', `${environment.apiUrl}/deliveryHandler/upload`, { documents: payload }, {

      reportProgress: true
    });
    return this._http.request(req);
  }


  public processDocumentsReq() {
    
    this.payloadForProcessDocuments = {
      foreignId:this.foreignId,
      dictionary: this.conversionCode,
      broadcastDelivery:this.broadcastDelivery,
      shServiceLineUsed:this.shServiceLineUsed,
      broadcastDeliverySubject:this.broadcastDeliverySubject.broadcastDeliverySubject,
      broadcastDeliveryReceiverCompanies: this.broadcastDeliverySubject.broadcastDeliveryReceiverCompanies,
    }
    if (this.deliverDocsToMultipleUsers) {
      this.payloadForProcessDocuments = {...this.payloadForProcessDocuments,broadcastDeliveryReceiversId:this.userIds}
    }
    // If there is no dictoanry then remove it from payload
    if (this.payloadForProcessDocuments.dictionary === '') {
      delete this.payloadForProcessDocuments.dictionary
    }
    /* 
      If user is assigned only one template then we 
      dont display select pdf conversion code we just send the one he is assigned
    */
    if (this.templates.length  === 1) {
      this.payloadForProcessDocuments.dictionary = this.templates[0];
    }
    if (this.shServiceLineUsed) {
      delete this.payloadForProcessDocuments.dictionary

    }
    if(!this._uploadViewService.isDropDownValueChosen) {
      delete this.payloadForProcessDocuments.dictionary

    }

    const files = this.documents;
    
    const backendResponse = this.foreignIds;
    const filesMap = {};
    files.forEach(file => {
      if (!filesMap[file.fileName]) {
        filesMap[file.fileName] = file;
      }
    });
    backendResponse.forEach(responseItem => {
      if (filesMap[responseItem.sourceFile]) {
        filesMap[responseItem.sourceFile].foreignId = responseItem.foreignId;
        filesMap[responseItem.sourceFile].isBeingProcessed = true;
        filesMap[responseItem.sourceFile].sourceFileUID = responseItem.sourceFileUID
      }
    });
    this.documents = files;

    this._http
    .post(`${environment.apiUrl}/deliveryHandler/processDocumentsAsync`,this.payloadForProcessDocuments)
    .subscribe((result: IProcessedDocumentsResult) => {
      this.uploadResult = {...result}
        })
       // Check the status of the uploaded documents
        this.checkUploadedDocumentsStatus();

        const interval$ = interval(5000);

        this.subscriptionInterval = interval$.pipe(
          takeWhile(() => !this.uploadResult?.processingOver)
        ).subscribe(() => {
          this.checkUploadedDocumentsStatus();
          this._uploadViewService.triggerChangeDetection.next(true)
        });

        this.subscriptionInterval.add(() => {
          this._uploadViewService.triggerChangeDetection.next(true)
        });
    }

    public checkUploadedDocumentsStatus() {
      this._http
      .post(`${environment.apiUrl}/deliveryHandler/checkDocumentProcessingStatus`,this.payloadForProcessDocuments)
      .subscribe((result: any) => {
        this.uploadResult = {...result}
        if (result.processingOver) {
          this.isProcessingOver = result.processingOver
          this.documents = this.documents.map((doc,i) => {doc.isBeingProcessed = false ;return doc})
          this._uploadViewService.disablePointerEvent = this.documents.some(document => document.isInvalidDictionary || document.isXMLTemplateInvalid);
        }
          this.uploadResult = {...result}
          this.zipContainsErrors = result.errorFiles;
          this.skippedFiles = result.skippedFiles;
          this._uploadViewService.triggerChangeDetection.next(true)
          if (this._uploadViewService.containsZip) { 
          result.errorFiles.map((err,i)=>{
            this.documents = this.documents.map(doc=>{
                if (doc.foreignId === err.foreignId && (doc.type === 'text/xml' || doc.type === 'text/plain')) {
                  doc.isXMLTemplateInvalid =true;
                } else if(doc.sourceFileUID === err.sourceFileUID && doc.fileName === err.fileName && doc.type === 'application/pdf'){
                  doc.isInvalidDictionary = true; 
                } else if(doc.sourceFileUID === err.sourceFileUID && err.reason === "XML template not supported for current tenant") {
                  this._uploadUtilityService.IsZipFile(doc) ? 
                  this._uploadUtilityService.IsZipFile(doc) 
                  && 
                  (doc.invalidZipFile = true) : 
                  doc.isXMLTemplateInvalidForTenant = true;
                  this._uploadUtilityService.IsZipFile(doc) ? 
                  this._uploadUtilityService.IsZipFile(doc) && (doc.invalidZipFile = true) && (doc.zipHasErrors = true) :doc.zipHasErrors = false
                } else if(this._uploadUtilityService.IsZipFile(doc) && doc.fileName === err.sourceFile) {
                  this._uploadUtilityService.IsZipFile(doc) ? (doc.invalidZipFile = true,doc.zipHasErrors = true) : (doc.isXMLTemplateInvalid = true,doc.zipHasErrors = false); 
                } else if(doc.sourceFileUID === err.sourceFileUID) {
                  doc.isXMLTemplateInvalid = true
                } 
                return doc;
              })
            })
    
              this._uploadViewService.hasError = this.documents.some(doc=> doc.isInvalidDictionary || doc.isXMLTemplateInvalid);
              this._uploadViewService.disablePointerEvent = this.documents.some(document => document.isInvalidDictionary || document.isXMLTemplateInvalid);
    
              this._uploadViewService.triggerChangeDetection.next(true)
            } else {
              result.errorFiles.map((err,i) => {
                this.documents = this.documents.map(doc => {
                  if (doc.foreignId === err.foreignId && (doc.type === 'text/xml' || doc.type === 'text/plain')) {
                    doc.isXMLTemplateInvalid =true;
                  } if(doc.foreignId === err.foreignId && doc.type === 'application/pdf'){
                    doc.isInvalidDictionary = true;
                    
                  } if (doc.foreignId === err.foreignId && doc.type === 'application/pdf' && this.broadcastDelivery) {
                    doc.isInvalidDictionary = false;
                    doc.isInvalidFileType = false; 
                    doc.unsupportedMediaType = false;
                    doc.invalidMassUpload = true;
                  } if (doc.foreignId === err.foreignId && err.reason === "The hash value in the filename does not match the expected hash.") {
                    doc.isInvalidDictionary = false;
                    doc.isInvalidHashValue  = true;
                  } if (doc.foreignId === err.foreignId && err.reason === "XML template not supported for current tenant") {
                    doc.isXMLTemplateInvalidForTenant = true;
                  } if(doc.foreignId === err.foreignId && err.reason === 'The file format is not supported') {
                    doc.isInvalidDictionary =false;
                    doc.unsupportedMediaType = false;
                    doc.invalidMassUpload = false;
                    doc.isInvalidFileType = true;
                  } if(doc.foreignId === err.foreignId && err.reason === 'Uploaded document prohibited as it belongs to other company') {
                    doc.isInvalidDictionary =false;
                    doc.unsupportedMediaType = false;
                    doc.invalidMassUpload = false;
                    doc.uploadProhibited = true;
                  }
                  return doc
                  })
              })
            }
            result.warningFiles.map((warnignFile, i) => {
              this.documents = this.documents.map(doc => {
                if (doc.sourceFileUID === warnignFile.sourceFileUID) {
                  doc.isWarnig = true;
                  doc.zipHasErrors  = true;
                }
                return doc;
            })})
            result.skippedFiles.map((err, i) => {
              this.documents = this.documents.map(doc => {
                if (doc.sourceFileUID === err.sourceFileUID) {
                  doc.isSkipped = true
                  doc.zipHasErrors  = true;
                }
                return doc
              })
      })
    
            this._uploadViewService.hasError = this.documents.some(doc=> doc.isInvalidDictionary || doc.isXMLTemplateInvalid || doc.isInvalidHashValue || doc.isXMLTemplateInvalidForTenant)
            this._uploadViewService.disablePointerEvent = this.documents.some(document => document.isInvalidDictionary || document.isXMLTemplateInvalid || document.isInvalidHashValue || document.isXMLTemplateInvalidForTenant)
            this._uploadViewService.triggerChangeDetection.next(true)
      },err => {
        this.subscriptionInterval.unsubscribe();
        this.documents = this.documents.map(document => {document.serviceUnavailable = true;document.isBeingProcessed = false; return document})
        this._uploadViewService.triggerChangeDetection.next(true)
        this.isProcessingOver = true;

      })
    }
  }  
