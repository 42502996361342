<div class="dialog-container">
    <div class="dialog-header">
      <h2 class="dialog-title">{{'Upload Documents' | translate}}</h2>
      <span class="close">
        <mat-icon [svgIcon]="'custom:close'" (click)="closeDialog()"></mat-icon>
      </span>
    </div>
  
    <div class="documents-upload" #document
      [ngStyle]="{'height':uploadService.documents.length > 5  ? '300px' : '100%','overflow-y': uploadService.documents.length > 5 ? 'scroll' : 'hidden'}">
      <div class="dialog-list">
        <mat-list>
          <mat-list-item *ngFor="let document of preUploadDocuments;let i = index">
            <div class="list-item">
              <div class="list-item-header">
                <div class="list-item-name">
                  {{document.name}}
                </div>
                <div class="list-item-status">
                  <span class="danger" *ngIf="document.unsupportedMediaType">
                    {{'The file format is not supported' | translate}}<br />
                  </span>
                  <span class="danger" *ngIf="!isValidCsv && !document.unsupportedMediaType">
                    {{'CSV file does not contain required fields and/or values' | translate}}<br />
                  </span>
                  <span class="success" *ngIf="isValidCsv">
                    {{'Document successfully uploaded' | translate}}<br />
                  </span>
                </div>
                <div class="list-item-size">
                  <span>
                    <span>{{document.size | displayDataSize}}</span>
                  </span>
                </div>
                <div class="list-item-control">
                  <mat-icon [svgIcon]="'custom:trash'" (click)="onDeletePreUpload(i)">
                  </mat-icon>  
                </div>
              </div>
              <div class="dialog-list">
                <div class="list-item-progress-bar">
                  <mat-progress-bar color="accent" mode="determinate" [value]="100">
                  </mat-progress-bar>
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="dialog-content">
      <div class="upload-section" *ngIf="viewService.displayFileUpload">
        <div (drop)="onFilesDrop($event); $event.stopPropagation();" (dragover)="onDragOver($event)">
          <div class="top-left-corner"></div>
          <div class="top-right-corner"></div>
          <div class="bottom-left-corner"></div>
          <div class="bottom-right-corner"></div>
          <form>
            <input accept=".csv" style="border:none" name="files" type="file" (change)="onFilesAdded($event); handleFileInput($event)" />
          </form>
          <span>{{'Drag documents to this area or click here to upload' | translate}}</span>
        </div>
      </div>
      <div>
      </div>
    </div>
    <div class="two-col-grid-controls separated select-input select-input-custom label-pos titles" 
        style="margin-top: 20px;" 
        (click)="selectConversionCode()">
          <h4>{{'Please choose' | translate}}</h4>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>{{'Company Prefix' | translate}}</mat-label>
            <mat-select formControlName="companyPrefix" placeholder="{{'Company Prefix' | translate}}" (selectionChange)="selectCompanyPrefix($event)">
              <mat-option *ngFor="let prefix of companyPrefixes" [value]="prefix">{{ prefix.subCompanyName + ' -' }} {{ prefix.userPrefix}} {{ prefix.subCompanyPrefix ? ' - ' + prefix.subCompanyPrefix : '' }}</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div class="dialog-footer">
      <div class="controls dialog-actions">
        <button type="button" #btn (click)="closeDialog()" color="primary" [ngStyle]="{
          'color': '#FFF',
          'background-color': '#9a9a9a',
          'pointer-events': 'visible'
        }">
          <div class="title back">
            {{'Cancel' | translate}}
          </div>
          <div class="button__footer">
            <div class="button__subline">
  
            </div>
            <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
              <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
            </div>
          </div>
        </button>
        <button type="button" #btn (click)="sendDocuments()" [ngStyle]="{
            'color': (authService.backgroundColors | async)?.buttonTextColor,
            'background-color': (preUploadDocuments.length === 0 || !viewService.displaySendBtn) ? 'rgba(0,0,0,.12)' : (authService.backgroundColors | async)?.primaryButtonColor,
            'pointer-events': (preUploadDocuments.length === 0 || !viewService.displaySendBtn) ? 'none' : 'visible'
          }">
          <div class="title">
            {{'Create Users' | translate}}
          </div>
          <div class="button__footer">
            <div class="button__subline">
  
            </div>
            <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
              <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
            </div>
          </div>
        </button>
  
      </div>
    </div>
  
  </div>
  <div class="loading-container" *ngIf="isSending">
    <div class="loading-spinner">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>