<section class="assign-admin-to-companies">

  <div class="assign-admin-to-companies__header">
    <h1>{{config.title}}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>

  <div class="assign-admin-to-companies__table" #assignAdminToCompanies 
  [ngStyle]="{'maxHeight': steps.stepTwo ? '500px' : '0px','overflowY':steps.stepTwo ? 'scroll' : 'none'}">

    <div class="assign-admin-to-companies__search__wrapper">
      <div class="assign-admin-to-companies__search__container" *ngIf="steps.stepOne">
        <div class="assign-admin-to-companies__search__container__input">
          <input matInput autocomplete="off" [(ngModel)]="filterValue"
            placeholder="{{config.inputPlaceHolder| translate}}">
        </div>
        <div class="assign-admin-to-companies__search__container__icon">
          <mat-icon class="icon-style">search</mat-icon>
        </div>
      </div>
    </div>

    <table>

      <thead>
        <tr>
          <th *ngIf="steps.stepOne">
          </th>
          <th *ngFor="let column of columns">{{ (column | translate) }}</th>
          <th *ngIf="steps.stepTwo">{{ 'Company Rights' | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngIf="steps.stepOne"></ng-container>
        <tr
          *ngFor="let row of  (steps.stepOne ? data : selectedData) | filter: filterValue: ['companyId', 'companyName', 'companyStatus']: handleFiltered | paginate: itemsPerPage : (pageIndex + 1); let i = index"
          [ngClass]="{'assign-admin-to-companies--added' : this.steps.stepTwo && _isCompanyAdded(row.companyId)}">
          <td *ngIf="steps.stepOne">
            <input type="checkbox" [(ngModel)]="row.assigned" (change)="toggleSelection(row,i)"
              [disabled]="row.primary">
          </td>
          <td>{{ row.companyId }}</td>
          <td>{{ row.companyName }}</td>
          <td>{{ row.companyStatus }}</td>
          <td style="padding: 0px 10px;" *ngIf="steps.stepTwo">
            <div *ngFor="let right of row.userRights | keyvalue; let i = index">
              <mat-checkbox class="assign-admin-to-companies__checkbox" color="primary"
                [(ngModel)]="row.userRights[right.key]">{{this.rightsMap[right.key] |
                translate }}
              </mat-checkbox>
            </div>

          </td>
        </tr>
      </tbody>

    </table>

  </div>
  <mat-paginator *ngIf="steps.stepOne" [length]="totalItems" [pageSize]="itemsPerPage" [pageIndex]="pageIndex"
    (page)="handlePageEvent($event)"></mat-paginator>
  <mat-paginator *ngIf="steps.stepTwo" [length]="selectedData.length" [pageSize]="itemsPerPage"
    [pageIndex]="pageIndex" (page)="handlePageEvent($event)"></mat-paginator>

  <div class="assign-admin-to-companies__actions">
    <button type="button" #btn (click)="closeDialog()" color="primary" [ngStyle]="{
          'color': '#FFF',
          'background-color': '#9a9a9a',
          'pointer-events': 'visible'
        }">
      <div class="title back">
        {{'Cancel' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
        </div>
      </div>
    </button>

    <button type="button" #btn *ngIf="steps.stepOne && displayNextButton" (click)="setStep()" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': selectedData.length > 0 ?  (authService.backgroundColors | async)?.primaryButtonColor : 'rgba(0,0,0,.12)' ,
          'pointer-events': selectedData.length > 0 ?  'visible' : 'none'

        }">
      <div class="title">
        {{ 'Next' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
    <button type="button" #btn (click)="submit()" *ngIf="steps.stepTwo || !displayNextButton" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': (authService.backgroundColors | async)?.primaryButtonColor,
        }">
      <div class="title">
        {{ 'Save' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
  </div>
</section>