<div *ngIf="loading" class="loading-overlay">
    <mat-progress-spinner class="loading-indicator" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div *ngFor="let data of displayData" class="e-brief-section">
    <h2>{{ data.title | translate }}</h2>
    <div *ngFor="let body of data.body" class="e-brief-row">
      <span>{{ body.key | translate }}:</span>
      <div *ngIf="body.key === 'Groups'" class="groups-wrapper">
        <span *ngFor="let item of body.value | keyvalue" (click)="handleItemClick(item.key)" class="group-item">{{ item.value }}</span>
      </div>
      <span *ngIf="body.key !== 'Groups'">{{ body.value }}</span>
    </div>
  </div>