import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidation(control: AbstractControl): { [key: string]: boolean } | null {
  const pattern = new RegExp('^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+[.])+[a-zA-Z]{2,}$');
  if (control.value) {
    const trimmedValue = control.value.trim();
    if (trimmedValue && !pattern.test(trimmedValue)) {
      return { email: true };
    }
  }
  return null;
}

export function onlySpacesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasOnlySpaces = /^\s*$/.test(control.value);
    return hasOnlySpaces ? { 'onlySpaces': true } : null;
  };
}

export function zipValidationforAt(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (!value) {
      return null
    }

    // Check if the value is a number and has 4 digits
    if (!isNaN(value) && /^\d{4}$/.test(value)) {
      const zipNumber = parseInt(value, 10);
      
      // Check if the zip code is within the specified range
      if (zipNumber >= 1000 && zipNumber <= 9999) {
        return null;
      }
    }

    return { 'invalidZip': true };
  };
}

export function zipValidationOtherCountries(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    // Check if the value is a string and does not contain special characters or spaces
    if (typeof value === 'string' && /^[a-zA-Z0-9]*$/.test(value)) {
      // Check if the string length is within the specified range
      if (value.length <= 20) {
        return null; // Valid zip code without special characters or spaces
      }
    }

    return { 'invalidZip': true };
  };
}