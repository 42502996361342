<div class="autologout__dialog">
<div class="autologout__dialog__header">
<h2 mat-dialog-title translate>Logout</h2>
<button mat-icon-button mat-dialog-close>
  <mat-icon [matTooltip]="closeButtonLabel" matTooltipPosition="right" aria-label="">close</mat-icon>
</button>
</div>
<mat-dialog-content>
  <h4 translate>Do you really want to log out?</h4>
  <div class="confirm-buttons">
    <button mat-button mat-dialog-close color="primary"><span   translate>NO</span></button>
    <button mat-button mat-dialog-close color="primary" (click)="logout(true)"><span  translate>YES</span></button>
  </div>
</mat-dialog-content>
</div>