import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

import { SupportViewConfig } from "../config/config";
import { NavigationStart, Router } from "@angular/router";
import { GroupService, RESET_FILTER } from "./group/group.service";
import {
  PersonService,
  RESET_FILTER_PERSON,
} from "./group/person/person.service";
import { AuthService } from "./auth/services/auth.service";
import { TenantService } from "./group/tenantService/tenant.service";
import { MaintenanceService } from "./shared/services/maintenance/maintenance.service";
import { Config } from "./shared/maintenance.config";

@Component({
  selector: "sv-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  ready = true;
  @ViewChild("appContainer") appContainer: ElementRef;
  private _interval;
  routerSubscription: any;
  public displayBanner: boolean;

  constructor(
    private _translateService: TranslateService,
    private _config: SupportViewConfig,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _router: Router,
    private _groupService: GroupService,
    private _personService: PersonService,
    private _authService: AuthService,
    private _renderer: Renderer2,
    private _tenant: TenantService,
    public maintenance: MaintenanceService
  ) {
    // Handle the navigation end event
    this.maintenance.getMaintananceConfig().subscribe(
      (config: Config) => {
        this.maintenance.checkMaintenanceMode(config, this._interval);
        this.maintenance.checkBanner(config);
        this.maintenance.setBannerDateFromToText(config);

        // You can add your custom logic here
        this._interval = setInterval(() => {
          this.maintenance.getMaintananceConfig().subscribe(
            (config: Config) => {
              this.maintenance.checkMaintenanceMode(config, this._interval);
              this.maintenance.checkBanner(config);
              this.maintenance.setBannerDateFromToText(config);
            },
            (error) => {
              console.log(error);
            }
          );
        }, 60000);
      },
      (error) => {
        console.log(error);
      }
    );

    this._authService.getStyles();
    this._router.events.subscribe((router) => {
      if (router instanceof NavigationStart) {
        this._groupService.filterValues = RESET_FILTER;
        this._personService.searchPersonQuery = RESET_FILTER_PERSON;
      }
    });
    iconRegistry.addSvgIconSetInNamespace(
      "custom",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icons/custom-icons.svg")
    );
    let defaultLanguage = this._translateService.getBrowserLang();
    if (this._config.availableLocales.indexOf(defaultLanguage) === -1) {
      defaultLanguage = this._config.defaultLocale;
    }
    this._translateService.setDefaultLang(defaultLanguage);
    const locale = defaultLanguage; // Add here user laguage if there is option for that (like in SenderCockpit)
    this._translateService.use(locale.toLowerCase()).subscribe(() => {
      this.ready = true;
    });
  }
  ngAfterViewInit(): void {
    this._authService.tenant.subscribe((tenant) => {
      if (!tenant) return;

      this._renderer.addClass(
        this.appContainer.nativeElement,
        `${tenant}-theme`
      );
    });
  }
}
