<div class="group-person-container">

  <div class="group-person-container__header">
    <h1 *ngIf="authService.tenant.value === 'payslip'">{{'User from' | translate}} {{ groupDescription | slice:0:20 }}{{
      groupDescription?.length > 20 ? '...' : '' }}</h1>
    <h1 *ngIf="authService.tenant.value === 'datapart'">{{'User' | translate}}</h1>
    <div class="button-wraper">
      <button 
      class="select-users-dialog-btn"
      color="primary" 
      *ngIf="(isDeliverySubmitter && isGroupActive)" 
      [ngStyle]="{
        'background-color': 'white',
        color: (authService.backgroundColors | async)?.primaryButtonColor
      }"
      mat-button
      mat-raised-button style="margin-right: 10px;"
      (click)="openSelectUsersDialog()">
      <span class="upload__document__text">{{'Select users to deliver documents' | translate}}</span>
      <mat-icon [svgIcon]="'custom:post-icon-upload'"></mat-icon>
      </button>
      <button color="primary" *ngIf="isAdministrator || masterAdmin || isUserAdmin || isSupporter" mat-button
        mat-raised-button style="margin-right: 10px;" (click)="createPerson()">{{'Create user' | translate}}</button>
      <div id="searchButton" class="search" (click)="openSearchPersonDialog()">
        <mat-icon class="search-icon" aria-label="">search</mat-icon>
        <span class="search-label" translate>Search</span>
        <mat-icon class="mat-arrow-drop-down" aria-label="">arrow_drop_down</mat-icon>
      </div>
      <!-- <button mat-button mat-raised-button color="accent" (click)="toggleGroup SupporterAndGroupActivation()">{{switchButtonTitle | translate}} </button> -->
    </div>
  </div>
  <sv-user-search-display [totalItems]="(persons$ | async)?.content?.length"
    [filterValues]="_personService.searchPersonQuery"
    (filterValuesChange)="filterValueChange($event)"></sv-user-search-display>

  <sv-table 
    [data]="(persons$ | async)?.content" 
    [columns]="personsTableColumns" 
    [page]="(persons$ | async)?.number"
    [(selected)]="selectedRows" 
    cursorOnHover="true" 
    [actions]="actions"
    (actionEvent)="action($event)" 
    [(sort)]="sort" 
    (sortChange)="sortEvent($event)"
    (handleRowClick)="handleRowClick($event)" 
    [selectable]="false" 
    (selectedChange)="selectUserForDocDelivery($event)"
    ></sv-table>
  <mat-paginator [length]="(persons$ | async)?.totalElements" [pageSize]="pageSize"
    [pageIndex]="(persons$ | async)?.number" (page)="changePage($event)"></mat-paginator>
  <div class="loading-container" *ngIf="isLoading">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</div>

<sv-multiple-notifications></sv-multiple-notifications>