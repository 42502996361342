<div class="document__visibility__content___header">
  <h1 mat-dialog-title>{{ "Rights" | translate }}</h1>
  <mat-icon
    class="close"
    (click)="closeDialog()"
    [svgIcon]="'custom:close'"
  ></mat-icon>
</div>
<div mat-dialog-content>
  <!-- <form [formGroup]="documentVisibilityForm" (ngSubmit)="submit()"> -->
  <!-- <mat-form-field  color="accent" (click)="preselectCategories()">
            <mat-select formControlName="documentVisibility" multiple >
                <mat-option #categoriesOption [selected]="category.allowed" *ngFor="let category of categories" [value]="category.categoryType">{{category.categoryType}}</mat-option>
        </mat-select>
    </mat-form-field> -->
  <div class="mat-checkbox-wrapper" *ngIf="tenantId !== 'payslip'">
    <mat-checkbox
      color="primary"
      [value]="category.categoryType"
      #checkbox
      *ngFor="let category of categories; let i = index"
      [checked]="category.allowed"
      >{{ category.categoryType }}</mat-checkbox
    >
  </div>
  <div class="mat-checkbox-wrapper" *ngIf="tenantId === 'payslip'">
    <mat-checkbox
      color="primary"
      [value]="category.id"
      [name]="category.name"
      #checkbox
      *ngFor="let category of transformedCategories$ | async; let i = index"
      [checked]="category.assigned"
      >{{ category.name | translate }}</mat-checkbox
    >
  </div>
  <ng-container *ngIf="tenantId === 'payslip'">
    <button
      color="primary"
      mat-button
      mat-raised-button
      (click)="submitForPayslip()"
      type="submit"
    >
      {{ "Submit" | translate }}
    </button>
  </ng-container>
  <!-- </form> -->
  <ng-container *ngIf="tenantId !== 'payslip'">
    <button
      color="primary"
      mat-button
      mat-raised-button
      (click)="submit()"
      type="submit"
    >
      {{ "Submit" | translate }}
    </button>
  </ng-container>
</div>
