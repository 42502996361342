import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/services/auth.service';

export interface IDeleteDialogData {
  title?: string;
  text?: {yes:string,no:string};
  closeButtonLabel?: string;
  selectedNumber?: number;
}

@Component({
  selector: 'sv-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  public title: string;
  public text: {yes:string,no:string};
  public selectedNumber: number;
  public closeButtonLabel: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: IDeleteDialogData,
    public auth: AuthService
  ) {
    this.title = data.title;
    this.selectedNumber = data.selectedNumber;
    this.text = data.text;
    this.closeButtonLabel = this._translateService.instant('Close');
  }

  delete() {
    this.dialogRef.close('delete');
  }

  close() {
    this.dialogRef.close();
  }
}
