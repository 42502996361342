<div class="create_edit_group_dialog_content">
    <div class="create_edit_person_dialog_content__header">
      <h1 mat-dialog-title>{{title | translate}}</h1>
      <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
    </div>
    <div mat-dialog-content class="create_edit_group_dialog__form">
      <form [formGroup]="createEditAdminUserForm" (ngSubmit)="submit()" *ngIf="this.createEditAdminUserForm">
        <mat-form-field color="primary">
          <mat-label>
            {{'first-name' | translate}}*
          </mat-label>
          <input matInput autocomplete="off" formControlName="firstName">
            <mat-error *ngIf="createEditAdminUserForm.controls.firstName.touched && createEditAdminUserForm.controls.firstName.hasError('required')">
              {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>
            {{'last-name' | translate}}*
          </mat-label>
          <input matInput autocomplete="off" formControlName="lastName">
            <mat-error *ngIf="createEditAdminUserForm.controls.lastName.touched && createEditAdminUserForm.controls.lastName.hasError('required')">
              {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>
            {{'Username' | translate}}*
          </mat-label>
          <input matInput autocomplete="off" formControlName="username">
            <mat-error *ngIf="createEditAdminUserForm.controls.username.touched && createEditAdminUserForm.controls.username.hasError('required')">
              {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
          <mat-label>
            Email*
          </mat-label>
          <input matInput autocomplete="off" formControlName="email">
            <mat-error *ngIf="createEditAdminUserForm.controls.email.touched && createEditAdminUserForm.controls.email.hasError('required')">
              {{'Required' | translate}}
            </mat-error>
            <mat-error *ngIf="createEditAdminUserForm.controls.email.touched && createEditAdminUserForm.controls.email.hasError('email')">
              {{'Email format is invalid' | translate}}
            </mat-error>
        </mat-form-field>
        <button color="primary"
        mat-button mat-raised-button [disabled]="createEditAdminUserForm.invalid"
          type="submit">{{'Submit' | translate}}</button>
      </form>
    </div>
  </div>
  <div class="loading-container" *ngIf="isLoading">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  