
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {switchMap, catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';




import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { UnauthorizedError } from '../../classes/unauthorized-error.class';

const ignoreEndpoints = [
  `${environment.apiUrl}/login/user`,
  `${environment.apiUrl}/login/refreshToken`,
  `${environment.apiUrl}/login/logout`
];

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth: AuthService = this._injector.get(AuthService);
    return next.handle(request).pipe(catchError((err) => {
      // We are checking http request for errors, first we check if we have an error at http request and it's error status = '401',
      // and also we have ignore routes for, so we not need to call refreshToken method at '/login/user',
      // '/login/refreshToken`' and '/login/logout' routes.
      if (err instanceof HttpErrorResponse && err.status === 401 && ignoreEndpoints.indexOf(request.url) === -1) {
        return auth.refreshToken().pipe(
          switchMap(() => {
            return next.handle(request);
          }),catchError(() => {
            return observableThrowError(new UnauthorizedError(
              request.url,
              '401',
              'Unauthorized',
            ));
          }),);
      } else {
        return observableThrowError(err);
      }
    }));
  }
}
