import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { formatNumberToLocalLangFormat } from '../../shared/utils/number.util';


/**
 * @class DisplayDataPipe
 * @classdesc Converts number in bytes to KB or MB
 * @description Usage : {{ number | displayDataSize }} or {{ number | displayDataSize }}
 */
@Pipe({
  name: 'displayDataSize',
  pure: false
})
export class DisplayDataSizePipe implements PipeTransform, OnDestroy {
  private kbValue: number;
  private mbValue: number;
  private formatedKBValue: string;
  private formatedMBVlaue: string;

  private _translateSub: Subscription;

  constructor(private translateService: TranslateService) {
    this._translateSub = this.translateService.onLangChange.subscribe(() => {
      this.formatValue();
    });
  }

  transform(value: number): number {
    let convertedValue;
    this.kbValue = value / 1024;
    this.mbValue = value / 1024 / 1024;
    this.formatValue();

    if (this.mbValue < 1) {
      convertedValue = `${this.formatedKBValue} KB`;
    } else {
      convertedValue = `${this.formatedMBVlaue} MB`;
    }

    return convertedValue;
  }

  formatValue() {
    this.formatedKBValue = formatNumberToLocalLangFormat('de', parseFloat(this.kbValue.toFixed(2)));
    this.formatedMBVlaue = formatNumberToLocalLangFormat('de', parseFloat(this.mbValue.toFixed(2)));
  }

  ngOnDestroy() {
  }
}
