import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { forkJoin } from "rxjs";
import { AuthService } from "../../auth/services/auth.service";
import { GroupService } from "../group.service";
import { DictionaryPDFService } from "./dictionary-pdf.service";

@Component({
  selector: "pdf-dictionary",
  templateUrl: "./dictionary-pdf.component.html",
  styleUrls: ["./dictionary-pdf.component.scss"],
})
export class DictionaryPDFComponent implements OnInit {
  public config: {
    title: string;
    groupName: string;
    inputPlaceHolder: string;
  } = {
    title: "",
    groupName: "",
    inputPlaceHolder: "",
  };
  columns = ["PDF Dictionary Name"];
  data = [];
  filteredItems = [];
  filterValue = "";
  pageIndex = 0;
  totalItems = 0;
  itemsPerPage = 6;
  public pageSize = 5;
  public allSelected = false;
  public isError = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private _dialogRef: MatDialogRef<DictionaryPDFComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      groupName: string;
      inputPlaceHolder: string;
    },
    private _dictionaryService: DictionaryPDFService,
    private _groupService: GroupService
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    this._getPdfTemplates();
  }

  public toggleAll() {
    this.data = this.data.map((data) => {
      if (
        this.allSelected &&
        this.filteredItems.length &&
        !this.filteredItems.some((item) => item === data)
      ) {
        return data;
      }

      return { ...data, isSelected: this.allSelected };
    });
  }

  public handleSelected() {
    this._updateAllSelected();
  }

  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  public submit() {
    const selectedDictionaries = this.data
      .filter((dictionary) => dictionary.isSelected)
      .map((item) => item.name);

    this._dictionaryService
      .assignPDFDictionary(this.config.groupName, selectedDictionaries)
      .subscribe((res) => {
        this._dictionaryService.handleSuccess(
          "PDF Dictionary has been assigned successfully"
        );
        this._groupService.triggerDataReaload.next(true);
        this.closeDialog();
      });
    return;
  }

  public closeDialog() {
    this._dialogRef.close();
  }

  private _updateAllSelected(): void {
    if (this.data.length === 0) {
      this.allSelected = false;
    }

    this.allSelected = this.data.every((data) => data.isSelected);
  }

  private _getPdfTemplates() {
    forkJoin([
      this._dictionaryService.getPdfDictionaries(),
      this._dictionaryService.getSelectedPDFDictionaryForCompany(
        this.config.groupName
      ),
    ]).subscribe((results) => {
      const pdfDictionaries = results[0];
      const selectedPDFDictionaries = new Set(results[1]);

      this.data = pdfDictionaries.map((pdfDictionary) => ({
        name: pdfDictionary,
        isSelected: selectedPDFDictionaries.has(pdfDictionary),
      }));
    });
  }

  public handleFiltered = (filteredData) => {
    this.filteredItems = filteredData;
    this.totalItems = filteredData.length;
    this.pageIndex = 0;

    // Keep page if possible - reset to first if not
    if (this.totalItems / this.pageSize < this.pageIndex) {
      this.pageIndex = 0;
    }
  };
}
