<div class="search-dialog">
    <div mat-dialog-content class="search-user-form">
      <form [formGroup]="searchUserForm" (ngSubmit)="onSubmit()">
        <div class="form-fields" *ngIf="!data.adminUsers">
          <div class="searchText-wrap">
            <p class="label">{{ 'Display Name' | translate}}</p>
            <mat-form-field color="accent">
              <input  name="displayName" matInput formControlName="displayName"
                placeholder="{{ 'Enter the displayname...' | translate }}">
            </mat-form-field>
          </div>
        </div>
        <div class="form-fields" *ngIf="data.adminUsers">
          <div class="searchText-wrap">
            <p class="label">{{ 'First Name' | translate}}:</p>
            <mat-form-field color="accent">
              <input  name="firstName" matInput formControlName="firstName"
                placeholder="{{ 'Enter the first name...' | translate }}">
            </mat-form-field>
          </div>
        </div>
        <div class="form-fields"  *ngIf="data.adminUsers">
          <div class="searchText-wrap">
            <p class="label">{{ 'Last Name' | translate}}:</p>
            <mat-form-field color="accent">
              <input  name="lastName" matInput formControlName="lastName"
                placeholder="{{ 'Enter the last name...' | translate }}">
            </mat-form-field>
          </div>
        </div>
        <div  class="form-fields">
            <div class="searchText-wrap">
              <p class="label">{{'Account Number' | translate}}:</p>
              <mat-form-field color="accent">
                <input  name="accountNumber" matInput formControlName="accountNumber"
                  placeholder="{{ 'Enter the account number..' | translate }}">
              </mat-form-field>
            </div>
          </div>
        <div  class="form-fields">
          <div class="searchText-wrap">
            <p class="label">{{'Email' | translate}}:</p>
            <mat-form-field color="accent">
              <input  name="email"  formControlName="email"
                matInput
                placeholder="{{ 'Enter E-Mail Address...' | translate }}">
            </mat-form-field>
          </div>
        </div>
        <div  class="form-fields">
            <div class="searchText-wrap">
              <p class="label">{{'Username' | translate}}:</p>
              <mat-form-field color="accent">
                <input name="userName" matInput formControlName="userName"
                  placeholder="{{ 'Enter the username...' | translate }}">
              </mat-form-field>
            </div>
        </div>
        <div *ngIf="tenant === 'datapart'" class="form-fields">
          <div class="searchText-wrap">
            <p class="label">{{'Function' | translate}}:</p>
            <mat-form-field color="accent">
              <mat-select formControlName="role">
                <mat-option *ngFor="let role of roles" [value]="role.value">
                  {{ role.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="form-actions">
            <button type="button" mat-button (click)="onReset()">{{'resetUserSearch' | translate}}</button>
            <button color="primary" mat-button mat-raised-button type="submit" [disabled]="!searchUserForm.valid"
              >{{'searchUser' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>