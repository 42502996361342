<div class="header">
  <sv-header></sv-header>
</div>

<div class="error-container">
    <div class="error-content-parent-wrap">
      <div class="error-content-first-wrap">
        <div class="error-img">
          <img src="assets/images/maintanace.jpg" alt="errorMessages.alt">
        </div>
      </div>
      <div class="error-content-second-wrap">
        <div class="error__teaser">
          <span class="horizontal-border"></span>
          <h1 class="heading heading--fineline-inline">
            <span>{{'Maintenance work' | translate}}</span>
          </h1>

          <p class="error__teaser__text">{{'Maintenance work is currently being carried out. Supportview will be available again soon.' | translate}} </p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <sv-footer></sv-footer>
  </div>
