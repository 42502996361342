// @ts-ignore
import * as _invert from 'lodash/invert';

import { ApiResource } from '../../shared/classes/api-resource.class';
import { ActivityLogCategory } from '../enums/activity-log-category.enum';

export class ActivityLogCategoriesResource extends ApiResource {
  public categories: ActivityLogCategory[];

  constructor(_resourceObject: any) {
    super(_resourceObject);

    const enumMap = _invert({
      [ActivityLogCategory.Notification]: 'NOTIFICATION',
      [ActivityLogCategory.DeliveryDeleted]: 'DELIVERY_DELETED',
      [ActivityLogCategory.Document]: 'DOCUMENT',
      [ActivityLogCategory.Login]: 'LOGIN',
      [ActivityLogCategory.Payment]: 'PAYMENT',
      [ActivityLogCategory.Email]: 'EMAIL',
      [ActivityLogCategory.Postbox]: 'POSTBOX',
      [ActivityLogCategory.SMS]: 'SMS',
      [ActivityLogCategory.UserAccount]: 'USER_ACCOUNT',
      [ActivityLogCategory.Delivery]: 'DELIVERY',
      [ActivityLogCategory.Update]: 'UPDATE',
      [ActivityLogCategory.Delete]: 'DELETE',

    });
    this.categories = _resourceObject.categories.map((v: string) => enumMap[v]);
  }
}
