import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonRole } from '../../../../group/enum/personRole.enum';

@Pipe({
  name: 'role',
})

export class RolePipe implements PipeTransform {
constructor(private _translateService:TranslateService) {

}

transform(value: string, ...args: unknown[]): string {
  if (value === PersonRole.supporter) {
    return this._translateService.instant("Administrator")
  } else if (value === PersonRole.user) {
    return this._translateService.instant("Student")
  } else if(value === PersonRole.instructor) {
    return this._translateService.instant('User')
  }
  else {
    return value
  }
}
}