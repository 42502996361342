import { Component, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { AuthService } from "../../auth/services/auth.service";
import { DictionaryXMLService } from "./dictionary-xml.service";

@Component({
  selector: "xml-dictionary",
  templateUrl: "./dictionary-xml.component.html",
  styleUrls: ["./dictionary-xml.component.scss"],
})
export class DictionaryXMLComponent implements OnInit {
  public config: {
    title: string;
    groupName: string;
    inputPlaceHolder: string;
  } = {
    title: "",
    groupName: "",
    inputPlaceHolder: "",
  };
  columns = ["Template UID", "Template Name"];
  data = [];
  filteredItems = [];
  filterValue = "";
  pageIndex = 0;
  totalItems = 0;
  itemsPerPage = 6;
  public pageSize = 5;
  public selectedData = [];
  public allSelected = false;
  public isError = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private _dialogRef: MatDialogRef<DictionaryXMLComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      isXMLDictionary: boolean;
      groupName: string;
      inputPlaceHolder: string;
    },
    private _dictionaryService: DictionaryXMLService
  ) {
    this.config = data;
    this._getXMLTemplates();
  }

  private _selectedData = () => this.data.filter((item) => item.assigned);

  ngOnInit(): void {}

  public toggleAll() {
    this.data = this.data.map((data) => {
      if (
        this.allSelected &&
        this.filteredItems.length &&
        !this.filteredItems.some((item) => item === data)
      ) {
        return data;
      }

      return { ...data, assigned: this.allSelected };
    });

    this._validateSelection();
  }

  public handleSelected() {
    this._validateSelection();
    this._updateAllSelected();
  }

  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  public submit() {
    const newPayload = this._selectedData()
      .map((data) => ({ id: data.id, name: data.name }))
      .filter((item) => item.id !== undefined);

    this._dictionaryService
      .assignXMLDictionary(this.config.groupName, newPayload)
      .subscribe((res) => {
        this._dictionaryService.handleSuccess(
          "XML template was assigned successfully"
        );
        this.closeDialog();
      });
  }

  public closeDialog() {
    this._dialogRef.close();
  }

  private _getXMLTemplates() {
    this._dictionaryService
      .getXMLTemplatesForCompany(this.config.groupName)
      .subscribe((data: any[]) => {
        this.data = data
          .map((data, index) => {
            const { id, name, assigned } = data;

            return {
              id,
              name,
              assigned,
            };
          })
          .sort((a, b) => (b.assigned ? 1 : -1) - (a.assigned ? 1 : -1));

        this.totalItems = this.data.length;
        this._updateAllSelected();
      });
  }

  private _validateSelection() {
    const idList = this._selectedData().map((data) => data.id);
    const idListSet = new Set(idList);

    const hasDuplicates = idList.length !== idListSet.size;

    this.isError = hasDuplicates;
  }

  private _updateAllSelected(): void {
    if (this.data.length === 0) {
      this.allSelected = false;
    }

    this.allSelected = this.data.every((data) => data.assigned);
  }

  public handleFiltered = (filteredData) => {
    this.filteredItems = filteredData;
    this.totalItems = filteredData.length;
    this.pageIndex = 0;

    if (this.totalItems / this.pageSize < this.pageIndex) {
      this.pageIndex = 0;
    }
  };
}
