import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, Subscription, forkJoin } from "rxjs";
import { AuthService } from "../../../auth/services/auth.service";
import { DictionaryPDFService } from "../../../group/dictionary-pdf/dictionary-pdf.service";
import { ITempUploadsState } from "../../../group/interfaces/temp-upload-state.interface";
import { IUploadErrors } from "../../../group/interfaces/upload-errors.interface";
import { CreateDeliveryModel } from "../../classes/create-delivery.model";
import { DocumentUploadModel } from "../../classes/document-upload-model.class";
import { UploadConfigModel } from "../../classes/uploadconfig.model";
import { MultipleNotificationsService } from "../multiple-notifications/multiple-notifications.service";
import { UploadUtilityService } from "./upload-utility.service";
import { UploadViewService } from "./upload-view.service";
import { UploadService } from "./upload.service";

@Component({
  selector: "app-upload-csv-dialog",
  templateUrl: "./upload-csv-files.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadCSVDialogComponent implements OnInit, OnDestroy {
  tempUploadsState: ITempUploadsState = {
    numOfDocumentsExceeded: false,
    uploadsSuccessful: false,
    totalFilesSizeExceeded: false,
    invalidExtention: false,
  };
  private _uploadState: ITempUploadsState;
  public isValidCsv: boolean;
  // save upload notification
  @ViewChild("files") public filesForm;
  @ViewChild("fileTrigger") public fileTrigger;
  @ViewChild("salutationSelect") salutationRef: MatSelect;
  @Output() tempUploadsStateOutput = new EventEmitter<ITempUploadsState>();
  tempValidDocuments = new BehaviorSubject<DocumentUploadModel[]>([]);

  numOfDocuments = 0;
  status = [];

  public files: Set<File> = new Set();
  public iconLeft = false;
  private _uploadProcessesSub: Subscription;
  uploadConfig: UploadConfigModel;
  uploadSortSubject = new Subject<void>();
  pendingDocuments = false;
  totalFilesSize: number;
  public createDeliveryModel: CreateDeliveryModel;
  public zipContainsErrors = [];
  foreignId: any;
  public groupName = "";
  public templates = [];
  preUploadDocuments = [];
  public visible = false;
  @ViewChild("document") document: HTMLDivElement;
  isSending: boolean;
  public companyPrefixes;
  public accountNumbers;

  constructor(
    private _translateService: TranslateService,
    public authService: AuthService,
    public viewService: UploadViewService,
    public uploadService: UploadService,
    public notificationsService: MultipleNotificationsService,
    private matDialogRef: MatDialogRef<UploadCSVDialogComponent>,
    private cd: ChangeDetectorRef,
    private _dictionaryService: DictionaryPDFService,
    private _uploadUtilityService: UploadUtilityService,
    private _cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.companyPrefixes = data.companyPrefixes;
    this.viewService.triggerChangeDetection.subscribe((canTrigger) => {
      if (canTrigger) {
        this._cdRef.detectChanges();
      }
    });
    const groupId = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.tenantId;
    this._dictionaryService
      .getSelectedPDFDictionaryForCompany(groupId)
      .subscribe((templates: any[]) => {
        this.templates = templates;
      });
    this.setUploadsState();
    this.uploadConfig = new UploadConfigModel(["csv", "xlsx"]);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.createDeliveryModel.documents.forEach((document) => document.cancel());
    this.files = new Set();
    this.uploadService.documents = [];
    this.viewService.displaySendBtn = false;
    this.viewService.displayFileUpload = true;
    this.uploadService.zipContainsErrors = [];
    this.viewService.displayPdfConversionCode = false;
  }

  public selectCompanyPrefix(e) {
    this.accountNumbers = {
      accountNumber: "",
      prefix: e.value,
    };
    this.viewService.displaySendBtn = this.isValidCsv;
  }

  onFilesDrop(event) {
    this.onFilesAdded(event);
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  getDeliveryDocuments(documents: DocumentUploadModel[]) {
    if (documents) {
      this.createDeliveryModel = new CreateDeliveryModel();
      this.createDeliveryModel.documents.push(...documents);
      let filesSize = 0;
      this.totalFilesSize = filesSize;
    }
  }

  getUploadState(state: ITempUploadsState) {
    if (state) {
      this._uploadState = state;
    }
  }

  // Checking CSV files
  handleFileInput(event: any) {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const contents = e.target.result;
        const rows = contents.split("\n");

        if (rows.length > 1) {
          const headerRow = rows[0].split(";");
          const dataRows = rows.slice(1);

          const hasRequiredFields = this.containsRequiredFields(headerRow);
          const hasRequiredValues = this.containsRequiredValues(dataRows);

          if (hasRequiredFields && hasRequiredValues) {
            this.isValidCsv = true;
            this.viewService.displaySendBtn = this.accountNumbers
              ? true
              : false;
          } else {
            this.isValidCsv = false;
            this.viewService.displaySendBtn = false;
          }
        }
      };

      reader.readAsText(file);
    }
  }

  containsRequiredFields(headerRow: string[]): boolean {
    const requiredFields = [
      "givenName",
      "familyName",
      "sex",
      "accountNumber",
      "email",
    ];
    const trimmedHeaderRow = headerRow.map((field) => field.trim());
    return requiredFields.every((field) => trimmedHeaderRow.includes(field));
  }

  containsRequiredValues(dataRows: string[]): boolean {
    return dataRows.every((row) => {
      const rowValues = row.split(";");
      return rowValues.some((value) => value.trim() !== "");
    });
  }

  onFilesAdded(event) {
    let files: { [key: string]: File };
    files = event.target.files;
    if (!files.length) {
      return;
    }
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(files[key]);
      }
    }

    if (this.files.size > 0) {
      this._uploadUtilityService
        .encodeFilesToBase64(
          this._uploadUtilityService.convertSetToFiles(files)
        )
        .subscribe((encoded) => {
          this.preUploadDocuments = encoded;

          this.preUploadDocuments = encoded.map((data) =>
            this._uploadUtilityService.checkForUnsportedMediaFiles(
              data,
              this.uploadConfig.allowedFileExtensionList
            )
          );
          this.viewService.preUpload = true;
          this.viewService.hasError = this.preUploadDocuments.some(
            (document) => document.unsupportedMediaType
          );
          this.viewService.hasError &&
            (this.viewService.displaySendBtn = false);
        });
    }
  }

  uploadFiles() {
    this.uploadService.sendCSVDocuments({
      csvFile: "",
      groupId: 1,
      accountNumbers: {},
    });
    this._cdRef.detectChanges();
  }
  selectConversionCode() {
    this.uploadService.conversionCode = this.salutationRef.value;
  }

  saveTemporaryUploadedData(files) {
    if (!files) {
      return;
    }
    const newObservables = [];

    const validatedFiles = this._uploadUtilityService.validateUploadFiles(
      files,
      this.numOfDocuments,
      this.uploadConfig.allowedFileExtensionList
    );

    const statuses = this.uploadTempDeliveryFiles(
      validatedFiles.validFiles as Set<File>
    );
    validatedFiles.validFiles.forEach((file) => {
      const document = new DocumentUploadModel();
      const status = statuses.filter(
        (fileStatus) => fileStatus.file === file
      )[0];
      document.fileName = file["name"];
      document.fileSize = file["size"];
      document.watchProgress(status.progress);
      document.cancel = status.cancel;
      document.valid = true;
      document.pending = false;
      document.type = file["mimeType"];
      document.payload = file["payload"].split(",")[0];
      this.numOfDocuments += 1;

      status.request.subscribe({
        next: (response) => {
          document.finishedUpload = true;
          document.id = Math.floor(Math.random() * 1000) + 1;
          document.fileName = file["name"];
          this.setUploadsState();
        },
      });

      status.uploadError.subscribe({
        next: (response) => {
          this.numOfDocuments -= 1;
          document.valid = false;
          document.hasVirus = response.hasVirus;
          document.unsupportedMediaType = response.unsupportedMediaType;
        },
      });

      // this.uploadService.documents = []
      this.uploadService.documents.push(document);
      newObservables.push(document.uploadedPercentageSubject);
    });
    validatedFiles.invalidFiles.forEach((fileObj) => {
      const document = new DocumentUploadModel();
      document.file = fileObj.file;
      document.fileName = fileObj.file.name;
      document.fileSize = fileObj.file.size;
      document.unsupportedMediaType = fileObj.error.extendion;
      document.valid = false;
      document.pending = fileObj.error.pending;
      document.cancel = () => {};
      this.uploadService.documents.push(document);
    });
    // When all progress-observables are completed...
    // this.uploadService.documents = this.uploadService.documents.filter((value,index,self) => index === self.findIndex((t) => (t.fileName === value.fileName)))

    this._uploadProcessesSub = forkJoin(newObservables).subscribe((end) => {
      this.setUploadsState();
    });
    this.setUploadsState();
    this.uploadSortSubject.next();
  }

  setUploadsState() {
    if (
      !this.uploadService.documents ||
      this.uploadService.documents.length < 1
    ) {
      this.tempUploadsState.uploadsSuccessful = false;
      this.getUploadState(this.tempUploadsState);
      this.getDeliveryDocuments([]);

      return;
    }
    // ... the upload was successful...
    const numOfValidDocuments = this.uploadService.documents.filter((d) => {
      return d.valid;
    }).length;
    const inavlidDoscs = this.uploadService.documents.filter((d) => {
      return !d.valid;
    }).length;
    const validUploadedDocuments = this.uploadService.documents.filter((d) => {
      return d.finishedUpload;
    });
    const numOfUploadedDocuments = validUploadedDocuments.length;

    const newUploadSuccessfulState =
      numOfValidDocuments === numOfUploadedDocuments && numOfValidDocuments > 0;
    this.tempUploadsState.uploadsSuccessful =
      newUploadSuccessfulState && inavlidDoscs === 0;
    this.getUploadState(this.tempUploadsState);
    this.getDeliveryDocuments(validUploadedDocuments);
  }
  convertToMb(fileSize: number): number {
    const convertedSize = fileSize / 1024 / 1024;
    return convertedSize;
  }
  handleFileChange(event) {
    const selectedFile = event.target.files[0];
  }

  uploadTempDeliveryFiles(files: Set<File>): {
    progress: Subject<number>;
    loaded: Subject<number>;
    cancel: () => void;
    file: File;
    request: Subject<any>;
    uploadError: Subject<IUploadErrors>;
  }[] {
    this.uploadService.uploadAsyncSingleTempFile(files);
    return this.uploadService.status;
  }

  onDelete(document: DocumentUploadModel) {
    const documents = this.uploadService.documents;
    const index = documents.indexOf(document);
    const pendingDocs = documents.filter((d) => {
      return d.pending;
    });

    documents.splice(index, 1);
    document.cancel();

    if (document.finishedUpload && pendingDocs.length) {
      const pendingDocSet = new Set();
      const pendingDocToUpload = pendingDocs[0];
      // prepere first pending file for upload
      pendingDocSet.add(pendingDocToUpload.file);
      // remove pending file from displayed invalid uploads and upload it as new upload
      documents.splice(documents.indexOf(pendingDocToUpload), 1);
      this.saveTemporaryUploadedData(pendingDocSet);
    }
    for (const obj of this.files) {
      if (obj.name === document.fileName) {
        this.files.delete(obj);
        let index = this.preUploadDocuments.findIndex(
          (upload) => upload.name === document.fileName
        );
        if (index !== -1) {
          this.preUploadDocuments.splice(index, 1);
        }
      }
    }

    if (this.uploadService.documents.length === 0) {
      this.viewService.displayFileUpload = true;
      this.viewService.displaySendBtn = false;
    }
    this.delete(document.fileName);
    this.viewService.hasError = this.uploadService.documents.some(
      (doc) => doc.isInvalidDictionary || doc.isXMLTemplateInvalid
    );
    this.viewService.disablePointerEvent = this.uploadService.documents.some(
      (document) => document.isInvalidDictionary
    );

    this.setUploadsState();
    this.cd.detectChanges();
  }
  onDeletePreUpload(index: number) {
    this.preUploadDocuments.splice(index, 1);
  }
  public delete(fileName: string) {
    this.uploadService.delete(fileName, this.uploadService.foreignId);
  }

  sendDocuments() {
    this.isSending = true;
    this.uploadService
      .sendCSVDocuments({
        csvFile: this.preUploadDocuments[0].payload,
        groupId: this.data.groupId,
        accountNumbers: this.accountNumbers,
      })
      .subscribe((res: any) => {
        const messages = [];

        if (res.numberOfCreatedUsers) {
          const createdMessage =
            res.numberOfCreatedUsers > 1
              ? `${res.numberOfCreatedUsers} ${this._translateService.instant(
                  "Users were created"
                )}`
              : `${res.numberOfCreatedUsers} ${this._translateService.instant(
                  "User was created"
                )}`;
          messages.push({ message: createdMessage, isSuccess: true });
        }

        if (res.numberOfSkippedUsers) {
          const updatedMessage =
            res.numberOfSkippedUsers > 1
              ? `${res.numberOfSkippedUsers} ${this._translateService.instant(
                  "Users were skipped, because they already exist"
                )}`
              : `${res.numberOfSkippedUsers} ${this._translateService.instant(
                  "User was skipped, because he already exists"
                )}`;
          messages.push({
            message: updatedMessage,
            isSuccess: true,
            info: true,
          });
        }

        if (res.numberOfFailedImports) {
          const failedMessage = ` ${this._translateService.instant(
            "Number of failed imports"
          )}: ${res.numberOfFailedImports}`;
          messages.push({ message: failedMessage, isSuccess: false });
        }

        this.notificationsService.showNotifications(messages);
        this.isSending = false;
        this.matDialogRef.close("sendDocuments");
      }),
      (err) => {
        this.isSending = false;
      };
  }

  public closeDialog() {
    this.matDialogRef.close();
  }
}
