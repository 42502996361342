<footer class="footer-container">
  <ul>
    <!-- <li><a [ngStyle]="{'color':(auth.footerBranding | async)?.textColor}" [href]="(auth.footerBranding | async)?.copyright?.href" target="_blank">{{'Imprint' | translate}}</a></li> -->
      <ng-container *ngFor="let link of footerLinks?.links">
        <li ><a class="footer-links" target="_blank"  [href]="link?.href">{{link?.text }}</a></li> 
      </ng-container>
  </ul>
  <div class="footer-logo-container" *ngIf="tenantService.tenant === 'payslip'">
    <a href="https://www.post.at/g/c/businesssolutions" target="_blank">
      <img src="assets/images/footerlogo.png" alt="footer-logo">
    </a>
  </div>
</footer>