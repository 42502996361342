<section class="dictionary-pdf">
  <div class="dictionary-pdf__header">
    <h1>{{config.title}}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>

  <div class="dictionary-pdf__table">
    <div class="dictionary-pdf__search-wrapper">

      <div class="dictionary-pdf__search-container">
        <div class="dictionary-pdf__search-container-input">
          <input matInput autocomplete="off" [(ngModel)]="filterValue"
            placeholder="{{config.inputPlaceHolder| translate}}">
        </div>
        <div class="dictionary-pdf__search-container-icon">
          <mat-icon class="icon-style">search</mat-icon>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>
            <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleAll()">
          </th>
          <th *ngFor="let columns of columns">{{ columns }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of data | filter: filterValue: ['name']: handleFiltered | paginate: itemsPerPage : (pageIndex + 1); let i = index">
          <td>
            <input type="checkbox" [(ngModel)]="row.isSelected" (change)="handleSelected(row,i)">
          </td>
          <td>{{ row.name }}</td>
        </tr>
      </tbody>
    </table>
    <div class="additional-elements">
      <mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)"></mat-paginator>
      <p *ngIf="isError" class="error-message">
        {{ 'More than one template with the same UID is selected' | translate}}
      </p>
    </div>
  </div>
  <div class="dictionary-pdf__actions">
    <button type="button" #btn (click)="closeDialog()" [ngStyle]="{
          'color': '#FFF',
          'background-color': '#9a9a9a',
        }">
      <div class="title back">
        {{'Cancel' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon back" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-left'" style="visibility: hidden;"></mat-icon>
        </div>
      </div>
    </button>

    <button type="button" #btn (click)="submit()" [disabled]="isError" [ngStyle]="{
          'color': (authService.backgroundColors | async)?.buttonTextColor,
          'background-color': !isError ? (authService.backgroundColors | async)?.primaryButtonColor : '#0000001f',
          'pointer-events': isError ? 'none' : 'visible'

        }">
      <div class="title">
        {{'Save' | translate}}
      </div>
      <div class="button__footer">
        <div class="button__subline">

        </div>
        <div class="button__icon" [ngStyle]="{'flex-basis.px': 10}">
          <mat-icon [svgIcon]="'custom:icon-right'"></mat-icon>
        </div>
      </div>
    </button>
  </div>
</section>
