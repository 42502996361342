import { Observable, Subject } from "rxjs";

export class DocumentUploadModel {
  public id: number;
  public fileName: string;
  public fileSize: number;
  public finishedUpload = false;
  public hasVirus: boolean;
  public unsupportedMediaType: boolean;
  public isXMLTemplateInvalidForTenant: boolean;
  public fileSizeExceeded: boolean;
  public uploadedPercentage = 0;
  public uploadedPercentageSubject: Subject<number>;
  public uploadedBytes: number;
  public valid: boolean;
  public pending: boolean;
  public file: File;
  public payload: string;
  public type: string;
  public isInvalidDictionary: boolean;
  public foreignId: string;
  public sourceFileUID: string;
  public isInvalidHashValue: boolean;
  public zipHasErrors: boolean;
  public serviceUnavailable: boolean;
  /**
   * isBeingProcessed is set to true untill the /processDocuments is finished, after we set it to false.
   */
  public isBeingProcessed: boolean;
  public isXMLTemplateInvalid: boolean;
  public isSkipped: boolean;
  public invalidZipFile: boolean;
  public cancel: () => void;
  isInvalidFileType: boolean;
  invalidMassUpload: boolean;
  isWarnig: boolean;
  uploadProhibited: boolean;

  constructor() {
    this.uploadedPercentage = 0;
    this.uploadedBytes = 0;
  }

  watchProgress(uploadedPercentageSubject: Subject<number>) {
    uploadedPercentageSubject.subscribe({
      next: (newPerc) => {
        this.uploadedPercentage = newPerc;
        this.uploadedBytes = (newPerc * this.fileSize) / 100;
      },
    });
    // uploadedPercentageSubject.subscribe((newPerc) => {
    //     this.uploadedPercentage = newPerc;
    //     this.uploadedBytes = newPerc * this.fileSize / 100;
    //   }
    // );
    this.uploadedPercentageSubject = uploadedPercentageSubject;
  }
}
