import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanComponentDeactivate, CanDeactivateType } from '../auth/guards/maintanace.guard';


// import { CanComponentDeactivate } from 'auth-lib';

@Component({
  selector: 'app-maintanace-page',
  templateUrl: './custom-maintanance-page.component.html',
  styleUrls: ['./custom-maintanance-page.component.scss'],
})
export class CustomMaintanacePageComponent implements OnInit,CanComponentDeactivate {

  public authenticating = true;
  public hasOpenAddressActivation = false;
  public returnUrl: string;
  public state: any;
  public test = false
  private code = '';
  private _apiUrl = '';
  public errorMessages= {
    title: '',
    errorMessage: '',
    causesTitle: '',
    causesMessage: '',
    img: '',
    alt: '',
    code:''
  };
  displayBanner: boolean;
  bannerStart = new Date(2023, 6, 21, 16, 43); // Months are 0-indexed in JS Date
  bannerEnd = new Date(2023, 6, 21, 16, 45);

  public sharedAppClassOnDevice = {
    'mobile': ['mobile'],
    'tablet-landscape, tablet-portrait': ['tablet'],
    'laptop': ['laptop'],
    'desktop': ['desktop'],
  };
  public postToken = '';
  step = 0;
  hasUnsavedChanges = true;
  constructor(
    private _router: Router
  ) {
    this._router
  }
  canDeactivate = (): boolean => {
    // Do not allow navigation away from this component.
    return true;
  }
  ngOnInit() {

  }

  checkIsEncoded(uri) {
    uri = uri || '';
    return uri !== decodeURIComponent(uri);
  }



  loginAgain() {
    window.location.reload();
  }


} 
