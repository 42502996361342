const metadataMap = new WeakMap();

/**
 * Gets metadata from a class
 * @param classProto       Prototype of the class the metadata is on
 * @param metadataKey      Key of the requested metadata
 * @param travelProtoChain Whether to get metadata from this class only (=false)
 *                         or to get all metadata from the prototype chain (=true)
 * @returns                The requested saved metadata
 */
export function getMetadata(classProto: object, metadataKey: symbol, travelProtoChain = false) {
  let metadata, metadataObj;
  if (!travelProtoChain) {
    metadataObj = metadataMap.get(classProto);
    metadata = metadataObj && metadataObj[metadataKey];
  } else {
    metadata = [];
    while (classProto) {
      metadataObj = metadataMap.get(classProto);
      const currentProtoMetadata = metadataObj && metadataObj[metadataKey];
      if (currentProtoMetadata) {
        metadata.push(currentProtoMetadata);
      }
      classProto = Object.getPrototypeOf(classProto);
    }
  }

  return metadata;
}

/**
 * Save some metadata on a class
 * @param classProto  Prototype of the class on which to save the metadata
 * @param metadataKey Key under which to save the metadata
 * @param value       The value to save as metadata
 */
export function saveMetadata(classProto: object, metadataKey: symbol, value: any) {
  let metadataObj = metadataMap.get(classProto);
  if (!metadataObj) {
    metadataObj = {};
    metadataMap.set(classProto, metadataObj);
  }
  metadataObj[metadataKey] = value;
}
