<div class="create_edit_group_dialog_content">
  <div class="create_edit_person_dialog_content__header">
    <h1 mat-dialog-title>{{title | translate}}</h1>
    <mat-icon class="close" (click)="closeDialog()" [svgIcon]="'custom:close'"></mat-icon>
  </div>
  <div mat-dialog-content class="create_edit_group_dialog__form">
    <form [formGroup]="cereateEditPersonForm" (ngSubmit)="submit()">
      <mat-form-field color="primary">
        <mat-label>{{'first-name' | translate}}*</mat-label>
        <input matInput autocomplete="off" formControlName="firstName" placeholder="{{'first-name' | translate}}*">
          <mat-error *ngIf="cereateEditPersonForm.controls.firstName.touched && cereateEditPersonForm.controls.firstName.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'last-name' | translate}}*</mat-label>
        <input matInput autocomplete="off" formControlName="lastName" placeholder="{{'last-name' | translate}}*">
          <mat-error *ngIf="cereateEditPersonForm.controls.lastName.touched && cereateEditPersonForm.controls.lastName.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'Email' | translate}}*</mat-label>
        <input matInput autocomplete="off" formControlName="email" placeholder="Email*">
          <mat-error *ngIf="cereateEditPersonForm.controls.email.touched && cereateEditPersonForm.controls.email.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.email.touched && cereateEditPersonForm.controls.email.invalid">
            {{'email-format' | translate}}
          </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'mobile-number' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="mobileNumber"
          placeholder="{{'mobile-number' | translate}}">
          <mat-error *ngIf="cereateEditPersonForm.controls.mobileNumber.touched && cereateEditPersonForm.controls.mobileNumber.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error *ngIf="cereateEditPersonForm.controls.mobileNumber.touched && !cereateEditPersonForm.controls.mobileNumber.hasError('required') &&
            cereateEditPersonForm.controls.mobileNumber.hasError('pattern')">
            {{'Phone number should be in +431233 format' | translate}}
          </mat-error>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'prefixed-title' | translate}}</mat-label>
        <input matInput autocomplete="off" formControlName="prefixedTitle"
          placeholder="{{'prefixed-title' | translate}}">
        <!-- <mat-error *ngIf="cereateEditPersonForm.controls.prefixedTitle.touched">
          <mat-error *ngIf="cereateEditPersonForm.controls.prefixedTitle.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
        </mat-error> -->
      </mat-form-field>
      <!-- <mat-form-field color="primary">
        <input matInput autocomplete="off" formControlName="accountNumber"
          placeholder="{{'Account Number' | translate}}*">
        <mat-error *ngIf="cereateEditPersonForm.controls.accountNumber.touched">
          <mat-error *ngIf="cereateEditPersonForm.controls.accountNumber.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
          <mat-error *ngIf="!cereateEditPersonForm.controls.accountNumber.hasError('required') &&
            cereateEditPersonForm.controls.accountNumber.hasError('pattern')">
            {{'Exactly 6 digits required' | translate}}
          </mat-error>
          <mat-error *ngIf="!cereateEditPersonForm.controls.accountNumber.hasError('required') &&
          cereateEditPersonForm.controls.accountNumber.hasError('notUnique')">
            {{'Unique id required' | translate}}
          </mat-error>
        </mat-error>
      </mat-form-field> -->
      <mat-form-field color="primary" >
        <mat-label>{{'Function' | translate}}*</mat-label>
        <mat-select formControlName="accountType" (selectionChange)="selectRole($event)">
          <mat-option value="BERT_GROUP_SUPPORT">{{ 'Adiministrator' | translate }}</mat-option>
          <mat-option value="BERT_GROUP_PRINCIPAL">{{ 'User' | translate }}</mat-option>
          <mat-option *ngIf = "accountType === 'BERT_USER'" value="BERT_USER">{{ 'Student' | translate }}</mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field color="primary" *ngIf="displayGroupsToAddSupporter">
        <mat-label>{{'Groups' | translate}}</mat-label>
        <mat-select [formControl]="cereateEditPersonForm.get('groupsForSupporter')" multiple>
          <mat-option *ngFor="let group of groupsForSupporter" [value]="group.id">{{ group.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="cereateEditPersonForm?.get('groupsForSupporter')?.hasError('required')">
          {{'Required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="groupsForSupporter.length > 0" color="primary">
        <mat-label>{{'Activate' | translate}}</mat-label>
        <mat-select [formControl]="cereateEditPersonForm.get('activate')">
          <mat-option [value]="cereateEditPersonForm.controls.activate.value">{{ messageActivate | translate}}</mat-option>
          <mat-option [value]="!cereateEditPersonForm.controls.activate.value">{{messageDeactivate | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="primary">
        <mat-label>{{'salutation' | translate}}*</mat-label>
        <mat-select formControlName="salutation">
          <mat-option value="MALE">{{ 'male' | translate }}</mat-option>
          <mat-option value="FEMALE">{{'female' | translate}}</mat-option>
        </mat-select>
          <mat-error *ngIf="cereateEditPersonForm.controls.salutation.touched && cereateEditPersonForm.controls.salutation.hasError('required')">
            {{'Required' | translate}}
          </mat-error>
      </mat-form-field>
      <button color="primary"
      mat-button mat-raised-button  [disabled]="!cereateEditPersonForm.valid"
        type="submit">{{'Submit' | translate}}</button>
    </form>
  </div>
</div>
<div class="loading-container" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
