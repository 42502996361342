import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { PersonRole } from "../group/enum/personRole.enum";
import { UserPermissionService } from "../shared/services/user-permission/user-permission.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard {
  constructor(private _userPermissionService: UserPermissionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAdmin = this._userPermissionService.hasRight(PersonRole.admin);

    if (isAdmin) {
      return true;
    }
    return false;
  }
}
