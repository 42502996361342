import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";

@Component({
  selector: "sv-export-accounting-csv-dialog",
  templateUrl: "./export-accounting-csv-dialog.component.html",
  styleUrls: ["./export-accounting-csv-dialog.component.scss"],
})
export class ExportAccountingCsvDialogComponent implements OnInit {
  public exportAccountingForm: UntypedFormGroup;
  public months: string[] = [];
  public types = ["Block Pricing", "Direct Accounting"];
  public monthMap;
  public isLoading: boolean;
  @ViewChild("field1") myFormField: ElementRef;
  constructor(
    private httpClient: HttpClient,
    private matDialogRef: MatDialogRef<ExportAccountingCsvDialogComponent>,
    public authService: AuthService,
    ) {
    this.monthMap = {
      January: "Januar",
      February: "Februar",
      March: "März",
      April: "April",
      May: "Mai",
      June: "Juni",
      July: "Juli",
      August: "August",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "Dezember",
    };

    const userData = this.authService.getUserData();

    this.exportAccountingForm = new UntypedFormGroup({
      month: new UntypedFormControl("", Validators.required),
      type: new UntypedFormControl("", Validators.required),
    });

    const currentTime = new Date();
    currentTime.setMonth(currentTime.getMonth() - 1);
    for (let i = 0; i < 3; i++) {
      const monthNameEn = currentTime.toLocaleString("en-US", {
        month: "long",
      });
      const monthNameDe = currentTime.toLocaleString("de-DE", {
        month: "long",
      });
      this.months.unshift(userData.userLanguage === "de" ? monthNameDe : monthNameEn);
      currentTime.setMonth(currentTime.getMonth() - 1);
    }
  }

  ngOnInit(): void {}

  public closeDialog() {
    this.matDialogRef.close();
  }

  mapMonthToGerman(monthEn: string): string {
    
    return this.monthMap[monthEn];
  }

  mapTypeToGerman(monthEn: string): string {
    const monthMap = {
      ["Block Pricing"]: "Staffel",
      ["Direct Accounting"]: "Direktverrechnung"
    };
    return monthMap[monthEn];
  }

  getEnglishMonthFromGerman(germanMonth) {
    for (const [english, german] of Object.entries(this.monthMap)) {
      if (german === germanMonth) {
        return english;
      }
    }
  }

  onSubmit() {
    const userData = this.authService.getUserData();
    const selectedMonth = userData.userLanguage === "en" ?  this.exportAccountingForm.value.month : this.getEnglishMonthFromGerman(this.exportAccountingForm.value.month)
  
    const url = `${environment.apiUrl}/statistics/csvExport/${selectedMonth}/type/${this.mapTypeToGerman(this.exportAccountingForm.value.type)}`;
  
    if (this.exportAccountingForm.valid) {
      this.isLoading = true
      this.httpClient
        .get(url, { observe: "response", responseType: "text" })
        .subscribe((response) => {
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          let filename = "exported-data.csv";
  
          if (contentDisposition) {
            const matches = /filename=([^;]+)/.exec(contentDisposition);
            if (matches && matches[1]) {
              filename = matches[1].replace(/"/g, '').trim();
            }
          }

          const blob = new Blob([response.body], { type: "text/csv" });
          const dataUrl = URL.createObjectURL(blob);
  
          const a = document.createElement("a");
          a.href = dataUrl;
          a.download = filename;
          a.style.display = "none";
  
          document.body.appendChild(a);
          a.click();
  
          document.body.removeChild(a);
          URL.revokeObjectURL(dataUrl);
  
          this.matDialogRef.close();
          this.isLoading = false
        });
    } else {
      Object.values(this.exportAccountingForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }
}
