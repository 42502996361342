<h2 mat-dialog-title>{{ 'Password reset' | translate}}</h2>
<button mat-icon-button mat-dialog-close>
  <mat-icon [matTooltip]="closeButtonLabel" matTooltipPosition="right" aria-label="close">close</mat-icon>
</button>
<mat-dialog-content gray-color-label>
  <h4 mat-dialog-query>{{ 'To which address do you want to send the reset e-mail?' | translate}}</h4>
  <span>{{'Email' | translate}}:</span>
  <mat-form-field color="accent">
    <mat-select [(ngModel)]="resetEmail">
      <mat-option *ngFor="let option of data" value="{{option.email}}">{{option.email}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="confirm-buttons">
    <button mat-button gray-button (click)="onNoClick()"><span>{{ 'cancel' | translate | uppercase}}</span></button>
    <button mat-button color="accent"
      [mat-dialog-close]="resetEmail"><span>{{ 'send' | translate | uppercase }}</span></button>
  </div>
</mat-dialog-content>