import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import {
  ISelectedRow,
  TableComponentActionList,
} from "../../design/components/table/table.component";
import { UploadDialogComponent } from "../../shared/components/upload/upload-dialog.component";
import { UserPermissionService } from "../../shared/services/user-permission/user-permission.service";
import { DeleteDialogComponent } from "../../users/components/delete-dialog/delete-dialog.component";
import { DictionaryPDFComponent } from "../dictionary-pdf/dictionary-pdf.component";
import { DictionaryXMLComponent } from "../dictionary-xml/dictionary-xml.component";
import { PersonRole } from "../enum/personRole.enum";
import { ExportAccountingCsvDialogComponent } from "../export-accounting-csv-dialog/export-accounting-csv-dialog.component";
import { GroupService, RESET_FILTER } from "../group.service";
import { IGroup, IGroupResponseData } from "../interfaces/group.interface";
import { Pagination } from "../interfaces/pagination.interface";
import { SearchGroupDialogComponent } from "../search-group-dialog/search-group-dialog.component";
import { TenantService } from "../tenantService/tenant.service";
import { xUserService } from "../xUserService/xUser.service";

@Component({
  selector: "sv-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent implements OnInit, AfterViewInit {
  public paginationAndSortConfig: Pagination = {
    page: 0,
    pageSize: 10,
    sort: { ascOrDesc: "asc", field: "name" },
  };

  public groups$: Observable<IGroupResponseData>;
  public pageSize = 10;
  public sort = "-sort";
  public selectedRows: ISelectedRow[] = [];
  public selectGroup: IGroup;
  public isMasterSupporter: boolean;
  public paginationColor = "";
  public isDataSubmiter: boolean;
  public deliverySubmiter: boolean;
  public tenant: string;
  public isLoadingDataPage: boolean = false;
  public groupTableColumns = this._tenantService.configureTableColumnsGroups();
  public displayName: string;
  public accountNumber: string;
  public accountNumberUser: any;
  public actions: Record<string, TableComponentActionList<IGroup>>;
  public isAdministrator: boolean;
  public isAdmin: boolean;
  public hasMembersIds$: Observable<boolean>;
  constructor(
    private _translateService: TranslateService,
    public _groupService: GroupService,
    private _router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private renderer: Renderer2,
    private _tenantService: TenantService,
    private _xUserService: xUserService,
    private _userPermissionService: UserPermissionService
  ) {
    this.tenant = JSON.parse(window.localStorage.getItem("tenant"));
    this._groupService.triggerDataReaload.subscribe((reload) => {
      if (!reload) return;
      // this.groups$ = this._groupService.getGroups({page:0,pageSize: 10,sort: {ascOrDesc: 'asc', field:'name'}},{accountNumber:"",displayName:"",groupDescription: ''});
      this.groups$ = this.fetchGroups(
        { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
        { accountNumber: "", displayName: "", groupDescription: "" }
      );
    });

    this._translateService.onLangChange.subscribe(() => {
      this.onTranslateChange();
    });

    this.isMasterSupporter = this._userPermissionService.hasRight(
      PersonRole.bertSupporter
    );

    this.deliverySubmiter = this._userPermissionService.hasRight(
      PersonRole.deliverySubmitter
    );

    this.isAdministrator = this._userPermissionService.hasRight(
      PersonRole.administrator
    );

    this.isAdmin = this._userPermissionService.hasRight(PersonRole.admin);

    const rights = this._userPermissionService.getAllRights();

    this.displayName = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )._resourceObject.displayName;

    this.accountNumber = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber?.substring(0, 3);
    this.accountNumberUser = JSON.parse(
      sessionStorage.getItem("sv-user-data")
    )?.accountNumber;

    this.actions = {
      payslip: [
        {
          edit: {
            name: "Edit",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: row.name,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "edit",
          },
          assignXmlTemplate: {
            name: "Assign XML Template",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: PersonRole.admin,
                tentnatId: row.name,
              });

              return hasRights;
            },
            onClick: "addDictionaryXML",
          },
          assignPDFDictionary: {
            name: "Assign PDF Dictionary",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: PersonRole.admin,
                tentnatId: row.name,
              });

              return hasRights;
            },
            onClick: "addDictionaryPDF",
          },
          adminUser: {
            name: "Admin User",
            showIf: (row: any) => {
              const hasRights = this._userPermissionService.hasRightForTenant({
                right: [PersonRole.admin, PersonRole.administrator],
                tentnatId: row.name,
              });

              return hasRights && row.username !== this.displayName;
            },
            onClick: "xUser",
          },
          user: {
            name: "view-person",
            showIf: (row: any) =>
              this._userPermissionService.hasRightForTenant({
                right: [
                  PersonRole.admin,
                  PersonRole.administrator,
                  PersonRole.deliverySubmitter,
                  PersonRole.userAdministrator,
                  PersonRole.eboxViewer,
                ],
                tentnatId: row.name,
              }),
            onClick: "viewPerson",
          },
        },
      ],
      datapart: [
        {
          edit: {
            name: "edit",
            showIf: () =>
              rights.some((elem) =>
                [
                  PersonRole.admin,
                  PersonRole.administrator,
                  PersonRole.bertSupporter,
                ].includes(elem)
              ),
            onClick: "edit",
          },
          delete: {
            name: "delete",
            showIf: (row: any) =>
              !(
                rights.includes(PersonRole.supporter) && row.role === "Student"
              ) &&
              !(rights.includes(PersonRole.supporter) && row.externalId) &&
              row.accountNumber !== this.accountNumber &&
              row.accountNumber !== this.accountNumberUser,
            onClick: "delete",
          },
          user: {
            name: "User",
            showIf: () => true,
            onClick: "viewPerson",
          },
        },
      ],
    };
  }
  ngAfterViewInit(): void {
    const elements = document.querySelectorAll(
      ".mat-paginator-page-size-label, .mat-paginator-range-label"
    );
    const itemsPerPage = document.querySelectorAll(
      ".mat-paginator-page-size-value"
    );
    const icons = document.querySelectorAll(".mat-paginator-icon");
    const paginationColor = JSON.parse(localStorage.getItem("paginationColor"));
    // icons.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // itemsPerPage.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
    // elements.forEach((element) => {
    //   this.renderer.setStyle(element, 'color', paginationColor);
    // });
  }

  ngOnInit(): void {
    this.groups$ = this.fetchGroups(
      { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
      {
        accountNumber: "",
        displayName: this._groupService.filterValues.displayName || "",
        groupDescription: "",
      }
    );
    this.hasMembersIds$ = this.groups$.pipe(
      map((groups) =>
        groups.content.some((content) => content.membersIds.length > 0)
      )
    );
  }
  fetchGroups(
    paginationAndSortConfig,
    filterValues
  ): Observable<IGroupResponseData> {
    this.isLoadingDataPage = true;

    return this._groupService
      .getGroups(paginationAndSortConfig, filterValues)
      .pipe(
        tap(() => (this.isLoadingDataPage = false)),
        catchError((error) => {
          this.isLoadingDataPage = false;
          throw error;
        })
      );
  }

  openExportCsvDialog() {
    this.dialog.open(ExportAccountingCsvDialogComponent, {
      disableClose: true,
      autoFocus: false,
    });
  }

  openUploadDialog(isBroadcastDelivery: boolean = false) {
    let isActive;
    let groupsContent;
    this.groups$.subscribe((data: any) => {
      groupsContent = data.content;
    });
    isActive = !groupsContent.every(
      (groupContent) => !groupContent.additionalData.eGehaltszettelEnabled
    );

    if (isActive) {
      const config = new MatDialogConfig();
      config.disableClose = true;
      config.autoFocus = false;
      config.data = { 
        isBroadcastDelivery: isBroadcastDelivery,
        title: isBroadcastDelivery ? 'Mass Upload' : 'Upload Document',
        subtitle: isBroadcastDelivery ? 'Mass Upload Info' : 'Upload Documents Info',
        buttonText: isBroadcastDelivery ? 'Mass Upload' : 'Upload Personal Document',
      };
      const dialogRef = this.dialog.open(UploadDialogComponent, config);
      dialogRef.afterClosed().subscribe((reason: any) => {
        if (reason === "sendDocuments") {
          setTimeout(() => {
            this.groups$ = this.fetchGroups(
              this.paginationAndSortConfig,
              this._groupService.filterValues
            );
            this.hasMembersIds$ = this.groups$.pipe(
              map((groups) =>
                groups.content.some((content) => content.membersIds.length > 0)
              )
            );
          }, 1000);
        }
      });
    } else {
      this._groupService.statusMessage(
        "snack-bar-fail",
        this._translateService.instant(
          "This company is disabled, please contact support"
        )
      );
    }
  }
  private onTranslateChange() {
    // Translate column title properties in a programatical way
    this.groups$ = this.fetchGroups(
      { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
      { accountNumber: "", displayName: "", groupDescription: "" }
    );

    const tableColumTranslations =
      this._tenantService.translationsForTableColumnsGroups();
    this.groupTableColumns?.map((tableCol) => {
      tableCol.title = this._translateService.instant(
        tableColumTranslations[tableCol.field]
      );
    });
  }

  public createGroup(): void {
    this._groupService.temporarilySavedTenantLogos = [];
    this.openCreateEditDialog(true);
  }

  editGroup(groupData) {
    this.openCreateEditDialog(false);
    this._tenantService.passDataToDialog(groupData);
  }

  public handleRowClick(eventData) {
    const hasRight = this._userPermissionService.hasRightForTenant({
      right: [PersonRole.admin, PersonRole.administrator],
      tentnatId: eventData.displayName,
    });
    if (hasRight) {
      this.editGroup(eventData);
    }
  }

  public action(event: { tableData: IGroup; action: string }): void {
    this.selectGroup = event.tableData;
    if (event.action === "edit") {
      this.editGroup(this.selectGroup);
    } else if (event.action === "viewPerson") {
      this._router.navigate([`dashboard/person/${this.selectGroup.id}`]);
    } else if (event.action === "delete") {
      this.openDeleteDialog();
    } else if (event.action === "addDictionaryXML") {
      this._openDictionaryDialogXML();
    } else if (event.action === "addDictionaryPDF") {
      this._openDictionaryDialogPDF();
    } else if (event.action === "xUser") {
      this._router.navigate([
        `dashboard/xUser/${this.selectGroup.displayName}`,
      ]);
    }
  }

  private _openDictionaryDialogXML() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "add-xml-dictionary";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "900px";
    _dialogConfig.height = "700px";
    _dialogConfig.data = {
      title: this._translateService.instant("Companies - Assign XML Template"),
      groupName: this.selectGroup.displayName,
      inputPlaceHolder: "Search for XML template",
    };
    this.dialog.open(DictionaryXMLComponent, _dialogConfig);
  }
  private _openDictionaryDialogPDF() {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "add-pdf-dictionary";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "900px";
    _dialogConfig.height = "700px";
    _dialogConfig.data = {
      title: this._translateService.instant(
        "Companies - Assign PDF Dictionary"
      ),
      groupName: this.selectGroup.displayName,
      inputPlaceHolder: "Search for PDF dictionary",
    };
    this.dialog.open(DictionaryPDFComponent, _dialogConfig);
  }
  public getPageIndex(): number {
    return this.paginationAndSortConfig.page - 1;
  }

  public changePage(event: PageEvent): void {
    this.paginationAndSortConfig.page = event.pageIndex;
    this.groups$ = this.fetchGroups(
      this.paginationAndSortConfig,
      this._groupService.filterValues
    );
  }
  public sortEvent(event: string): void {
    const isAsc = /^-/.test(event) ? "asc" : "desc";
    let propertyName = event.replace("-", "");
    console.log("propertyName",propertyName)
    /**
     * since there is no backend implementation for sorting this two fields we are doing it on front-end
     */
    if (
      propertyName === "numberOfMembers" 
    ) {
      propertyName = 'membersIds'
    }
    if (propertyName === "numberOfSupporters") {
      propertyName = 'groupSupporterIds'
      
    }
    this.paginationAndSortConfig.sort.field = propertyName;
    this.paginationAndSortConfig.sort.ascOrDesc = isAsc;
    this.groups$ = this.fetchGroups(
      this.paginationAndSortConfig,
      this._groupService.filterValues
    );
  }

  public openCreateEditDialog(isCreate: boolean): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "edit-create-group-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.maxWidth = "700px";
    _dialogConfig.minWidth = "700px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: isCreate
        ? this._tenantService.tenant === "datapart"
          ? this._translateService.instant("Create Group")
          : this._translateService.instant("Create Company")
        : this._tenantService.tenant === "datapart"
        ? this._translateService.instant("Edit Group")
        : this._translateService.instant("Edit Company"),
    };
    const dialogRef = this.dialog.open(
      this._tenantService.openGroupDialog(),
      _dialogConfig
    );
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason === "close") return;
      this.groups$ = this.fetchGroups(
        this.paginationAndSortConfig,
        RESET_FILTER
      );
    });
  }

  public openDeleteDialog(): void {
    const _dialogConfig = new MatDialogConfig<any>();
    _dialogConfig.disableClose = true;
    _dialogConfig.panelClass = "delete-groups-dialog";
    _dialogConfig.autoFocus = true;
    _dialogConfig.width = "600px";
    _dialogConfig.height = "auto";
    _dialogConfig.data = {
      title: this._translateService.instant(
        "Are you sure you want to delete group?"
      ),
    };
    const dialogRef = this.dialog.open(DeleteDialogComponent, _dialogConfig);
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason === undefined || reason === "") return;
      this.deleteGroup();
    });
  }

  public deleteGroup(): void {
    this._groupService.deleteGroup(this.selectGroup.id).subscribe(() => {
      this._groupService.statusMessage(
        "snack-bar-success",
        "Group deleted succesfully"
      );
      this.groups$ = this.fetchGroups(
        this.paginationAndSortConfig,
        RESET_FILTER
      );
    });
  }
  public openSearchGroupDialog(): void {
    const position = document.getElementById("searchButton");
    const topPosition =
      position.getBoundingClientRect().top + window.pageYOffset;
    const dialogRef = this.dialog.open(SearchGroupDialogComponent, {
      width: "474px",
      autoFocus: false,
      position: {
        top: topPosition + position.scrollHeight + "px",
        right: "42px",
      },
      disableClose: true,
      // data: this.filterValues || new UserSearchModel(),
    });
    dialogRef.afterClosed().subscribe((fiterValues) => {
      if (!fiterValues) {
        this.groups$ = this.fetchGroups(
          { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
          RESET_FILTER
        );
        // this._groupService.filterValues = {accountNumber:'',displayName:'',groupDescription:''};
        return;
      }
      this.groups$ = this.fetchGroups(
        { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
        fiterValues
      );
    });
  }
  filterValueChange(event) {
    this.groups$ = this.fetchGroups(
      { page: 0, pageSize: 10, sort: { ascOrDesc: "asc", field: "name" } },
      this._groupService.filterValues
    );
  }
  clearSearch() {}
}
