import { Moment } from 'moment';

import { ApiResource, ApiResourceValue, ApiResourceMap, ApiResourceDateValue } from '../../shared/classes/api-resource.class';
import { UserStatus } from '../enums/user-status.enum';

export class UserResource extends ApiResource {
  @ApiResourceValue() id: string;
  @ApiResourceValue() displayName: string;
  @ApiResourceValue() userName: string;
  @ApiResourceValue() email: string;
  @ApiResourceValue() accountNumber: string;
  @ApiResourceMap({
    'OPEN': UserStatus.Open,
    'NOT_OPEN': UserStatus.NotOpen,
    'DEACTIVATED': UserStatus.Deactivated,
    'CLOSED': UserStatus.Closed,
    'DISABLED': UserStatus.Disabled,
    'INACTIVE': UserStatus.Inactive,
    'UNCONFIRMED': UserStatus.Unconfirmed
  }) status: UserStatus;
  @ApiResourceValue() inboxWithoutEmail: boolean;
  @ApiResourceValue() optIn: boolean;
  @ApiResourceValue() corporateCustomer: boolean;
  @ApiResourceValue() employee: boolean;
  @ApiResourceValue() unreadDocuments: number;
  @ApiResourceDateValue() deletedOn: Moment;
  public deletedOnDisplay: string;
}
